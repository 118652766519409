<div class="container d-flex flex-column align-items-center mt-5">
    <h1 class="text-center">The page you are looking for doesn't exist</h1>
    <div class="row">
        <div class="col-6 offset-3">
            <div class="text-center">This is unfortunate. You could start over from <a href="/">here</a>.</div>
            <div class="text-center">If this page appears again please let us know at assistance@k-invent.com</div>
        </div>
        <a href="/">
            <app-image [image]="art" [lazily]="false"></app-image>
        </a>
    </div>
</div>
