import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../components/base/base.component";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends BaseComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.analyticsSwitchPage('Profile', undefined, 'profile');
    }
}
