import {Component, Input, OnInit} from '@angular/core';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {BodySideType} from '../../../models/bodySideType';
import {ExerciseType} from '../../../models/exerciseType';
import {JumpAnalysisActivityResultsModel} from '../../../dtos/statistics/jumpAnalysisActivityResultsModel';
import {DsiScoreCategory} from '../../../dtos/DsiScoreCategory';
import {Color} from "common";
import {DynamicStrengthIndexSummaryResultsModelDto} from '../../../dtos/statistics/dynamicStrengthIndexSummaryResultsModelDto';
import {Protocols} from '../../../utils/protocols';
import {BaseComponent} from "../../base/base.component";
import {Colors} from "../../../services/colors";
import {UnitType} from "../../../utils/values/unitType";
import {Value} from "../../../utils/values/value";
import {Percent} from "../../../utils/values/percent";
import {Kg} from "../../../utils/values/kg";

@Component({
    selector: 'app-protocol-summary-dsi-results',
    templateUrl: './protocol-summary-dsi-results.component.html',
    styleUrls: ['./protocol-summary-dsi-results.component.scss']
})

export class ProtocolSummaryDsiResultsComponent extends BaseComponent implements OnInit {
    public readonly colors = Colors;
    public readonly dsiCategory = DsiScoreCategory;

    @Input()
    public protocol: ProtocolDto;

    @Input()
    public analysis: ProtocolAnalysisServiceResponseDto;

    public pieValuesUnitType: UnitType;
    public activitiesTitles: string[];

    public jumpTotalForceFormated: string;
    public imptTotalForceFormated: string;

    public dsiScore: string;

    public dsi: Value | null;
    public dsiPercent: Percent | null;

    public maxPerSideImtp: Map<BodySideType, Value>;
    public maxPerSideJump: Map<BodySideType, Value>;

    constructor() {
        super();
        this.pieValuesUnitType = UnitType.NEWTON;
        this.maxPerSideImtp = new Map<BodySideType, Value>();
        this.maxPerSideJump = new Map<BodySideType, Value>();
    }

    ngOnInit(): void {
        // TODO support IMTP
        const imtpActivity = Protocols.findResultPerActivityExerciseType(this.analysis, ExerciseType.TOTAL_EVALUATION);
        const jumpActivity = Protocols.findResultPerActivityExerciseType(this.analysis, ExerciseType.JUMP_ANALYSIS) as JumpAnalysisActivityResultsModel;
        console.log(jumpActivity);
        this.activitiesTitles = [];
        this.activitiesTitles.push('CMJ');
        this.activitiesTitles.push('IMTP');

        const maxJump = jumpActivity ? jumpActivity.jump : undefined;
        this.maxPerSideJump.set(BodySideType.LEFT, new Kg(maxJump && maxJump.leftForceInMaxTotal ? maxJump.leftForceInMaxTotal : 0));
        this.maxPerSideJump.set(BodySideType.RIGHT, new Kg(maxJump && maxJump.maxForce && maxJump.leftForceInMaxTotal ? maxJump.maxForce - maxJump.leftForceInMaxTotal : 0));
        const jumpForce = new Kg(this.maxPerSideJump.get(BodySideType.LEFT).value + this.maxPerSideJump.get(BodySideType.RIGHT).value);
        this.jumpTotalForceFormated = jumpForce.formatTo(this.pieValuesUnitType, 1);

        this.maxPerSideImtp.set(BodySideType.LEFT, new Kg(0));
        this.maxPerSideImtp.set(BodySideType.RIGHT, new Kg(0));
        const imptForce = new Kg(0);
        this.imptTotalForceFormated = imptForce.formatTo(this.pieValuesUnitType, 1);

        const summaryResults = this.analysis.protocolsResults[0].protocolSummaryResultsModel as DynamicStrengthIndexSummaryResultsModelDto;

        this.dsi = summaryResults.dsi !== null ? new Value(summaryResults.dsi) : null;
        if (this.dsi !== null) {
            this.dsiPercent = new Percent(this.dsi.value * 100);
            this.dsiScore = `${this.dsi.format(2)} ${this.formatValue(this.dsiPercent, 0)}`;
        } else {
            this.dsiScore = this.text('invalid');
        }

    }

    public getScoreBackgroundColor(category: DsiScoreCategory): Color {
        if (this.dsi === null || isNaN(this.dsi.value)) {
            return undefined;
        }
        const dsi = this.dsi.value;
        if ((category === DsiScoreCategory.LOW && dsi < 0.6) ||
            (category === DsiScoreCategory.MODERATE && dsi >= 0.6 && dsi <= 0.8) ||
            (category === DsiScoreCategory.HIGH && dsi > 0.8)) {
            return Colors.GREEN;
        }
        return undefined;
    }

    public getScoreColor(category: DsiScoreCategory): Color {
        if (this.dsi === null || isNaN(this.dsi.value)) {
            return undefined;
        }
        const dsi = this.dsi.value;
        if ((category === DsiScoreCategory.LOW && dsi < 0.6) ||
            (category === DsiScoreCategory.MODERATE && dsi >= 0.6 && dsi <= 0.8) ||
            (category === DsiScoreCategory.HIGH && dsi > 0.8)) {
            return Colors.WHITE;
        }
        return undefined;
    }
}
