import {ParticipantMetaDto} from './participantMetaDto';
import {ParticipantMetaKeys} from '../models/participantMetaKeys';
import {BaseDto, Code, Gender, GenderUtils} from "common";
import {ParticipantTagDto} from "./participantTag.dto";

export class ParticipantDto extends BaseDto {

    firstName: string;
    lastName: string;
    userCode: Code;
    age: number;
    dateOfBirth: number;
    gender: string;
    dateConsentToKeepData: number;
    dateConsentToAnonymousAnalytics: number;
    photo: string;
    email: string;
    khomeAcceptedOn: number;
    height: number;
    metaFields: ParticipantMetaDto[];
    tags: ParticipantTagDto[];

    public deserialize(input: any): this {
        Object.assign(this, input);

        this.metaFields = this.deserializeArray(input.metaFields, ParticipantMetaDto);
        this.tags = this.deserializeArray(input.tags, ParticipantTagDto);

        return this;
    }

    public get fullName(): string {
        return `${this.lastName} ${this.firstName}`;
    }

    public get genderType(): Gender {
        return GenderUtils.parse(this.gender);
    }

    public getMeta(key: string): ParticipantMetaDto {
        if (!this.metaFields) {
            return undefined;
        }
        return this.metaFields.find(metaField => metaField.metaKey &&
            metaField.metaKey.toLowerCase() === key.toLowerCase());
    }

    public getMetaString(key: string): string {
        let metaField: ParticipantMetaDto = this.getMeta(key);
        if (metaField !== undefined && metaField.metaValue !== undefined) {
            return metaField.metaValue;
        } else {
            return '';
        }
    }

    public getFavoriteActivityConfigCodes(): Array<string> {
        return this.getMetaString(ParticipantMetaKeys.FAVORITE_ACTIVITY_CONFIGS).split(',');
    }

    public getFavoriteProtocolConfigCodes(): Array<string> {
        return this.getMetaString(ParticipantMetaKeys.FAVORITE_PROTOCOLS).split(',');
    }
}
