import {BalanceDistributionConfigViewModel} from "./balanceDistributionConfigViewModel";
import {SquatCycle} from "./squatCycle";
import {ActivityResultsModel} from "./activityResultsModel";
import {StanceEvaluationActivityResultsModel} from "./stanceEvaluationActivityResultsModel";

export class DynamicDistributionEvaluationResultsModel extends ActivityResultsModel {
    public maxLeftDistribution: number; // [0-100]
    public minLeftDistribution: number; // [0-100]
    public avgLeftDistribution: number; // [0-100]
    public avgRightDistribution: number; // [0-100]
    public avgRightDistributionPercent: number; // [0, 1]
    public maxRightDistribution: number; // [0-100]
    public minRightDistribution: number; // [0-100]

    public totalMaxForce: number;
    public leftMaxForce: number;
    public rightMaxForce: number;

    public syncedTimestamps: number[];
    public syncedForcesLeft: number[];
    public syncedSumForces: number[];
    public syncedForcesRight: number[];

    public balanceDistributionConfig: BalanceDistributionConfigViewModel;
    public squats: SquatCycle[];
    public duration: number; // sec
    public pace: number; // reps/min
    public standardDeviation: number;

    public smallLoadsLeftLimbRatio: number; // [0, 1]
    public smallLoadsRightLimbRatio: number; // [0, 1]
    public normalLoadsLeftLimbRatio: number; // [0, 1]
    public normalLoadsRightLimbRatio: number; // [0, 1]
    public bigLoadsLeftLimbRatio: number; // [0, 1]
    public bigLoadsRightLimbRatio: number; // [0, 1]
    public lowIntensityLeftDistribution: number; // [0-100]
    public mediumIntensityLeftDistribution: number; // [0-100]
    public highIntensityLeftDistribution: number; // [0-100]
    public lowIntensityRightDistribution: number; // [0-100]
    public mediumIntensityRightDistribution: number; // [0-100]
    public highIntensityRightDistribution: number; // [0-100]
    public stanceResults: StanceEvaluationActivityResultsModel;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.syncedTimestamps = input.syncedTimestamps;
        this.syncedForcesLeft = input.syncedForcesLeft;
        this.syncedSumForces = input.syncedSumForces;
        this.syncedForcesRight = input.syncedForcesRight;

        this.squats = this.deserializeArray(input.squats, SquatCycle);

        if (input.stanceResults) {
            this.stanceResults = new StanceEvaluationActivityResultsModel().deserialize(input.stanceResults);
        }

        if (input.balanceDistributionConfig) {
            this.balanceDistributionConfig = new BalanceDistributionConfigViewModel().deserialize(input.balanceDistributionConfig);
        }

        return this;
    }
}
