<div class="component">
    <ng-container *ngIf="viewType === resultViewTypes.FULL">
        <ng-container *ngTemplateOutlet="sectionKpis"></ng-container>
        <ng-container *ngTemplateOutlet="sectionPowerReferenceTable"></ng-container>
        <ng-container *ngIf="statistics.jumps.length > 0">
            <ng-container *ngTemplateOutlet="sectionHeightPerJump"></ng-container>
            <ng-container *ngTemplateOutlet="sectionGroundReactionForcesPerRep"></ng-container>
        </ng-container>
        <ng-container *ngTemplateOutlet="sectionForces"></ng-container>
    </ng-container>
    <ng-container *ngIf="viewType === resultViewTypes.ROW">
        <div class="d-flex flex-row">
            <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
            <div class="max-per-side flex-grow-1">
                <ng-container *ngTemplateOutlet="sectionKpis"></ng-container>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #sectionKpis>
    <div class="section">
        <h2>{{text('performance_analysis.section.title')}}</h2>
        <div class="metrics d-flex flex-row justify-content-between">
            <div class="d-flex flex-column">
                <app-value-widget [iconType]="valueWidgetIcons.REFRESH"
                                  [title]="text('widget.num_of_jumps.title')"
                                  [value]="numberOfJumps"
                                  [valueFractionDigits]="0"
                ></app-value-widget>
                <app-value-widget [iconType]="valueWidgetIcons.HEIGHT"
                                  [title]="text('multiple_jumps.height_average')"
                                  [value]="averageHeight"
                                  [valueFractionDigits]="1">
                </app-value-widget>
            </div>
            <div class="d-flex flex-column">
                <app-value-widget [iconType]="valueWidgetIcons.TIMER"
                                  [title]="text('exercise.dynamic_distribution_evaluation.config.duration.title')"
                                  [value]="duration"
                                  [valueFractionDigits]="1">
                </app-value-widget>
                <app-value-widget [iconType]="valueWidgetIcons.FATIGUE_INDEX"
                                  [title]="text('widget.fatigue_index.title')"
                                  [value]="fatigueIndex">
                </app-value-widget>
            </div>
            <div class="d-flex flex-column">
                <app-value-widget [iconType]="valueWidgetIcons.PACE"
                                  [title]="text('stats.hints.pace')"
                                  [value]="pace"
                                  [valueFractionDigits]="1">
                </app-value-widget>
                <app-value-widget [iconType]="valueWidgetIcons.MAX"
                                  [title]="text('multiple_jumps.average_power')"
                                  [value]="averagePower"
                                  [valueFractionDigits]="1">
                </app-value-widget>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #sectionPowerReferenceTable>
    <mat-table class="power-index-table full-width mt-5 mat-elevation-z1" [dataSource]="powerIndexTableData">
        <ng-container matColumnDef="category">
            <mat-header-cell *matHeaderCellDef>{{text('Average Power (W/Kg)')}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.category}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="first">
            <mat-header-cell *matHeaderCellDef [class.highlighted]="powerIndexTableHighlightedColumn==='first'">1 ({{text('multiple_jumps.avg_power.performance.poor')}})
            </mat-header-cell>
            <mat-cell *matCellDef="let element" [class.highlighted]="powerIndexTableHighlightedColumn==='first'">{{element.first}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="second">
            <mat-header-cell *matHeaderCellDef [class.highlighted]="powerIndexTableHighlightedColumn==='second'">2</mat-header-cell>
            <mat-cell *matCellDef="let element" [class.highlighted]="powerIndexTableHighlightedColumn==='second'">{{element.second}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="third">
            <mat-header-cell *matHeaderCellDef [class.highlighted]="powerIndexTableHighlightedColumn==='third'">3</mat-header-cell>
            <mat-cell *matCellDef="let element" [class.highlighted]="powerIndexTableHighlightedColumn==='third'">{{element.third}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fourth">
            <mat-header-cell *matHeaderCellDef [class.highlighted]="powerIndexTableHighlightedColumn==='fourth'">4</mat-header-cell>
            <mat-cell *matCellDef="let element" [class.highlighted]="powerIndexTableHighlightedColumn==='fourth'">{{element.fourth}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fifth">
            <mat-header-cell *matHeaderCellDef [class.highlighted]="powerIndexTableHighlightedColumn==='fifth'">5 ({{text('multiple_jumps.avg_power.performance.excellent')}})
            </mat-header-cell>
            <mat-cell *matCellDef="let element" [class.highlighted]="powerIndexTableHighlightedColumn==='fifth'">{{element.fifth}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="powerIndexTableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: powerIndexTableColumns"></mat-row>
    </mat-table>
</ng-template>

<ng-template #sectionHeightPerJump>
    <div class="section mt-5">
        <h2>{{text('height_per_jump.section.title')}}</h2>
        <div>
            <app-stacked-bar-with-line-chart [columnDescription]="text('multipleJumpsAnalysis.jump')"
                                             [columns]="heightPerJumpLabels"
                                             [stackedData]="heightPerJumpData"
                                             [lineData]="heightPerJumpPace"
                                             [displayLegend]="true">
            </app-stacked-bar-with-line-chart>
        </div>
    </div>
</ng-template>

<ng-template #sectionGroundReactionForcesPerRep>
    <div class="section ground-reaction-forces mt-5">
        <h2>{{text('ground_reaction_forces_per_rep.section.title')}}</h2>
        <div class="average-distribution d-flex flex-column mb-4">
            <div class="align-self-center fw-bold mb-2">{{text('average_distribution.section.title')}}</div>
            <div class="row">
                <div class="col-6 offset-3 d-flex">
                    <div class="side side-left">
                        <div class="in-units text-center ps-5 pe-5 pt-2 pb-2">{{averageDistributionLeftUnits}}</div>
                        <div class="in-percent text-center ps-5 pe-5 pt-2 pb-2">{{averageDistributionLeftPercent}}</div>
                    </div>
                    <div class="side side-right">
                        <div class="in-units text-center ps-5 pe-5 pt-2 pb-2">{{averageDistributionRightUnits}}</div>
                        <div class="in-percent text-center ps-5 pe-5 pt-2 pb-2">{{averageDistributionRightPercent}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <app-stacked-bar-with-line-chart [columnDescription]="text('multipleJumpsAnalysis.jump')"
                                             [columns]="heightPerJumpLabels"
                                             [stackedData]="groundReactionJumpData"
                                             [lineData]="heightPerJumpPace"
                                             [displayLegend]="true">
            </app-stacked-bar-with-line-chart>
        </div>
    </div>
</ng-template>

<ng-template #sectionForces>
    <div class="section mt-5">
        <h2>{{text('exersice.forces')}}</h2>
        <div>
            <app-samples-line-chart [data]="forcesPerSide"
                                    [total]="forcesTotal"
                                    [weight]="statistics.weight"
            >
            </app-samples-line-chart>
        </div>
    </div>
</ng-template>
