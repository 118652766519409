import {ParticipantTagConfigDto} from "../dtos/participantTagConfig.dto";
import {Gender, TextUtils} from "common";

export class ParticipantsFilter {
    public query: string;
    public gender: Gender;
    public tags: ParticipantTagConfigDto[];

    constructor() {
        this.tags = [];
    }

    public hasQuery(): boolean {
        return !TextUtils.isEmpty(this.query);
    }

    public hasFilters(): boolean {
        return this.hasQuery() || this.gender !== undefined || this.hasTags();
    }

    public hasTags(): boolean {
        return this.tags.length > 0;
    }
}
