<div class="container mt-4">
    <mat-card class="profile" *ngIf="userAccount !== undefined">
        <mat-card-title>{{userAccount.firstName + ' ' + userAccount.lastName}}</mat-card-title>
        <mat-card-subtitle>{{userAccount.email}}</mat-card-subtitle>
        <mat-card-content>
            <div class="mb-3">
                <small>Country</small>
                <div>{{userAccount.country || '-'}}</div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
