import {BodySideType, BodySideTypes} from "../../models/bodySideType";
import {ForceAndTimePacket} from "./forceAndTimePacket";
import {Dto} from "common";

export class TotalEvaluationRepResultsModel extends Dto {
    public repOrdinal: number;
    public repSide: BodySideType;
    public maxValue: number; // kg
    public maxLeftValue: number; // kg
    public averageValue: number; // kg
    public forcesLeft: number[]; // synced and transformed sum of all channels
    public forcesRight: number[]; // synced and transformed sum of all channels
    public deltas: number[]; // synced deltas in ms
    public timeToMax: number; // ms
    public rfdToMax: number; // Kg/s
    public rfdAt100ms: number; // Kg/s
    public rfdAt250ms: number; // Kg/s
    public rfdFromMax: number; // Kg/s (fatigue RFD)

    // ingoring below values atm till rfd calcualtions finalization
    public preMeasurementAvg: number; // Kg
    public preMeasurementStd: number;
    public rfdMeasurementsEndTime: number; // ms
    public leftDataFromStartPoint: ForceAndTimePacket[];
    public rightDataFromStartPoint: ForceAndTimePacket[];
    public totalDataFromStartPoint: ForceAndTimePacket[];
    public rfdStartingPoint: ForceAndTimePacket;

    deserialize(input: any): this {
        Object.assign(this, input);
        this.repSide = BodySideTypes.parse(input.repSide);
        if (input.leftDataFromStartPoint) {
            this.deserializeArray(input.leftDataFromStartPoint, ForceAndTimePacket);
        }
        if (input.rightDataFromStartPoint) {
            this.deserializeArray(input.rightDataFromStartPoint, ForceAndTimePacket);
        }
        if (input.totalDataFromStartPoint) {
            this.deserializeArray(input.totalDataFromStartPoint, ForceAndTimePacket);
        }
        if (input.rfdStartingPoint) {
            this.rfdStartingPoint = new ForceAndTimePacket().deserialize(input.rfdStartingPoint);
        }

        return this;
    }

}
