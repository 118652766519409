import {Sample} from "./sample";

export class SampleUtils {
    /**
     * Combines deltas and samples (eg forces) to create Sample items
     */
    public static createSampleCollection(deltas: number[], data: number[]): Sample[] {
        if (deltas.length !== data.length) {
            throw new Error('Samples have inconsistent deltas');
        }

        const samples: Sample[] = [];
        for (let i = 0; i < deltas.length; i++) {
            samples.push(new Sample(deltas[i] / 1000 /* To Sec*/, data[i]));
        }
        return samples;
    }
}
