import {ForceAndTimePacket} from "./forceAndTimePacket";
import {JumpCycle} from "./jumpCycle";
import {ActivityResultsModel} from "./activityResultsModel";

export class JumpAnalysisActivityResultsModel extends ActivityResultsModel {
    public impulsion: number;
    public rightImpulsion: number;
    public leftImpulsion: number;
    public rightImpulsionPercentage: number;
    public leftImpulsionPercentage: number;
    public totalRfd: number;
    public leftRfd: number;
    public rightRfd: number;
    public pointWhereJumpProcedureStarted: ForceAndTimePacket;
    public minValuePair: ForceAndTimePacket;
    public maxValuePair: ForceAndTimePacket;

    public jump: JumpCycle | null;
    public weight: number;

    public leftForces: ForceAndTimePacket[];
    public rightForces: ForceAndTimePacket[];
    public deltas: number[];

    public contactTime: number;
    public reactiveStrengthIndex: number;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.pointWhereJumpProcedureStarted = new ForceAndTimePacket().deserialize(input.pointWhereJumpProcedureStarted);
        this.minValuePair = new ForceAndTimePacket().deserialize(input.minValuePair);
        this.maxValuePair = new ForceAndTimePacket().deserialize(input.maxValuePair);
        if (input.jump !== null) {
            this.jump = new JumpCycle().deserialize(input.jump);
        }

        this.leftForces = this.deserializeArray(input.leftForces, ForceAndTimePacket);
        this.rightForces = this.deserializeArray(input.rightForces, ForceAndTimePacket);

        this.deltas = [];
        for (const datum of input.deltas as any) {
            this.deltas.push(datum);
        }

        return this;
    }

}
