import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../components/base/base.component";

@Component({
    selector: 'app-user-home',
    templateUrl: './user-home.component.html',
    styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent extends BaseComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.analyticsSwitchPage('Participants', undefined, '/participants');
    }
}
