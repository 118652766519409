<div class="component">
    <div class="d-flex flex-row justify-content-between align-items-baseline">
        <h2>{{text('filters')}}</h2>
        <button *ngIf="filter.hasFilters()" mat-button color="primary" (click)="clearFilter()">Clear</button>
    </div>
    <div class="filter filter-query mt-4">
        <mat-form-field class="full-width">
            <mat-label>{{text('activity_selection_screen.searchHint')}}</mat-label>
            <input matNativeControl
                   placeholder="{{text('protocol_activity_selection_screen.searchHint')}}"
                   (keyup)="onQueryChange($event)"
                   [value]="filter.hasQuery() ? filter.query : ''">
        </mat-form-field>
    </div>
    <div class="filter filter-exercise-types mt-2">
        <div class="title mb-1">{{text('data_activity_exercise_types_title')}}</div>
        <mat-chip-list multiple="true">
            <mat-chip class="tag" [selected]="filter.performType.includes(performTypes.Activity)"
                      (click)="onPerformTypeChange(performTypes.Activity)">{{text('selection.filters.single.title')}}</mat-chip>
            <mat-chip class="tag" [selected]="filter.performType.includes(performTypes.Protocol)"
                      (click)="onPerformTypeChange(performTypes.Protocol)">{{text('selection.filters.program.title')}}</mat-chip>
        </mat-chip-list>
        <mat-chip-list multiple="true">
            <mat-chip *ngFor="let type of activityTypes | keyvalue"
                      [selected]="filter.activityTypes.includes(type.key)"
                      class="tag tag-{{type.key.toString().toLowerCase()}}"
                      (click)="onActivityTypeClick(type.key)">
                {{type.value}}
            </mat-chip>
        </mat-chip-list>
    </div>
    <div class="filter filter-sensors mt-4">
        <div class="title mb-1">{{text('activity_selection_screen.accessories')}}</div>
        <button mat-icon-button (click)="onDeviceClick(deviceTypes.GRIP)">
            <mat-icon
                [svgIcon]="filter.devices.includes(deviceTypes.GRIP) ? getMatIcon(deviceTypes.GRIP, graphicsShadeTypes.COLOR) : getMatIcon(deviceTypes.GRIP, graphicsShadeTypes.GREY)"></mat-icon>
        </button>
        <button mat-icon-button (click)="onDeviceClick(deviceTypes.MUSCLE_TESTER)">
            <mat-icon
                [svgIcon]="filter.devices.includes(deviceTypes.MUSCLE_TESTER) ? getMatIcon(deviceTypes.MUSCLE_TESTER, graphicsShadeTypes.COLOR) : getMatIcon(deviceTypes.MUSCLE_TESTER, graphicsShadeTypes.GREY)"></mat-icon>
        </button>
        <button mat-icon-button (click)="onDeviceClick(deviceTypes.PLATES)">
            <mat-icon
                [svgIcon]="filter.devices.includes(deviceTypes.PLATES) ? getMatIcon(deviceTypes.PLATES, graphicsShadeTypes.COLOR) : getMatIcon(deviceTypes.PLATES, graphicsShadeTypes.GREY)"></mat-icon>
        </button>
        <button mat-icon-button (click)="onDeviceClick(deviceTypes.SENS)">
            <mat-icon
                [svgIcon]="filter.devices.includes(deviceTypes.SENS) ? getMatIcon(deviceTypes.SENS, graphicsShadeTypes.COLOR) : getMatIcon(deviceTypes.SENS, graphicsShadeTypes.GREY)"></mat-icon>
        </button>
        <button mat-icon-button (click)="onDeviceClick(deviceTypes.BUBBLE)">
            <mat-icon
                [svgIcon]="filter.devices.includes(deviceTypes.BUBBLE) ? getMatIcon(deviceTypes.BUBBLE, graphicsShadeTypes.COLOR) : getMatIcon(deviceTypes.BUBBLE, graphicsShadeTypes.GREY)"></mat-icon>
        </button>
        <button mat-icon-button (click)="onDeviceClick(deviceTypes.LINK)">
            <mat-icon
                [svgIcon]="filter.devices.includes(deviceTypes.LINK) ? getMatIcon(deviceTypes.LINK, graphicsShadeTypes.COLOR) : getMatIcon(deviceTypes.LINK, graphicsShadeTypes.GREY)"></mat-icon>
        </button>
        <button mat-icon-button (click)="onDeviceClick(deviceTypes.HEXAS)">
            <mat-icon
                [svgIcon]="filter.devices.includes(deviceTypes.HEXAS) ? getMatIcon(deviceTypes.HEXAS, graphicsShadeTypes.COLOR) : getMatIcon(deviceTypes.HEXAS, graphicsShadeTypes.GREY)"></mat-icon>
        </button>
    </div>
    <div class="filter filter-date mt-4">
        <div class="d-flex align-items-baseline justify-content-between flex-row">
            <div class="title mb-1">Date</div>
            <div class="small text-muted pe-1">{{formatPeriodType(this.filter.period)}}</div>
        </div>
        <mat-slider class="full-width"
                    thumbLabel
                    [displayWith]="periodSliderTitleFormatter"
                    tickInterval="1"
                    step="1"
                    color="primary"
                    min="1"
                    max="5"
                    [value]="filterPeriodToSliderIndex(this.filter.period)"
                    (valueChange)="onDateChange($event)"
        ></mat-slider>

    </div>
    <div class="filter filter-body-parts mt-4">
        <div class="title mb-1">{{text('data_activity_group_body_parts_title')}}</div>
        <mat-chip-list *ngFor="let group of [bodyPartGroupTypes.TORSO_NECK, bodyPartGroupTypes.ARMS, bodyPartGroupTypes.LEGS]"
                       multiple="true"
                       class="group group-{{group.toString().toLowerCase()}}">
            <mat-chip *ngFor="let tag of bodyParts.get(group)"
                      class="tag tag-{{tag.key}}"
                      [selected]="filter.tags.includes(tag.key)"
                      (click)="onTagClick(tag.key)">
                {{tag.value}}
            </mat-chip>
        </mat-chip-list>
    </div>
    <div class="filter filter-type mt-4">
        <div class="title mb-1">{{text('data_activity_group_type_title')}}</div>
        <mat-chip-list multiple="true">
            <mat-chip *ngFor="let type of types | keyvalue"
                      class="tag tag-{{type.key}}"
                      [selected]="filter.tags.includes(type.key)"
                      (click)="onTagClick(type.key)">
                {{type.value}}
            </mat-chip>
        </mat-chip-list>
    </div>
</div>
