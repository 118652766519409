import {BaseDto, Code} from "common";

export class ParticipantTagConfigDto extends BaseDto {
    userCode: Code;
    name: string;
    color: string;
    appAgent: string;

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }

}
