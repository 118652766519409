<div class="component d-block position-relative full-width full-height">
    <canvas baseChart
            [datasets]="[datasets]"
            [labels]="dataTitles"
            [options]="chartOptions"
            [plugins]="chartPlugins"
            chartType="bar"
    >
    </canvas>
</div>
