import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {JwtHelperService} from "@auth0/angular-jwt";
import {AuthenticationService} from "../services/api/authentication.service";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(private  _authenticationService: AuthenticationService, public _jwtHelper: JwtHelperService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const user = this._authenticationService.account;
        const haveToken = user?.authToken;
        const isTokenExpired = haveToken && this._jwtHelper.isTokenExpired(user.authToken);

        // The user is navigated to login if there is no user or if the auth token is expired
        if (!haveToken || isTokenExpired) {
            this._authenticationService.logout();
            return false;
        } else {
            return true;
        }
    }
}

