import {ChartDataSets, ChartOptions} from 'chart.js';
import {ActivityDto} from '../../../dtos/activity.dto';
import {ProgressOverTimeFilter} from '../../../viewmodels/progressOverTimeFilter';
import {Label} from 'ng2-charts';
import {I18nService} from '../../../services/i18n/i18n.service';
import {ActivityResultsModel} from '../../../dtos/statistics/activityResultsModel';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ProtocolSummaryResultsModelDto} from '../../../dtos/statistics/protocolSummaryResultsModelDto';
import {KeyValue} from "common";

export abstract class ProtocolsLineChartProvider {
    public abstract getDatasets(protocols: ProtocolDto[], analysis: ProtocolAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: ChartOptions, i18n: I18nService): KeyValue<Label[], ChartDataSets[]>;

    protected combineActivitiesToResults<T extends ActivityResultsModel>(activities: ActivityDto[], analysis: ProtocolAnalysisServiceResponseDto): Map<ActivityDto, T> {
        const activitiesResults = analysis.protocolsResults.flatMap(p => p.activitiesResults).filter(r => r !== undefined);
        return new Map(activities.map(a => [a, activitiesResults.find(r => r.activityCode === a.code)?.activityResultsModel] as [ActivityDto, T]));
    }

    protected combineProtocolsToResults<T extends ProtocolSummaryResultsModelDto>(protocols: ProtocolDto[], analysis: ProtocolAnalysisServiceResponseDto): Map<ProtocolDto, T> {
        const protocolsResults = analysis.protocolsResults;
        return new Map(protocols.map(p => [p, protocolsResults.find(r => r.protocolCode === p.code).protocolSummaryResultsModel] as [ProtocolDto, T]));
    }
}
