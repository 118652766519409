<div class="container">
    <h1>{{'acceptAccountAssociation.title'|text}}</h1>
    <div class="content">
        <div *ngIf="isLoadingState(loadingStates.LOADING)">
            {{'acceptAccountAssociation.verifying'|text}}
        </div>
        <div *ngIf="isLoadingState(loadingStates.LOADED)" class="success">
            <p>
                {{'acceptAccountAssociation.congratulations'|text}}
                <br/>
                <br/>
                {{'acceptAccountAssociation.success'|text}}
            </p>
        </div>
        <div *ngIf="isLoadingState(loadingStates.ERROR)">
            <p>
                {{'acceptAccountAssociation.error'|text}} <a href="mailto:info@k-invent.com" target="_blank">info@k-invent.com</a>
            </p>
        </div>

    </div>
</div>

