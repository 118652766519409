import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Value} from "../../../utils/values/value";

@Component({
    selector: 'app-plates-distribution-results',
    templateUrl: './plates-distribution-results.component.html',
    styleUrls: ['./plates-distribution-results.component.scss']
})
export class PlatesDistributionResultsComponent extends BaseComponent implements OnInit {

    @Input()
    public avgLeftDistribution: number;

    @Input()
    public avgRightDistribution: number;

    @Input()
    public avgRightDistributionPercent: number;

    @Input()
    public targetTolerance: Range;

    @Input()
    public stdToDistribution: Range;

    @Input()
    public stdValue: Value;

    @Input()
    public maxLeftDistribution: number;
    @Input()
    public minLeftDistribution: number;
    @Input()
    public maxRightDistribution: number;
    @Input()
    public minRightDistribution: number;

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

}
