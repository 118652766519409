/**
 * Describes a vertical distribution. [0-1]
 * Sibling class of HorizontalDistribution
 */
import {Value} from "../utils/values/value";

export class VerticalDistribution<Type extends number | Value> {
    public top: Type;
    public bottom: Type;

    constructor(top: Type, bottom: Type) {
        this.top = top;
        this.bottom = bottom;
    }
}
