import {IconResource, MatIconResource, Resource, Title} from "common";
import {ProgressOverTimeMetricType} from "./progressOverTimeMetricType";

export class ProgressOverTimeMetric {
    constructor(public type: ProgressOverTimeMetricType) {
    }

    public get title(): Title {
        return ProgressOverTimeMetric.title(this.type);
    }

    public get icon(): IconResource {
        return ProgressOverTimeMetric.icon(this.type);
    }

    public get matIcon(): MatIconResource {
        return ProgressOverTimeMetric.matIcon(this.type);
    }

    public toString(): string {
        return this.title;
    }

    private static title(type: ProgressOverTimeMetricType): string {
        let title = '';
        switch (type) {
            case ProgressOverTimeMetricType.MAX_VALUE:
                title = 'report.chartDataType.max';
                break;
            case ProgressOverTimeMetricType.AVG_VALUE:
                title = 'report.chartDataType.average';
                break;
            case ProgressOverTimeMetricType.PERCENTAGE:
                title = 'report.chartDataType.successPercentage';
                break;
            case ProgressOverTimeMetricType.POINTS:
                title = 'report.chartDataType.points';
                break;
            case ProgressOverTimeMetricType.TARGETS:
                title = 'report.chartDataType.targets';
                break;
            case ProgressOverTimeMetricType.EFFICIENCY:
                title = 'report.chartDataType.efficiency';
                break;
            case ProgressOverTimeMetricType.DISTRIBUTION:
                title = 'report.chartDataType.distribution';
                break;
            case ProgressOverTimeMetricType.LATERAL_AMPLITUDE:
                title = 'report.chartDataType.widthAmplitude';
                break;
            case ProgressOverTimeMetricType.LONGITUDINAL_AMPLITUDE:
                title = 'report.chartDataType.heightAmplitude';
                break;
            case ProgressOverTimeMetricType.REP_COUNT:
                title = 'report.chartDataType.rep_count';
                break;
            case ProgressOverTimeMetricType.JUMP_COUNT:
                title = 'progressOverTimeMetric.jumpCount';
                break;
            case ProgressOverTimeMetricType.SKIP_COUNT:
                title = 'progressOverTimeMetric.skippingCount';
                break;
            case ProgressOverTimeMetricType.COP_SURFACE:
                title = 'report.chartDataType.cop_surface';
                break;
            case ProgressOverTimeMetricType.JUMP_HEIGHT:
                title = 'report.chartDataType.jump_height';
                break;
            case ProgressOverTimeMetricType.RFD:
                title = 'report.chartDataType.rfd';
                break;
            case ProgressOverTimeMetricType.IMPULSION:
                title = 'report.chartDataType.impulsion';
                break;
            case ProgressOverTimeMetricType.RSI:
                title = 'report.chartDataType.rsi';
                break;
            case ProgressOverTimeMetricType.CONTACT_TIME:
                title = 'report.chartDataType.contact_time';
                break;
            case ProgressOverTimeMetricType.MCCALL:
                title = 'McCall';
                break;
            case ProgressOverTimeMetricType.MCCALL_90:
                title = '90°';
                break;
            case ProgressOverTimeMetricType.MCCALL_30:
                title = '30°';
                break;
            case ProgressOverTimeMetricType.IYT:
                title = 'IYT';
                break;
            case ProgressOverTimeMetricType.IYT_I:
                title = 'I';
                break;
            case ProgressOverTimeMetricType.IYT_Y:
                title = 'Y';
                break;
            case ProgressOverTimeMetricType.IYT_T:
                title = 'T';
                break;
        }
        return title;
    }

    public static icon(type: ProgressOverTimeMetricType): Resource {
        let resource: string;
        switch (type) {
            case ProgressOverTimeMetricType.MAX_VALUE:
                resource = './assets/icons/ic_flash_monochrome.svg';
                break;
            case ProgressOverTimeMetricType.AVG_VALUE:
                resource = './assets/icons/ic_vertical_align_center_monochrome.svg';
                break;
            case ProgressOverTimeMetricType.PERCENTAGE:
                resource = './assets/icons/ic_target_monochrome.svg';
                break;
        }
        return resource;
    }

    /**
     * Icons registered in the iconRegistryService
     */
    private static matIcon(type: ProgressOverTimeMetricType): MatIconResource {
        let icon: MatIconResource;
        switch (type) {
            case ProgressOverTimeMetricType.MAX_VALUE:
                icon = 'progress-over-time-max-value';
                break;
            case ProgressOverTimeMetricType.AVG_VALUE:
                icon = 'progress-over-time-avg-value';
                break;
            case ProgressOverTimeMetricType.PERCENTAGE:
                icon = 'progress-over-time-percentage';
                break;
        }
        return icon;
    }
}
