import {BaseDto} from "common";

export class ActivityConfigSetMetaDto extends BaseDto {
    metaKey?: string;
    metaValue?: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
