import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../services/api/authentication.service';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {NavigationService} from '../../services/navigation.service';
import {SubscriptionPlanDaoService, SubscriptionPlanType} from "common";

/**
 * Guard to allow only users having an excellence or pro+ subscription types
 */
@Injectable({
    providedIn: 'root'
})
export class SubscriptionTypeGuard implements CanActivate {

    constructor(private authenticationService: AuthenticationService, private navigation: NavigationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let canActivate = false;
        let excellenceSubscription = new SubscriptionPlanDaoService().getForType(SubscriptionPlanType.EXCELLENCE);
        let proPlusSubscription = new SubscriptionPlanDaoService().getForType(SubscriptionPlanType.PRO_PLUS);
        for (const subscription of this.authenticationService.subscriptions) {
            if (subscription.name === excellenceSubscription.name || subscription.name === proPlusSubscription.name) {
                canActivate = true;
                break;
            }
        }

        if (!canActivate) {
            this.navigation.goToProPlusPromo();
        }

        return canActivate;
    }
}
