import {BodySideType, BodySideTypes} from "../../models/bodySideType";
import {Dto} from "common";

export class IsometryActivityRepResultsModel extends Dto {
    public pieEntries: number[];
    public upperBoundTarget: number; // Kg
    public bottomBoundTarget: number; // Kg
    public forces: number[];
    public deltas: number[];
    public repSide: BodySideType;
    public repOrdinal: number;
    public maxValue: number; // Kg
    public averageValue: number; // Kg
    public rfdToMax: number; //N/s
    public rfdFromMax: number; // N/s
    public timeToMax: number; // ms
    public maxTimeOnTarget: number; // ms
    public totalTimeOnTarget: number; // ms
    public successRate: number; // % [0-100]

    deserialize(input: any): this {
        Object.assign(this, input);
        this.repSide = BodySideTypes.parse(input.repSide);

        return this;
    }

}
