import {Component, OnInit} from '@angular/core';
import {UnipodalStanceEvaluationActivityResultsModel} from '../../../dtos/statistics/unipodalStanceEvaluationActivityResultsModel';
import {BodySideType} from '../../../models/bodySideType';
import {UnipodalStanceEvaluationActivitySideResultsModel} from '../../../dtos/statistics/unipodalStanceEvaluationActivitySideResultsModel';
import {UnipodalStanceEvaluationSideResultsViewModel} from '../../../viewmodels/unipodalStanceEvaluationSideResultsViewModel';
import {BuiltInExerciseTemplates} from '../../../models/builtInExerciseTemplateType';
import {Sample} from '../../../utils/sample';
import {ActivityAnalysisResultDto} from '../../../dtos/statistics/activityAnalysisResultDto';
import {DeviceType, KeyValue, LoadingState, Resource} from "common";
import {ActivityImageResolverService} from '../../../services/activity-image-resolver.service';
import {ResultsComponent} from "../resultsComponent";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {VerticalDistribution} from "../../../models/verticalDistribution";
import {Value} from "../../../utils/values/value";
import {UnitType} from "../../../utils/values/unitType";

@Component({
    selector: 'app-stance-evaluation-single-leg-results-component',
    templateUrl: './stance-evaluation-single-leg-results.component.html',
    styleUrls: ['./stance-evaluation-single-leg-results.component.scss']
})
export class StanceEvaluationSingleLegResultsComponent extends ResultsComponent implements OnInit {
    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: UnipodalStanceEvaluationActivityResultsModel;
    public loadingState: LoadingState;
    public device: DeviceType.PLATES | DeviceType.HEXAS;

    private sides: BodySideType[];
    public haveLeft: boolean;
    public haveRight: boolean;
    public isDropLand: boolean;
    public leftSideResultsModel: UnipodalStanceEvaluationActivitySideResultsModel;
    public rightSideResultsModel: UnipodalStanceEvaluationActivitySideResultsModel;
    public leftVm: UnipodalStanceEvaluationSideResultsViewModel;
    public rightVm: UnipodalStanceEvaluationSideResultsViewModel;
    public ellipseSurfaces: KeyValue<BodySideType, Value>[];
    public sideAreaCoveredPerTime: Map<[BodySideType, UnitType], Sample[]>;
    public image: Resource;
    public distributionData: Map<BodySideType, VerticalDistribution<Value>>[];

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
        this.ellipseSurfaces = [];
    }

    ngOnInit(): void {
        this.loadingState = LoadingState.LOADING;

        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. UnipodalStanceEvaluationResultsComponent`, this.analysis);
            this.loadingState = LoadingState.ERROR;
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.analysis.activitiesResults[0].activityResultsModel as UnipodalStanceEvaluationActivityResultsModel;
        const activityBaseConfig = this.activity.config.baseConfigCode;

        // common
        this.image = this.activityImageResolverService.do(this.activity.image);

        // device
        let resultsDeviceType = this.activityAnalysis.config.activityConfigDevices[0].deviceTypeType;
        if (!(resultsDeviceType === DeviceType.PLATES || resultsDeviceType === DeviceType.HEXAS)) {
            return;
        }
        this.device = resultsDeviceType;

        // Setup sides
        this.sides = this.statistics.resultsPerSide.map(value => value.side);
        this.haveLeft = this.sides.includes(BodySideType.LEFT);
        this.haveRight = this.sides.includes(BodySideType.RIGHT);

        // Get data per side
        const distributionData = new Map<BodySideType, VerticalDistribution<Value>>();
        if (this.haveLeft) {
            this.leftSideResultsModel = this.statistics.resultsPerSide.find(value => value.side === BodySideType.LEFT);
            this.leftVm = UnipodalStanceEvaluationSideResultsViewModel.fromResults(this.leftSideResultsModel);
            this.ellipseSurfaces.push(KeyValue.of(BodySideType.LEFT, this.leftVm.surfaceUnits));
            distributionData.set(BodySideType.LEFT, this.leftVm.heelTipDistribution);
        }
        if (this.haveRight) {
            this.rightSideResultsModel = this.statistics.resultsPerSide.find(value => value.side === BodySideType.RIGHT);
            this.rightVm = UnipodalStanceEvaluationSideResultsViewModel.fromResults(this.rightSideResultsModel);
            this.ellipseSurfaces.push(KeyValue.of(BodySideType.RIGHT, this.rightVm.surfaceUnits));
            distributionData.set(BodySideType.RIGHT, this.rightVm.heelTipDistribution);
        }
        this.distributionData = [distributionData];

        // surface/time
        this.isDropLand = BuiltInExerciseTemplates.isUnipodalDropLand(activityBaseConfig);
        this.sideAreaCoveredPerTime = new Map<[BodySideType, UnitType], Sample[]>();
        if (this.isDropLand) {
            if (this.haveLeft) {
                const samples: Sample[] = [];
                for (const entry of this.leftSideResultsModel.copSurfacePerTime) {
                    samples.push(Sample.of(entry.x / 1000, entry.y));
                }
                this.sideAreaCoveredPerTime.set([BodySideType.LEFT, UnitType.KG], samples);
            }
            if (this.haveRight) {
                const samples: Sample[] = [];
                for (const entry of this.rightSideResultsModel.copSurfacePerTime) {
                    samples.push(Sample.of(entry.x / 1000, entry.y));
                }
                this.sideAreaCoveredPerTime.set([BodySideType.RIGHT, UnitType.KG], samples);
            }
        }

        this.loadingState = LoadingState.LOADED;
    }
}
