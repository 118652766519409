import {ActivityCode, ProtocolCode} from "../utils/types";
import {CollectionUtils, Dto} from "common";

export class AnalyseProtocolActivitiesRequestDto extends Dto {
    public protocolActivities: Map<ProtocolCode, ActivityCode[]>;

    deserialize(input: any): this {
        // Not implemented, not needed atm
        return undefined;
    }

    /**
     * convert dto to an object with the map converted to JSON.Stringify compatible
     */
    public toSerializableObject(): object {
        const serializable = {};
        serializable['protocolActivities'] = CollectionUtils.convertMapToSerializable(this.protocolActivities);
        return serializable;
    }
}
