<mat-card (click)="onSelect($event)" (middleClick)="onSelect($event)" [class.disabled]="!enabled"
          appMiddleClick class="component p-0 ratio ratio-1x1 position-relative"
          matRipple
>
    <app-image class="activity" [image]="getImage()" description="{{title}}"></app-image>
    <app-image *ngIf="overflowImage" class="overflowImage full-width position-absolute" [image]="overflowImage" [fallback]="false"></app-image>
    <div class="top-right-icons position-absolute d-flex flex-row pt-1">
        <app-image *ngFor="let exerciseType of exerciseTypeData" class="icon pe-2"
                   [image]="exerciseType.key"
                   [title]="exerciseType.value"
                   [fallback]="false"></app-image>
    </div>
    <div class="info full-width position-absolute">
        <div class="text-center">{{title}}</div>
    </div>
</mat-card>
