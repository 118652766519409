import {ActivityLineChartProvider} from './activityLineChartProvider';
import {ActivityDto} from '../../../dtos/activity.dto';
import {ActivityAnalysisServiceResponseDto} from '../../../dtos/statistics/activityAnalysisServiceResponseDto';
import {Label} from 'ng2-charts';
import {I18nService} from '../../../services/i18n/i18n.service';
import {ProgressOverTimeFilter} from '../../../viewmodels/progressOverTimeFilter';
import {Activities} from '../../../utils/activities';
import {format} from 'date-fns';
import {ChartDataSets} from 'chart.js';
import {BodySideType, BodySideTypes} from '../../../models/bodySideType';
import {ProgressOverTimeMetric} from '../../../viewmodels/progressOverTimeMetric';
import {ProgressOverTimeMetricType} from '../../../viewmodels/progressOverTimeMetricType';
import {DynamicDistributionEvaluationResultsModel} from '../../../dtos/statistics/dynamicDistributionEvaluationResultsModel';
import {ChartUtils} from "../../../utils/chartUtils";
import {Percent} from "../../../utils/values/percent";
import {DateFormat, Dates, KeyValue} from "common";

export class DynamicDistributionLineChatProvider extends ActivityLineChartProvider {
    constructor() {
        super();
    }

    getDatasets(activities: ActivityDto[], analysis: ActivityAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: Chart.ChartOptions, i18n: I18nService): KeyValue<Label[], Chart.ChartDataSets[]> {
        const activitiesByDay = Activities.groupByDay(activities);

        const days = Array.from(activitiesByDay.keys()).sort();
        const labels: Label[] = days.map(day => format(day, Dates.getFormat(DateFormat.DATE_SHORTER)));

        const datasets = this.createDatasets(activitiesByDay, analysis, filter.metric, days, i18n);

        let maxValue = 0;
        if (filter.metric.type === ProgressOverTimeMetricType.DISTRIBUTION) {
            maxValue = 100;
        }

        chartOptions.scales.yAxes[0].ticks = {min: 0, max: maxValue};

        // Chart options
        if (!chartOptions.plugins) {
            chartOptions.plugins = {};
        }
        chartOptions.plugins.datalabels = {
            formatter: this.labelFormatter.bind(this),
            anchor: 'end',
            align: 'end',
        };

        return KeyValue.of(labels, datasets);
    }

    private createDatasets(activitiesPerDay: Map<number, ActivityDto[]>, analysis: ActivityAnalysisServiceResponseDto, metric: ProgressOverTimeMetric, days: number[], i18n: I18nService): Chart.ChartDataSets[] {
        // Create the dataset. Distribution has two datasets. First is left/tip and second is right/heel
        const datasets: ChartDataSets[] = [];
        if (metric.type === ProgressOverTimeMetricType.DISTRIBUTION) {
            for (const side of [BodySideType.LEFT, BodySideType.RIGHT]) {
                const dataSet: ChartDataSets = {};
                dataSet.data = [];
                dataSet.label = BodySideTypes.format(side);
                dataSet.spanGaps = true;
                dataSet.lineTension = 0;
                datasets.push(dataSet);
            }
        } else {
            throw new Error(`Unsupported filter for Dynamic Distribution ${metric.type}`);
        }

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value: number = NaN;
            // Create values for metrics.
            // Add NaN when there is no value for the day except for the last day, where we add the previous value
            if (activitiesPerDay.has(day)) {
                let activities = activitiesPerDay.get(day);
                let activitiesToResults = this.combineActivitiesToResults<DynamicDistributionEvaluationResultsModel>(activities, analysis);
                let results = activities.map(a => activitiesToResults.get(a));
                value = this.getMetricFromActivities(results, metric.type);
                datasets[0].data.push(value);

                // on distribution, calculate the right side manually.
                if (metric.type === ProgressOverTimeMetricType.DISTRIBUTION) {
                    datasets[1].data.push(100 - value);
                }
            }
        }

        // Theme datasets
        ChartUtils.formatDatasetForSide(datasets[0], BodySideType.LEFT);
        ChartUtils.formatDatasetForSide(datasets[1], BodySideType.RIGHT);

        return datasets;
    }

    private getMetricFromActivities(results: DynamicDistributionEvaluationResultsModel[], type: ProgressOverTimeMetricType) {
        let value: number;
        switch (type) {
            case ProgressOverTimeMetricType.DISTRIBUTION:
                if (results.length === 0) {
                    value = NaN;
                    break;
                }
                const lastResult = results[results.length - 1];
                const lastAverageDistribution = lastResult.avgLeftDistribution;
                value = lastAverageDistribution ?? NaN;
                break;
        }
        return value;
    }

    private labelFormatter(value: number): string {
        return new Percent(value).format(1);
    }
}
