import {ActivityDto} from "../../dtos/activity.dto";
import {CsvConverter} from "./csvConverter";
import {ExerciseType} from "../../models/exerciseType";
import {MeterEnduranceCsvConverter} from "./meter-endurance-csv-converter";
import {BuiltInExerciseTemplates} from "../../models/builtInExerciseTemplateType";

export class CsvConverterFactory {

    constructor() {
    }

    public static getFor(activity: ActivityDto): CsvConverter {
        const exerciseType = activity.config.exerciseTypeEnum;

        let converter: CsvConverter;
        switch (exerciseType) {
            case ExerciseType.METER:
            case ExerciseType.METER_ENDURANCE:
                converter = new MeterEnduranceCsvConverter();
                break;
            default:
                throw new Error(`Activity doesn't support CSV`);
        }

        return converter;
    }

}
