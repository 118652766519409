import {Point} from "./point";

/**
 * Immutable rectangle
 */
export class Rect {
    constructor(public readonly top: number, public readonly left: number,
                public readonly width: number, public readonly height: number) {
    }

    public get right(): number {
        return this.left + this.width;
    }

    public get bottom(): number {
        return this.top + this.height;
    }

    public isSquare(): boolean {
        return this.width === this.height;
    }

    public get aspectRatio(): number {
        return this.width / this.height;
    }

    public get center(): Point {
        return new Point(this.left + this.width / 2, this.top + this.height / 2);
    }

    public static zero(): Rect {
        return new Rect(0, 0, 0, 0);
    }

    public static fromPoints(topLeft: Point, bottomRight: Point) {
        return new Rect(topLeft.y, topLeft.x, bottomRight.x - topLeft.x, bottomRight.y - topLeft.y);
    }

    public static scale(rect: Rect, scaleFactor: number): Rect {
        const zoomedWidth = rect.width * scaleFactor;
        const zoomedHeight = rect.height * scaleFactor;
        const offsetX = -(zoomedWidth - rect.width) / 2;
        const offsetY = -(zoomedHeight - rect.height) / 2;
        return new Rect(offsetY, offsetX, zoomedWidth, zoomedHeight);
    }

    public toString(fractionDigits: number = 0): string {
        return `top:${this.top.toFixed(fractionDigits)}, left:${this.left.toFixed(fractionDigits)}, bottom:${this.bottom.toFixed(fractionDigits)}, right:${this.right.toFixed(fractionDigits)}, ${this.width.toFixed(fractionDigits)}x${this.height.toFixed(fractionDigits)}`;
    }
}
