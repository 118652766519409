import {CsvConverter} from './csvConverter';
import {ActivityDto} from '../../dtos/activity.dto';
import {ActivityAnalysisResultDto} from '../../dtos/statistics/activityAnalysisResultDto';
import {MeterEnduranceActivityResultsModel} from '../../dtos/statistics/meterEnduranceActivityResultsModel';
import {BodySideTypes} from '../../models/bodySideType';
import {BluetoothDeviceTypeUtils} from '../../models/bluetoothDeviceType';
import {DeviceType} from "common";

export class MeterEnduranceCsvConverter extends CsvConverter {

    constructor() {
        super();
    }

    convert(activity: ActivityDto, analysis: ActivityAnalysisResultDto): string[][] {
        if (!(analysis.activityResultsModel instanceof MeterEnduranceActivityResultsModel)) {
            throw new Error('Invalid analysis for meter activity');
        }
        const isPlates = activity.config.getDeviceTypes().includes(DeviceType.PLATES) || activity.config.getDeviceTypes().includes(DeviceType.HEXAS);

        let resultsModel = analysis.activityResultsModel as MeterEnduranceActivityResultsModel;

        for (const result of resultsModel.repResults) {
            this.addLine(`Repetition: ${result.repOrdinal}. Side: ${BodySideTypes.format(result.bodySideType)}`);
            this.addLine(`Device: ${BluetoothDeviceTypeUtils.getBluetoothDeviceName(result.deviceType)}`);

            this.addLine('Max Value', result.maxValue.toFixed(2));
            this.addLine('Average Value', result.averageValue.toFixed(2));
            this.addLine('RFD To Max (Kg/s)', result.rfdToMax.toFixed(2));
            this.addLine('RFD From Max (Kg/s)', result.rfdFromMax.toFixed(2));
            this.addLine('Time To Max (s)', (result.timeToMax / 1000).toFixed(2));

            // Headers
            if (!isPlates) {
                this.addLine('Time', 'CHANNEL_1');
                for (const delta of result.deltas) {
                    this.addLine(this.formatTime(delta), this.formatSample(result.forces[0]))
                }
            } else {
                this.addLine('Time', 'CHANNEL_1', 'CHANNEL_2', 'CHANNEL_3', 'CHANNEL_4');
                for (const delta of result.deltas) {
                    this.addLine(this.formatSample(delta), this.formatSample(result.forces[0]), this.formatSample(result.forces[1]), this.formatSample(result.forces[2]), this.formatSample(result.forces[3]));
                }
            }
        }

        return this.lines;
    }
}
