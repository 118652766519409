import {Dto} from "common";

export class Entry extends Dto {
    public x: number;
    public y: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    public static of(x: number, y: number): Entry {
        const entry = new Entry();
        entry.x = x;
        entry.y = y;
        return entry;
    }

}
