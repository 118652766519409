<ng-container *ngIf="viewType === resultViewTypes.FULL">
    <div class="section row">
        <div class="col-4">
            <app-value-widget [value]="kpiSquats"
                              [title]="'exercise.dynamic_distribution_evaluation.indicator.squatCount.title'|text"
                              [valueFractionDigits]="0"
                              [iconType]="valueWidgetIcons.REPEAT"
            ></app-value-widget>
        </div>
        <div class="col-4">
            <app-value-widget [value]="kpiDuration"
                              [title]="'exercise.dynamic_distribution_evaluation.config.duration.title'|text"
                              [valueFractionDigits]="1"
                              [iconType]="valueWidgetIcons.TIMER"
            ></app-value-widget>
        </div>
        <div class="col-4">
            <app-value-widget [value]="kpiPace"
                              [title]="'stats.hints.pace'|text"
                              [valueFractionDigits]="1"
                              [iconType]="valueWidgetIcons.PACE"
            ></app-value-widget>
        </div>
    </div>
    <div class="section section-average-distribution mt-3">
        <div class="title pt-2 pb-2 ps-3 pe-3 mb-3">{{'average_distribution.section.title'|text}}</div>
        <app-plates-distribution-results
            [avgLeftDistribution]="statistics.avgLeftDistribution"
            [avgRightDistribution]="100 - statistics.avgLeftDistribution"
            [minLeftDistribution]="statistics.minLeftDistribution"
            [minRightDistribution]="100 - statistics.minLeftDistribution"
            [maxLeftDistribution]="statistics.maxLeftDistribution"
            [maxRightDistribution]="100 - statistics.maxLeftDistribution"
            [stdValue]="standardDeviation"
            [avgRightDistributionPercent]="rightDistributionPercent"
            [targetTolerance]="distributionTolerance"
            [stdToDistribution]="stdToDistribution"

        ></app-plates-distribution-results>
    </div>
    <div class="section section-distribution-phase mt-3">
        <div class="title pt-2 pb-2 ps-3 pe-3 mb-3">{{'distribution_per_phase.section.title'|text}}</div>
        <div class="d-flex flex-row">
            <div class="average flex-shrink-0">
                <app-distribution-bar-chart
                    [data]="squatPhaseAvgData"
                    [valueFractionDigits]="1"
                    [topAreaTitle]="'advanced_squat_analysis.label.concentric.title'|text"
                    [bottomAreaTitle]="'advanced_squat_analysis.label.eccentric.title'|text"
                    [dataTitles]="['report.chartDataType.average'|text]"
                ></app-distribution-bar-chart>
            </div>
            <div class="repetitions flex-grow-1">
                <app-distribution-bar-chart
                    [data]="squatPhaseAvgPerRep"
                    [valueFractionDigits]="1"
                ></app-distribution-bar-chart>
            </div>
        </div>
    </div>
    <div class="section section-max-forces mt-3">
        <div class="title pt-2 pb-2 ps-3 mb-3">{{'max_ground_reaction_forces_per_rep.section.title'|text}}</div>
        <div class="d-flex flex-row">
            <div class="average flex-grow-0">
                <app-body-side-bar-chart [axisXLabel]="'report.chartDataType.average'|text"
                                         [axisYLabel]="'charts.axis_force_label'|text:unitTypeUtils.title(userSettings.weightUnit)"
                                         [data]="squatMaxAverage"
                                         [valueFractionDigits]="1"
                >
                </app-body-side-bar-chart>
            </div>
            <div class="repetitions flex-grow-1">
                <app-body-side-bar-chart [axisXLabel]="'reps'|text"
                                         [data]="squatMaxPerRep"
                                         [valueFractionDigits]="1"
                >
                </app-body-side-bar-chart>
            </div>
        </div>
    </div>
    <div class="section section-max-amplitude mt-3">
        <div class="title pt-2 pb-2 ps-3 mb-3">{{'max_amplitude_per_rep.section.title'|text}}</div>
        <div class="d-flex flex-row">
            <div class="average flex-grow-0">
                <app-body-side-bar-chart [axisXLabel]="'report.chartDataType.average'|text"
                                         [axisYLabel]="'charts.axis_amplitude_label'|text:unitTypeUtils.title(unitTypes.ANGLES)"
                                         [data]="amplitudeAverage"
                                         [valueFractionDigits]="1"
                >
                </app-body-side-bar-chart>
            </div>
            <div class="repetitions flex-grow-1">
                <app-stacked-bar-with-line-chart [axisRightLabel]="'charts_yaxis_label.paces.rep_per_min'|text"
                                                 [columnDescription]="'dataTable.header.repetition'|text"
                                                 [columns]="amplitudeAveragePerRepLabels"
                                                 [displayAxisLeft]="false"
                                                 [lineData]="amplitudeAveragePace"
                                                 [stackedData]="amplitudeAveragePerRep"
                >
                </app-stacked-bar-with-line-chart>
            </div>
        </div>
    </div>
    <div class="section section-forces mt-3">
        <div class="title pt-2 pb-2 ps-3 mb-3">{{'line_charts.section.title'|text}}</div>
        <div>
            <app-samples-line-chart
                [data]="forcesData"
                [total]="forcesDataTotal"
                [weight]="statistics.weight"

            ></app-samples-line-chart>
        </div>
    </div>
    <div class="section section-dynamic-cop mt-3 mb-4">
        <div class="title pt-2 pb-2 ps-3 mb-3">{{'dynamic.CoP'|text}}</div>
        <div>
            <app-stance-results [activities]="[activity]" [analysis]="dynamicCopAnalysis" [viewType]="resultViewTypes.FULL"></app-stance-results>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="viewType === resultViewTypes.ROW">
    <div class="section d-flex flex-row">
        <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
        <div class="max-per-side flex-grow-1 d-flex flex-row align-items-center">
            <app-value-widget class="flex-grow-0"
                              [value]="kpiSquats"
                              [title]="'exercise.dynamic_distribution_evaluation.indicator.squatCount.title'|text"
                              [valueFractionDigits]="0"
                              [iconType]="valueWidgetIcons.REPEAT"
            ></app-value-widget>
            <div class="flex-grow-1">
                <div class="text-center">
                    <div class="title rounded-pill ps-3 pe-3 pt-1 pb-1 d-inline-block">{{'report.chartDataType.distribution'|text}}</div>
                </div>
                <app-body-side-bar-chart [data]="distributionPerSideData"
                                         [valueFractionDigits]="1"
                                         [dataTitles]="distributionPerSideDataLabels">
                </app-body-side-bar-chart>
            </div>
        </div>
    </div>
</ng-container>
