import {ProtocolConfigStepDto} from './protocolConfigStep.dto';
import {ProtocolOrActivityConfigTag} from '../models/protocolOrActivityConfigTag';
import {BaseDto, Code, TextUtils} from "common";

export class ProtocolConfigDto extends BaseDto {
    title: string;
    baseConfigCode: Code;
    templateCode: Code;
    coverImage: string;
    steps: ProtocolConfigStepDto[];

    deserialize(input: any): this {
        Object.assign(this, input);

        this.steps = this.deserializeArray(input.steps, ProtocolConfigStepDto);
        return this;
    }

    public getBaseConfigCode(): string {
        return TextUtils.isEmpty(this.templateCode) ? this.baseConfigCode! : this.templateCode!;
    }

    public getTags(): ProtocolOrActivityConfigTag[] {
        const tags = new Set<ProtocolOrActivityConfigTag>();
        for (const step of this.steps) {
            for (const tag of step.activityConfig.getTags()) {
                tags.add(tag);
            }
        }
        return Array.from(tags);
    }
}
