<div class="container mt-4 mb-5">
    <div class="filters">
        <app-text-search [value]="filter.query" (valueChange)="onSearchChanged($event)"></app-text-search>
        <app-tag-selection class="d-inline"
                           [tags]="participantTagConfigs"
                           [(selected)]="filter.tags"
                           (selectedChange)="selectedTagsChange()"
        ></app-tag-selection>
        <div class="secondary d-flex justify-content-between">
            <app-order-selection [orderOptions]="orderOptions" (orderChanged)="orderChanged($event)" [defaultOrder]="currentOrder"></app-order-selection>
            <app-gender-selection (genderSelected)="onGenderChanged($event)"></app-gender-selection>
        </div>
    </div>
    <div *ngIf="loadingState === loadingStates.LOADED; else loadingComponent">
        <table *ngIf="filteredParticipants.length > 0; else noResults" mat-table [dataSource]="filteredParticipants" class="list full-width mat-elevation-z8">
            <ng-container matColumnDef="fullname">
                <th mat-header-cell *matHeaderCellDef>Full name</th>
                <td mat-cell *matCellDef="let element"
                    class="item d-flex flex-row align-items-center pt-3 pb-3">
                    <app-avatar [imageBase64]="element.photo" [size]="34" class="me-3"></app-avatar>
                    <span><span class="lastName">{{element.lastName}}</span> {{element.firstName}}</span>
                    <span class="flex-grow-1"></span>
                    <app-tag *ngFor="let tag of participantTags.get(element.code)" class="ms-2" [tag]="tag" [selected]="filter.tags.includes(tag)"></app-tag>
                </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: ['fullname'];" (click)="onParticipantSelected(row)"></tr>
        </table>
    </div>
</div>
<ng-template #noResults>
    <div class="d-flex flex-column align-items-center mt-5">
        <div class="row">
            <app-image class="col-4 offset-4" image="./assets/brand/noResults.svg" [lazily]="false"></app-image>
            <h2 class="text-center text-muted">{{text('participants.emptyState_after_filter.title')}}</h2>
        </div>
    </div>
</ng-template>
<ng-template #loadingComponent>
    <app-loading class="d-flex align-items-center justify-content-center" [state]="loadingState"></app-loading>
</ng-template>
