import {ChartDataSets, ChartOptions} from 'chart.js';
import {ProgressOverTimeFilter} from '../../../viewmodels/progressOverTimeFilter';
import {Label} from 'ng2-charts';
import {format} from 'date-fns';
import {I18nService} from '../../../services/i18n/i18n.service';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {ProtocolsLineChartProvider} from './protocolsLineChartProvider';
import {Protocols} from '../../../utils/protocols';
import {ForceVelocityProtocolSummaryResultsModelDto} from '../../../dtos/statistics/forceVelocityProtocolSummaryResultsModelDto';
import {MathUtils} from "../../../utils/mathUtils";
import {ChartUtils} from "../../../utils/chartUtils";
import {Kg} from "../../../utils/values/kg";
import {Colors} from "../../../services/colors";
import {DateFormat, Dates, KeyValue} from "common";

export class ForceVelocityLineChartProvider extends ProtocolsLineChartProvider {
    private maxPowerdataset?: ChartDataSets;

    constructor() {
        super();
    }

    getDatasets(protocols: ProtocolDto[], analysis: ProtocolAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: ChartOptions, i18n: I18nService): KeyValue<Label[], ChartDataSets[]> {
        this.maxPowerdataset = undefined;

        const protocolsByDay = Protocols.groupByDay(protocols);

        // Due to how charts lib works, labels are shared among all datasets, and where a dataset doesn't have a value, they have a NaN value
        const days = Array.from(protocolsByDay.keys()).sort();

        // create labels
        const labels: Label[] = days.map(day => format(day, Dates.getFormat(DateFormat.DATE_SHORTER)));

        const datasets: ChartDataSets[] = [];

        let maxValue = 0;
        const dataset = this.createMaxPowerDataset(protocolsByDay, analysis, days, i18n);
        maxValue = Math.max(maxValue, ...(dataset.data as number[]).filter(value => !Number.isNaN(value)));
        this.maxPowerdataset = dataset;
        datasets.push(dataset);

        chartOptions.scales.yAxes[0].ticks = {min: 0, max: maxValue * 1.2};

        // Chart options
        if (!chartOptions.plugins) {
            chartOptions.plugins = {};
        }
        chartOptions.plugins.datalabels = {
            formatter: this.labelFormatter.bind(this),
            anchor: 'end',
            align: 'end',
        };

        return KeyValue.of(labels, datasets);
    }

    private createMaxPowerDataset(protocolsPerDay: Map<number, ProtocolDto[]>, analysis: ProtocolAnalysisServiceResponseDto, days: number[], i18n: I18nService): ChartDataSets {
        const dataset: ChartDataSets = {};

        dataset.label = i18n.format("max_power");
        dataset.data = [];

        dataset.spanGaps = true;
        dataset.lineTension = 0;

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value: number = NaN;
            // Create dataset with values for metrics.
            // Add NaN when there is no value for the day except for the last day, where we add the previous value
            if (protocolsPerDay.has(day)) {
                const protocols = protocolsPerDay.get(day);
                const protocolsToSummaryResults = this.combineProtocolsToResults<ForceVelocityProtocolSummaryResultsModelDto>(protocols, analysis);
                const summaryResults = protocols.map(a => protocolsToSummaryResults.get(a)).filter(r => r !== undefined);
                const summaryMaxPowers = summaryResults.map(r => r.maxPower);
                if (summaryMaxPowers.length > 0) {
                    value = MathUtils.maxOrDefault(summaryMaxPowers, NaN);
                } else if (i === days.length - 1 && days.length > 1) {
                    value = dataset.data[i - 1] as number;
                }
            }
            dataset.data.push(value);
        }

        ChartUtils.formatDatasetWithColor(dataset, Colors.colorPrimary);
        return dataset;
    }

    private labelFormatter(value: number): string {
        // default to integer
        let formatted: string = value.toFixed(0);

        // find the value in the datasets
        let found = false;
        const dataset = this.maxPowerdataset;
        for (const datum of dataset.data) {
            if (datum === value) {
                formatted = new Kg(value).format(1);
                found = true;
                break;
            }
        }
        return formatted;
    }
}
