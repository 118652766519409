import {ChartDataSets, ChartOptions} from 'chart.js';
import {ProgressOverTimeFilter} from '../../../viewmodels/progressOverTimeFilter';
import {Label} from 'ng2-charts';
import {format} from 'date-fns';
import {I18nService} from '../../../services/i18n/i18n.service';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {ProtocolsLineChartProvider} from './protocolsLineChartProvider';
import {Protocols} from '../../../utils/protocols';
import {JumpAnalysisActivityResultsModel} from '../../../dtos/statistics/jumpAnalysisActivityResultsModel';
import {DynamicStrengthIndexSummaryResultsModelDto} from '../../../dtos/statistics/dynamicStrengthIndexSummaryResultsModelDto';
import {ExerciseType} from '../../../models/exerciseType';
import {ActivityResultsModel} from '../../../dtos/statistics/activityResultsModel';
import {Color, DateFormat, Dates, KeyValue} from "common";
import {Colors} from "../../../services/colors";
import {MathUtils} from "../../../utils/mathUtils";
import {ChartUtils} from "../../../utils/chartUtils";
import {Kg} from "../../../utils/values/kg";
import {Value} from "../../../utils/values/value";

export class DsiLineChartProvider extends ProtocolsLineChartProvider {
    private static AXIS_DSI_ID = 'dsi';
    private activitiesDatasets?: Map<ExerciseType, ChartDataSets>;
    private dsiDataset?: ChartDataSets;

    constructor() {
        super();
        this.activitiesDatasets = new Map<ExerciseType, ChartDataSets>();
    }

    getDatasets(protocols: ProtocolDto[], analysis: ProtocolAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: ChartOptions, i18n: I18nService): KeyValue<Label[], ChartDataSets[]> {
        this.dsiDataset = undefined;
        this.activitiesDatasets.clear();

        const activities = protocols.flatMap(p => p.activities);
        const protocolsByDay = Protocols.groupByDay(protocols);

        // Due to how charts lib works, labels are shared among all datasets, and where a dataset doesn't have a value, they have a NaN value
        const days = Array.from(protocolsByDay.keys()).sort();

        // create labels
        const labels: Label[] = days.map(day => format(day, Dates.getFormat(DateFormat.DATE_SHORTER)));

        const datasets: ChartDataSets[] = [];

        let maxValue = 0;
        const uniqueActivitiesExerciseTypes = new Set(activities.map(activity => activity.config.exerciseTypeEnum));
        for (const activityExerciseType of uniqueActivitiesExerciseTypes) {
            const dataset = this.createPeakForceDataset(activityExerciseType, protocolsByDay, analysis, days);
            maxValue = Math.max(maxValue, ...(dataset.data as number[]).filter(value => !Number.isNaN(value)));
            this.activitiesDatasets.set(activityExerciseType, dataset);
            datasets.push(dataset);
        }

        chartOptions.scales.yAxes[0].ticks = {min: 0, max: maxValue * 1.2};

        const dsiDataset = this.createDsiDataset(protocolsByDay, analysis, days);
        const maxDsi = Math.max(0, ...(dsiDataset.data as number[]).filter(value => !Number.isNaN(value)));
        this.dsiDataset = dsiDataset;
        datasets.push(dsiDataset);

        chartOptions.scales.yAxes.push({id: DsiLineChartProvider.AXIS_DSI_ID, position: 'right', display: true, ticks: {min: 0, max: maxDsi * 1.2}});

        // Chart options
        if (!chartOptions.plugins) {
            chartOptions.plugins = {};
        }
        chartOptions.plugins.datalabels = {
            formatter: this.labelFormatter.bind(this),
            anchor: 'end',
            align: 'end',
        };

        return KeyValue.of(labels, datasets);
    }

    private createPeakForceDataset(activityExerciseType: ExerciseType, protocolsPerDay: Map<number, ProtocolDto[]>, analysis: ProtocolAnalysisServiceResponseDto, days: number[]): ChartDataSets {
        const dataset: ChartDataSets = {};
        dataset.data = [];

        const isJumpActivity = activityExerciseType === ExerciseType.JUMP_ANALYSIS;
        const isTotalActivity = activityExerciseType === ExerciseType.TOTAL_EVALUATION;

        let color: Color;
        if (isJumpActivity) {
            dataset.label = 'JUMP';
            color = Colors.colorAccent;
        } else if (isTotalActivity) {
            dataset.label = 'IMTP';
            color = Colors.colorPrimary;
        }

        dataset.spanGaps = true;
        dataset.lineTension = 0;

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value: number = NaN;
            // Create dataset with values for metrics.
            // Add NaN when there is no value for the day except for the last day, where we add the previous value
            if (protocolsPerDay.has(day)) {
                const protocols = protocolsPerDay.get(day);
                const activities = protocols.flatMap(p => p.activities).filter(a => a.config.exerciseTypeEnum === activityExerciseType);
                const activitiesToResults = this.combineActivitiesToResults<JumpAnalysisActivityResultsModel | ActivityResultsModel>(activities, analysis);

                if (isJumpActivity) {
                    const jumpsMaxValues = Array.from(activitiesToResults.values()).map((r: JumpAnalysisActivityResultsModel) => {
                        return r.jump.maxForce;
                    });
                    value = MathUtils.maxOrDefault(jumpsMaxValues, 0);
                } else if (isTotalActivity) {
                    //TODO support total Evaluation
                }
            }
            dataset.data.push(value);
        }

        ChartUtils.formatDatasetWithColor(dataset, color);
        return dataset;
    }

    private createDsiDataset(protocolsPerDay: Map<number, ProtocolDto[]>, analysis: ProtocolAnalysisServiceResponseDto, days: number[]): ChartDataSets {
        const dataset: ChartDataSets = {};

        dataset.label = 'DSI';
        dataset.data = [];

        dataset.spanGaps = true;
        dataset.lineTension = 0;

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value: number = NaN;
            // Create dataset with values for metrics.
            // Add NaN when there is no value for the day except for the last day, where we add the previous value
            if (protocolsPerDay.has(day)) {
                const protocols = protocolsPerDay.get(day);
                const protocolsToSummaryResults = this.combineProtocolsToResults<DynamicStrengthIndexSummaryResultsModelDto>(protocols, analysis);
                const summaryDsi = protocols.map(a => protocolsToSummaryResults.get(a)).filter(r => r !== undefined).map(r => r.dsi);
                if (summaryDsi.length > 0) {
                    value = MathUtils.maxOrDefault(summaryDsi, 0);
                } else if (i === days.length - 1 && days.length > 1) {
                    value = dataset.data[i - 1] as number;
                }
            }
            dataset.data.push(value);
        }
        dataset.yAxisID = DsiLineChartProvider.AXIS_DSI_ID;
        dataset.borderDash = [2];
        ChartUtils.formatDatasetWithColor(dataset, Colors.RED);
        return dataset;
    }


    private labelFormatter(value: number): string {
        // default to integer
        let formatted: string = value.toFixed(0);

        // find the value in the datasets
        let found = false;
        for (const dataset of Array.from(this.activitiesDatasets.values())) {
            for (const datum of dataset.data) {
                if (datum === value) {
                    formatted = new Kg(value).format(1);
                    found = true;
                    break;
                }
            }
            if (found) {
                break;
            }
        }

        const dataset = this.dsiDataset;
        for (const datum of dataset.data) {
            if (datum === value) {
                formatted = new Value(value).format(1);
                found = true;
                break;
            }
        }

        return formatted;
    }
}
