import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {ConfirmEmailDto} from '../../dtos/confirmEmail.dto';
import {NavigationService} from '../navigation.service';
import {SubscriptionService} from '../subscription.service';
import {AccountDto} from "common";
import {BrowserStorageService, SubscriptionDto} from "common";
import {environment} from "../../../environments/environment";
import { AcceptAccountAssociationDto } from '../../dtos/acceptAssociatedAccount.dto';
import { AcceptParticipantShareDto } from '../../dtos/acceptParticipantShare.dto';

/**
 * Handles authentication with the server as well as handles the authentication state on the browser
 * Server is using Basic Authentication
 */
@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    public static readonly PERSISTENCE_USER_ACCOUNT_KEY = 'account';
    public static readonly PERSISTENCE_USER_SUBSCRIPTIONS_KEY = 'subscriptions';

    private readonly _accountSubject: BehaviorSubject<AccountDto>;
    private readonly _accountSubscriptionsSubject: BehaviorSubject<SubscriptionDto[]>;

    constructor(private httpClient: HttpClient, private storage: BrowserStorageService,
                private subscriptionService: SubscriptionService, private navigation: NavigationService) {
        this._accountSubject = new BehaviorSubject<AccountDto>(storage.loadDto(AuthenticationService.PERSISTENCE_USER_ACCOUNT_KEY, AccountDto));
        this._accountSubscriptionsSubject = new BehaviorSubject<SubscriptionDto[]>(storage.loadDtos(AuthenticationService.PERSISTENCE_USER_SUBSCRIPTIONS_KEY, SubscriptionDto));
    }

    public login(email: string, password: string): Observable<AccountDto> {
        // before login in, log user out. Accidental visits to log-in component shouldn't log out user, but only when they request to act.
        this.logout();

        const authData = window.btoa(email + ':' + password);
        return this.httpClient.post<any>(this.apiUrl + 'api/authorization/login', null, {
            headers: new HttpHeaders({
                'Authorization': 'Basic ' + authData
            })
        }).pipe(mergeMap(response => {
            // Response contains the token. Create an empty account having only the token.
            // It will be consumed by authGuard and will be included in the /self request for authentication
            const accountDto = new AccountDto();
            accountDto.authToken = response.token;
            this.accountSubject.next(accountDto);
            this.saveAccountData();
            return this.getSelf();
        })).pipe(map(account => {
            // Account is now updated with the response from /self
            this.accountSubject.next(account);
            this.saveAccountData();
            return of(account);
        })).pipe(mergeMap(_account => {
            // Get the user subscriptions
            return this.subscriptionService.getSubscriptions();
        })).pipe(mergeMap(subscriptions => {
            // Store user subscriptions
            this._accountSubscriptionsSubject.next(subscriptions);
            this.saveAccountData();
            return of(this.account);
        }));
    }

    private getSelf(): Observable<AccountDto> {
        return this.httpClient.get<any>(this.apiUrl + 'api/authorization/self')
            .pipe(map(selfData => {
                let accountDto = new AccountDto().deserialize(selfData);
                accountDto.authToken = this.account.authToken;
                this._accountSubject.next(accountDto);
                this.saveAccountData();
                return accountDto;
            }));
    }

    public get account(): AccountDto {
        return this._accountSubject.value;
    }

    public get subscriptions(): SubscriptionDto[] {
        return this._accountSubscriptionsSubject.value;
    }

    public get accountSubject(): Subject<AccountDto> {
        return this._accountSubject;
    }

    public logout(): void {
        console.log(`Logging out`);
        this.storage.deleteAll();
        this.accountSubject.next(null);
        this.navigation.goToLogin();
    }

    private saveAccountData(): void {
        this.storage.saveDto(AuthenticationService.PERSISTENCE_USER_ACCOUNT_KEY, this.account);
        this.storage.saveDtos(AuthenticationService.PERSISTENCE_USER_SUBSCRIPTIONS_KEY, this.subscriptions)
    }

    public confirmEmail(dto: ConfirmEmailDto): Observable<any> {
        return this.httpClient.post<any>(this.apiUrl + `api/authorization/confirmEmail`, dto);
    }

    public acceptAccountAssociation(dto: AcceptAccountAssociationDto): Observable<any> {
        return this.httpClient.post<any>(this.apiUrl + `api/authorization/acceptAccountAssociation`, dto);
    }

    public acceptParticipantShare(dto: AcceptParticipantShareDto): Observable<any> {
        return this.httpClient.post<any>(this.apiUrl + `api/authorization/acceptParticipantShare`, dto);
    }

    private get apiUrl(): string {
        return environment.apiUrl;
    }
}
