import {Injectable} from '@angular/core';
import {AuthenticationService} from './api/authentication.service';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CdnService {

    constructor(private authenticationService: AuthenticationService) {
    }

    public getPath(cdnResource: string): string {
        return `${environment.cdnUrl}${cdnResource}?token=${this.authenticationService.account.authToken}`;
    }
}
