export class ActivityConfigSetMetaKeys {
    public static readonly TARGET = "target";
    public static readonly TARGET_REPS = "targetRepetitions";
    public static readonly MARGIN_ERROR = "marginError"; // The upper/bottom bound around the target in which is still considered success (in percent)
    public static readonly DIFFICULTY_LEVEL = "difficultyLevel";
    public static readonly MIN_DISTRIBUTION_RANGE = "minDistributionRange"; // value in range [0,1]
    public static readonly MAX_DISTRIBUTION_RANGE = "maxDistributionRange"; // value in range [0,1]
    public static readonly GAME_TYPE = "GameType";
    public static readonly JUMP_TYPE = "JumpType";
    public static readonly LEFT_DISTRIBUTION = "leftDistribution"; // value in range [0,1] (i.e. 0.4 = 40% left, so 60% right)
    public static readonly FRONT_DISTRIBUTION = "frontDistribution"; // value in range [0,1]
    public static readonly SENS_MOVEMENT = "sensMovement";
    public static readonly INFLATABLE_TOOL = "InflatableTool";
    public static readonly EYES_OPEN = "eyesOpen";
    public static readonly MOVEMENT_DIRECTION = "movementDirection";
    public static readonly BODY_WEIGHT = "bodyWeight";
    public static readonly INPUT_SEGMENT_LENGTH = "inputSegmentLength";
}
