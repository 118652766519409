<div class="component full-width full-height d-flex full-width flex-row">
    <div class="d-flex flex-column flex-grow-1">
        <app-table-header [compact]="compact"></app-table-header>
        <app-table-title *ngFor="let t of titles" [compact]="compact">{{t}}</app-table-title>
    </div>
    <div *ngFor="let d of data" class="d-flex flex-column flex-grow-1">
        <app-table-title [backgroundColor]="d.key[1]" [compact]="compact" class="text-center">{{d.key[0]}}</app-table-title>
        <app-table-value *ngFor="let v of d.value" [compact]="compact">{{v}}</app-table-value>
    </div>
</div>
