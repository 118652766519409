<div>
    <ng-container *ngIf="viewType === resultViewTypes.FULL">
        <div class="section section-top-row d-flex flex-row">
            <div class="image me-3">
                <app-image [image]="image" [lazily]="false" [description]="activityTitle(activity)"></app-image>
            </div>
            <div class="max-per-side flex-grow-1 flex-column">
                <app-max-per-side-chart class="d-block" [maxPerSide]="maxPerSide"
                                        [chartTitle]="'isometricExercise.results.maxSuccessRateMainTitle' | text"></app-max-per-side-chart>
            </div>
        </div>
        <div class="repetition mb-5 mt-4" *ngFor="let rep of statistics.repResults">
            <div class="rep-title d-flex flex-row mb-2">
                <div>{{'dataTable.header.repetition' | text}} {{rep.repOrdinal}}:</div>
                <div class="side ms-2" [style.color]="formatSideAttribute(rep.repSide, sideTypeAttributes.COLOR)">
                    {{formatSideAttribute(rep.repSide, sideTypeAttributes.TITLE)}}
                </div>
            </div>
            <div class="stats row">
                <div class="col-3 d-flex flex-column align-items-center">
                    <b>{{rep.successRate | toValue:unitTypes.PERCENT}}</b>
                    <div>{{'exercise.isometry.results.success' | text}}</div>
                    <b class="mt-2">{{rep.maxTimeOnTarget / 1000 | toValue: unitTypes.SECONDS : 1}}</b>
                    <div>{{'exercise.isometry.results.longest_success_time' | text}}</div>
                    <b class="mt-2">{{rep.rfdToMax | toValue: unitTypes.KG_PER_SECOND: 1}}</b>
                    <div>{{'exercise.isometry.results.rfd_to_max' | text}}</div>
                </div>
                <div class="col-3 d-flex flex-column align-items-center">
                    <b>{{rep.totalTimeOnTarget / 1000 | toValue:unitTypes.SECONDS: 1}}</b>
                    <div>{{'exercise.isometry.results.total_success_time' | text}}</div>
                    <b class="mt-2">{{rep.timeToMax / 1000 | toValue:unitTypes.SECONDS: 1}}</b>
                    <div>{{'exercise.isometry.results.time_to_max' | text}}</div>
                    <b class="mt-2">{{rep.rfdFromMax | toValue: unitTypes.KG_PER_SECOND: 1}}</b>
                    <div>{{'exercise.isometry.results.rfd_from_max' | text}}</div>
                </div>
                <div class="col-6 d-flex">
                    <app-samples-line-chart class="chart"
                                            [data]="repChartData.get(rep.repOrdinal + rep.repSide)"
                                            [markArea]="repChartArea.get(rep.repOrdinal + rep.repSide)"></app-samples-line-chart>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="viewType === resultViewTypes.ROW">
        <div class="view-type-row d-flex flex-row">
            <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
            <div class="section flex-grow-1 row">
                <div class="col-8 offset-2">
                    <div class="text-center">
                        <div class="title rounded-pill ps-3 pe-3 pt-1 pb-1 d-inline-block">
                            {{'protocolResults.summary.isometric.title' | text}}
                        </div>
                    </div>
                    <div class="overflow-auto d-flex flex-row mt-3">
                        <div class="flex-grow-1 text-center">
                            <app-table-header>{{'data' | text}}</app-table-header>
                            <app-table-title>{{'protocolResults.summary.isometric.averageSetsSuccess' | text}}</app-table-title>
                        </div>
                        <div *ngFor="let rep of statistics.meanSuccessRatePerSide" class="flex-grow-1">
                            <app-table-header [backgroundColor]="formatSideAttribute(rep.side, sideTypeAttributes.COLOR)" color="white">
                                {{formatSideAttribute(rep.side, sideTypeAttributes.TITLE)}}</app-table-header>
                            <app-table-value [sideForColor]="rep.side">{{rep.value | toValue:unitTypes.PERCENT}}</app-table-value>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
