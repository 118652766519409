import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConfirmEmailComponent} from './screens/confirm-email/confirm-email.component';
import {ParticipantComponent} from './screens/participant/participant.component';
import {ProfileComponent} from './screens/profile/profile.component';
import {UserHomeComponent} from './screens/user-home/user-home.component';
import {ParticipantProgressComponent} from './screens/participant-progress/participant-progress.component';
import {ProtocolResultsScreenComponent} from './screens/protocol-results-screen/protocol-results-screen.component';
import {SubscriptionTypeGuard} from './utils/guards/subscription-type-guard';
import {ProPlusPromoComponent} from './screens/pro-plus-promo/pro-plus-promo.component';
import {LoginComponent} from "./components/login/login.component";
import {AuthGuard} from "./guards/auth-guard";
import {DevPlaygroundComponent} from "./components/dev-playground/dev-playground.component";
import {DevGuard} from "./guards/dev-guard.service";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import { AcceptAccountAssociationComponent } from './screens/accept-account-association/accept-account-association.component';
import { AcceptParticipantShareComponent } from './screens/accept-participant-share/accept-participant-share.component';

const routes: Routes = [
    // Home
    {path: '', redirectTo: 'participants', pathMatch: 'full'},

    // Role User
    {
        path: 'participants',
        data: {
            breadcrumb: {label: 'Participants'},
        },
        children: [
            {path: '', component: UserHomeComponent, canActivate: [AuthGuard, SubscriptionTypeGuard]},
            {
                path: ':code',
                data: {
                    breadcrumb: {label: 'Participant', alias: 'participantCode'}
                },
                children: [
                    {path: '', component: ParticipantComponent, canActivate: [AuthGuard, SubscriptionTypeGuard]},
                    {
                        path: 'progress',
                        data: {breadcrumb: 'Progress'},
                        children: [
                            {path: '', component: ParticipantProgressComponent, canActivate: [AuthGuard, SubscriptionTypeGuard]},
                            {
                                path: 'protocol/:baseCode',
                                component: ProtocolResultsScreenComponent,
                                canActivate: [AuthGuard, SubscriptionTypeGuard],
                                data: {breadcrumb: {alias: 'protocolTitle'}}
                            },
                            {
                                path: 'activity/:baseCode',
                                component: ProtocolResultsScreenComponent,
                                canActivate: [AuthGuard, SubscriptionTypeGuard],
                                data: {breadcrumb: {alias: 'protocolTitle'}}
                            },
                        ]
                    },
                ]
            },
        ]
    },

    // unauthorized
    {path: 'auth/login', component: LoginComponent, data: {breadcrumb: {skip: true}}},
    {path: 'confirmEmail/:email/:token', component: ConfirmEmailComponent, data: {breadcrumb: {skip: true}}},
    {path: 'acceptAccountAssociation/:email/:token', component: AcceptAccountAssociationComponent, data: {breadcrumb: {skip: true}}},
    {path: 'acceptParticipantShare/:token', component: AcceptParticipantShareComponent, data: {breadcrumb: {skip: true}}},

    // authorized. Any role
    {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard, SubscriptionTypeGuard], data: {breadcrumb: {skip: true}}},

    // ProPlus promo
    {path: 'features/proPlus', component: ProPlusPromoComponent, canActivate: [AuthGuard], data: {breadcrumb: {skip: true}}},

    // Dev
    {
        path: 'dev', component: DevPlaygroundComponent, canActivate: [AuthGuard, DevGuard], data: {breadcrumb: {skip: true}}
    },

    // 404
    {path: '**', component: PageNotFoundComponent, data: {breadcrumb: {skip: true}}}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'corrected'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
