import {ProtocolDto} from '../../../dtos/protocol.dto';
import {BuiltinProtocols} from '../../../models/builtinProtocols';
import {McCallLineChartProvider} from './mcCallLineChartProvider';
import {ProtocolsLineChartProvider} from './protocolsLineChartProvider';
import {IytLineChartProvider} from './IytLineChartProvider';
import {KneeAntagonistLineChartProvider} from './kneeAntagonistLineChartProvider';
import {ForceVelocityLineChartProvider} from './forceVelocityLineChartProvider';
import {DsiLineChartProvider} from './dsiLineChartProvider';
import {AdvancedStandingEvaluationLineChartProvider} from "./advancedStandingEvaluationChartProvider";

export class ProtocolsLineChartFactory {
    public static createProvider(protocols: ProtocolDto[]): ProtocolsLineChartProvider {
        let provider: ProtocolsLineChartProvider;
        const protocolConfig = protocols[0].config;
        switch (protocolConfig.getBaseConfigCode()) {
            case BuiltinProtocols.IDENTIFIERS.MC_CALL:
                provider = new McCallLineChartProvider();
                break;
            case BuiltinProtocols.IDENTIFIERS.ADVANCED_STANDING_EVALUTATION:
                provider = new AdvancedStandingEvaluationLineChartProvider();
                break;
            case BuiltinProtocols.IDENTIFIERS.ASH_IYT:
                provider = new IytLineChartProvider();
                break;
            case BuiltinProtocols.IDENTIFIERS.KNEE_ANTAGONIST:
                provider = new KneeAntagonistLineChartProvider();
                break;
            case BuiltinProtocols.IDENTIFIERS.FORCE_VELOCITY:
                provider = new ForceVelocityLineChartProvider();
                break;
            case BuiltinProtocols.IDENTIFIERS.DSI:
                provider = new DsiLineChartProvider();
                break;
            default:
                throw new Error('Unsupported');
        }
        return provider;
    }
}
