import {BodySideType} from './bodySideType';
import {I18nService} from "../services/i18n/i18n.service";

export enum BodyPart {
    UNKNOWN = "UNKNOWN",
    LEG = "LEG",
    ARM = "ARM",
    TORSO = "TORSO",
    NECK = "NECK",
    THIGH = "THIGH",
    CALF = "CALF"
}

export class BodyParts {
    public static i18n: I18nService;

    public static parse(bodyPart: string): BodyPart {
        return BodyPart[bodyPart as keyof typeof BodyPart];
    }

    public static format(bodyPart: BodyPart, side: BodySideType = BodySideType.NONE): string {
        let title: string;
        if (side === BodySideType.NONE) {
            switch (bodyPart) {
                case BodyPart.NECK:
                    title = this.i18n.text('bodyPart.neck');
                    break;
                case BodyPart.TORSO:
                    title = this.i18n.text('bodyPart.torso');
                    break;
                case BodyPart.ARM:
                    title = this.i18n.text('bodyPart.arm');
                    break;
                case BodyPart.LEG:
                    title = this.i18n.text('bodyPart.leg');
                    break;
                case BodyPart.UNKNOWN:
                default:
                    title = this.i18n.text('bodyside.none');
                    break;
            }
        } else if (side === BodySideType.BOTH) {
            switch (bodyPart) {
                case BodyPart.NECK:
                    title = this.i18n.text('bodyPart.neck');
                    break;
                case BodyPart.TORSO:
                    title = this.i18n.text('bodypartside.none');
                    break;
                case BodyPart.ARM:
                    title = this.i18n.text('bodyPart.arms');
                    break;
                case BodyPart.LEG:
                    title = this.i18n.text('bodyPart.legs');
                    break;
                case BodyPart.UNKNOWN:
                default:
                    title = this.i18n.text('bodypartside.both');
                    break;
            }
        }
        return title;
    }
}
