import {ActivityConfigsOfBuiltinProtocols} from './activityConfigsOfBuiltinProtocols';
import {Code} from "common";

export enum BuiltInExerciseTemplateType {
    KFORCE_GRIP = 'ea81d302-126a-4e39-9613-c02202827b27',
    KFORCE_GRIP_KINESTHESIA = 'b7253d17-ff73-48ce-8157-196449e0121f',
    GRIP_FAST = 'a5fed68a-07ed-47c0-ae77-44c8e7a8cb8f',
    GRIP_ENDURANCE = '68545ae8-875b-11e9-bc42-526af7764f64',
    GRIP_REP_COUNT = '58d1784e-8182-42ca-97e0-801b469e6294',
    GRIP_ISOMETRIC = 'a3d57a5e-c0f6-4eb8-832a-de78e4ca6d28',
    LEG_QUADRICEPS_IN_STRAIGHT_KNEE_ANGLE = 'b9899479-192b-418b-97b5-961518044ae2',
    LEG_KNEE_EXTENSION = '3d0d1c26-f8e7-459a-8f09-4dc951bb99b7',
    LEG_KNEE_EXTENSION_METER = '15613c38-4ef8-42f9-b93f-3cc6af5ba1a0',
    LEG_KNEE_EXTENSION_60_LINK_ENDURANCE = '89662663-ad8f-45d8-84de-869d0fbac6bb',
    LEG_KNEE_FLEXION_METER = 'e6ef84a0-efee-4ef1-9913-95d6dbb01be5',
    LEG_KNEE_FLEXION = 'aaf52673-4ae8-4ca8-834e-4f9691b59719',
    LEG_KNEE_HAMSTRINGS_90 = '585af95b-3f4a-4294-a428-87c41ae6da9a',
    LEG_ABDUCTORS = 'a06ea82b-60dc-4b6b-b5c3-3de470baf1dc',
    LEG_ADDUCTORS = '7a2c2635-abd7-47a6-91d7-f0e11d0aac6e',
    LEG_ANKLE_EXTENSION = 'c894c2a1-0ba3-4853-90dd-bb58e3d3c23a',
    LEG_ANKLE_FLEXION = '425e9dc0-003f-41a4-8342-8f9342702ef9',
    LEG_HIP_EXTENSION = '7aec4c6f-82a9-4842-b2b0-216ec5923667',
    TORSO_TRUNK_EXTENSION = 'ce26d637-b200-4826-9c0f-df4965dc10c9',
    TORSO_TRUNK_FLEXION = '4af9c9dc-13c8-4704-97f8-3f9c7956259d',
    ARM_SHOULDER_SIDE_PROXIMAL_EXTENSION_HORIZONTAL_DELTOID = '98376419-9bcd-4934-a948-e6a915e31b72',
    ARM_DELTOID = 'deae5e47-6ca3-41ac-9a36-8dcbf9afd4c3',
    ARM_SHOULDER_FRONT_PROXIMAL_EXTENSION_HORIZONTAL_DELTOID = '99540360-2414-45dd-a41b-38e4f87087c2',
    ARM_BICEPS = '8b9b9398-4bab-4368-ab87-d23eb53fcde9',
    ARM_TRICEPS = '2a3ca2ea-a032-4c04-9a50-71fa5653e03c',
    ARM_ELBOW_BACKWARDS_INFRASPINATUS = '79ac6ca4-0702-444e-98f0-932d80ee37d8',
    HEAD_NECK_FLEXION = '48effe42-d866-4c57-84f9-2aa10396b0f5',
    HEAD_NECK_FLEXION_METER = '7a01b44f-a1b4-4ea3-a1c8-d0a37295a074',
    HEAD_NECK_LINK_FLEXION_METER = '768db8e2-193a-4b22-b0f8-e8704a44ee65',
    HEAD_NECK_VERTICAL_SIDE_FLEXION = 'c14071e0-a450-47ef-8f6a-5882e44dbe7e',
    HEAD_NECK_SIDE_FLEXION_45 = 'fc7a31ff-904c-47f0-a110-fa0c727c4df5',
    ARM_CLAMPING_FRONT = 'e1824b21-0dc6-4a2e-ae19-09dc513b8e7f',
    ARM_CLAMPING_UP = '8dc8593d-e8f6-4764-adf8-ac378a3412c8',
    ARM_PUSH_DOWN = '55945ac3-642d-4097-aab9-c96a85331849',
    ARM_PUSH_THE_FRONT_WALL = '9dcf7f49-d68f-42e1-90ed-b44ced4b88ac',
    ARM_PUSH_THE_SIDE_WALL = '038e171a-aa7c-4df5-9442-d18d2324c88e',
    MUSCLE_TESTER_KINESTHESIA = 'c8e01848-ce7f-44da-ba23-abdccbd90e63',
    NORDIC_HAMSTRING = 'fd21b14c-5304-4fb1-8910-d7881ae38835',
    MUSCLE_FAST = '5c2910a1-5efc-41e1-8a49-10c0e2c41e4e',
    LEG_QUADRICEPS_IN_STRAIGHT_KNEE_ANGLE_ENDURANCE = '2827db34-8760-11e9-bc42-526af7764f64',
    LEG_KNEE_EXTENSION_ENDURANCE = 'acc2ad80-883f-11e9-bc42-526af7764f64',
    LEG_KNEE_FLEXION_30_MC_ENDURANCE = '1b30ad22-2556-43aa-8ab1-76c1473457dc',
    LEG_KNEE_FLEXION_45_ENDURANCE = 'c65e0956-883f-11e9-bc42-526af7764f64',
    LEG_KNEE_HAMSTRINGS_90_ENDURANCE = '68466c82-8849-11e9-bc42-526af7764f64',
    LEG_ABDUCTORS_ENDURANCE = '744c0602-0a2c-46f1-83e2-d409bb3e060c',
    LEG_ADDUCTORS_ENDURANCE = '923fb6ab-2389-4182-afe0-092fee711a76',
    LEG_ANKLE_EXTENSION_ENDURANCE = '4d7a3c08-2beb-43a2-aea7-6107dd63387e',
    LEG_ANKLE_FLEXION_ENDURANCE = 'a5596a63-8c5d-46a1-9037-5ccc2dda9654',
    LEG_HIP_EXTENSION_ENDURANCE = '253688c4-2115-49f3-8e2b-7152520f5932',
    TORSO_TRUNK_EXTENSION_ENDURANCE = 'a429cb50-e200-41df-8587-2162c1e45592',
    TORSO_TRUNK_FLEXION_ENDURANCE = '1ec77b5c-81ee-472d-ba58-6147dc5ae05d',
    ARM_SHOULDER_SIDE_PROXIMAL_EXTENSION_HORIZONTAL_DELTOID_ENDURANCE = '04aeea4e-ed3a-4ddf-a079-515ac8b5fe61',
    ARM_DELTOID_ENDURANCE = '5bc4a11f-55b7-4726-9617-03bc38dadfbf',
    ARM_SHOULDER_FRONT_PROXIMAL_EXTENSION_HORIZONTAL_DELTOID_ENDURANCE = '17c3b970-46e7-4c91-8fbb-5817afc20d15',
    ARM_BICEPS_ENDURANCE = '889e69c0-5ba9-4a24-9e71-ccf2676605ce',
    ARM_TRICEPS_ENDURANCE = '251bae91-8ce4-49ee-a28f-ca5c0813c1b9',
    ARM_ELBOW_BACKWARDS_INFRASPINATUS_ENDURANCE = '11b60738-d5dc-4b38-809d-4ef4b5fed98d',
    HEAD_NECK_FLEXION_ENDURANCE = '0aeb4bdd-e311-46c1-88ec-6bdb0414eb3d',
    HEAD_NECK_VERTICAL_SIDE_FLEXION_ENDURANCE = '4f576240-f5fe-4a44-9f0c-92bc4484e5a5',
    HEAD_NECK_SIDE_FLEXION_45_ENDURANCE = 'a554a3da-c9af-4f0e-914a-875d5ac85a44',
    ARM_CLAMPING_FRONT_ENDURANCE = 'ce3c6ac7-95f5-4ddb-b752-d7cbf0b78f09',
    ARM_CLAMPING_UP_ENDURANCE = 'c931a4d1-c6c1-4c3f-8a26-01fec5926d43',
    ARM_PUSH_DOWN_ENDURANCE = 'e7ee0b8a-395e-49ab-9ee9-162d7dd7a871',
    ARM_PUSH_THE_FRONT_WALL_ENDURANCE = 'b4280713-7e51-4b46-a80a-d2b61b1714a0',
    ARM_PUSH_THE_SIDE_WALL_ENDURANCE = '8808181f-8825-4280-a492-be0326f03d36',
    LEG_QUADRICEPS_IN_STRAIGHT_KNEE_ANGLE_REP_COUNT = '443f6fec-0ac0-4b89-9895-381205201070',
    LEG_KNEE_EXTENSION_REP_COUNT = '7284e209-fff5-4c49-a000-c3d20fe91e2a',
    LEG_KNEE_FLEXION_REP_COUNT = '585c3eb8-aee4-4524-81d0-9a1bd8c1e9d5',
    LEG_KNEE_HAMSTRINGS_90_REP_COUNT = 'b4c4c576-56d4-4840-bea3-ae467ae88c38',
    LEG_ABDUCTORS_REP_COUNT = '946cbcda-5f4f-4775-a32a-7e6dc8d2eba3',
    LEG_ADDUCTORS_REP_COUNT = '19be0567-9c43-41e0-b8cd-34cd544207d7',
    LEG_ANKLE_EXTENSION_REP_COUNT = 'd9f924c5-00ca-49f5-bf23-327c259f86b7',
    LEG_ANKLE_FLEXION_REP_COUNT = 'e7fe5b40-2491-4a51-98d8-f35d84c2d2bf',
    LEG_HIP_EXTENSION_REP_COUNT = 'fbcc8380-42ee-49a5-9c81-cbc7b5c873dd',
    TORSO_TRUNK_EXTENSION_REP_COUNT = '4ef4be8b-f739-4c02-80e5-4f05c5c2f1b9',
    TORSO_TRUNK_FLEXION_REP_COUNT = '800c7fa7-ea9f-45e8-8285-6928532484ad',
    ARM_SHOULDER_SIDE_PROXIMAL_EXTENSION_HORIZONTAL_DELTOID_REP_COUNT = '676feab2-d48f-46dd-be1a-b7d69e8e9cb9',
    ARM_DELTOID_REP_COUNT = 'de95f2ed-ea14-4d29-afef-eade4f344e3c',
    ARM_SHOULDER_FRONT_PROXIMAL_EXTENSION_HORIZONTAL_DELTOID_REP_COUNT = 'be64cbe4-f8e3-47e5-afdb-13b3add77c9c',
    ARM_BICEPS_REP_COUNT = '41ccc357-cd56-42ee-ba94-51da0b878ee2',
    ARM_TRICEPS_REP_COUNT = 'd5dc4eae-9a12-456d-b61c-414cf9791489',
    ARM_ELBOW_BACKWARDS_INFRASPINATUS_REP_COUNT = 'b10f866d-c5d9-43fc-92ae-2c13ffd3b373',
    HEAD_NECK_FLEXION_REP_COUNT = '6d03669e-6e88-4aa1-b85c-f84c698d8fa2',
    HEAD_NECK_VERTICAL_SIDE_FLEXION_REP_COUNT = '9da5f55b-5caa-4a17-9002-fa1790867007',
    HEAD_NECK_SIDE_FLEXION_45_REP_COUNT = 'f3a20c50-ef03-4afa-b24c-5f1dd1483517',
    ARM_CLAMPING_FRONT_REP_COUNT = 'df492bc0-09c1-45c1-8b50-52ff74cc659a',
    ARM_CLAMPING_UP_REP_COUNT = '9d488bf0-a594-43cb-b55e-b2c7b5701356',
    ARM_PUSH_DOWN_REP_COUNT = '8eed265d-92b2-420b-9d5e-79e2d86235a5',
    ARM_PUSH_THE_FRONT_REP_COUNT = 'f20694ef-3b04-4de7-8c6f-a56e303757c4',
    ARM_PUSH_THE_SIDE_REP_COUNT = 'cf271679-46d8-4329-9ee1-22b5efc37a59',
    LEG_QUADRICEPS_IN_STRAIGHT_KNEE_ANGLE_ISOMETRIC = 'e2ea3718-f7b1-4095-8bac-1d2efbed448e',
    LEG_KNEE_HAMSTRINGS_90_ISOMETRIC = 'd0e2855f-67a1-41fa-95ec-258ad8392538',
    LEG_KNEE_EXTENSION_ISOMETRIC = '46216854-77b1-4f5b-90ad-4a53c8002caf',
    LEG_KNEE_FLEXION_ISOMETRIC = 'b194c256-5451-495e-86b8-70175e69f69e',
    LEG_ABDUCTORS_ISOMETRIC = '9cfc2fbf-ac2c-4caa-b07a-9122e7d600fc',
    LEG_ADDUCTORS_ISOMETRIC = '62edfa31-782a-4646-92d9-63deb1b2ae06',
    LEG_ANKLE_EXTENSION_ISOMETRIC = '34da7818-6f98-4b1b-942e-70c2bb4ba57a',
    LEG_ANKLE_FLEXION_ISOMETRIC = 'c4282aaf-4a6d-469e-bf6d-a0ff43b7dfc8',
    LEG_HIP_EXTENSION_ISOMETRIC = '6099ca27-03a2-46f8-90e5-66f4a7f1fd55',
    TORSO_TRUNK_EXTENSION_ISOMETRIC = '3d2be082-d4e3-4ede-a98a-96da39da2b6a',
    TORSO_TRUNK_FLEXION_ISOMETRIC = '46ec4cec-bcbf-4d9f-b022-ff6cf73eef10',
    ARM_SHOULDER_SIDE_PROXIMAL_EXTENSION_HORIZONTAL_DELTOID_ISOMETRIC = 'db4b1d8c-2b64-483a-8f0c-abf8ce31af59',
    ARM_DELTOID_ISOMETRIC = '217542c1-c4f0-421d-b12f-a7d741270a8f',
    ARM_SHOULDER_FRONT_PROXIMAL_EXTENSION_HORIZONTAL_DELTOID_ISOMETRIC = '7cde4524-a5bc-452c-bc58-bfc9baacc5d0',
    ARM_BICEPS_ISOMETRIC = '883c5c9f-eae3-4a32-a539-fc291dc8a09d',
    ARM_TRICEPS_ISOMETRIC = '650be0e3-2e41-43f3-afbe-0b9d3b5e9a9b',
    ARM_ELBOW_BACKWARDS_INFRASPINATUS_ISOMETRIC = '40db05bf-d46a-4d3a-9f8e-d25345f27532',
    HEAD_NECK_FLEXION_ISOMETRIC = '7b09fe9b-c935-4bcb-9909-91a8699102df',
    HEAD_NECK_VERTICAL_SIDE_FLEXION_ISOMETRIC = '88f2308c-3df7-4cb0-b7c6-b5b555d93b1a',
    HEAD_NECK_SIDE_FLEXION_45_ISOMETRIC = '875a78b2-c9e9-49d6-ac26-d146d70bc08e',
    ARM_CLAMPING_FRONT_ISOMETRIC = '4e3ecee3-33af-41f0-9ad1-a186d62e8d68',
    ARM_CLAMPING_UP_ISOMETRIC = '84118966-4c56-4582-8b0c-14e41895835c',
    ARM_PUSH_DOWN_ISOMETRIC = '8fccc0a1-b493-4395-94a5-38c505b626af',
    ARM_PUSH_THE_FRONT_WALL_ISOMETRIC = '53eb1c82-7a04-4880-8628-47f2eda7888d',
    ARM_PUSH_THE_SIDE_WALL_ISOMETRIC = '95e45d33-f293-4eed-854c-53b445dca51f',
    STABLE_BIPODAL_POSTURE__STATIC_DISTRIBUTION_EXERCISE__NORMAL = 'e8e80be3-cb67-48fe-87ba-51298a9c38ec',
    STABLE_BIPODAL_POSTURE__STATIC_DISTRIBUTION_EXERCISE__HEELS_TOES = '8e2a3c25-b95b-42b5-b7af-24099a19a1d8',
    STABLE_MONOPODAL_POSTURE__STATIC_DISTRIBUTION_EXERCISE__RIGHT = 'ea1c3a9c-f7d6-445b-9bf9-2d674d18202b',
    STABLE_MONOPODAL_POSTURE__STATIC_DISTRIBUTION_EXERCISE__LEFT = '31fac9f2-5a92-4427-a77a-7004ad131c35',
    MAX_EVALUATION = 'b728e6e0-f3b4-4768-bf8c-21543a15864e',
    STABILITY = 'c8777c5f-68b1-4f71-949b-93d75b338563',
    STANDING_EVALUATION = 'f90bf019-0847-4ec4-bd58-feb2632a0936',
    STANDING_EVALUATION_HEXAS = 'fc005f62-51d6-4763-836d-f12dacd92e19',
    /** @deprecated 2 sensors unipodal*/
    LEFT_UNIPODAL_STANCE = 'a806431f-721f-4809-a07f-8b7f75892eac',
    /** @deprecated 2 sensors unipodal*/
    RIGHT_UNIPODAL_STANCE = '0f9223ff-c400-4d97-bc80-e9f2ddcd234c',
    UNIPODAL_STANCE = '1e41c57c-276d-4549-9cca-aae1d22c0549',
    UNIPODAL_STANCE_DELTAS = '1db31623-0424-4a2a-b7cb-3dc2b4588cb9',
    DROP_LAND_DELTAS = '9d19edb5-bf0e-4f2d-92e9-54d0316a5578',
    UNIPODAL_DROP_LAND_DELTAS = 'ed65159b-be35-48be-bf54-14a2aad81693',
    LATERAL_STANCE = '5819ee75-c559-4606-ad90-2a66c18049db',
    UNSTABLE_BIPODAL_POSTURE__STATIC_DISTRIBUTION_EXERCISE__NORMAL = 'bbf9b33d-3d5d-46dc-9b02-880b72676c6d',
    UNSTABLE_BIPODAL_POSTURE__STATIC_DISTRIBUTION_EXERCISE__HEELS_TOES = '72dbc872-eacb-47e9-bae8-128137d168bd',
    UNSTABLE_BIPODAL_POSTURE__STATIC_DISTRIBUTION_EXERCISE__SQUAT = 'ba4dbe4c-cfe7-494c-bb8c-22daaa8d926e',
    UNSTABLE_MONOPODAL_POSTURE__STATIC_DISTRIBUTION_EXERCISE__RIGHT = 'bc50f54c-24fa-4822-b754-1817012c0f1f',
    UNSTABLE_MONOPODAL_POSTURE__STATIC_DISTRIBUTION_EXERCISE__LEFT = '7360e475-c3c8-49e5-9c8a-cdabf3bc8de3',
    LEG_PRESS_DYNAMIC_REPORT = 'f311af83-6794-4e64-b217-107951b6fa3c',
    LEGPRESS__STATIC_DISTRIBUTION_EXERCISE__EXERCISE = 'a5b0df54-0bf5-497d-8ccf-067086c243ed',
    SQUAT_DYNAMIC_REPORT = '0b178b57-33ca-4a1d-8987-aeccda28aa44',
    SQUAT__STATIC_DISTRIBUTION_EXERCISE__EXERCISE = 'be4b40aa-e62c-439a-850c-72a0b5c34aee',
    SQUAT__COMBINED_ANALYSIS__EXERCISE = '10d13223-7557-44a2-9143-de963909d62a',
    HEXAS_SQUAT__COMBINED_ANALYSIS__EXERCISE = '2baa71e2-1cb0-49f0-8348-f3aa181e3c93',
    PUSHUP__COMBINED_ANALYSIS__EXERCISE = '7486b2a5-61d8-454b-b750-67928960f3b7',
    HEXAS_PUSHUP__COMBINED_ANALYSIS__EXERCISE = 'cfe02830-dde0-4a1d-92e6-d6dd089cbb0a',
    HAMSTRINGS_DYNAMIC_REPORT = '7cc85242-71f1-42df-adad-751c5b69f692',
    HAMSTRINGS__STATIC_DISTRIBUTION_EXERCISE__EXERCISE = '93f7054d-da9d-408c-8b9a-734bdfc3aa7b',
    ISOINERTIAL_DYNAMIC_REPORT = 'd23a5d49-a9d1-47f6-adf2-1d6523c78ec0',
    ISOINERTIAL__STATIC_DISTRIBUTION_EXERCISE__EXERCISE = 'f3f47681-c262-4c86-ba5b-0c595820a233',
    PUSHUPS_DYNAMIC_REPORT = '0240995d-d011-4c9f-8567-7a8956f8ac0a',
    PUSHUPS__STATIC_DISTRIBUTION_EXERCISE__EXERCISE = 'b5fea7ff-e47a-4d0c-a485-928c39a1a8b6',
    SHOULDERS__STATIC_DISTRIBUTION_EXERCISE__STRETCHED_ARMS__NORMAL = '9e31e9b2-823f-451d-8f97-f8b0f28cf209',
    SHOULDERS__STATIC_DISTRIBUTION_EXERCISE__STRETCHED_ARMS__FRONT_BACK = 'a263a35e-cee0-4ad9-92b6-b632c6021e05',
    SHOULDERS__STATIC_DISTRIBUTION_EXERCISE__BENT_ARMS__NORMAL = '5707bc4f-61a1-4660-a7bc-3c0a685967bd',
    SHOULDERS__STATIC_DISTRIBUTION_EXERCISE__BENT_ARMS__FRONT_BACK = '3d119baf-fef8-48fe-b9a1-cd6fc22308ef',
    SHOULDERS__STATIC_DISTRIBUTION_EXERCISE__HANDSTAND__NORMAL = '7b8d5ae3-d603-4c51-b1e6-d4927e7ccba0',
    SHOULDERS__STATIC_DISTRIBUTION_EXERCISE__HANDSTAND__FRONT_BACK = 'd1956858-440c-4e63-9e9e-2a3abffa1dbc',
    LOW_BACK_PAIN__STATIC_DISTRIBUTION_EXERCISE__OPPOSED_ARM_LEG = 'c5c16454-28a6-4c06-9bec-454817ddd479',
    PLATES_DUAL_KINESTHESIA = 'b5609572-52b2-43f6-a02b-716fbd426aea',
    PLATES_KANGAROO_SURFING = '68f5dc83-2dca-43da-8d30-52850f8d1896',
    PLATES_KANGAROO_SCUBA = 'd3bd3e10-bcb2-412b-9002-3345f01e5d8a',
    JUMP_ANALYSIS_EVALUATION_CMJ = '799483aa-73b0-4170-a246-ed6f01315fb1',
    JUMP_ANALYSIS_EVALUATION_SJ = 'ed1505d3-cb12-40ec-be11-36a96b74abe2',
    JUMP_ANALYSIS_HEXAS_EVALUATION_CMJ = '81edc038-4ff5-4692-9ded-3b0e93b7fa4d',
    JUMP_ANALYSIS_HEXAS_EVALUATION_SJ = '1bfeae36-4f88-423f-a609-c09bff801959',
    JUMP_ANALYSIS_HEXAS_EVALUATION_JD = '87ed0d7b-1e81-41d1-b88a-a37fb7a58112',
    JUMP_ANALYSIS_HEXAS_EVALUATION_UNIPODAL_CMJ = '8509ca8c-2f65-4332-b75d-c6dfef4924da',
    JUMP_ANALYSIS_HEXAS_EVALUATION_UNIPODAL_SJ = 'aec4b317-f0e7-4133-babc-4a3fd8e6c465',
    JUMP_ANALYSIS_PLATES_EVALUATION_UNIPODAL_CMJ = '7c28a00d-87b0-40d1-9bc2-13ae7526b255',
    JUMP_ANALYSIS_PLATES_EVALUATION_UNIPODAL_SJ = '8b5860d9-3834-4bbe-8323-bcc1609a7665',
    BUBBLE = 'a1d58436-7888-487a-9f4b-49ef72a8f197',
    BUBBLE_KINESTHESIA = 'c7721eee-9034-43d0-a555-d2a90727c211',
    BUBBLE_KINESTHESIA_DUAL = '31397916-b5cb-4f7d-8f2f-2890719257df',
    BUBBLE_ENDURANCE = '9626c43a-8763-11e9-bc42-526af7764f64',
    BUBBLE_REP_COUNT = '02f6d9a2-638d-42de-9909-cfea5caa2ca5',
    BUBBLE_ISOMETRIC = '9b93f591-f0c6-4498-a4bc-31f523269c78',
    SENS_SQUAT = '0583c518-f4e7-41fa-add6-88ac473d5d50',
    SENS_SHOULDER = '7eef36d3-30cf-43c1-a8af-e0de9626a699',
    SENS_BACK = '85cd6459-88a6-4ba8-a2a5-86ef8db46468',
    SENS_KNEE = 'b2fd2caf-b05a-4fad-a0a6-102d116c0eb3',
    SENS_KNEE_FLEXION_METER = 'a84c17ea-9f14-4992-9e55-2fcd9604fbcd',
    SENS_KINESTHESIA = '92e3ce8c-b853-43c2-b85f-c60327d23dd0',
    SENS_SQUAT_ENDURANCE = '714e2d5c-8762-11e9-bc42-526af7764f64',
    SENS_SHOULDER_ENDURANCE = '14439d60-883c-11e9-bc42-526af7764f64',
    SENS_BACK_ENDURANCE = '1932c440-883c-11e9-bc42-526af7764f64',
    SENS_KNEE_ENDURANCE = '1dc97544-883c-11e9-bc42-526af7764f64',
    SENS_SQUAT_REP_COUNT = '24e0aa6f-2267-4e3c-aad6-13f892c576d0',
    SENS_SHOULDER_REP_COUNT = 'ef1c5a10-b8a0-495e-909f-51abcc6a1e48',
    SENS_BACK_REP_COUNT = '650e8900-830b-4426-b88a-6e2edc48cbed',
    SENS_KNEE_REP_COUNT = 'd2b0e128-75ed-4e0c-914a-fde870afbd38',
    SENS_SQUAT_ISOMETRIC = '06e4fd47-b915-4841-9d03-b11186ed6698',
    SENS_SHOULDER_ISOMETRIC = '4c16472a-692a-4ea1-94ae-6286e025c690',
    SENS_BACK_ISOMETRIC = 'f5b3c353-a0e2-4ecc-9af0-b4fb5a921250',
    SENS_KNEE_ISOMETRIC = 'a647e106-fc40-4890-97e5-531d6b8dd8c0',
    LINK = 'a83c2249-3e76-4922-8b70-adcec9262166',
    LINK_ENDURANCE = 'b87a54fc-8763-11e9-9716-526af7764f64',
    LINK_REP_COUNT = '90328dcf-1d0d-4de9-ad64-82c32f8ab73f',
    LINK_ISOMETRIC = '52dd2b88-aea0-4b95-b5fb-f95592774288',
    LINK_FAST = '85d439c7-391d-48b8-8235-3dc3eb1f6fa9',
    LEG_QUADRICEPS_IN_STRAIGHT_KNEE_ANGLE_KANGAROO_DESERT = '51eaa90b-5524-426e-9826-dff8bb25d393',
    LEG_KNEE_EXTENSION_KANGAROO_DESERT = '580439c4-0bd4-4db2-8a43-c18ad057fd6a',
    LEG_KNEE_FLEXION_KANGAROO_DESERT = '3efbde6c-0543-4c3e-82f0-0513f7098d30',
    LEG_KNEE_HAMSTRINGS_90_KANGAROO_DESERT = '65241e82-7ce1-40f1-a369-1152a30b8d83',
    LEG_ABDUCTORS_KANGAROO_DESERT = '80ede619-db5d-429b-8794-8df0283a6aa5',
    LEG_ADDUCTORS_KANGAROO_DESERT = '962ac527-ec4d-4ba1-9419-cbadeba7926a',
    LEG_ANKLE_EXTENSION_KANGAROO_DESERT = '75d78684-678e-4055-b365-57aa9e940faa',
    LEG_ANKLE_FLEXION_KANGAROO_DESERT = '6568fe2e-54df-44e4-98ff-0afde9344cf1',
    LEG_HIP_EXTENSION_KANGAROO_DESERT = '6221cddb-765c-4a56-b81a-7f941eb4770f',
    TORSO_TRUNK_EXTENSION_KANGAROO_DESERT = 'd09a9467-2eff-4c54-84ac-793ebcfcd198',
    TORSO_TRUNK_FLEXION_KANGAROO_DESERT = '51022d09-7165-4bd2-ade4-e8535944c0c8',
    ARM_SHOULDER_SIDE_PROXIMAL_EXTENSION_HORIZONTAL_DELTOID_KANGAROO_DESERT = '98d510ae-c832-442f-a538-fc3c88cf84d3',
    ARM_DELTOID_KANGAROO_DESERT = 'cfe05e4a-ba17-4c29-9dfb-8b49093c8f95',
    ARM_SHOULDER_FRONT_PROXIMAL_EXTENSION_HORIZONTAL_DELTOID_KANGAROO_DESERT = 'ac145042-2b40-4146-bfb5-c6e8610a6834',
    ARM_BICEPS_KANGAROO_DESERT = '5e3f1acc-8439-4f9e-a9b2-be75106d3beb',
    ARM_TRICEPS_KANGAROO_DESERT = '634a227a-b299-42b1-a1ff-c72997b0ca01',
    ARM_ELBOW_BACKWARDS_INFRASPINATUS_KANGAROO_DESERT = 'ce2c47b4-37bb-445c-94be-d98865ce7c38',
    HEAD_NECK_FLEXION_KANGAROO_DESERT = '9f05ab1b-1fcb-49f4-89a0-ff9010a8f247',
    HEAD_NECK_VERTICAL_SIDE_FLEXION_KANGAROO_DESERT = '4ce2c37c-9a27-4781-add2-ee3f9872b5e8',
    HEAD_NECK_SIDE_FLEXION_45_KANGAROO_DESERT = 'b49e82d1-b5f3-4dbd-864d-26fbb4fdc820',
    ARM_CLAMPING_FRONT_KANGAROO_DESERT = 'ee5bdc6b-af47-4b27-881f-9a2a7156a597',
    ARM_CLAMPING_UP_KANGAROO_DESERT = '1bd7e514-6d05-4577-b2f5-01f0e840fc35',
    ARM_PUSH_DOWN_KANGAROO_DESERT = 'dec98347-8cce-4583-9956-eb867991caae',
    ARM_PUSH_THE_FRONT_KANGAROO_DESERT = 'bba16396-1a65-421f-a328-a55c1362fb3f',
    ARM_PUSH_THE_SIDE_KANGAROO_DESERT = '7f3a6fa6-a243-4507-9ad7-47e9d1ce5899',
    KANGAROO_DESERT_GRIP = 'f63ca2fd-5757-40ef-964e-433b85ca5f9d',
    KANGAROO_SCUBA_GRIP = '11dbf7e3-6bdb-4064-93d8-8aa29dc3df72',
    KANGAROO_DESERT_BUBBLE = 'b16008f1-a2ef-4c8c-9535-7ea1f050394a',
    KANGAROO_SCUBA_BUBBLE = '8e5384b1-5bb0-496a-a496-4e88a76a8607',
    KANGAROO_DESERT_LINK = '64621675-67b8-4246-88ed-f9c79c411c3e',
    SENS_SQUAT_KANGAROO_SCUBA = '68d42a3e-7cae-4c4e-ac86-6ae493151718',
    SENS_SHOULDER_KANGAROO_SCUBA = '8b8d8ff1-255e-4037-b0c5-4ad44b0209f7',
    SENS_BACK_KANGAROO_SCUBA = '8a19c624-7861-4fa1-ba88-607857b95cb4',
    SENS_KNEE_KANGAROO_SCUBA = '961201ed-2a6b-4e3a-befd-77cf990b9586',
    FAST_TRACK_COP_ANALYSIS = 'ec529d6c-4523-4230-8267-d784b816b31f',
    HEAD_NECK_EXTENSION_METER = '571d8df8-d7b2-4b59-abad-063f88ff06fa',
    HEAD_NECK_TILTING_METER = '1261de5a-c2ff-4b9b-b201-433964846fcc',
    HEAD_NECK_ROTATION_METER = '49e6bb5b-8850-4cec-85be-749ada1c54de',
    HEAD_NECK_LINK_EXTENSION_METER = '53a200f1-ad37-4495-acce-887ed8d926df',
    HEAD_NECK_LINK_TILTING_METER = '731fa527-782d-4bed-9796-d66741c09738',
    HEAD_RIGHT_NECK_LINK_TILTING_METER = 'b1fb8c91-eea0-43a1-b4b9-d09ac7e4fea0',
    LINK_KNEE_FLEXION_90_METER = '88c48ee3-e26c-4db3-a172-03a01884854f',
    MUSCLE_KNEE_EXTENSION_METER = '09c833f4-648b-44fb-b4d2-3f313f86a693',
    MUSCLE_KNEE_FLEXION_METER = '0cc9be50-4f9e-4132-9cbe-8814a4c938b2',
    SENS_SHOULDER_ANTEPULSION_METER = 'b70db170-3fa8-4536-97ea-ae24a5146df6',
    ARM_SHOULDER_ANTEPULSION_45 = '514e1087-2cf1-45c4-9371-d8ff95fb1874',
    ARM_SHOULDER_INTERNAL_ROTATION = '419fae1c-8fcf-4b56-bf7d-662184718b65',
    ARM_SHOULDER_EXTERNAL_ROTATION = '3ac2a82a-81f2-4e19-8eea-eb0679b0e000',
    KNEE_KINEMATICS = '11a5dd91-827e-4482-b90e-6c62d98e0041',
    TIMED_UP_AND_GO = '1db51aec-75dc-458b-8500-4b6e6c628077',
    CLIMB_9_STAIRS = '5597de7b-079e-4412-bbdc-187cf6025efc',
    GAIT_ANALYSIS = '20f62dd9-45b0-4092-9445-48549705a7b0',
    SKIPPING_DELTAS = '3b36a4d6-9861-4479-9cfc-3c403eb2154b',
    SJ_DYNAMIC_BW = '211f0122-f9c8-45d3-8ca6-25da7fd70ad8',
    FAST_ISOINERTIAL = '51c92a49-9639-43ef-b845-1c165f873140',
    ADVANCED_NORDIC_HAMSTRING = '84fd5576-71be-482b-9902-f7a5d0a8ff60',
    BODY_WEIGHT_CALCULATION = '522197ee-fff6-42d1-99c5-568e9069ac44',
    BODY_WEIGHT_CALCULATION_HEXAS = '0a0f1785-9f1b-48f9-aa07-089812f403fb',
    LEG_KNEE_EXTENSION_60_ENDURANCE_MUSCLE = '352d2f82-1f53-4b32-b014-55a6bfd54cf2',
    LEG_KNEE_EXTENSION_90_ENDURANCE_MUSCLE = '01cfa652-e653-4790-bf5b-c95f18931eca',
    ARM_SHOULDER_INTERNAL_ROTATION_ENDURANCE = 'fc7c2044-d98f-4289-b4aa-5721be1f4f2a',
    ARM_SHOULDER_EXTERNAL_ROTATION_ENDURANCE = 'ab314143-c83e-40c0-ad31-c142c1d411ae',
    LEG_KNEE_EXTENSION_90_LINK_ENDURANCE = 'e08cd922-048b-4f30-82eb-183b04e242ff',
    LEG_KNEE_EXTENSION_45_LINK_ENDURANCE = '238aec5c-cea9-499f-87a8-866f30a4232a',
    LEG_KNEE_EXTENSION_30_LINK_ENDURANCE = '92f9c047-8e68-4e7d-86c5-823ae093a524',
    LEG_KNEE_FLEXION_30_LINK_ENDURANCE = '26d3dc83-cc45-49ec-8c06-78bfb1242099',
    LEG_KNEE_FLEXION_90_LINK_ENDURANCE = '4101aa96-7735-407a-970f-fdb846030623',
    ADVANCED_SQUAT_ANALYSIS_HEXAS = '761bbeb9-ee63-4d42-9124-d5d2eb65d3f3',
    ADVANCED_SQUAT_ANALYSIS_PLATES = '8897909f-2d78-47e7-99a9-dba6089cd4b0',
    WOMAC = 'ea59874b-1462-4327-b62a-5fe323d91d4a',
    MULTIPLE_JUMPS_HEXAS_ACTIVITY = 'b71e946b-eac8-49fd-8401-e3cba2b828fc',
    LINKED_NORDIC = 'ff6840c7-c638-4ef8-aec5-b2a18fdd5ffb',
    IKDC = 'd7ff35af-b460-444a-9055-3e065eb905bf',
    CALF_RAISES_ADVANCED_ANALYSIS = '95eaa71a-e5a6-4bad-9edd-32c47d09d9cf',
    SINGLE_LEG_BRIDGE_ADVANCED_ANALYSIS = '5ef820c9-5313-42a0-906f-69379d965b7b',
    SQUAT_DISTRIBUTION_PLATES = '8d9c4f3f-a63b-4955-b83a-a53713232c27',
    SQUAT_DYNAMIC_REPORT_HEXAS = '0cbc5177-cc05-42c2-9bc9-1a5bb09c9e67',
    SQUAT_DISTRIBUTION_HEXAS = '0c3bc512-bd41-4dc8-a91c-7e963216aa5d',
    PUSHUPS_DISTRIBUTION_PLATES = '445c89ba-8b85-41eb-9a4e-eb91470e4422',
    IMTP_PLATES = 'be56f126-79f3-4ba9-b9e9-65245f0dcf7b',
    BRIDGE_HAMSTRINGS_TEST_PLATES = 'b4ad3fe4-4c52-4a94-a5be-bead541b3479'
}

export class BuiltInExerciseTemplates {
    public static getTypeForCode(code: Code): BuiltInExerciseTemplateType | null {
        let result = null;
        const match = Object.entries(BuiltInExerciseTemplateType).find(([_key, value]) => value === code);
        if (match) {
            const [key] = match;
            result = key;
        }

        return BuiltInExerciseTemplateType[result as keyof typeof BuiltInExerciseTemplateType];
    }

    public static isUnipodalStance(code?: Code): boolean {
        if (!code) {
            return false;
        }

        const unipodalCodes = [
            BuiltInExerciseTemplateType.UNIPODAL_STANCE,
            BuiltInExerciseTemplateType.LEFT_UNIPODAL_STANCE,
            BuiltInExerciseTemplateType.RIGHT_UNIPODAL_STANCE,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_STANCE_LEFT,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_STANCE_RIGHT
        ];

        return unipodalCodes.includes(code);
    }

    public static isUnipodalStanceWithOneSensor(code?: Code): boolean {
        if (!code) {
            return false;
        }

        const unipodalCodes = [
            BuiltInExerciseTemplateType.UNIPODAL_STANCE,
            BuiltInExerciseTemplateType.UNIPODAL_STANCE_DELTAS,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_STANCE_LEFT,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_STANCE_RIGHT
        ];

        return unipodalCodes.includes(code);
    }

    public static isUnipodalJump(configCode?: Code): boolean {
        if (!configCode) {
            return false;
        }

        const unipodalCodes: string[] = [
            BuiltInExerciseTemplateType.JUMP_ANALYSIS_HEXAS_EVALUATION_UNIPODAL_CMJ,
            BuiltInExerciseTemplateType.JUMP_ANALYSIS_PLATES_EVALUATION_UNIPODAL_CMJ,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_CMJ_PLATES_LEFT,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_CMJ_PLATES_RIGHT,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_CMJ_HEXAS_LEFT,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_CMJ_HEXAS_RIGHT,

            BuiltInExerciseTemplateType.JUMP_ANALYSIS_HEXAS_EVALUATION_UNIPODAL_SJ,
            BuiltInExerciseTemplateType.JUMP_ANALYSIS_PLATES_EVALUATION_UNIPODAL_SJ,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_SJ_PLATES_LEFT,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_SJ_PLATES_RIGHT,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_SJ_HEXAS_LEFT,
            ActivityConfigsOfBuiltinProtocols.UNIPODAL_SJ_HEXAS_RIGHT
        ];
        return unipodalCodes.includes(configCode);
    }

    public static isUnipodalDropLand(configCode?: Code): boolean {
        return configCode === BuiltInExerciseTemplateType.UNIPODAL_DROP_LAND_DELTAS;
    }

    public static isDropLand(configCode: Code): boolean {
        return configCode === BuiltInExerciseTemplateType.DROP_LAND_DELTAS;
    }

    public static isDynamicDistributionSimple(configCode?: string): boolean {
        if (!configCode) {
            return false;
        }
        const dynamicDistributionSimpleCodes: Array<string> = [
            BuiltInExerciseTemplateType.SQUAT_DISTRIBUTION_HEXAS,
            BuiltInExerciseTemplateType.LEG_PRESS_DYNAMIC_REPORT,
            BuiltInExerciseTemplateType.SQUAT_DYNAMIC_REPORT,
            BuiltInExerciseTemplateType.HAMSTRINGS_DYNAMIC_REPORT,
            BuiltInExerciseTemplateType.ISOINERTIAL_DYNAMIC_REPORT,
            BuiltInExerciseTemplateType.PUSHUPS_DYNAMIC_REPORT,
            BuiltInExerciseTemplateType.SQUAT_DISTRIBUTION_PLATES,
            BuiltInExerciseTemplateType.SQUAT_DYNAMIC_REPORT_HEXAS,
            BuiltInExerciseTemplateType.PUSHUPS_DISTRIBUTION_PLATES,
            BuiltInExerciseTemplateType.BRIDGE_HAMSTRINGS_TEST_PLATES
        ];
        return dynamicDistributionSimpleCodes.includes(configCode);
    }

    public static isBodyWeightCalculation(configCode: string): boolean {
        if (!configCode) {
            return false;
        }
        const bodyWeightCalculationCodes: Array<string> = [
            BuiltInExerciseTemplateType.BODY_WEIGHT_CALCULATION,
            BuiltInExerciseTemplateType.BODY_WEIGHT_CALCULATION_HEXAS
        ];

        return bodyWeightCalculationCodes.includes(configCode);
    }

    public static isUnipodalCombinedAnalysis(configCode?: string): boolean {
        if (!configCode) {
            return false;
        }
        const unipodalAdvancedAnalysisCodes: Array<string> = [
            BuiltInExerciseTemplateType.CALF_RAISES_ADVANCED_ANALYSIS,
            BuiltInExerciseTemplateType.SINGLE_LEG_BRIDGE_ADVANCED_ANALYSIS,
        ];

        return unipodalAdvancedAnalysisCodes.includes(configCode);
    }
}
