import {Component, Input, OnInit} from '@angular/core';
import {ProtocolDto} from "../../../dtos/protocol.dto";
import {ProtocolAnalysisServiceResponseDto} from "../../../dtos/statistics/protocolAnalysisServiceResponseDto";
import {ForceVelocityProtocolSummaryResultsModelDto} from "../../../dtos/statistics/forceVelocityProtocolSummaryResultsModelDto";
import {ValueWidgetIcon} from "../../../viewmodels/valueWidgetIcons";
import {ActivityConfigSetMetaKeys} from "../../../models/activityConfigSetMetaKeys";
import {BodySideType} from "../../../models/bodySideType";
import {JumpAnalysisActivityResultsModel} from "../../../dtos/statistics/jumpAnalysisActivityResultsModel";
import {KeyValue, Title} from "common";
import {DatasetStyle} from "../../charts/datasetStyle";
import {Rect} from "../../../models/rect";
import {Point} from "../../../models/point";
import {BaseComponent} from "../../base/base.component";
import {UnitType, UnitTypes} from "../../../utils/values/unitType";
import {Colors} from "../../../services/colors";
import {Value} from "../../../utils/values/value";

@Component({
    selector: 'app-protocol-summary-force-velocity-results',
    templateUrl: './protocol-summary-force-velocity-results.component.html',
    styleUrls: ['./protocol-summary-force-velocity-results.component.scss']
})
export class ProtocolSummaryForceVelocityResultsComponent extends BaseComponent implements OnInit {
    public readonly valueWidgetIcons = ValueWidgetIcon;
    public readonly colors = Colors;

    @Input()
    public protocol: ProtocolDto;

    @Input()
    public analysis: ProtocolAnalysisServiceResponseDto;

    private results: ForceVelocityProtocolSummaryResultsModelDto;

    public maxPower: Value;
    public f0: Value;
    public v0: Value;
    public jumpHeightPerLoadData: Map<BodySideType, Value>[];
    public jumpHeightPerLoadTitles: string[];
    public jumpHeightAxisYLabel: Title;
    public jumpHeightAxisXLabel: Title;

    public forcePowerChartLeftAxisTitle: Title;
    public forcePowerChartRightAxisTitle: Title;
    public forcePowerChartAxisXValues: number[];
    public forcePowerChartVelocityValues: KeyValue<DatasetStyle, Value[]>[];
    public forcePowerChartPowerValues: KeyValue<DatasetStyle, Value[]>[];
    public forcePowerChartMaxVelocityArea: Rect;
    public forcePowerChartAxisXTitle: Title;
    public realRelativeForceVelocityData: Point[];
    public realRelativeForceVelocityAxisYTitle: Title;
    public realRelativeForceVelocityAxisXTitle: Title;
    public realRelativeForceVelocityTrend: Point[];
    public profilesTitle: Title;
    public profilesData: KeyValue<DatasetStyle, Point[]>[];
    public profilesAxisXTitle: Title;
    public profilesAxisYTitle: Title;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis.protocolsResults[0].protocolSummaryResultsModel instanceof ForceVelocityProtocolSummaryResultsModelDto)) {
            return;
        }

        this.results = this.analysis.protocolsResults[0].protocolSummaryResultsModel as ForceVelocityProtocolSummaryResultsModelDto;

        this.initKpis();
        this.initJumpHeightPerLoadGraph();
        this.initForcePowerVelocityGraph();
        this.initRealRelativeForceVelocityGraph();
        this.initProfilesGraph();
    }

    private initKpis() {
        this.maxPower = new Value(this.results.maxPower, UnitType.WATT_PER_KG);
        this.f0 = new Value(this.results.f0, UnitType.NEWTON_PER_KG);
        this.v0 = new Value(this.results.v0, UnitType.MM_PER_SECOND)
    }

    private initJumpHeightPerLoadGraph() {
        // Graph jump height per load
        this.jumpHeightPerLoadTitles = [];
        this.jumpHeightPerLoadData = [];
        for (const activity of this.protocol.activities) {
            const target = activity.config.activityConfigSets[0].getMetaFloat(ActivityConfigSetMetaKeys.TARGET);
            const activityResults = this.analysis.protocolsResults[0].activitiesResults.find(a => a.activityCode === activity.code);
            const jumpResults = activityResults.activityResultsModel as JumpAnalysisActivityResultsModel;
            const jumpHeightMeters = jumpResults.jump ? jumpResults.jump.jumpHeight : 0;
            this.jumpHeightPerLoadTitles.push(new Value(target).format(0));
            this.jumpHeightPerLoadData.push(new Map<BodySideType, Value>().set(BodySideType.LEFT, new Value(jumpHeightMeters * 100)));
        }
        this.jumpHeightAxisYLabel = this.text('widget.jump_height.cm.title');
        this.jumpHeightAxisXLabel = this.format('heights_per_jump_load.left_side_axis.title', UnitTypes.symbol(UnitType.KG));
    }

    private initForcePowerVelocityGraph() {
        this.forcePowerChartAxisXTitle = this.text('force_power_velocity_graph.left_axis.title');
        const velocityTitle = this.format('force_power_velocity_graph.bottom_axis.title', UnitTypes.symbol(UnitType.KG));
        const powerTitle = this.format('force_power_velocity_graph.right_axis.title', UnitTypes.symbol(UnitType.KG));
        this.forcePowerChartLeftAxisTitle = velocityTitle;
        this.forcePowerChartRightAxisTitle = powerTitle;
        this.forcePowerChartAxisXValues = [];
        this.forcePowerChartVelocityValues = [];
        this.forcePowerChartPowerValues = [];

        if (this.results.powerVelocityEntries.length === 0) {
            return;
        }

        // the x is the max power*2 + 20% for visibility
        const maxPower = this.results.powerVelocityEntries.sort((a, b) => b.y - a.y)[0];
        const step = Math.abs(this.results.powerVelocityEntries[0].x - this.results.powerVelocityEntries[1].x);
        const maxAxisXValue = maxPower.x * 2 * 1.2;
        const steps = Math.round(maxAxisXValue / step);
        for (let i = 0; i < steps; i++) {
            this.forcePowerChartAxisXValues.push((i * step));
        }

        this.forcePowerChartVelocityValues.push(KeyValue.of(new DatasetStyle(velocityTitle, Colors.colorPrimary), this.results.relativeForceVelocityEntries.map(e => new Value(e.y))));

        // Velocity values come reversed from analyzer, possible bug on that side. Sort them manually
        this.results.powerVelocityEntries.sort((a, b) => a.x - b.x);
        let velocityValues = this.results.powerVelocityEntries.map(e => new Value(e.y));
        this.forcePowerChartPowerValues.push(KeyValue.of(new DatasetStyle(powerTitle, Colors.colorAccent), velocityValues));

        // Highlight max velocity
        this.forcePowerChartMaxVelocityArea = new Rect(maxPower.y, maxPower.x, undefined, undefined);
    }

    private initRealRelativeForceVelocityGraph() {
        this.realRelativeForceVelocityData = this.results.forceVelocityPerJump.map(v => Point.of(v.x, v.y));
        this.realRelativeForceVelocityAxisYTitle = this.text('force_velocity_graph.bottom_axis.title');
        this.realRelativeForceVelocityAxisXTitle = this.text('force_velocity_graph.left_axis.title');
        this.realRelativeForceVelocityTrend = this.results.realRelativeForceVelocityEntries.map(v => Point.of(v.x, v.y));
    }

    private initProfilesGraph() {
        this.profilesTitle = this.format('force_power_velocity.optimal_profile.deficit.title', new Value(this.results.optimalMeasuredProfileDeficit * 100).format(1));
        this.profilesAxisXTitle = this.text('force_velocity_graph.left_axis.title');
        this.profilesAxisYTitle = this.format('force_velocity_graph.bottom_axis.title', UnitTypes.symbol(UnitType.KG));
        this.profilesData = [];

        // measured profile
        const measuredProfileTitle = this.text('force_velocity_graph.measured_profile.legend');
        this.profilesData.push(KeyValue.of(new DatasetStyle(measuredProfileTitle, Colors.colorPrimary), this.results.measuredProfileEntries.map(v => new Point(v.x, v.y))));

        // optimal profile
        const optimalProfileTitle = this.text('force_velocity_graph.optimal_profile.legend');
        this.profilesData.push(KeyValue.of(new DatasetStyle(optimalProfileTitle, Colors.GREEN), this.results.optimalProfileEntries.map(v => new Point(v.x, v.y))));

    }
}
