import {BodySideType, BodySideTypes} from "../../models/bodySideType";
import {TotalEvaluationRepResultsModel} from "./totalEvaluationRepResultsModel";
import {Dto} from "common";

export class TotalEvaluationActivityResultsModel extends Dto {
    public repResults: TotalEvaluationRepResultsModel[];
    public maxPerSide: { side: BodySideType; value: number }[];

    deserialize(input: any): this {
        Object.assign(this, input);
        if (input.repResults) {
            this.repResults = this.deserializeArray(input.repResults, TotalEvaluationRepResultsModel);
        }

        this.maxPerSide = [];
        if (input.maxPerSide) {
            for (const datum of input.maxPerSide as any) {
                this.maxPerSide.push({side: BodySideTypes.parse(datum.side), value: datum.value});
            }
        }

        return this;
    }
}
