<div class="component w-100 h-100">
    <div class="text-center">
        <div class="title rounded-pill text-light d-inline-block">{{chartTitle ? chartTitle : "Max Strength"}}</div>
    </div>
    <div class="d-flex justify-content-center align-items-stretch content">
        <div class="side side-left flex-grow-1 d-flex align-items-end flex-column justify-content-center">
            <span>{{leftSideTitle}}</span>
            <span class="value">{{leftSideValue}}</span>
        </div>
        <div class="graph h-100">
            <canvas class="w-100"
                    baseChart
                    [datasets]="datasets"
                    [labels]="chartLabels"
                    [plugins]="chartPlugins"
                    chartType="doughnut"
                    [options]="chartOptions"
            >
            </canvas>
        </div>
        <div class="side side-right flex-grow-1 d-flex align-items-start flex-column justify-content-center">
            <span>{{rightSideTitle}}</span>
            <span class="value">{{rightSideValue}}</span>
        </div>
    </div>
</div>
