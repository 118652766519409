export enum JumpType {
    UNKNOWN = "UNKNOWN",
    CMJ = "CMJ",
    SJ = "SJ",
    DROP = "DROP",
    MULTIPLE_JUMPS = "MULTIPLE_JUMPS"
}

export class JumpTypes {
    public static parse(jumpType: string): JumpType {
        return JumpType[jumpType as keyof typeof JumpType];
    }
}
