import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {SubscriptionDto} from "common";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    constructor(private httpClient: HttpClient) {
    }

    /**
     * Returns the subscriptions of the logged in user
     */
    public getSubscriptions(): Observable<SubscriptionDto[]> {
        return this.httpClient.get<any>(environment.apiUrl + 'api/subscriptions/v1')
            .pipe(map(subscriptionData => {
                const subscriptions: SubscriptionDto[] = [];
                for (const datum of (subscriptionData as any)) {
                    subscriptions.push(new SubscriptionDto().deserialize(datum));
                }
                return subscriptions;
            }));
    }
}
