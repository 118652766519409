import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProtocolGroupFilter} from '../../viewmodels/protocolGroupFilter';
import {PerformType} from '../../viewmodels/performType';
import {BodyPartGroupType} from '../../models/bodyPartGroupType';
import {ProtocolOrActivityConfigTag, ProtocolOrActivityConfigTagUtils} from '../../models/protocolOrActivityConfigTag';
import {protocolOrActivityConfigTagCategory} from '../../models/protocolOrActivityConfigTagCategory';
import {ActivityType, ActivityTypes} from '../../models/activityType';
import {FilterPeriodType, FilterPeriodTypes} from '../../viewmodels/filterPeriodType';
import {GraphicsShadeType} from "../../models/graphicsShadeType";
import {Arrays, DeviceType, KeyValue, MatIconResource, Title} from "common";
import {Device} from "../../viewmodels/device";
import {BaseComponent} from "../base/base.component";

@Component({
    selector: 'app-protocol-filters',
    templateUrl: './protocol-filters.component.html',
    styleUrls: ['./protocol-filters.component.scss']
})
export class ProtocolFiltersComponent extends BaseComponent implements OnInit {

    public readonly performTypes: typeof PerformType = PerformType;
    public readonly bodyPartGroupTypes: typeof BodyPartGroupType = BodyPartGroupType;
    public readonly graphicsShadeTypes: typeof GraphicsShadeType = GraphicsShadeType;

    @Input()
    public filter: ProtocolGroupFilter;

    @Output()
    public filterChanged: EventEmitter<ProtocolGroupFilter>;

    public activityTypes: Map<ActivityType, Title>;
    public bodyParts: Map<BodyPartGroupType, KeyValue<ProtocolOrActivityConfigTag, Title>[]>;
    public types: Map<ProtocolOrActivityConfigTag, Title>;

    constructor() {
        super();
        this.filterChanged = new EventEmitter<ProtocolGroupFilter>();
        this.activityTypes = new Map<ActivityType, Title>();
        this.bodyParts = new Map<BodyPartGroupType, KeyValue<ProtocolOrActivityConfigTag, string>[]>();
        this.types = new Map<ProtocolOrActivityConfigTag, string>();
    }

    ngOnInit(): void {
        for (const type of [ActivityType.EVALUATION, ActivityType.EXERCISE]) {
            this.activityTypes.set(type, this.text(ActivityTypes.title(this.i18n, type)));
        }

        this.activityTypes.set(ActivityType.EVALUATION, this.text(ActivityTypes.title(this.i18n, ActivityType.EVALUATION)));
        for (const group of Object.values(BodyPartGroupType)) {
            this.bodyParts.set(group, []);
            for (const tag of ProtocolOrActivityConfigTagUtils.getTagsForBodyPartGroup(group)) {
                this.bodyParts.get(group).push(KeyValue.of(tag, this.text(ProtocolOrActivityConfigTagUtils.getTitleKey(tag))));
            }
        }
        for (const type of ProtocolOrActivityConfigTagUtils.getTagsForCategory(protocolOrActivityConfigTagCategory.TYPE)) {
            this.types.set(type, this.text(ProtocolOrActivityConfigTagUtils.getTitleKey(type)));
        }
    }

    onQueryChange($event: Event) {
        const queryInput = $event.target as HTMLInputElement;
        this.filter.query = queryInput.value;
        this.filterChanged.emit(this.filter);
    }

    onPerformTypeChange(type: PerformType) {
        Arrays.toggleValue(this.filter.performType, type);
        this.filterChanged.emit(this.filter);
    }

    onDeviceClick(device: DeviceType) {
        Arrays.toggleValue(this.filter.devices, device);
        this.filterChanged.emit(this.filter);
    }

    clearFilter() {
        this.filter = new ProtocolGroupFilter();
        this.filterChanged.emit(this.filter);
    }

    onTagClick(tag: ProtocolOrActivityConfigTag) {
        Arrays.toggleValue(this.filter.tags, tag);
        this.filterChanged.emit(this.filter);
    }

    onActivityTypeClick(type: ActivityType) {
        if (this.filter.activityTypes.includes(type)) {
            Arrays.toggleValue(this.filter.activityTypes, type);
        } else {
            this.filter.activityTypes = [type];
        }
        this.filterChanged.emit(this.filter);
    }

    public periodSliderTitleFormatter = (index: number): string => {
        return FilterPeriodTypes.title(this.dateSliderIndexToFilterDuration(index));
    };

    private dateSliderIndexToFilterDuration(index: number): FilterPeriodType {
        let type: FilterPeriodType;
        if (index === 1) {
            type = FilterPeriodType.ALL;
        } else if (index === 2) {
            type = FilterPeriodType.YEAR;
        } else if (index === 3) {
            type = FilterPeriodType.NINE_MONTHS;
        } else if (index === 4) {
            type = FilterPeriodType.SIX_MONTHS;
        } else if (index === 5) {
            type = FilterPeriodType.THREE_MONTHS;
        }
        return type;
    }

    public filterPeriodToSliderIndex(type: FilterPeriodType): number {
        let index: number;
        switch (type) {
            case FilterPeriodType.ALL:
                index = 1;
                break;
            case FilterPeriodType.YEAR:
                index = 2;
                break;
            case FilterPeriodType.NINE_MONTHS:
                index = 3;
                break;
            case FilterPeriodType.SIX_MONTHS:
                index = 4;
                break;
            case FilterPeriodType.THREE_MONTHS:
                index = 5;
                break;
        }
        return index;
    }

    public onDateChange(value: number | null): void {
        this.filter.period = this.dateSliderIndexToFilterDuration(value);
        this.filterChanged.emit(this.filter);
    }

    public formatPeriodType(type: FilterPeriodType): string {
        return FilterPeriodTypes.title(type);
    }

    public getMatIcon(type: DeviceType, shadeType: GraphicsShadeType): MatIconResource {
        return Device.matIcon(type, shadeType);
    }
}
