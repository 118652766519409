<mat-toolbar>
    <ng-container *ngIf="supportsExportPdf && supportsExportCsv">
        <button mat-button [matMenuTriggerFor]="menu">
            {{text('protocolResultsActions.export')}}
        </button>
        <mat-menu #menu="matMenu">
            <ng-container *ngTemplateOutlet="exportPdfButton"></ng-container>
            <ng-container *ngTemplateOutlet="exportCsvButton"></ng-container>
        </mat-menu>
    </ng-container>
    <ng-container *ngIf="supportsExportPdf && !supportsExportCsv">
        <ng-container *ngTemplateOutlet="exportPdfButton"></ng-container>
    </ng-container>
</mat-toolbar>

<ng-template #exportPdfButton>
    <button mat-menu-item (click)="onExportPdf.emit()">
        <mat-icon>description</mat-icon>
        {{text('exercise.meter.finished.exportPdf')}}
    </button>
</ng-template>

<ng-template #exportCsvButton>
    <button mat-menu-item (click)="onExportCsv.emit()">
        <mat-icon>reorder</mat-icon>
        {{text('protocolResultsActions.csvExport')}}
    </button>
</ng-template>
