import {Component, Input, OnInit} from '@angular/core';
import {BodySideType, BodySideTypes} from '../../../models/bodySideType';
import {ChartDataSets, ChartOptions} from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {TextUtils, Title} from "common";
import {Annotation} from '../../balance/annotation';
import {Value} from "../../../utils/values/value";
import {MathUtils} from "../../../utils/mathUtils";

@Component({
    selector: 'app-body-side-bar-chart',
    templateUrl: './body-side-bar-chart.component.html',
    styleUrls: ['./body-side-bar-chart.component.scss']
})
export class BodySideBarChartComponent implements OnInit {

    @Input()
    public data: Map<BodySideType, Value>[];

    /**
     * The xAxis labels. When grouping by Order label is based on index of the data,
     * when by BodySide the labels are used for each side
     */
    @Input()
    public dataTitles: string[];

    @Input()
    public valueFractionDigits: number = 0;

    @Input()
    public axisYLabel: Title;

    @Input()
    public axisXLabel: Title;

    @Input()
    public groupBy: 'Order' | 'BodySide' = 'Order';

    @Input()
    public annotations: Annotation[];

    public datasets: ChartDataSets[];
    public chartPlugins: any[];
    public chartOptions: ChartOptions;

    constructor() {
        this.chartPlugins = [pluginDataLabels];
        this.dataTitles = [];
    }

    ngOnInit(): void {

        this.datasets = [];
        // Create datasets. When groupping by order in the graph, the underling datasets need to be ordered by side and vice versa.
        const dataPerSide = new Map<BodySideType, Value[]>();
        for (const datum of this.data) {
            for (const side of datum.keys()) {
                if (!dataPerSide.has(side)) {
                    dataPerSide.set(side, []);
                }
                dataPerSide.get(side).push(datum.get(side));
            }
        }

        if (this.groupBy === 'Order') {
            // Create a dataset for each side

            // If there are no data titles (title for each set of side values) create them as ordinals based on the sides
            if (!this.dataTitles || this.dataTitles.length === 0) {
                this.dataTitles = [];
                const maxDataPerSide = Math.max(...Array.from(dataPerSide.values()).map(s => s.length));

                for (let i = 0; i < maxDataPerSide; i++) {
                    this.dataTitles.push((i + 1).toString());
                }
            }

            for (const side of dataPerSide.keys()) {
                this.datasets.push({
                    data: dataPerSide.get(side).map(value => value.value),
                    backgroundColor: BodySideTypes.color(side),
                    borderWidth: 0,
                    label: BodySideTypes.format(side),
                    hoverBackgroundColor: BodySideTypes.color(side),
                    barPercentage: .7,
                    xAxisID: 'x-axis',
                    yAxisID: 'y-axis'
                });
            }
        } else if (this.groupBy === 'BodySide') {
            // Create a dataset by order
            const maxOrder = Math.max(...Array.from(dataPerSide.values()).flatMap(a => a.length));
            const sides = Array.from(dataPerSide.keys());
            for (let i = 0; i < maxOrder; i++) {
                const sideValuesForOrder = new Map<BodySideType, Value>();
                // Get nth item for each side
                for (const side of sides) {
                    const sideValues = dataPerSide.get(side);
                    if (i <= sideValues.length - 1) {
                        sideValuesForOrder.set(side, dataPerSide.get(side)[i]);
                    } else {
                        sideValuesForOrder.set(side, new Value(NaN));
                    }
                }

                // Create a dataset for each
                this.datasets.push({
                    data: Array.from(sideValuesForOrder.values()).map(v => v.value),
                    backgroundColor: Array.from(sideValuesForOrder.keys()).map(side => BodySideTypes.color(side)),
                    borderWidth: 0,
                    label: MathUtils.randomInt(1, 5).toFixed(0),
                    hoverBackgroundColor: Array.from(sideValuesForOrder.keys()).map(side => BodySideTypes.color(side)),
                    barPercentage: .7,
                    xAxisID: 'x-axis',
                    yAxisID: 'y-axis'
                });

                // Create labels
                this.dataTitles = [];
                for (const side of sides) {
                    this.dataTitles.push(BodySideTypes.format(side));
                }
            }
        }

        this.chartOptions = {
            animation: {
                duration: 0,
                animateScale: false,
                animateRotate: false
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            devicePixelRatio: 2,
            annotation: {annotations: []},
            tooltips: {
                enabled: false
            },
            legend: {
                display: false,
            },
            scales: {
                yAxes: [{
                    id: 'y-axis',
                    ticks: {
                        min: 0,
                        max: this.getAxisYMax()
                    },
                    scaleLabel: {labelString: this.axisYLabel, display: !TextUtils.isEmpty(this.axisYLabel)}
                }],
                xAxes: [{
                    id: 'x-axis',
                    scaleLabel: {labelString: this.axisXLabel, display: !TextUtils.isEmpty(this.axisXLabel)}
                }]
            },
            plugins: {
                datalabels: {
                    formatter: this.labelFormatter.bind(this),
                    anchor: 'end',
                    align: 'end',
                    font: {size: 12},
                    offset: -2
                },
            }
        };

        if (this.annotations) {
            for (const annotation of this.annotations) {
                this.chartOptions.annotation.annotations.push({
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y-axis',
                    value: annotation.value.value,
                    borderColor: annotation.color,
                    borderDash: annotation.borderDash,
                    borderDashOffset: annotation.borderDashOffset,
                    borderWidth: 2,
                    drawTime: 'beforeDatasetsDraw',
                });
            }
        }
    }

    private labelFormatter(value: number): string {
        // find the value in the data by value and format it
        let foundValue: Value;
        for (const datum of this.data) {
            for (const entry of datum.entries()) {
                if (entry[1].value === value) {
                    foundValue = entry[1];
                    break;
                }
            }
            if (foundValue) {
                break;
            }
        }

        if (foundValue) {
            return foundValue.format(this.valueFractionDigits);
        } else {
            return value.toFixed(this.valueFractionDigits);
        }
    }

    private getAxisYMax(): number {
        // 20% above max value, rounded
        const allValues = this.data.flatMap(value => value.values())
            .flatMap(value => Array.from(value))
            .map(value => value.value);

        return Math.round(Math.max(...allValues) * 1.2);
    }
}
