import {ActivityConfigDto} from "../dtos/activityConfig.dto";

export enum ActivityConfigTag {
    LOW_BACK_PAIN = "LOW_BACK_PAIN",
    SHOULDERS = "SHOULDERS",
    BOSU = "BOSU",
    PUSH_UPS = "PUSH_UPS",
    ISOINTERTIAL = "ISOINTERTIAL",
    HAMSTRINGS = "HAMSTRINGS",
    SQUAT = "SQUAT",
    LEG_PRESS = "LEG_PRESS",
    SPORT = "SPORT",
    UNSTABLE_CUSHION = "UNSTABLE_CUSHION",
    STANDING_POSTURE = "STANDING_POSTURE",
    JUMP_SUITE = "JUMP_SUITE",
    REDUCED_WEIGHT = "REDUCED_WEIGHT",
    PROTOLAB = "PROTOLAB", // special case, used for builtins protocols
    NORDIC_TEST = "NORDIC_TEST"
}

export class ActivityConfigTags {
    public static GROUPS_SUFFIX: string = "_group";

    public static getGroupTag(activityConfig: ActivityConfigDto): string {
        if(!activityConfig.tags){
            return undefined;
        }

        return activityConfig.tags.split(",").find(tag => tag.endsWith(this.GROUPS_SUFFIX));
    }
}
