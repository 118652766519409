import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivityImageResolverService} from '../../services/activity-image-resolver.service';
import {ExerciseType, ExerciseTypes} from '../../models/exerciseType';
import {Code, IconResource, KeyValue, TextUtils, Title} from "common";
import {BaseComponent} from "../base/base.component";

@Component({
    selector: 'app-protocol-card',
    templateUrl: './protocol-card.component.html',
    styleUrls: ['./protocol-card.component.scss']
})
export class ProtocolCardComponent extends BaseComponent implements OnInit {

    @Input()
    public code: Code;

    @Input()
    public title: Title;

    @Input()
    public image?: string;

    @Input()
    public overflowImage?: string;

    @Input()
    public exerciseTypeIndicators: ExerciseType[] = [];

    @Output()
    public selected: EventEmitter<[Code, MouseEvent]>;

    @Input()
    public enabled: boolean = true;

    public exerciseTypeData: KeyValue<IconResource, Title>[] = [];

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
        this.selected = new EventEmitter<[Code, MouseEvent]>();
    }

    ngOnInit(): void {
        for (const exerciseType of this.exerciseTypeIndicators) {
            const title = ExerciseTypes.title(this.i18n, exerciseType);
            const icon = ExerciseTypes.icon(exerciseType);
            if (TextUtils.isEmpty(icon)) {
                continue;
            }
            this.exerciseTypeData.push(KeyValue.of(icon, title));
        }
    }

    public getImage(): string {
        return this.activityImageResolverService.do(this.image);
    }

    public onSelect($event: MouseEvent) {
        if (this.enabled) {
            this.selected.emit([this.code, $event]);
        }
    }
}
