import {Injectable} from '@angular/core';
import {ParticipantDto} from "../dtos/participant.dto";
import {ParticipantsFilter} from "../viewmodels/participantsFilter";
import * as fuzzysort from 'fuzzysort'

@Injectable({
    providedIn: 'root'
})
export class ParticipantsFilteringService {

    constructor() {
    }

    public applyFilter(participants: ParticipantDto[], filter: ParticipantsFilter): ParticipantDto[] {
        if (!filter.hasFilters()) {
            return participants;
        }

        let filtered: ParticipantDto[];
        if (filter.hasQuery()) {
            filtered = this.filterByQuery(participants, filter.query);
        } else {
            filtered = participants;
        }

        if (filter.gender) {
            filtered = filtered.filter(p => p.genderType === filter.gender);
        }

        if (filter.hasTags()) {
            const tagCodes = filter.tags.map(t => t.code);
            filtered = filtered.filter(p => {
                const participantTags = p.tags.filter(t => !t.deleted).map(t => t.participantTagConfigCode);
                return tagCodes.every(tag => participantTags.includes(tag));
            });
        }

        return filtered;
    }

    private filterByQuery(participants: ParticipantDto[], query: string): ParticipantDto[] {
        return fuzzysort.go(query, participants, {keys: ['fullName', 'email']})
            .map(r => r.obj);
    }
}
