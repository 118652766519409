import {Component, Input, OnInit} from '@angular/core';
import {ValueWidgetIcon, ValueWidgetIcons} from "../../../viewmodels/valueWidgetIcons";
import {Value} from "../../../utils/values/value";

@Component({
    selector: 'app-value-widget',
    templateUrl: './value-widget.component.html',
    styleUrls: ['./value-widget.component.scss']
})
export class ValueWidgetComponent implements OnInit {

    @Input()
    public icon: string;
    @Input()
    public iconType: ValueWidgetIcon;
    @Input()
    public value: Value;
    @Input()
    public valueFractionDigits: number = 2;
    @Input()
    public title: string;

    public iconResolved: string;

    constructor() {
    }

    ngOnInit(): void {
        if(this.iconType !== undefined) {
            this.iconResolved = ValueWidgetIcons.icon(this.iconType);
        } else {
            this.iconResolved = this.icon;
        }
    }

    public static
}
