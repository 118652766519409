<div>
    <div class="section d-flex flex-column align-items-center">
        <h2>{{text('standingEvaluation.results.surface_mm')}}</h2>
        <div class="d-flex flex-row">
            <app-simple-barchart [title]="eyesOpenTitle"
                                 [data]="eyesOpenData"
                                 [color]="eyesOpenBackgroundColor"
                                 [maxY]="eyesChartMaxY"
                                 [valueFractionDigits]="0"
            ></app-simple-barchart>
            <app-simple-barchart [title]="eyesClosedTitle"
                                 [data]="eyesClosedData"
                                 [color]="eyesClosedBackgroundColor"
                                 [displayAxisY]="false"
                                 [maxY]="eyesChartMaxY"
                                 [valueFractionDigits]="0"
            ></app-simple-barchart>
        </div>
    </div>
    <div class="section overflow-auto mt-5 d-flex flex-row">
        <div class="left-column flex-grow-1">
            <app-table-header></app-table-header>
            <app-table-header></app-table-header>
            <app-table-title>{{text('advancedStandingEvaluation.results.distributionLeftRight')}}</app-table-title>
            <app-table-title>{{text('standingEvaluation.results.surface_mm')}}</app-table-title>
            <app-table-title>{{text('standingEvaluation.results.mean_velocity')}}</app-table-title>
            <app-table-title>{{text('standingEvaluation.results.longitudinal amplitude')}}</app-table-title>
            <app-table-title>{{text('standingEvaluation.results.lateral amplitude')}}</app-table-title>
        </div>
        <div class="flex-grow-1">
            <app-table-header [backgroundColor]="eyesOpenBackgroundColor" [color]="eyesOpenColor">{{eyesOpenTitle}}</app-table-header>
            <div class="d-flex flex-row">
                <div class="flex-grow-1">
                    <app-table-title class="text-center">{{format('advancedStandingEvaluation.results.trial', '1')}}</app-table-title>
                    <app-table-value>
                        <app-left-right-text [leftValue]="eyesOpenStats[0][0]?.value" [rightValue]="100-eyesOpenStats[0][0]?.value" [displayPercent]="false"></app-left-right-text>
                    </app-table-value>
                    <app-table-value>{{eyesOpenStats[0][1]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenStats[0][2]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenStats[0][3]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenStats[0][4]?.format(1)}}</app-table-value>
                </div>
                <div class="flex-grow-1">
                    <app-table-title class="text-center">{{format('advancedStandingEvaluation.results.trial', '2')}}</app-table-title>
                    <app-table-value>
                        <app-left-right-text [leftValue]="eyesOpenStats[1][0]?.value" [rightValue]="100-eyesOpenStats[1][0]?.value" [displayPercent]="false"></app-left-right-text>
                    </app-table-value>
                    <app-table-value>{{eyesOpenStats[1][1]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenStats[1][2]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenStats[1][3]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenStats[1][4]?.format(1)}}</app-table-value>
                </div>
                <div class="flex-grow-1">
                    <app-table-title class="text-center">{{format('advancedStandingEvaluation.results.trial', '3')}}</app-table-title>
                    <app-table-value>
                        <app-left-right-text [leftValue]="eyesOpenStats[2][0]?.value" [rightValue]="100-eyesOpenStats[2][0]?.value" [displayPercent]="false"></app-left-right-text>
                    </app-table-value>
                    <app-table-value>{{eyesOpenStats[2][1]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenStats[2][2]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenStats[2][3]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenStats[2][4]?.format(1)}}</app-table-value>
                </div>
                <div class="flex-grow-1">
                    <app-table-title class="text-center" [backgroundColor]="eyesOpenBackgroundColor"
                                     [color]="eyesOpenColor">{{text('exercise.meter.repSummary.header.average')}}</app-table-title>
                    <app-table-value>
                        <app-left-right-text [leftValue]="eyesOpenTotalAverage[0]?.value" [rightValue]="100-eyesOpenTotalAverage[0]?.value" [displayPercent]="false"></app-left-right-text>
                    </app-table-value>
                    <app-table-value>{{eyesOpenTotalAverage[1]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenTotalAverage[2]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenTotalAverage[3]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesOpenTotalAverage[4]?.format(1)}}</app-table-value>
                </div>
            </div>
        </div>
        <div class="flex-grow-1">
            <app-table-header [backgroundColor]="eyesClosedBackgroundColor" [color]="eyesClosedColor">{{eyesClosedTitle}}</app-table-header>
            <div class="d-flex flex-row">
                <div class="flex-grow-1">
                    <app-table-title class="text-center">{{format('advancedStandingEvaluation.results.trial', '1')}}</app-table-title>
                    <app-table-value>
                        <app-left-right-text [leftValue]="eyesClosedStats[0][0]?.value" [rightValue]="100-eyesClosedStats[0][0]?.value"
                                             [displayPercent]="false"></app-left-right-text>
                    </app-table-value>
                    <app-table-value>{{eyesClosedStats[0][1]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedStats[0][2]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedStats[0][3]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedStats[0][4]?.format(1)}}</app-table-value>
                </div>
                <div class="flex-grow-1">
                    <app-table-title class="text-center">{{format('advancedStandingEvaluation.results.trial', '2')}}</app-table-title>
                    <app-table-value>
                        <app-left-right-text [leftValue]="eyesClosedStats[1][0]?.value" [rightValue]="100-eyesClosedStats[1][0]?.value"
                                             [displayPercent]="false"></app-left-right-text>
                    </app-table-value>
                    <app-table-value>{{eyesClosedStats[1][1]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedStats[1][2]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedStats[1][3]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedStats[1][4]?.format(1)}}</app-table-value>
                </div>
                <div class="flex-grow-1">
                    <app-table-title class="text-center">{{format('advancedStandingEvaluation.results.trial', '3')}}</app-table-title>
                    <app-table-value>
                        <app-left-right-text [leftValue]="eyesClosedStats[2][0]?.value" [rightValue]="100-eyesClosedStats[2][0]?.value"
                                             [displayPercent]="false"></app-left-right-text>
                    </app-table-value>
                    <app-table-value>{{eyesClosedStats[2][1]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedStats[2][2]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedStats[2][3]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedStats[2][4]?.format(1)}}</app-table-value>
                </div>
                <div class="flex-grow-1">
                    <app-table-title class="text-center" [backgroundColor]="eyesClosedBackgroundColor"
                                     [color]="eyesClosedColor">{{text('exercise.meter.repSummary.header.average')}}</app-table-title>
                    <app-table-value>
                        <app-left-right-text [leftValue]="eyesClosedTotalAverage[0]?.value" [rightValue]="100-eyesClosedTotalAverage[0]?.value"
                                             [displayPercent]="false"></app-left-right-text>
                    </app-table-value>
                    <app-table-value>{{eyesClosedTotalAverage[1]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedTotalAverage[2]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedTotalAverage[3]?.format(1)}}</app-table-value>
                    <app-table-value>{{eyesClosedTotalAverage[4]?.format(1)}}</app-table-value>
                </div>
            </div>
        </div>

    </div>
    <div class="section row mt-4">
        <div class="col-4 offset-4 d-flex flex-column">
            <app-table-header [backgroundColor]="colors.colorAccent" color="white">{{text('advanced_stance_evaluation_summary_romberg_quotient')}}</app-table-header>
            <app-table-value>{{rombergQuotient.format(1)}}</app-table-value>
        </div>
    </div>
</div>
