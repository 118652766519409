import {Component, Input, OnInit} from '@angular/core';
import {Chart, ChartDataSets, ChartOptions} from 'chart.js';
import {BodySideType, BodySideTypes} from '../../../models/bodySideType';
import {Sample} from '../../../utils/sample';
import * as chartjsPluginAnnotation from 'chartjs-plugin-annotation';
import {ChartAreaViewModel} from '../../../viewmodels/chartAreaViewModel';
import {BaseComponent} from "../../base/base.component";
import {UnitType, UnitTypes} from "../../../utils/values/unitType";
import {ChartPoint} from "../../../utils/charts/chartPoint";
import {Colors} from "../../../services/colors";
import {Arrays} from "common";

@Component({
    selector: 'app-samples-line-chart',
    templateUrl: './samples-line-chart.component.html',
    styleUrls: ['./samples-line-chart.component.scss']
})
export class SamplesLineChartComponent extends BaseComponent implements OnInit {

    @Input()
    public data: Map<[BodySideType, UnitType], Sample[]>;

    @Input()
    public total: Sample[];

    @Input()
    public weight: number;

    @Input()
    public markArea: ChartAreaViewModel;

    @Input()
    public xAxisMin: number;

    public datasets: ChartDataSets[];
    public chartOptions: ChartOptions;

    constructor() {
        super();
        Chart.pluginService.register(chartjsPluginAnnotation);
        this.total = [];
    }

    ngOnInit(): void {

        this.datasets = [];

        // We handle two cases here. Have only KG or Angles as the only type of data or have KG and Angles at the same time.
        // On the first case we plot everything uniformly. One axis.
        // On the second we plot KG on the left axis and angles on the right axis
        // There is no support for other unit combinations.
        const unitTypes = Arrays.uniqueValues(Array.from(this.data.keys()).map(value => value[1]));

        for (const key of this.data.keys()) {
            if (unitTypes.length === 1 || key[1] === UnitType.KG) {
                this.datasets.push({
                    data: this.data.get(key).map(i => ChartPoint.of(i.timestamp, i.value)),
                    label: BodySideTypes.format(key[0]),
                    borderColor: BodySideTypes.color(key[0]),
                    pointRadius: 0,
                    backgroundColor: 'transparent',
                    borderWidth: 2,
                    type: 'line',
                    xAxisID: 'x-axis',
                    yAxisID: 'y-axis'
                });
            } else if (key[1] === UnitType.ANGLES) {
                this.datasets.push({
                    data: this.data.get(key).map(i => ChartPoint.of(i.timestamp, i.value)),
                    label: UnitTypes.title(UnitType.ANGLES),
                    borderColor: Colors.GREEN,
                    pointRadius: 0,
                    backgroundColor: 'transparent',
                    borderWidth: 2,
                    type: 'line',
                    xAxisID: 'x-axis',
                    yAxisID: 'y-axis-2'
                });
            }
        }
        if (this.total.length > 0) {
            this.datasets.push({
                data: this.total.map(i => ChartPoint.of(i.timestamp, i.value)),
                label: this.text('graphs.lineSet.title.total'),
                borderColor: 'black',
                pointRadius: 0,
                backgroundColor: 'transparent',
                borderWidth: 2,
                type: 'line',
                xAxisID: 'x-axis',
                yAxisID: 'y-axis',
            });
        }

        // Axis min/max values
        const xAxisValues = this.datasets.flatMap(d => d.data as ChartPoint[]).map(c => c.x as number);
        if (!Number.isFinite(this.xAxisMin)) {
            this.xAxisMin = Math.min(...xAxisValues);
        }
        const xAxisMax: number = Math.max(...xAxisValues);
        let yAxis1Max = 1;
        for (const key of this.data.keys()) {
            if (key[1] === UnitType.KG) {
                yAxis1Max = Math.max(yAxis1Max, ...this.data.get(key).map(value => value.value));
            }
        }
        yAxis1Max = Math.max(yAxis1Max, ...this.total.map(v => v.value));
        if (yAxis1Max > 10) {
            yAxis1Max = Math.ceil(yAxis1Max * 1.2)
        }

        let yAxis2Max = 1;
        for (const key of this.data.keys()) {
            if (key[1] === UnitType.ANGLES) {
                yAxis2Max = Math.max(yAxis2Max, ...this.data.get(key).map(value => value.value));
            }
        }
        if (yAxis2Max > 10) {
            yAxis2Max = Math.ceil(yAxis2Max * 1.2)
        }

        this.chartOptions = {
            animation: {
                duration: 0,
                animateScale: false,
                animateRotate: false
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            devicePixelRatio: 2,
            annotation: {annotations: []},
            scales: {
                xAxes: [{
                    id: 'x-axis',
                    display: true,
                    stacked: false,
                    ticks: {
                        autoSkipPadding: 20,
                        autoSkip: true,
                        maxRotation: 0,
                        min: this.xAxisMin,
                        max: xAxisMax,
                        stepSize: 1
                    },

                }],
                yAxes: [
                    {
                        id: 'y-axis',
                        ticks: {
                            min: 0,
                            max: yAxis1Max
                        }
                    }
                ]
            },
            legend: {
                display: true,
                position: 'bottom',
                align: 'end',
                labels: {
                    boxWidth: 1
                }
            },
            tooltips: {
                enabled: false
            }
        };

        // Add right axis for degrees when kg and degrees are plotted
        if (unitTypes.length > 1) {
            this.chartOptions.scales.yAxes[0].scaleLabel = {
                display: true,
                labelString: UnitTypes.title(UnitType.KG),
                padding: 0
            };
            this.chartOptions.scales.yAxes.push({
                id: 'y-axis-2',
                ticks: {
                    min: 0,
                    max: yAxis2Max * 1.2,
                },
                position: 'right',
                scaleLabel: {display: true, labelString: UnitTypes.title(UnitType.ANGLES), padding: 0},
                gridLines: {display: false}
            });
        }

        if (this.weight) {
            this.chartOptions.annotation.annotations.push({
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis',
                value: this.weight,
                borderColor: 'red',
                borderDash: [3],
                borderWidth: 2
            })
        }

        if (this.markArea) {
            this.chartOptions.annotation.annotations.push({
                type: 'box',
                xScaleID: 'x-axis',
                yScaleID: 'y-axis',
                yMin: this.markArea.yMin,
                yMax: this.markArea.yMax,
                borderColor: this.markArea.borderColor,
                borderWidth: this.markArea.borderWidth,
                backgroundColor: this.markArea.backgroundColor,
                xMin: -1000,
                xMax: 10000
            });
            this.chartOptions.annotation.drawTime = 'beforeDatasetsDraw';

            // Update the yAxis max
            const yAxisMax = Math.max(yAxis1Max, this.markArea.yMax);
            this.chartOptions.scales.yAxes[0].ticks.max = yAxisMax;
        }
    }

}
