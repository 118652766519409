export enum ProgressOverTimeMetricType {
    MAX_VALUE,
    AVG_VALUE,
    PERCENTAGE,
    POINTS,
    TARGETS,
    EFFICIENCY,
    DISTRIBUTION,
    LATERAL_AMPLITUDE,
    LONGITUDINAL_AMPLITUDE,
    COP_SURFACE,
    REP_COUNT,
    JUMP_COUNT,
    SKIP_COUNT,
    JUMP_HEIGHT,
    RFD,
    IMPULSION,
    RSI,
    CONTACT_TIME,
    MCCALL,
    MCCALL_90,
    MCCALL_30,
    IYT,
    IYT_I,
    IYT_Y,
    IYT_T,
}
