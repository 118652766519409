/**
 * Datum for scatter chart in charts.js library
 */
import {ChartPoint as ChartChartPoint} from "chart.js";
import {Moment} from "moment";

export class ChartPoint implements ChartChartPoint {
    x?: number | string | Date | Moment | undefined;
    y?: number | string | Date | Moment | undefined;
    r?: number | undefined;
    t?: number | string | Date | Moment | undefined;

    public static of(x: number, y: number): ChartPoint {
        const d = new ChartPoint();
        d.x = x;
        d.y = y;
        return d;
    }
}
