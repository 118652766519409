import {Component, Input, OnInit} from '@angular/core';
import {BodySideType, BodySideTypes} from "../../../models/bodySideType";
import {Color} from "common";

@Component({
    selector: 'app-table-value',
    templateUrl: './table-value.component.html',
    styleUrls: ['./table-value.component.scss']
})
export class TableValueComponent implements OnInit {

    @Input()
    public color: Color;

    @Input()
    public sideForColor: BodySideType;

    @Input()
    public backgroundColor: Color;

    @Input()
    public compact: boolean;

    constructor() {
    }

    ngOnInit(): void {
        if (this.sideForColor) {
            this.color = BodySideTypes.color(this.sideForColor);
        }
    }
}
