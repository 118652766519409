import {Point} from "../../models/point";

export class TextDrawingParams {
    text: string;
    point: Point;
    fontStyle: string; // CanvasRenderingContext2D.font
    color: string; // CanvasRenderingContext2D.color
    rotationRad: number;

    public constructor() {
        this.text = '';
        this.point = Point.zero();
        this.color = 'black';
        this.rotationRad = 0;
    }

    public setText(text: string): TextDrawingParams {
        this.text = text;
        return this;
    }

    public setPoint(point: Point): TextDrawingParams {
        this.point = point;
        return this;
    }

    public setFontStyle(fontStyle: string): TextDrawingParams {
        this.fontStyle = fontStyle;
        return this;
    }

    public setColor(color: string): TextDrawingParams {
        this.color = color;
        return this;
    }

    public setRotationRad(angleRad: number): TextDrawingParams {
        this.rotationRad = angleRad;
        return this;
    }
}
