import {Component, OnInit} from '@angular/core';
import {ResultsComponent} from "../resultsComponent";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {NordicHamstringResultsModel} from "../../../dtos/statistics/nordicHamstringResultsModel";
import {AdvancedNordicHamstringResultsModel} from "../../../dtos/statistics/advancedNordicHamstringResultsModel";
import {Resource} from "common";
import {ActivityImageResolverService} from "../../../services/activity-image-resolver.service";
import {BodySideType} from "../../../models/bodySideType";
import {Biomechanics} from "../../../utils/biomechanics";
import {Sample} from "../../../utils/sample";
import {SampleUtils} from "../../../utils/sampleUtils";
import {Value} from "../../../utils/values/value";
import {UnitType} from "../../../utils/values/unitType";
import {Degrees} from "../../../utils/values/degrees";
import {Kg} from "../../../utils/values/kg";

@Component({
    selector: 'app-nordic-hamstring-results',
    templateUrl: './nordic-hamstring-results.component.html',
    styleUrls: ['./nordic-hamstring-results.component.scss']
})
export class NordicHamstringResultsComponent extends ResultsComponent implements OnInit {
    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    private statistics: NordicHamstringResultsModel | AdvancedNordicHamstringResultsModel;

    public image: Resource;
    public maxPerSide: Map<BodySideType, Value>;
    public tableTotalValues: Value[];
    public tableLeftValues: Value[];
    public tableRightValues: Value[];
    public tableDeficitValues: Value[];
    public isAdvancedNordic: boolean;
    public samples: Map<[BodySideType, UnitType], Sample[]>;
    public totalForces: Sample[];
    public maxRelativeForce: Value;
    public maxTorque: Value;
    public forcesPerDegreesData: Map<[BodySideType, UnitType], Sample[]>;
    public forcesPerDegreesTotal: Sample[];
    public torquePerDegreesData: Map<[BodySideType, UnitType], Sample[]>;
    public torquePerDegreesTotal: Sample[];
    public maxTorqueAngle: Value;
    public averageTorqueLeft: Value;
    public timeToMaxLeft: Value;
    public averageTorqueRight: Value;
    public timeToMaxRight: Value;
    public maxTorquePerSide: Map<BodySideType, Value>;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            this.warn(`Unsupported analysis`);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        const results = this.activityAnalysis.activityResultsModel;
        if (results instanceof NordicHamstringResultsModel || results instanceof AdvancedNordicHamstringResultsModel) {
            this.statistics = results;
        } else {
            this.warn(`Unsupported analysis`);
            return;
        }

        const statistics = this.statistics;

        this.isAdvancedNordic = statistics instanceof AdvancedNordicHamstringResultsModel;
        this.image = this.activityImageResolverService.do(this.activity.image);
        this.maxPerSide = this.getMaxPerSide();

        // Table
        // The arrays for the table values contain in order: Average, Rfd, TimeToMax, Fatique
        this.tableTotalValues = [];
        this.tableLeftValues = [];
        this.tableRightValues = [];

        this.tableTotalValues.push(new Value(statistics.average));
        this.tableLeftValues.push(new Value(statistics.averageLeft));
        this.tableRightValues.push(new Value(statistics.averageRight));

        this.tableTotalValues.push(new Value(statistics.rfd));
        this.tableLeftValues.push(new Value(statistics.rfdLeft));
        this.tableRightValues.push(new Value(statistics.rfdRight));

        this.tableTotalValues.push(new Value(statistics.timeToMax / 1000));
        this.tableLeftValues.push(new Value(statistics.timeToMaxLeft / 1000));
        this.tableRightValues.push(new Value(statistics.timeToMaxRight / 1000));

        this.tableTotalValues.push(new Value(statistics.fatigue));
        this.tableLeftValues.push(new Value(statistics.fatigueLeft));
        this.tableRightValues.push(new Value(statistics.fatigueRight));

        if (this.isAdvancedNordic) {
            this.tableDeficitValues = [];
            this.tableDeficitValues.push(new Value(Biomechanics.deficit(statistics.averageLeft, statistics.averageRight).value));
            this.tableDeficitValues.push(new Value(Biomechanics.deficit(statistics.rfdLeft, statistics.rfdRight).value));
            this.tableDeficitValues.push(new Value(Biomechanics.deficit(statistics.timeToMaxLeft, statistics.timeToMaxRight).value));
            this.tableDeficitValues.push(new Value(Biomechanics.deficit(statistics.fatigueLeft, statistics.fatigueRight).value));
        }

        // Graph
        this.samples = new Map<[BodySideType, UnitType], Sample[]>();
        this.samples.set([BodySideType.LEFT, UnitType.KG], SampleUtils.createSampleCollection(statistics.syncedTimestamps, statistics.syncedForcesLeft));
        this.samples.set([BodySideType.RIGHT, UnitType.KG], SampleUtils.createSampleCollection(statistics.syncedTimestamps, statistics.syncedForcesRight));

        // Total
        if (statistics.syncedTimestamps.length === statistics.syncedForcesLeft.length && statistics.syncedForcesRight.length === statistics.syncedForcesRight.length) {
            this.totalForces = statistics.syncedTimestamps.map((value, index) =>
                Sample.of(value / 1000, statistics.syncedForcesLeft[index] + statistics.syncedForcesRight[index])
            );
        } else {
            this.warn('Cannot plot total. Samples mismatch');
        }

        if (this.isAdvancedNordic) {
            const advancedNordicStatistics = statistics as AdvancedNordicHamstringResultsModel;
            if (advancedNordicStatistics.syncedTimestamps.length === advancedNordicStatistics.syncedAngles.length) {
                this.samples.set([BodySideType.NONE, UnitType.ANGLES], SampleUtils.createSampleCollection(advancedNordicStatistics.syncedTimestamps, advancedNordicStatistics.syncedAngles))
            }

            // Value widgets
            this.maxRelativeForce = new Value(advancedNordicStatistics.relativeForce, UnitType.NEWTON_PER_KG);
            this.maxTorque = new Value(advancedNordicStatistics.maxTorque, UnitType.NEWTON_METERS);

            // Force per degrees chart
            this.forcesPerDegreesData = new Map<[BodySideType, UnitType], Sample[]>();
            this.forcesPerDegreesData.set([BodySideType.LEFT, UnitType.KG], this.createSamplesWithDegreesOnAxisX(advancedNordicStatistics.syncedAngles, advancedNordicStatistics.syncedForcesLeft));
            this.forcesPerDegreesData.set([BodySideType.RIGHT, UnitType.KG], this.createSamplesWithDegreesOnAxisX(advancedNordicStatistics.syncedAngles, advancedNordicStatistics.syncedForcesRight));
            this.forcesPerDegreesTotal = [];
            if (advancedNordicStatistics.syncedAngles.length === this.totalForces.length) {
                this.forcesPerDegreesTotal = this.createSamplesWithDegreesOnAxisX(advancedNordicStatistics.syncedAngles, this.totalForces.map(value => value.value));
            }

            // Toque per degrees chart
            this.torquePerDegreesData = new Map<[BodySideType, UnitType], Sample[]>();
            this.torquePerDegreesData.set([BodySideType.LEFT, UnitType.KG], this.createSamplesWithDegreesOnAxisX(advancedNordicStatistics.syncedAngles, advancedNordicStatistics.leftTorques));
            this.torquePerDegreesData.set([BodySideType.RIGHT, UnitType.KG], this.createSamplesWithDegreesOnAxisX(advancedNordicStatistics.syncedAngles, advancedNordicStatistics.rightTorques));
            this.torquePerDegreesTotal = [];
            if (advancedNordicStatistics.syncedAngles.length === advancedNordicStatistics.totalTorques.length) {
                this.torquePerDegreesTotal = this.createSamplesWithDegreesOnAxisX(advancedNordicStatistics.syncedAngles, advancedNordicStatistics.totalTorques);
            }

            // Table
            this.maxTorqueAngle = new Degrees(advancedNordicStatistics.angleOfMaxTorque);
            this.averageTorqueLeft = new Value(advancedNordicStatistics.avgTorqueLeft);
            this.averageTorqueRight = new Value(advancedNordicStatistics.avgTorqueRight);
            this.timeToMaxLeft = new Value(advancedNordicStatistics.timeToMaxLeft / 1000);
            this.timeToMaxRight = new Value(advancedNordicStatistics.timeToMaxRight / 1000);

            this.maxTorquePerSide = new Map<BodySideType, Value>();
            this.maxTorquePerSide.set(BodySideType.LEFT, new Value(advancedNordicStatistics.maxTorqueLeft, UnitType.NEWTON_METERS));
            this.maxTorquePerSide.set(BodySideType.RIGHT, new Value(advancedNordicStatistics.maxTorqueRight, UnitType.NEWTON_METERS));
        }
    }

    public createSamplesWithDegreesOnAxisX(degrees: number[], data: number[]): Sample[] {
        if (degrees.length !== data.length) {
            this.warn('Samples have inconsistent deltas');
        }

        const samples: Sample[] = [];
        for (let i = 0; i < degrees.length; i++) {
            samples.push(new Sample(degrees[i], data[i]));
        }
        return samples;
    }

    public getMaxPerSide(): Map<BodySideType, Value> {
        const maxPerSideMap = new Map<BodySideType, Value>();
        maxPerSideMap.set(BodySideType.LEFT, new Kg(this.statistics.leftMaxForce));
        maxPerSideMap.set(BodySideType.RIGHT, new Kg(this.statistics.rightMaxForce));

        return maxPerSideMap;
    }
}
