<div>
    <div *ngIf="analysis; else loadingView">
        <ng-container *ngIf="viewType === resultViewTypes.FULL">
            <ng-container *ngTemplateOutlet="sectionKpis"></ng-container>
            <ng-container *ngTemplateOutlet="sectionGroundReactionForces"></ng-container>
            <ng-container *ngTemplateOutlet="sectionTimeOnAir"></ng-container>
            <ng-container *ngTemplateOutlet="sectionForces"></ng-container>
        </ng-container>
        <ng-container *ngIf="viewType === resultViewTypes.ROW">
            <div class="d-flex flex-row">
                <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
                <div class="flex-grow-1">
                    <ng-container *ngTemplateOutlet="sectionKpis"></ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #loadingView>
    <app-loading [state]="loadingStates.LOADING"></app-loading>
</ng-template>

<ng-template #sectionKpis>
    <div class="section">
        <h2>{{text('performance_analysis.section.title')}}</h2>
        <div class="metric d-flex flex-row justify-content-between">
            <div class="d-flex flex-column">
                <app-value-widget [iconType]="valueWidgetIcons.REFRESH"
                                  [title]="text('skipping.results.numberOfSkips')"
                                  [value]="numberOfSkips"
                                  [valueFractionDigits]="0"
                ></app-value-widget>
                <app-value-widget [iconType]="valueWidgetIcons.HEIGHT"
                                  [title]="text('widget.time_on_air.title')"
                                  [value]="timeOnAir"
                                  [valueFractionDigits]="1">
                </app-value-widget>
            </div>
            <div class="d-flex flex-column">
                <app-value-widget [iconType]="valueWidgetIcons.TIMER"
                                  [title]="text('exercise.dynamic_distribution_evaluation.config.duration.title')"
                                  [value]="duration"
                                  [valueFractionDigits]="1">
                </app-value-widget>
                <app-value-widget [iconType]="valueWidgetIcons.FATIGUE_INDEX"
                                  [title]="text('widget.fatigue_index.title')"
                                  [value]="fatigueIndex">
                </app-value-widget>
            </div>
            <div class="d-flex flex-column">
                <app-value-widget [iconType]="valueWidgetIcons.PACE"
                                  [title]="text('stats.hints.pace')"
                                  [value]="pace"
                                  [valueFractionDigits]="1">
                </app-value-widget>
                <app-value-widget [iconType]="valueWidgetIcons.MAX"
                                  [title]="text('multiple_jumps.average_power')"
                                  [value]="power"
                                  [valueFractionDigits]="1">
                </app-value-widget>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #sectionGroundReactionForces>
    <div class="ground-reaction-forces section mt-4">
        <h2>{{text('skipping.results.groundReactionForcesPerLeg')}}</h2>
        <div class="average-distribution d-flex flex-column mb-4">
            <div class="align-self-center fw-bold mb-2">{{text('average_distribution.section.title')}}</div>
            <div class="row">
                <div class="col-6 offset-3 d-flex">
                    <div class="side side-left">
                        <div class="in-units text-center ps-5 pe-5 pt-2 pb-2">{{averageDistributionLeftUnits}}</div>
                        <div class="in-percent text-center ps-5 pe-5 pt-2 pb-2">{{averageDistributionLeftPercent}}</div>
                    </div>
                    <div class="side side-right">
                        <div class="in-units text-center ps-5 pe-5 pt-2 pb-2">{{averageDistributionRightUnits}}</div>
                        <div class="in-percent text-center ps-5 pe-5 pt-2 pb-2">{{averageDistributionRightPercent}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <app-body-side-bar-chart [data]="groundReactionForcesPerRep"
                                     [valueFractionDigits]="1">
            </app-body-side-bar-chart>
        </div>
    </div>
</ng-template>

<ng-template #sectionTimeOnAir>
    <div class="section mt-4">
        <h2>{{text('skipping.results.timeOnAirPerLeg')}}</h2>
        <div class="row">
            <div class="col-8 offset-2">
                <div class="d-flex flex-row">
                    <div class="flex-grow-1">
                        <app-table-header></app-table-header>
                        <app-table-header></app-table-header>
                        <app-table-title>{{format('averageForce.kg', unitTypeUtils.symbol(unitTypes.KG))}}</app-table-title>
                        <app-table-title>{{text('timeOnAir.sec')}}</app-table-title>
                        <app-table-title>{{text('contact_time')}}</app-table-title>
                    </div>
                    <div class="flex-grow-1">
                        <app-table-header>{{text('skipping.results.infoPerFoot')}}</app-table-header>
                        <div class="d-flex flex-row flex-grow-1">
                            <div class="flex-grow-1">
                                <app-table-title [sideForBackgroundColor]="sideTypes.LEFT">{{formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.TITLE)}}</app-table-title>
                                <app-table-value [sideForColor]="sideTypes.LEFT">{{avgForceLeft}}</app-table-value>
                                <app-table-value [sideForColor]="sideTypes.LEFT">{{timeOnAirLeft}}</app-table-value>
                                <app-table-value [sideForColor]="sideTypes.LEFT">{{contactTimeLeft}}</app-table-value>
                            </div>
                            <div class="flex-grow-1">
                                <app-table-title [sideForBackgroundColor]="sideTypes.RIGHT">{{formatSideAttribute(sideTypes.RIGHT, sideTypeAttributes.TITLE)}}</app-table-title>
                                <app-table-value [sideForColor]="sideTypes.RIGHT">{{avgForceRight}}</app-table-value>
                                <app-table-value [sideForColor]="sideTypes.RIGHT">{{timeOnAirRight}}</app-table-value>
                                <app-table-value [sideForColor]="sideTypes.RIGHT">{{contactTimeRight}}</app-table-value>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #sectionForces>
    <div class="section mt-4">
        <h2>{{text('skipping.results.lineGraph')}}</h2>
        <div>
            <app-samples-line-chart [data]="forces">
            </app-samples-line-chart>
        </div>
    </div>
</ng-template>
