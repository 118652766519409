import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions} from "chart.js";
import {Label} from "ng2-charts";
import {BodySideType, BodySideTypes} from "../../../models/bodySideType";
import {Sample} from "../../../utils/sample";
import {BaseComponent} from "../../base/base.component";
import {Colors} from "../../../services/colors";
import {Arrays} from "common";

@Component({
    selector: 'app-jump-analysis-line-chart',
    templateUrl: './jump-analysis-line-chart.component.html',
    styleUrls: ['./jump-analysis-line-chart.component.scss']
})
export class JumpAnalysisLineChartComponent extends BaseComponent implements OnInit {

    @Input()
    public sidesSamples: Map<BodySideType, Sample[]>;

    @Input()
    public rfdPoints: Sample[];

    @Input()
    public timeOnAirPoints: Sample[];

    @Input()
    public weight: number;

    public datasets: ChartDataSets[];
    public lineChartLabels: Label[];
    public chartOptions: ChartOptions;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.datasets = [];

        const leftSamples = this.sidesSamples.get(BodySideType.LEFT) ?? [];
        const rightSamples = this.sidesSamples.get(BodySideType.RIGHT) ?? [];

        // total
        if (this.sidesSamples.has(BodySideType.LEFT) && this.sidesSamples.has(BodySideType.RIGHT)) {
            const totalSamples = leftSamples.map((v, i) => new Sample(v.timestamp, (rightSamples.length > i ? rightSamples[i].value : 0) + v.value));
            this.datasets.push({
                data: totalSamples.map(i => i.value),
                label: this.text('graphs.lineSet.title.total'),
                borderColor: 'black',
                pointRadius: 0,
                backgroundColor: "transparent",
                borderWidth: 1,
                pointBackgroundColor: 'black'
            });
        }

        for (const side of this.sidesSamples.keys()) {
            this.datasets.push({
                data: this.sidesSamples.get(side).map(i => i.value),
                label: BodySideTypes.format(side),
                borderColor: BodySideTypes.color(side),
                pointRadius: 0,
                backgroundColor: "transparent",
                borderWidth: 1,
                pointBackgroundColor: BodySideTypes.color(side)
            });
        }

        const allTimes = Arrays.uniqueValues(leftSamples.map(value => value.timestamp), rightSamples.map(value => value.timestamp));
        if (leftSamples.length > 0) {
            this.lineChartLabels = leftSamples.map(i => ((i.timestamp) / 1000).toFixed(1));
        } else {
            this.lineChartLabels = rightSamples.map(i => ((i.timestamp) / 1000).toFixed(1));
        }

        // rfd
        if (this.rfdPoints.length > 0) {
            let rfdStartOffset = allTimes.findIndex(value => value > this.rfdPoints[0].timestamp);
            let rfdEndOffset = allTimes.findIndex(value => value > this.rfdPoints[1].timestamp);
            if (rfdStartOffset > 1 && rfdEndOffset > rfdStartOffset) {
                let rfdStartOffsetArray = new Array<number>(rfdStartOffset - 1).fill(NaN);
                let rfdEndOffsetArray = new Array<number>((rfdEndOffset - rfdStartOffset) - 1).fill(NaN);
                rfdStartOffsetArray.push(this.rfdPoints[0].value);
                rfdStartOffsetArray = rfdStartOffsetArray.concat(rfdEndOffsetArray);
                rfdStartOffsetArray.push(this.rfdPoints[1].value);

                const rfdDataset: ChartDataSets = {
                    data: rfdStartOffsetArray,
                    label: 'RFD',
                    borderColor: Colors.GREEN,
                    borderDash: [3],
                    pointRadius: 3,
                    pointBorderWidth: 3,
                    pointBorderColor: Colors.GREEN,
                    backgroundColor: "transparent",
                    borderWidth: 1,
                    pointBackgroundColor: Colors.GREEN,
                    spanGaps: true,
                };
                this.datasets.push(rfdDataset);
            }
        }

        // time on air
        if (this.timeOnAirPoints.length > 0) {
            let onAirStartOffset = allTimes.findIndex(value => value > this.timeOnAirPoints[0].timestamp);
            let onAirEndOffset = allTimes.findIndex(value => value > this.timeOnAirPoints[1].timestamp);
            let onAirStartOffsetArray = new Array<number>(onAirStartOffset - 1).fill(NaN);
            let onAirEndOffsetArray = new Array<number>((onAirEndOffset - onAirStartOffset) - 1).fill(NaN);
            onAirStartOffsetArray.push(this.timeOnAirPoints[0].value);
            onAirStartOffsetArray = onAirStartOffsetArray.concat(onAirEndOffsetArray);
            onAirStartOffsetArray.push(this.timeOnAirPoints[1].value);

            const onAirDataset: ChartDataSets = {
                data: onAirStartOffsetArray,
                label: this.text('jump_analysis.cmj.workout.cycle_time.title'),
                borderColor: Colors.RED,
                pointRadius: 3,
                pointBorderWidth: 3,
                pointBorderColor: Colors.RED,
                backgroundColor: "transparent",
                borderWidth: 1,
                pointBackgroundColor: Colors.RED,
                spanGaps: true,
            };
            this.datasets.push(onAirDataset);
        }

        // weight
        if (this.weight && allTimes.length > 1) {
            const data = new Array<number>(allTimes.length);
            data[0] = this.weight;
            data[data.length - 1] = this.weight;

            const weightDataset: ChartDataSets = {
                data: data,
                label: this.text('exercise.jump_analysis.weight'),
                borderColor: Colors.RED,
                borderDash: [3],
                pointBorderWidth: 0,
                pointRadius: 0,
                pointBorderColor: Colors.RED,
                backgroundColor: "transparent",
                borderWidth: 1,
                pointBackgroundColor: Colors.RED,
                spanGaps: true,
            };
            this.datasets.push(weightDataset);
        }

        this.chartOptions = {
            animation: {
                duration: 0,
                animateScale: false,
                animateRotate: false
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            devicePixelRatio: 2,
            scales: {
                xAxes: [{
                    display: true,
                    stacked: false,
                    ticks: {
                        autoSkipPadding: 20,
                        autoSkip: true,
                        maxRotation: 0
                    },
                }],
                yAxes: [
                    {
                        ticks: {
                            min: 0
                        }
                    },
                ]
            },
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    boxWidth: 1
                }
            },
            tooltips: {
                enabled: false
            }

        };
    }
}
