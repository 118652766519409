import {Code} from "common";

export class BuiltinProtocols {
    public static IDENTIFIERS = {
        ASH_IYT: "f7247d65-6fcb-4c7a-9ac4-f5e2efcea39c",
        MC_CALL: "a59ff5a0-5b2b-47ee-938e-9b0f1265d364",
        DSI: "ba5f9741-4b09-49d8-9495-073eb2ffad5b",
        KNEE_ANTAGONIST: "e35fb062-9b0a-424c-beba-46b311dd7c50",
        NECK_REPORT: "7ca9002e-fc95-473e-b445-a31a2101bb31",
        KNEE_ANTAGONIST_MUSCLE: "c0058adf-b3a4-4ba3-825c-5170c0d51e21",
        KNEE_ADMISSION: "27f53634-d02c-43ba-95e5-a0d3e747ef58",
        KNEE_FINAL: "1bfac5b4-c13d-4360-81c2-06e49731d77a",
        SHOULDER: "2d8a92da-92b8-4e2c-a949-475f7076b2e0",
        FORCE_VELOCITY: "b084bfdf-f50a-4c92-a69b-d61aa9392939",
        /** @deprecated */
        MULTIPLE_JUMPS: "650362c2-6431-43d3-9e56-dda864fbc801",
        /** @deprecated */
        UNIPODAL_CMJ: "0efffe6a-ec3d-436d-a972-002d7a45a834",
        /** @deprecated */
        UNIPODAL_SJ: "7347afa5-3a79-4777-a057-006bede88ed2",
        /** @deprecated */
        UNIPODAL_STANCE_EVALUATION: "0714eac6-c761-4bb2-90c9-67687e781ee6",
        ADVANCED_STANDING_EVALUTATION: "cb32efb1-fce0-455f-b050-5ecc16462083"
    };

    /* Activities of builtin protocols*/
    public static ACTIVITY_IDENTIFIERS = {
        MCCALL_HAMSTRINGS_90_DEGREES: 'cb2a1de5-5442-4cc9-b783-b17ed9331a29',
        MCCALL_HAMSTRINGS_30_DEGREES: '2f96b1f2-40bb-42de-a774-3bc119ddfa2b',
        ASH_IYT_I: "8960a0e0-d019-4cf7-9d6f-2ac7ac952f3a",
        ASH_IYT_Y: "2f6327c7-9fa2-40fe-bbd5-f1e80990f16a",
        ASH_IYT_T: "d708cdae-954b-4817-8c8d-89630b9c741c",
        HEAD_NECK_FLEXION_METER: "7a01b44f-a1b4-4ea3-a1c8-d0a37295a074",
        HEAD_NECK_EXTENSION_METER: "571d8df8-d7b2-4b59-abad-063f88ff06fa",
        HEAD_NECK_TILTING_METER: "1261de5a-c2ff-4b9b-b201-433964846fcc",
        HEAD_NECK_ROTATION_METER: "49e6bb5b-8850-4cec-85be-749ada1c54de",
        HEAD_NECK_LINK_FLEXION_METER: "768db8e2-193a-4b22-b0f8-e8704a44ee65",
        HEAD_NECK_LINK_EXTENSION_METER: "53a200f1-ad37-4495-acce-887ed8d926df",
        HEAD_NECK_LINK_TILTING_METER: "731fa527-782d-4bed-9796-d66741c09738",
        HEAD_RIGHT_NECK_LINK_TILTING_METER: "b1fb8c91-eea0-43a1-b4b9-d09ac7e4fea0",
        LEG_KNEE_EXTENSION_METER: "15613c38-4ef8-42f9-b93f-3cc6af5ba1a0",
        MUSCLE_KNEE_EXTENSION_METER: "09c833f4-648b-44fb-b4d2-3f313f86a693",
        LEG_KNEE_FLEXION_METER: "e6ef84a0-efee-4ef1-9913-95d6dbb01be5",
        LINK_KNEE_FLEXION_90_METER: "88c48ee3-e26c-4db3-a172-03a01884854f",
        MUSCLE_KNEE_FLEXION_METER: "0cc9be50-4f9e-4132-9cbe-8814a4c938b2",
    };

    public static KNEE_ANTAGONIST_EXTENSION_CODES = [BuiltinProtocols.ACTIVITY_IDENTIFIERS.LEG_KNEE_EXTENSION_METER, BuiltinProtocols.ACTIVITY_IDENTIFIERS.MUSCLE_KNEE_EXTENSION_METER];
    public static KNEE_ANTAGONIST_FLEXION_CODES = [BuiltinProtocols.ACTIVITY_IDENTIFIERS.LEG_KNEE_FLEXION_METER, BuiltinProtocols.ACTIVITY_IDENTIFIERS.LINK_KNEE_FLEXION_90_METER, BuiltinProtocols.ACTIVITY_IDENTIFIERS.MUSCLE_KNEE_FLEXION_METER];

    public static isBuiltIn(code: Code): boolean {
        return Object.values(BuiltinProtocols.IDENTIFIERS).includes(code);
    }
}
