import {ActivityConfigSetMetaDto} from './activityConfigSetMeta.dto';
import {BodySideType, BodySideTypes} from '../models/bodySideType';
import {BodyPart, BodyParts} from '../models/bodyPart';
import {BaseDto} from "common";

export class ActivityConfigSetDto extends BaseDto {
    ordinal?: number;
    bodySide?: string;
    bodyPart?: string;
    deviceType?: string;
    balancePattern?: string;
    metaFields?: Array<ActivityConfigSetMetaDto>;

    public get bodySideType(): BodySideType {
        return BodySideTypes.parse(this.bodySide);
    }

    public get bodyPartType(): BodyPart {
        return BodyParts.parse(this.bodyPart);
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        this.metaFields = this.deserializeArray(input.metaFields, ActivityConfigSetMetaDto);

        return this;
    }

    public getMeta(key: string): ActivityConfigSetMetaDto {
        if (!this.metaFields) {
            return undefined;
        }
        return Array.from(this.metaFields).find(metaField => metaField.metaKey && metaField.metaKey.toLowerCase() === key.toLowerCase());
    }

    public getMetaString(key: string): string {
        let metaValue = this.getMeta(key);
        if (!metaValue) {
            return '';
        }

        return metaValue.metaValue;
    }

    public getMetaFloat(key: string): number {
        let metaValue = this.getMeta(key);
        if (!metaValue) {
            return 0.0;
        }

        return Number(metaValue.metaValue);
    }

    public getMetaInt(key: string): number {
        let metaValue = this.getMeta(key);

        if (!metaValue) {
            return 0;
        }

        return Math.round(Number(metaValue.metaValue));
    }

    public getMetaBoolean(key: string): boolean {
        const metaField: ActivityConfigSetMetaDto = this.getMeta(key);

        if (metaField != undefined && metaField.metaValue != undefined) {
            return metaField.metaValue === 'true';
        } else {
            return false;
        }
    }
}
