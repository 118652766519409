<div class="container mt-3">
    <div *ngIf="loadingState === loadingStates.LOADED; else loadingComponent" class="row">
        <app-protocol-filters class="col-3" [filter]="filter" (filterChanged)="onFilterChanged($event)">
        </app-protocol-filters>
        <div class="col-9">
            <h1>{{text('participants.progress')}}</h1>
            <div class="d-flex align-items-baseline justify-content-between">
                <app-order-selection [defaultOrder]="orderType" [orderOptions]="orderOptions" (orderChanged)="onOrderChanged($event)"></app-order-selection>
                <div>{{format('participantProgress.total', this.filteredItems.length.toString())}}</div>
            </div>
            <div class="row">
                <app-protocol-card *ngFor="let item of this.filteredItems"
                                   class="col-6 col-md-4 col-lg-3 mb-4"
                                   [code]="item.code"
                                   [title]="text(item.title)"
                                   [image]="item.image"
                                   [overflowImage]="item.overflowImage"
                                   [exerciseTypeIndicators]="item.exerciseTypes"
                                   [enabled]="item.enabled"
                                   (selected)="onCardSelected($event)"
                ></app-protocol-card>
            </div>
        </div>
    </div>
</div>
<ng-template #loadingComponent>
    <app-loading class="d-flex align-items-center justify-content-center" [state]="loadingState"></app-loading>
</ng-template>
