import {ActivityConfigDto} from "./activityConfig.dto";
import {BaseDto} from "common";

export class ProtocolConfigStepDto extends BaseDto {
    activityConfig: ActivityConfigDto;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.activityConfig) {
            this.activityConfig = new ActivityConfigDto().deserialize(input.activityConfig);
        }

        return this;
    }

}
