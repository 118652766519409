import {Component, OnInit} from '@angular/core';
import {ResultsComponent} from "../resultsComponent";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {RepCountActivityResultsModel} from "../../../dtos/statistics/repCountActivityResultsModel";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {ValueWidgetIcon} from "../../../viewmodels/valueWidgetIcons";
import {ActivityConfigSetMetaKeys} from "../../../models/activityConfigSetMetaKeys";
import {BodySideType, BodySideTypes} from "../../../models/bodySideType";
import {Annotation} from "../../balance/annotation";
import {Arrays, Color, KeyValue} from "common";
import {Value} from "../../../utils/values/value";
import {Kg} from "../../../utils/values/kg";

@Component({
    selector: 'app-rep-count-results',
    templateUrl: './rep-count-results.component.html',
    styleUrls: ['./rep-count-results.component.scss']
})
export class RepCountResultsComponent extends ResultsComponent implements OnInit {
    public readonly valueWidgetIcons = ValueWidgetIcon;

    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    private statistics: RepCountActivityResultsModel;

    public sets: Value;
    public reps: Value;
    public rest: Value;
    public bodyChartData: Map<BodySideType, Value>[][]; // Sets of data that the bodySide component accepts
    public targets: Annotation[];
    public paces: KeyValue<number, KeyValue<Value, Color>[]>[];
    public successRates: KeyValue<number, KeyValue<Value, Color>[]>[];
    public rowStats: Map<BodySideType, [number, number, number]>; // average, pace, success

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. RepCountResultsComponent`, this.analysis);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.activityAnalysis.activityResultsModel as RepCountActivityResultsModel;
        const statistics = this.statistics;

        const groupedReps = Arrays.groupBy(statistics.repResults, rep => rep.side);
        const sets = Math.max(...Array.from(groupedReps.values()).map(r => r.length));
        const sides = Array.from(groupedReps.keys());

        this.sets = new Value(sets);
        this.reps = new Value(this.activity.config.activityConfigSets[0].getMetaFloat(ActivityConfigSetMetaKeys.TARGET_REPS));
        this.rest = new Value(this.activity.config.rest);

        this.bodyChartData = [];
        this.paces = [];
        this.successRates = [];

        // Charts. Create sets of data per side and repetition index (eg first left with first right)
        // Set table data also
        for (let i = 0; i < sets; i++) {
            const repChartData: Map<BodySideType, Value>[] = [];

            const paceData: KeyValue<Value, Color>[] = [];
            const successRateData: KeyValue<Value, Color>[] = [];
            for (const side of sides) {
                const sideSets = groupedReps.get(side);
                if (sideSets.length <= i) {
                    continue;
                }

                const result = sideSets[i];
                for (const repCycle of result.repCycles) {
                    const cycleMap = new Map<BodySideType, Value>();
                    cycleMap.set(result.side, new Value(repCycle.maxForce));
                    repChartData.push(cycleMap)
                }
                paceData.push(KeyValue.of(new Value(result.pace), BodySideTypes.color(side)));
                successRateData.push(KeyValue.of(new Value(result.successRate * 100), BodySideTypes.color(side)));
            }
            this.paces.push(KeyValue.of(i, paceData));
            this.successRates.push(KeyValue.of(i, successRateData));
            this.bodyChartData.push(repChartData);
        }

        const dashWidth = 3;
        this.targets = [];
        for (const side of sides) {
            const results = groupedReps.get(side);
            const target = new Kg(results[0].target);
            const title = `${BodySideTypes.format(side)} ${this.text('exercise.config.sets.title')}, ${target.formatTo(this.userSettings.weightUnit)}`;
            const annotation = Annotation.of(title, target, BodySideTypes.color(side));
            annotation.borderDash = [dashWidth];
            annotation.borderDashOffset = side === BodySideType.RIGHT ? dashWidth : 0;
            this.targets.push(annotation);
        }

        // view type row stats
        this.rowStats = new Map<BodySideType, [number, number, number]>();
        for (const side of sides) {
            const results = groupedReps.get(side);
            const average = Math.max(...results.map(r => r.average));
            const pace = Math.max(...results.map(r => r.pace));
            const success = Math.max(...results.map(r => r.successRate)) * 100;
            this.rowStats.set(side, [average, pace, success]);
        }
    }

}
