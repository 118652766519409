import {Injectable} from '@angular/core';
import {ProtocolDto} from '../dtos/protocol.dto';
import {BuiltinProtocols} from '../models/builtinProtocols';
import {ExerciseType} from '../models/exerciseType';
import {ProtocolGroupFilter} from '../viewmodels/protocolGroupFilter';
import {GameType} from '../models/gameType';
import {ActivityConfigTags} from '../models/activityConfigTag';
import {ActivityDto} from '../dtos/activity.dto';
import {ProtocolViewModel} from '../viewmodels/protocolViewModel';
import {PerformType} from '../viewmodels/performType';
import {ActivityType} from '../models/activityType';
import {FilterPeriodType} from '../viewmodels/filterPeriodType';
import {isAfter, subMonths, subYears} from 'date-fns';
import {DeviceType, KeyValue, TextUtils} from "common";

@Injectable({
    providedIn: 'root'
})
export class ProtocolFilteringService {
    constructor() {
    }

    /**
     * Groups protocols
     */
    public group(protocols: ProtocolDto[]): Map<ProtocolGroupFilter, ProtocolDto[]> {
        const grouped = new Map<ProtocolGroupFilter, ProtocolDto[]>();
        protocols.forEach((protocol) => {
            const filter = ProtocolFilteringService.getProtocolIdentifier(protocol);
            let existingFilter: ProtocolGroupFilter;
            for (const key of grouped.keys()) {
                if (key.getFilterId() === filter.getFilterId()) {
                    existingFilter = key;
                    break;
                }
            }
            if (!existingFilter) {
                grouped.set(filter, [protocol]);
            } else {
                grouped.get(existingFilter).push(protocol);
            }
        });

        return grouped;
    }

    private static getProtocolIdentifier(p: ProtocolDto): ProtocolGroupFilter {
        let filter: ProtocolGroupFilter;
        // For multiple jumps old protocol, we just care about its single activity inside it, to be grouped with our current single activity multiple jumps.
        // So force create it as a mock protocol so that it's grouped together
        if (!p.singleActivity || (p.config && p.config.baseConfigCode !== BuiltinProtocols.IDENTIFIERS.MULTIPLE_JUMPS)) {
            filter = new ProtocolGroupFilter();
            filter.baseConfigCode = p.config.getBaseConfigCode();
        } else {
            filter = ProtocolFilteringService.getGroupIdentifierForSingleActivity(p);
        }
        return filter;
    }

    private static getGroupIdentifierForSingleActivity(protocol: ProtocolDto): ProtocolGroupFilter {
        const config = protocol.activities[0].config;
        // edge case - in the past, if a user edited the deviceType of an existing activity, we end up with same base code but different devices.
        // we need to not group these but show a card for each device
        const devices = config.getDeviceTypes();
        const firstDevice = devices.length > 0 ? devices[0] : undefined;

        // don't group by gameType
        let gameType: GameType;
        if (config.exerciseTypeEnum !== ExerciseType.KINESTHESIA_OBSTACLES) {
            gameType = undefined;
        } else {
            gameType = config.gameTypeEnum;
        }

        const filter = new ProtocolGroupFilter();
        filter.baseConfigGroup = ActivityConfigTags.getGroupTag(config);
        filter.baseConfigCode = config.baseConfigCode;
        if (firstDevice) {
            filter.devices.push(firstDevice);
        }
        filter.gameType = gameType;
        filter.exerciseType = config.exerciseTypeEnum;
        filter.activityTypes.push(config.activityTypeEnum);
        filter.exerciseType = config.exerciseTypeEnum === ExerciseType.METER_ENDURANCE ? ExerciseType.METER : config.exerciseTypeEnum;
        return filter;
    }

    public applyFilter(protocols: ProtocolDto[], filter: ProtocolGroupFilter): ProtocolDto[] {
        let filtered: ProtocolDto[] = [];

        if (filter.baseConfigGroup) {
            filtered = ProtocolFilteringService.withBaseConfigGroup(filter.baseConfigGroup, protocols);
        } else if (filter.baseConfigCode) {
            filtered = ProtocolFilteringService.withBaseConfigCode(filter.baseConfigCode, protocols);
        }

        if (filter.activityTypes.length > 0) {
            filtered = ProtocolFilteringService.withActivityTypes(filter.activityTypes, filtered);
        }

        if (filter.exerciseType) {
            filtered = ProtocolFilteringService.withExerciseType(filter.exerciseType, filtered);
        }
        if (filter.devices.length > 0) {
            filtered = ProtocolFilteringService.withDevices(filter.devices, filtered);
        }
        if (filter.gameType) {
            filtered = ProtocolFilteringService.withGame(filter.gameType, filtered);
        }

        return filtered;
    }

    public filterViewmodels(vms: ProtocolViewModel[], filter: ProtocolGroupFilter): ProtocolViewModel[] {
        let filtered: ProtocolViewModel[];

        if (!TextUtils.isEmpty(filter.query)) {
            filtered = vms.filter(vm => vm.title.toLowerCase().includes(filter.query.toLowerCase().trim()));
        } else {
            filtered = vms;
        }

        if (filter.performType.length > 0) {
            const keepActivities = filter.performType.includes(PerformType.Activity);
            const keepProtocols = filter.performType.includes(PerformType.Protocol);
            filtered = filtered.filter(vm => (vm.isSingleActivity && keepActivities) || (!vm.isSingleActivity && keepProtocols));
        }

        if (filter.activityTypes.length > 0) {
            // At least one activity type should exist
            filtered = filtered.filter(vm => filter.activityTypes.some(d => vm.activityType === d));
        }

        if (filter.period !== undefined && filter.period !== FilterPeriodType.ALL) {
            filtered = filtered.filter(value => this.withinPeriod(value.date, filter.period));
        }

        if (filter.devices.length > 0) {
            // All devices should exist
            filtered = filtered.filter(vm => filter.devices.every(d => vm.devices.includes(d)));
        }

        if (filter.tags.length > 0) {
            // All tags should exist
            filtered = filtered.filter(vm => filter.tags.every(d => vm.tags.includes(d)));
        }

        return filtered;
    }

    public withinPeriod(date: Date, type: FilterPeriodType): boolean {
        const now = new Date();
        let passes = false;
        switch (type) {
            case FilterPeriodType.ALL:
                passes = true;
                break;
            case FilterPeriodType.YEAR:
                passes = isAfter(date, subYears(now, 1));
                break;
            case FilterPeriodType.NINE_MONTHS:
                passes = isAfter(date, subMonths(now, 9));
                break;
            case FilterPeriodType.SIX_MONTHS:
                passes = isAfter(date, subMonths(now, 6));
                break;
            case FilterPeriodType.THREE_MONTHS:
                passes = isAfter(date, subMonths(now, 3));
                break;
        }
        return passes;
    }

    public activityPassesFilter(activity: ActivityDto, filter: ProtocolGroupFilter): boolean {
        let passes: boolean = true;
        if (filter.baseConfigGroup) {
            passes = filter.baseConfigGroup === ActivityConfigTags.getGroupTag(activity.config);
        } else if (filter.baseConfigCode) {
            passes = filter.baseConfigCode === activity.config.baseConfigCode;
        }

        if (passes && filter.exerciseType) {
            const activityExerciseType = activity.config.exerciseTypeEnum === ExerciseType.METER_ENDURANCE ? ExerciseType.METER : activity.config.exerciseTypeEnum;
            passes = activityExerciseType === filter.exerciseType;
        }
        if (passes && filter.devices.length > 0) {
            passes = activity.config.getDeviceTypes().some(device => filter.devices.includes(device));
        }
        if (passes && filter.gameType) {
            passes = activity.config.gameTypeEnum === filter.gameType;
        }
        return passes;
    }

    /**
     * Returns an array of key-value of a protocol and one of its activities that passes the filter.
     * If a protocol contains multiple activities that pass the filter, multiple key-values will be returned, one for each activity and with the same protocol
     */
    public filterActivities(protocols: ProtocolDto[], filter: ProtocolGroupFilter): KeyValue<ProtocolDto, ActivityDto>[] {
        const filtered: KeyValue<ProtocolDto, ActivityDto>[] = [];
        for (const protocol of protocols) {
            for (const activity of protocol.activities) {
                if (!activity.config) {
                    continue;
                }

                if (this.activityPassesFilter(activity, filter)) {
                    filtered.push(KeyValue.of(protocol, activity));
                }
            }
        }

        return filtered;
    }

    private static withBaseConfigGroup(baseConfigGroup: string, protocols: ProtocolDto[]): ProtocolDto[] {
        const filtered: ProtocolDto[] = [];
        for (const protocol of protocols) {
            for (const activity of protocol.activities) {
                if (ActivityConfigTags.getGroupTag(activity.config) === baseConfigGroup) {
                    filtered.push(protocol);
                    break;
                }
            }
        }
        return filtered;
    }

    private static withBaseConfigCode(baseConfigCode: string, protocols: ProtocolDto[]): ProtocolDto[] {
        const filtered: ProtocolDto[] = [];
        for (const protocol of protocols) {
            if (protocol.config && protocol.config.baseConfigCode === baseConfigCode) {
                filtered.push(protocol);
                continue;
            }
            for (const activity of protocol.activities) {
                if (activity.config.baseConfigCode === baseConfigCode) {
                    filtered.push(protocol);
                    break;
                }
            }
        }
        return filtered;
    }

    /**
     * Returns protocols with at least one activity using a device
     */
    private static withDevices(devices: DeviceType[], protocols: ProtocolDto[]): ProtocolDto[] {
        const filtered: ProtocolDto[] = [];
        for (const protocol of protocols) {
            for (const activity of protocol.activities) {
                if (activity.config.getDeviceTypes().some(d => devices.includes(d))) {
                    filtered.push(protocol);
                    break;
                }
            }
        }

        return filtered;
    }

    private static withActivityTypes(types: ActivityType[], protocols: ProtocolDto[]) {
        const filtered: ProtocolDto[] = [];
        for (const protocol of protocols) {
            for (const activity of protocol.activities) {
                if (types.includes(activity.config.activityTypeEnum)) {
                    filtered.push(protocol);
                    break;
                }
            }
        }
        return filtered;
    }

    private static withGame(game: GameType, protocols: ProtocolDto[]): ProtocolDto[] {
        const filtered: ProtocolDto[] = [];
        for (const protocol of protocols) {
            for (const activity of protocol.activities) {
                if (activity.config.gameTypeEnum === game) {
                    filtered.push(protocol);
                    break;
                }
            }
        }

        return filtered;
    }

    private static withExerciseType(exerciseType: ExerciseType, protocols: ProtocolDto[]): ProtocolDto[] {
        const filtered: ProtocolDto[] = [];
        for (const protocol of protocols) {
            for (const activity of protocol.activities) {
                const activityExerciseType = activity.config.exerciseTypeEnum === ExerciseType.METER_ENDURANCE ? ExerciseType.METER : activity.config.exerciseTypeEnum;
                if (activityExerciseType === exerciseType) {
                    filtered.push(protocol);
                    break;
                }
            }
        }

        return filtered;
    }
}
