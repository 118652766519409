import {Component, Input} from "@angular/core";
import {ActivityDto} from "../../dtos/activity.dto";
import {ProtocolDto} from "../../dtos/protocol.dto";
import {ActivityAnalysisServiceResponseDto} from "../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ProtocolAnalysisServiceResponseDto} from "../../dtos/statistics/protocolAnalysisServiceResponseDto";
import {ResultsViewType} from "./resultsViewType";
import {ViewMediumType} from "./viewMediumType";
import {BaseComponent} from "../base/base.component";

@Component({
    selector: 'app-results',
    template: '',
    styles: [''],
})
export class ResultsComponent extends BaseComponent {
    public readonly resultViewTypes: typeof ResultsViewType = ResultsViewType;

    @Input()
    activities?: ActivityDto[];

    @Input()
    protocols?: ProtocolDto[];

    @Input()
    analysis: ActivityAnalysisServiceResponseDto | ProtocolAnalysisServiceResponseDto;

    @Input()
    viewType: ResultsViewType;

    @Input()
    mediumType: ViewMediumType;

    constructor() {
        super();
    }
}
