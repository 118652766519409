import {ActivityDto} from "../../dtos/activity.dto";
import {ActivityAnalysisResultDto} from "../../dtos/statistics/activityAnalysisResultDto";

export abstract class CsvConverter {
    private readonly _lines: string[][];

    protected constructor() {
        this._lines = [];
    }

    abstract convert(activity: ActivityDto, analysis: ActivityAnalysisResultDto): string[][];

    protected addLine(...items: string[]) {
        this._lines.push(items);
        Array.of()
    }

    protected get lines(): string[][] {
        return this._lines;
    }

    public formatTime(deltaMs: number): string {
        return (deltaMs / 1000).toString();
    }

    protected formatSample(value: number): string {
        return value.toFixed(15);
    }
}
