<div class="container">
    <h1>Email Confirmation</h1>
    <div class="content">
        <div *ngIf="isViewType(viewTypes().LOADING)">
            Verifying...
        </div>
        <div *ngIf="isViewType(viewTypes().SUCCESS)" class="success">
            <p>
                Congratulations!
                <br/>
                <br/>
                You have successfully verified your email address.
            </p>
        </div>
        <div *ngIf="isViewType(viewTypes().ERROR)">
            <p>
                Could not verify your email address. Please try again or contact <a href="mailto:info@k-invent.com" target="_blank">info@k-invent.com</a>
            </p>
        </div>

    </div>
</div>

