import {Component, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {validate as uuidValidate} from 'uuid';
import {AcceptAccountAssociationDto} from '../../dtos/acceptAssociatedAccount.dto';
import {BaseComponent} from "../../components/base/base.component";
import {EmailValidator, LoadingState} from "common";

@Component({
    selector: 'app-accept-account-association',
    templateUrl: './accept-account-association.component.html',
    styleUrls: ['./accept-account-association.component.scss']
})
export class AcceptAccountAssociationComponent extends BaseComponent implements OnInit {
    private loadingState?: LoadingState;

    constructor(private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.route.paramMap
            .pipe(takeUntil(this.destroySubject))
            .subscribe((params) => {
                // validate token is uuid
                const email = params.get('email');
                const token = params.get('token');
                if (token === null || email === null || !uuidValidate(token) || !EmailValidator.isValid(email)) {
                    this.loadingState = LoadingState.ERROR;
                    return;
                }

                this.loadingState = LoadingState.LOADING;

                this.analyticsSwitchPage('Accept account association', '/acceptAccountAssociation', '/acceptAccountAssociation');

                const dto = new AcceptAccountAssociationDto();
                dto.email = email;
                dto.token = token;
                this.authenticationService.acceptAccountAssociation(dto)
                    .pipe(takeUntil(this.destroySubject))
                    .subscribe(_response => {
                        this.onConfirmationSuccess();
                    }, error => {
                        this.onConfirmationError(error);
                    });
            });
    }

    private onConfirmationSuccess(): void {
        this.loadingState = LoadingState.LOADED;
    }

    private onConfirmationError(_error: any): void {
        this.loadingState = LoadingState.ERROR;
    }

    public isLoadingState(loadingState: LoadingState): boolean {
        return this.loadingState === loadingState;
    }
}
