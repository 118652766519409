import {ActivityMetaDto} from './activityMetaDto';
import {JumpType} from './jumpType';
import {ActivityConfigDto} from './activityConfig.dto';
import {BaseDto} from "common";

export class ActivityDto extends BaseDto {
    image?: string;
    config: ActivityConfigDto;
    metaFields: ActivityMetaDto[];
    startTime?: number;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.config) {
            this.config = new ActivityConfigDto().deserialize(input.config);
        }

        this.metaFields = this.deserializeArray(input.metaFields, ActivityMetaDto);

        return this;
    }

    public get jumpType(): JumpType {
        return this.config.jumpType;
    }

    public getMeta(key: string): ActivityMetaDto {
        if (!this.metaFields) {
            return undefined;
        }
        return Array.from(this.metaFields).find(metaField => metaField.metaKey && metaField.metaKey.toLowerCase() === key.toLowerCase());
    }

    public getMetaFloat(key: string): number {
        let metaField: ActivityMetaDto = this.getMeta(key);
        if (metaField !== undefined && metaField !== null && metaField.metaValue !== undefined) {
            return Number(metaField.metaValue);
        } else {
            return 0.0;
        }
    }
}
