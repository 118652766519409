import {Component, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {validate as uuidValidate} from 'uuid';
import {BaseComponent} from "../../components/base/base.component";
import { AcceptParticipantShareDto } from '../../dtos/acceptParticipantShare.dto';
import { LoadingState } from 'common';

@Component({
    selector: 'app-accept-participant-share',
    templateUrl: './accept-participant-share.component.html',
    styleUrls: ['./accept-participant-share.component.scss']
})
export class AcceptParticipantShareComponent extends BaseComponent implements OnInit {
    private loadingState?: LoadingState;

    constructor(private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.route.paramMap
            .pipe(takeUntil(this.destroySubject))
            .subscribe((params) => {
                // validate token is uuid
                const token = params.get('token');
                if (token === null || !uuidValidate(token)) {
                    this.loadingState = LoadingState.ERROR;
                    return;
                }

                this.loadingState = LoadingState.LOADING;

                this.analyticsSwitchPage('Accept participant share', '/acceptParticipantShare', '/acceptParticipantShare');

                const dto = new AcceptParticipantShareDto();
                dto.token = token;
                this.authenticationService.acceptParticipantShare(dto)
                    .pipe(takeUntil(this.destroySubject))
                    .subscribe(_response => {
                        this.onConfirmationSuccess();
                    }, error => {
                        this.onConfirmationError(error);
                    });
            });
    }

    private onConfirmationSuccess(): void {
        this.loadingState = LoadingState.LOADED;
    }

    private onConfirmationError(_error: any): void {
        this.loadingState = LoadingState.ERROR;
    }
    
    public isLoadingState(loadingState: LoadingState): boolean {
        return this.loadingState === loadingState;
    }
}
