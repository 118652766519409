import {BodySideType} from '../models/bodySideType';
import {MeterEnduranceActivityResultsModel} from '../dtos/statistics/meterEnduranceActivityResultsModel';
import {ProtocolAnalysisServiceResponseDto} from '../dtos/statistics/protocolAnalysisServiceResponseDto';
import {ExerciseType} from '../models/exerciseType';
import {ActivityResultsModel} from '../dtos/statistics/activityResultsModel';
import {startOfDay} from 'date-fns';
import {ProtocolDto} from '../dtos/protocol.dto';
import {Code} from "common";

export class Protocols {
    public static findResultPerProtocolActivityCodeSide(analysis: ProtocolAnalysisServiceResponseDto, code: Code, side?: BodySideType): MeterEnduranceActivityResultsModel {
        return analysis.protocolsResults[0].activitiesResults.find(a => {
            const isCorrectProtocol = a.config.baseConfigCode === code;
            const isCorrectSide = (a.activityResultsModel as MeterEnduranceActivityResultsModel).repResults[0].bodySideType === side;
            return side ? isCorrectProtocol && isCorrectSide : isCorrectProtocol;
        })?.activityResultsModel as MeterEnduranceActivityResultsModel;
    }

    public static findResultPerActivityExerciseType(analysis: ProtocolAnalysisServiceResponseDto, exerciseType: ExerciseType): ActivityResultsModel {
        return analysis.protocolsResults[0].activitiesResults.find(a => {
            return a.config.exerciseTypeEnum === exerciseType;
        })?.activityResultsModel;
    }

    public static groupByDay(protocols: ProtocolDto[]): Map<number, ProtocolDto[]> {
        const groups = new Map<number, ProtocolDto[]>();

        for (const protocol of protocols) {
            let date = startOfDay(protocol.getStartTime());

            if (!groups.has(date.getTime())) {
                groups.set(date.getTime(), []);
            }
            groups.get(date.getTime()).push(protocol);
        }

        return groups;
    }
}
