import {I18nService} from '../services/i18n/i18n.service';

export enum GameType {
    UNKNOWN = 'UNKNOWN',
    KANGAROO_SCUBA = 'KANGAROO_SCUBA',
    KANGAROO_DESERT = 'KANGAROO_DESERT',
    KANGAROO_SURFING = 'KANGAROO_SURFING',
    KANGAROO_RUGBY = 'KANGAROO_RUGBY',
    KANGAROO_SMOOTHIE = 'KANGAROO_SMOOTHIE',
    KANGAROO_MEMORY = 'KANGAROO_MEMORY',
    KANGAROO_SKIPPING = 'KANGAROO_SKIPPING',
    KANGAROO_BREAKOUT = 'KANGAROO_BREAKOUT',
    KANGAROO_HARLEY = 'KANGAROO_HARLEY'
}

export default class GameTypes {
    public static parse(gameText: string): GameType {
        return GameType[gameText as keyof typeof GameType];
    }

    public static getTitle(i18n: I18nService, gameType: GameType): string {
        let title: string;
        switch (gameType) {
            case GameType.KANGAROO_DESERT:
                title = i18n.text('kangaroo_desert_title');
                break;
            case GameType.KANGAROO_SCUBA:
                title = i18n.text('kangaroo_scuba_title');
                break;
            case GameType.KANGAROO_SURFING:
                title = i18n.text('kangaroo_surfing_title');
                break;
            case GameType.KANGAROO_RUGBY:
                title = i18n.text('kangaroo_rugby_title');
                break;
            case GameType.KANGAROO_MEMORY:
                title = i18n.text('kangaroo_memory_title');
                break;
            case GameType.KANGAROO_SMOOTHIE:
                title = i18n.text('kangaroo_smoothie_title');
                break;
            case GameType.KANGAROO_SKIPPING:
                title = i18n.text('kangaroo_skipping_title');
                break;
            case GameType.KANGAROO_BREAKOUT:
                title = i18n.text('kangaroo_breakout_title');
                break;
            case GameType.KANGAROO_HARLEY:
                title = i18n.text('kangaroo_harley_title');
                break;
            default:
                title = i18n.text('karl.rehab.game.title');
        }
        return title;
    }

    public static getOverflowImage(gameType: GameType): string {
        let resource: string;
        switch (gameType) {
            case GameType.KANGAROO_DESERT:
                resource = './assets/icons/kangaroo_desert_overlay.png';
                break;
            case GameType.KANGAROO_SCUBA:
                resource = './assets/icons/kangaroo_scuba_overlay.png';
                break;
            case GameType.KANGAROO_SURFING:
                resource = './assets/icons/kangaroo_surfing_overlay.png';
                break;
            case GameType.KANGAROO_RUGBY:
                resource = './assets/icons/kangaroo_rugby_overlay.png';
                break;
            case GameType.KANGAROO_MEMORY:
                resource = './assets/icons/kangaroo_memory_overlay.png';
                break;
            case GameType.KANGAROO_SMOOTHIE:
                resource = './assets/icons/kangaroo_smoothie_overlay.png';
                break;
            case GameType.KANGAROO_SKIPPING:
                resource = './assets/icons/kangaroo_skipping_overlay.png';
                break;
            case GameType.KANGAROO_BREAKOUT:
                resource = './assets/icons/kangaroo_breakout_overlay.png';
                break;
            case GameType.KANGAROO_HARLEY:
                resource = './assets/icons/kangaroo_harley_overlay.png';
                break;
            default:
                resource = './assets/icons/kangaroo_rugby_overlay.png';
        }
        return resource;
    }
}
