import {Component, Input, OnInit} from '@angular/core';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {BuiltinProtocols} from '../../../models/builtinProtocols';
import {BodySideType} from '../../../models/bodySideType';
import {Code, Resource} from "common";
import {MeterEnduranceActivityResultsModel} from '../../../dtos/statistics/meterEnduranceActivityResultsModel';
import {ActivityImageResolverService} from '../../../services/activity-image-resolver.service';
import {MeterEnduranceStatisticsRowViewModel} from '../../../viewmodels/meterEnduranceStatisticsRowViewModel';
import {ActivityDto} from '../../../dtos/activity.dto';
import {MeterEnduranceActivityRepResultsModel} from '../../../dtos/statistics/meterEnduranceActivityRepResultsModel';
import {Biomechanics} from '../../../utils/biomechanics';
import {Activities} from '../../../utils/activities';
import {Protocols} from '../../../utils/protocols';
import {BaseComponent} from "../../base/base.component";
import {Colors} from "../../../services/colors";
import {Value} from "../../../utils/values/value";
import {Kg} from "../../../utils/values/kg";

@Component({
    selector: 'app-protocol-summary-knee-antagonist-results',
    templateUrl: './protocol-summary-knee-antagonist-results.component.html',
    styleUrls: ['./protocol-summary-knee-antagonist-results.component.scss']
})

export class ProtocolSummaryKneeAntagonistResultsComponent extends BaseComponent implements OnInit {
    public readonly colors = Colors;

    @Input()
    public protocol: ProtocolDto;

    @Input()
    public analysis: ProtocolAnalysisServiceResponseDto;

    public kneeFlexionActivity?: ActivityDto;
    public kneeExtensionActivity?: ActivityDto;

    public kneeFlexionActivityTitle?: string;
    public kneeExtensionActivityTitle?: string;

    public kneeFlexionImage: Resource;
    public kneeExtensionImage: Resource;

    public kneeFlexionViewmodel: MeterEnduranceStatisticsRowViewModel[];
    public kneeExtensionViewmodel: MeterEnduranceStatisticsRowViewModel[];

    public ratioRightFormatted: string;
    public ratioLeftFormatted: string;

    public kneeFlexionDeficitFormatted: string;
    public kneeExtensionDeficitFormatted: string;
    public ratioDeficitFormatted: string;

    public kneeFlexionMaxLeftFormatted: string;
    public kneeFlexionMaxRightFormatted: string;
    public kneeExtensionMaxLeftFormatted: string;
    public kneeExtensionMaxRightFormatted: string;

    public kneeFlexionMaxPerSide: Map<BodySideType, Value>;
    public kneeExtensionMaxPerSide: Map<BodySideType, Value>;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
    }

    ngOnInit(): void {
        const kneeExtensionCodes = BuiltinProtocols.KNEE_ANTAGONIST_EXTENSION_CODES;
        const kneeFlexionCodes = BuiltinProtocols.KNEE_ANTAGONIST_FLEXION_CODES;

        //extension
        this.kneeExtensionActivity = this.findProtocolActivity(kneeExtensionCodes);
        if (this.kneeExtensionActivity) {
            const code = this.kneeExtensionActivity.config.baseConfigCode;

            this.kneeExtensionActivityTitle = Activities.getTitle(this.i18n, this.kneeExtensionActivity);
            this.kneeExtensionImage = this.activityImageResolverService.do(this.kneeExtensionActivity.image);

            const kneeExtensionRight = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, code, BodySideType.RIGHT);
            const kneeExtensionLeft = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, code, BodySideType.LEFT);

            this.kneeExtensionViewmodel = [];
            this.kneeExtensionMaxPerSide = new Map<BodySideType, Value>();
            if (kneeExtensionRight) {
                const maxRep = this.findMaxRep(kneeExtensionRight);
                this.kneeExtensionViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(maxRep));
                this.kneeExtensionMaxPerSide.set(BodySideType.RIGHT, new Kg(maxRep.maxValue));
            }
            if (kneeExtensionLeft) {
                const maxRep = this.findMaxRep(kneeExtensionLeft);
                this.kneeExtensionViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(maxRep));
                this.kneeExtensionMaxPerSide.set(BodySideType.LEFT, new Kg(maxRep.maxValue));
            }
        }
        //flexion
        this.kneeFlexionActivity = this.findProtocolActivity(kneeFlexionCodes);
        if (this.kneeFlexionActivity) {
            const code = this.kneeFlexionActivity.config.baseConfigCode;
            this.kneeFlexionActivityTitle = Activities.getTitle(this.i18n, this.kneeFlexionActivity);
            this.kneeFlexionImage = this.activityImageResolverService.do(this.kneeFlexionActivity?.image);

            const kneeFlexionRight = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, code, BodySideType.RIGHT);
            const kneeFlexionLeft = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, code, BodySideType.LEFT);

            this.kneeFlexionViewmodel = [];
            this.kneeFlexionMaxPerSide = new Map<BodySideType, Value>();
            if (kneeFlexionRight) {
                const maxRep = this.findMaxRep(kneeFlexionRight);
                this.kneeFlexionViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(maxRep));
                this.kneeFlexionMaxPerSide.set(BodySideType.RIGHT, new Kg(maxRep.maxValue));
            }
            if (kneeFlexionLeft) {
                const maxRep = this.findMaxRep(kneeFlexionLeft);
                this.kneeFlexionViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(maxRep));
                this.kneeFlexionMaxPerSide.set(BodySideType.LEFT, new Kg(maxRep.maxValue));
            }
        }
        if (this.kneeFlexionActivity && this.kneeExtensionActivity) {
            this.kneeFlexionMaxLeftFormatted = Value.formatOrEmptyValue(this.kneeFlexionMaxPerSide.get(BodySideType.LEFT), 2);
            this.kneeFlexionMaxRightFormatted = Value.formatOrEmptyValue(this.kneeFlexionMaxPerSide.get(BodySideType.RIGHT), 2);
            this.kneeExtensionMaxLeftFormatted = Value.formatOrEmptyValue(this.kneeExtensionMaxPerSide.get(BodySideType.LEFT), 2);
            this.kneeExtensionMaxRightFormatted = Value.formatOrEmptyValue(this.kneeExtensionMaxPerSide.get(BodySideType.RIGHT), 2);

            const ratioRight = Biomechanics.ratio(this.kneeFlexionMaxPerSide.get(BodySideType.RIGHT), this.kneeExtensionMaxPerSide.get(BodySideType.RIGHT));
            this.ratioRightFormatted = Value.formatOrEmptyValue(ratioRight, 2);

            const ratioLeft = Biomechanics.ratio(this.kneeFlexionMaxPerSide.get(BodySideType.LEFT), this.kneeExtensionMaxPerSide.get(BodySideType.LEFT));
            this.ratioLeftFormatted = Value.formatOrEmptyValue(ratioLeft, 2);

            const kneeFlexionDeficit = Biomechanics.deficit(this.kneeFlexionMaxPerSide.get(BodySideType.LEFT), this.kneeFlexionMaxPerSide.get(BodySideType.RIGHT));
            this.kneeFlexionDeficitFormatted = Value.formatOrEmptyValue(kneeFlexionDeficit, 2);

            const kneeExtensionDeficit = Biomechanics.deficit(this.kneeExtensionMaxPerSide.get(BodySideType.LEFT), this.kneeExtensionMaxPerSide.get(BodySideType.RIGHT));
            this.kneeExtensionDeficitFormatted = Value.formatOrEmptyValue(kneeExtensionDeficit, 2);

            const ratioDeficit = Biomechanics.deficit(ratioLeft, ratioRight);
            this.ratioDeficitFormatted = Value.formatOrEmptyValue(ratioDeficit, 2);
        }
    }

    private findMaxRep(results: MeterEnduranceActivityResultsModel): MeterEnduranceActivityRepResultsModel {
        let max = results.repResults[0].maxValue;
        let maxRep = results.repResults[0];
        results.repResults.forEach(repResult => {
            if (repResult.maxValue > max) {
                max = repResult.maxValue;
                maxRep = repResult;
            }
        });
        return maxRep;
    }

    private findProtocolActivity(codes: Code[]): ActivityDto | undefined {
        return this.protocol.activities.find(a => {
            return codes.includes(a.config.baseConfigCode);
        });
    }
}
