/**
 * Immutable instance of device physical properties
 * Distance is in mm
 */
import {PhysicalDevice} from "./physicalDevice";

export default class PlatePhysicalDevice implements PhysicalDevice {
    constructor(public readonly width: number, public readonly height: number,
                public readonly horizontalSensorDistance: number, public readonly verticalSensorDistance: number) {
    }

    public static classic() {
        return new PlatePhysicalDevice(165, 320, 120, 260);
    }

    public static plate100Kg() {
        return new PlatePhysicalDevice(165, 320, 122.4, 273.3);
    }

    public static xl() {
        return new PlatePhysicalDevice(272, 437, 215.00, 372.53);
    }

    public toString(): string {
        return `Plates: width: ${this.width} height: ${this.height}`;
    }
}
