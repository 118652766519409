import {ActivityResultsModel} from "./activityResultsModel";
import {UnipodalJumpAnalysisActivitySideResultsModel} from "./unipodalJumpAnalysisActivitySideResultsModel";

export class UnipodalJumpAnalysisActivityResultsModel extends ActivityResultsModel {
    public resultsPerSide: Array<UnipodalJumpAnalysisActivitySideResultsModel>;
    public heightDeficit: number; // %
    public maxForcesDeficit: number; // %
    public rfdsDeficit: number; // %
    public impulsionsDeficit: number; // %
    public averageWeight: number;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.resultsPerSide = this.deserializeArray(input.resultsPerSide, UnipodalJumpAnalysisActivitySideResultsModel);

        return this;
    }

}
