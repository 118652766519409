import {Entry} from "../dtos/statistics/entry";
import {StanceOval} from "../dtos/statistics/stanceOval";
import {UnipodalStanceEvaluationActivitySideResultsModel} from "../dtos/statistics/unipodalStanceEvaluationActivitySideResultsModel";
import {VerticalDistribution} from "../models/verticalDistribution";
import {Value} from "../utils/values/value";
import {MillimetersSquared} from "../utils/values/millimetersSquared";
import {Millimeters} from "../utils/values/millimeters";
import {Percent} from "../utils/values/percent";

/**
 * Viewmodel for one side of the UnipodalStanceEvaluationResultsComponent
 */
export class UnipodalStanceEvaluationSideResultsViewModel {
    public sideGridCopPercentage: Entry[];
    public sideOval: StanceOval;
    public ellipseSurface: Value;
    public lateralAvg: Value;
    public longitudinalAvg: Value;
    public surface: Value;
    public surfaceUnits: MillimetersSquared;
    public totalDisplacement: Value;
    public meanVelocity: Value;
    public latDeviation: Value;
    public latDeviationUnits: Millimeters;
    public longDeviationUnits: Millimeters;
    public longDeviation: Value;
    public heelTipDistribution: VerticalDistribution<Value>;

    public static fromResults(results: UnipodalStanceEvaluationActivitySideResultsModel): UnipodalStanceEvaluationSideResultsViewModel {
        let vm = new UnipodalStanceEvaluationSideResultsViewModel();
        vm.sideGridCopPercentage = results.gridCopCoordsInPercentage.map(value => Entry.of(value.x * 2, value.y));

        // Transform entries to double width since the stance component accepts per plate positions while the dto contains relative to both sensors
        vm.sideOval = this.convertOvalToUnipodal(results.oval);

        vm.latDeviationUnits = new Millimeters(results.lateralDeviation);
        vm.longDeviationUnits = new Millimeters(results.longitudinalDeviation);
        vm.lateralAvg = new Millimeters(results.lateralAvg);
        vm.longitudinalAvg = new Millimeters(results.longitudinalAvg);
        vm.ellipseSurface = new Millimeters(results.surface);
        vm.surface = new Value(results.surface);
        vm.surfaceUnits = new MillimetersSquared(results.surface);
        vm.totalDisplacement = new Value(results.totalCopDisplacement);
        vm.meanVelocity = new Value(results.avgVelocity);
        vm.longDeviation = new Value(results.longitudinalDeviation);
        vm.latDeviation = new Value(results.lateralDeviation);
        vm.heelTipDistribution = new VerticalDistribution<Value>(new Percent(results.tipValuePercent), new Percent(results.heelValuePercent));

        return vm;
    }

    private static convertOvalToUnipodal(source: StanceOval): StanceOval {
        const oval = new StanceOval();
        oval.cY = source.cY;
        oval.cX = source.cX * 2;
        oval.width = source.width * 2;
        oval.height = source.height;
        return oval;
    }
}
