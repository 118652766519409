import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ParticipantTagConfigDto} from "../../dtos/participantTagConfig.dto";
import {BaseComponent} from "../base/base.component";

@Component({
    selector: 'app-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})
export class TagComponent extends BaseComponent implements OnInit {
    @Input()
    public tag: ParticipantTagConfigDto;

    @Input()
    public selected: boolean;

    @Output()
    public clicked: EventEmitter<ParticipantTagConfigDto>;

    constructor() {
        super();
        this.clicked = new EventEmitter<ParticipantTagConfigDto>();
    }

    ngOnInit(): void {
    }

}
