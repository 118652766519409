import {Component, Input, OnInit} from '@angular/core';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Color, Title} from "common";
import {Context} from 'chartjs-plugin-datalabels/types/context';
import {BaseComponent} from "../../base/base.component";
import {Value} from "../../../utils/values/value";
import {KeyValue, TextUtils} from "common";

@Component({
    selector: 'app-stacked-bar-with-line-chart',
    templateUrl: './stacked-bar-with-line-chart.component.html',
    styleUrls: ['./stacked-bar-with-line-chart.component.scss']
})
export class StackedBarWithLineChartComponent extends BaseComponent implements OnInit {

    private static LINECHART_AXIS_ID = 'pace';

    /**
     * Labels in the x-axis
     */
    @Input()
    public columns: string[];

    /**
     * What each column represents. Eg. Jump, Repetition (singular)
     */
    @Input()
    public columnDescription: string;

    /**
     * Data for each item in the {columns} input
     */
    @Input()
    public stackedData: Map<KeyValue<Title, Color>, Value[]>;

    /**
     * Data to be displayed as line chart, on top of {stackedData}
     */
    @Input()
    public lineData: KeyValue<Title, Value[]>;

    @Input()
    public displayLegend: boolean = false;

    @Input()
    public displayAxisLeft: boolean = true;

    @Input()
    public axisRightLabel: Title;

    public datasets: ChartDataSets[];
    public chartPlugins: any[];
    public chartOptions: ChartOptions;

    constructor() {
        super();
        this.chartPlugins = [pluginDataLabels];
    }

    ngOnInit(): void {
        this.datasets = [];

        let lineChartMax = undefined;
        if (this.lineData && this.lineData.value.length > 0) {
            this.datasets.push({
                label: this.lineData.key,
                data: this.lineData.value.map(value => value.value),
                borderColor: 'red',
                backgroundColor: 'transparent',
                pointBorderColor: 'red',
                pointBackgroundColor: 'white',
                pointRadius: 5,
                pointBorderWidth: 3,
                pointHoverRadius: 8,
                pointHoverBorderWidth: 3,
                pointHoverBackgroundColor: 'white',
                pointHoverBorderColor: 'red',
                borderDash: [5],
                yAxisID: StackedBarWithLineChartComponent.LINECHART_AXIS_ID
            });

            lineChartMax = Math.max(0, ...this.lineData.value.map(value => value.value));
        }

        for (const setInfo of this.stackedData.keys()) {
            this.datasets.push({
                label: setInfo.key,
                data: this.stackedData.get(setInfo).map(value => value.value),
                borderColor: setInfo.value,
                backgroundColor: setInfo.value,
                stack: 'combined',
                type: 'bar',
                pointBorderColor: 'red',
                pointHoverBorderColor: 'red',
                borderWidth: 10,
                barPercentage: .6
            });
        }

        // find max value for stacked columns
        const sides = Array.from(this.stackedData.values());
        const sumPerColumn: number[] = new Array(this.columns.length).fill(0);
        for (let i = 0; i < this.columns.length; i++) {
            for (const sideData of sides) {
                if (sideData.length >= i) {
                    sumPerColumn[i] = sumPerColumn[i] + sideData[i].value;
                }
            }
        }
        const stackedMax = Math.max(0.1, ...sumPerColumn);

        this.chartOptions = this.getChartOptions(Math.ceil(stackedMax * 1.2), Math.ceil(lineChartMax * 1.2));

    }

    private getChartOptions(stackedMax: number, lineChartMax: any): Chart.ChartOptions {
        const self = this;
        return {
            animation: {
                duration: 0,
                animateScale: false,
                animateRotate: false
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            devicePixelRatio: 2,
            tooltips: {
                enabled: true,
                mode: 'index',
                callbacks: {
                    title(item: Chart.ChartTooltipItem[], _data: Chart.ChartData): string | string[] {
                        return `${self.columnDescription}: ${item[0].label}`;
                    },
                    label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                        const datasetName = data.datasets[tooltipItem.datasetIndex].label;
                        return `${datasetName}: ${Number(tooltipItem.value).toFixed(1)}`;
                    }
                }
            },
            legend: {
                display: this.displayLegend,
                position: 'bottom',
                labels: {
                    boxWidth: 1
                },
                align: 'end',
                reverse: true
            },
            scales: {
                xAxes: [{
                    offset: true
                }],
                yAxes: [{
                    ticks: {
                        min: 0,
                        max: stackedMax
                    },
                    gridLines: {
                        drawOnChartArea: false
                    },
                    display: this.displayAxisLeft
                }, {
                    id: StackedBarWithLineChartComponent.LINECHART_AXIS_ID,
                    display: lineChartMax !== undefined,
                    position: 'right',
                    gridLines: {
                        drawOnChartArea: false
                    },
                    ticks: {
                        min: 0,
                        max: lineChartMax
                    },
                    scaleLabel: {display: !TextUtils.isEmpty(this.axisRightLabel), labelString: this.axisRightLabel}
                }]
            },
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    formatter: this.labelFormatter.bind(this),
                    color: 'black'
                }
            }
        };
    }

    private labelFormatter(value: number, context: Context): string {
        const column = context.dataIndex;
        const datasetIndex = context.datasetIndex;
        const datasets = context.chart.data.datasets;

        const isTopStackDataset = datasetIndex === datasets.length - 1;
        const haveLineChart = this.lineData !== undefined;
        const isLineChart = haveLineChart && datasetIndex === 0;

        let formatted: string;
        if (isLineChart) {
            formatted = value.toFixed(1);
        } else if (isTopStackDataset) {
            // For the top of the stack display the sum, for the rest hide the value
            let sum = 0;
            datasets.slice(haveLineChart ? 1 : 0).forEach(dataset => {
                if (dataset.data.length >= column) {
                    sum += dataset.data[column] as number;
                }
            });

            formatted = sum.toFixed(1);
        } else {
            formatted = '';
        }

        return formatted;
    }
}
