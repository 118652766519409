import {ActivityLineChartProvider} from './activityLineChartProvider';
import {MeterExerciseLineChartProvider} from './meterExerciseLineChartProvider';
import {ActivityDto} from '../../../dtos/activity.dto';
import {ExerciseType} from '../../../models/exerciseType';
import {StanceEvaluationLineChatProvider} from './stanceEvaluationLineChatProvider';
import {JumpAnalysisLineChartProvider} from './jumpAnalysisLineChartProvider';
import {BuiltInExerciseTemplates, BuiltInExerciseTemplateType} from '../../../models/builtInExerciseTemplateType';
import {SingleLegJumpAnalysisLineChatProvider} from './singleLegJumpAnalysisLineChatProvider';
import {JumpType} from '../../../dtos/jumpType';
import {MultipleJumpAnalysisLineChartProvider} from './multipleJumpAnalysisLineChartProvider';
import {SkippingLineChartProvider} from './skippingLineChartProvider';
import {DynamicDistributionLineChatProvider} from './dynamicDistributionLineChatProvider';
import {BodyWeightLineChartProvider} from "./bodyWeightLineChartProvider";

export class ActivityLineChartFactory {
    public static createProvider(activities: ActivityDto[]): ActivityLineChartProvider {
        let provider: ActivityLineChartProvider;
        const firstActivity = activities[0];
        let firstActivityBaseConfigCode = firstActivity.config.baseConfigCode;
        let exerciseType = firstActivity.config.exerciseTypeEnum;
        switch (exerciseType) {
            case ExerciseType.METER:
            case ExerciseType.METER_ENDURANCE:
                provider = new MeterExerciseLineChartProvider();
                break;
            case ExerciseType.STANDING_EVALUATION:
                provider = new StanceEvaluationLineChatProvider();
                break;
            case ExerciseType.JUMP_ANALYSIS:
                const jumpType = firstActivity.jumpType;
                if (BuiltInExerciseTemplates.isUnipodalJump(firstActivityBaseConfigCode)) {
                    provider = new SingleLegJumpAnalysisLineChatProvider();
                } else if (jumpType === JumpType.MULTIPLE_JUMPS) {
                    provider = new MultipleJumpAnalysisLineChartProvider();
                } else if (firstActivityBaseConfigCode === BuiltInExerciseTemplateType.SKIPPING_DELTAS) {
                    provider = new SkippingLineChartProvider();
                } else {
                    provider = new JumpAnalysisLineChartProvider();
                }
                break;
            case ExerciseType.DYNAMIC_DISTRIBUTION_EVALUATION:
                if (BuiltInExerciseTemplates.isDynamicDistributionSimple(firstActivityBaseConfigCode)) {
                    provider = new DynamicDistributionLineChatProvider();
                } else {
                    throw new Error(`Unsupported dynamic distribution activity`);
                }
                break;
            case ExerciseType.TOTAL_EVALUATION:
                if (BuiltInExerciseTemplates.isBodyWeightCalculation(firstActivityBaseConfigCode)) {
                    provider = new BodyWeightLineChartProvider();
                } else {
                    throw new Error(`Unsupported total evaluation activity`);
                }
                break;
            default:
                throw new Error('Unsupported');
        }
        return provider;
    }
}
