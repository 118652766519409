<div class="component full-width">
    <h3 class="pt-3 mb-0 ps-2">Sessions</h3>
    <mat-list #sessionList disableRipple="true">
        <mat-list-item *ngFor="let session of sessions; last as last" [class.selected]="isSelected(session)">
            <div class="item d-flex flex-row flex-grow-1">
                <div class="date flex-grow-1 ps-2 user-select-none" (click)="onSessionAction.emit([session, crudType.READ])">{{formatDate(session)}}</div>
                <button mat-icon-button [mat-menu-trigger-for]="appMenu" [matMenuTriggerData]="{session: session}">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>
            <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
        </mat-list-item>
    </mat-list>
    <mat-menu #appMenu="matMenu">
        <ng-template matMenuContent let-session="session">
            <button mat-menu-item (click)="onSessionAction.emit([session, crudType.DELETE])">Delete</button>
        </ng-template>
    </mat-menu>
</div>
