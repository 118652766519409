import {Dto} from "common";

export class ActivityMetaDto extends Dto {
    public metaKey: string;
    public metaValue: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
