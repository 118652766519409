<div>
    <div class="section section-kpis d-flex flex-row">
        <app-value-widget [title]="text('max_power')" [value]="maxPower" [valueFractionDigits]="1" [iconType]="valueWidgetIcons.MAX"></app-value-widget>
        <app-value-widget class="ms-2" title="F0" [value]="f0" [valueFractionDigits]="1" [iconType]="valueWidgetIcons.FORCE"></app-value-widget>
        <app-value-widget class="ms-2" title="V0" [value]="v0" [valueFractionDigits]="1" [iconType]="valueWidgetIcons.SPEED"></app-value-widget>
    </div>
    <div class="section row mt-5">
        <div class="col-6">
            <h2 class="text-center">{{text('heights_per_jump_load.graph.title')}}</h2>
            <div>
                <app-body-side-bar-chart [data]="jumpHeightPerLoadData"
                                         [valueFractionDigits]="1"
                                         [dataTitles]="jumpHeightPerLoadTitles"
                                         [axisYLabel]="jumpHeightAxisYLabel"
                                         [axisXLabel]="jumpHeightAxisXLabel"
                ></app-body-side-bar-chart>
            </div>
        </div>
        <div class="col-6">
            <h2 class="text-center">{{text('force_power_velocity_graph.title')}}</h2>
            <div>
                <app-force-velocity-chart [xAxisTitle]="forcePowerChartAxisXTitle"
                                          [xAxisLabels]="forcePowerChartAxisXValues"
                                          [leftAxisData]="forcePowerChartVelocityValues"
                                          [rightAxisData]="forcePowerChartPowerValues"
                                          [yAxisLeftTitle]="forcePowerChartLeftAxisTitle"
                                          [yAxisRightTitle]="forcePowerChartRightAxisTitle"
                                          [rightAxisHighlightRegion]="forcePowerChartMaxVelocityArea"
                ></app-force-velocity-chart>
            </div>
        </div>
    </div>
    <div class="section row mt-5">
        <div class="col-6">
            <h2 class="text-center">{{text('force_velocity_graph.title')}}</h2>
            <div>
                <app-scatter-chart [dataPoints]="realRelativeForceVelocityData"
                                   [trendLinePoints]="realRelativeForceVelocityTrend"
                                   [xAxisTitle]="realRelativeForceVelocityAxisXTitle"
                                   [yAxisTitle]="realRelativeForceVelocityAxisYTitle"
                ></app-scatter-chart>
            </div>
        </div>
        <div class="col-6">
            <h2 class="text-center">{{profilesTitle}}</h2>
            <app-lines-chart [lines]="profilesData" [xAxisTitle]="profilesAxisXTitle" [yAxisTitle]="profilesAxisYTitle"
            ></app-lines-chart>
        </div>
    </div>
</div>
