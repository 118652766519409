<div class="component">
    <div *ngIf="analysis; else loadingView">
        <ng-container *ngIf="viewType === resultViewTypes.FULL">
            <ng-container *ngTemplateOutlet="sectionTopRow"></ng-container>
            <ng-container *ngTemplateOutlet="sectionSecondRow"></ng-container>
            <ng-container *ngTemplateOutlet="sectionDropLand"></ng-container>
        </ng-container>
        <ng-container *ngIf="viewType === resultViewTypes.ROW">
            <div class="component d-flex flex-row">
                <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
                <ng-container *ngTemplateOutlet="sectionTopRow"></ng-container>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #loadingView>
    Loading...
</ng-template>

<ng-template #sectionTopRow>
    <div class="top-row row mb-4">
        <app-results-title class="text-center mb-2" [title]="text('standingEvaluation.results.mainTitle')"></app-results-title>
        <div class="item col-4">
            <div class="text-center">
                <div class="title d-inline-block">{{text('standingEvaluation.results.plateGraphMappingTitle')}}</div>
            </div>
            <div class="ratio ratio-1x1">
                <app-stance-indicator
                    [device]="device"
                    [sides]="sideTypes.BOTH"
                    [copPoints]="statistics.gridCopCoordsInPercentage"
                    [leftCopPoints]="statistics.leftGridCopCoordsInPercentage"
                    [rightCopPoints]="statistics.rightGridCopCoordsInPercentage"
                    [oval]="statistics.oval"
                    [zoomToEllipse]="false"
                    [rotateLeft]="isHeelTip"
                ></app-stance-indicator>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center">
                <app-stance-mean-cop-position [lateralAverage]="lateralAvg" [longitudinalAverage]="longitudinalAvg"></app-stance-mean-cop-position>
            </div>
        </div>
        <div class="item col-4">
            <div class="text-center">
                <div class="title rounded-pill d-inline-block">
                    {{text('standingEvaluation.results.plateGraphConfidenceEllipseTitle')}}
                </div>
            </div>
            <div class="ratio ratio-1x1">
                <app-stance-indicator
                    [device]="device"
                    [sides]="sideTypes.BOTH"
                    [copPoints]="statistics.gridCopCoordsInPercentage"
                    [leftCopPoints]="statistics.leftGridCopCoordsInPercentage"
                    [rightCopPoints]="statistics.rightGridCopCoordsInPercentage"
                    [oval]="statistics.oval"
                    [zoomToEllipse]="true"
                    [rotateLeft]="isHeelTip"
                    [lateralDeviation]="lateralDeviation"
                    [longitudinalDeviation]="longitudinalDeviation"
                ></app-stance-indicator>
            </div>
            <app-stance-ellipse-statistics class="d-flex flex-column align-items-center justify-content-center"
                                           [surfaces]="this.surfaceValues" [hidePopulation]="isSingleLeg"></app-stance-ellipse-statistics>
        </div>
        <div class="item col-4 weight-distribution">
            <div class="text-center">
                <div class="title rounded-pill d-inline-block">
                    {{text('standingEvaluation.results.weightDistributionTitle')}}
                </div>
            </div>
            <div class="ratio ratio-1x1">
                <app-body-side-bar-chart class="overflow-hidden"
                                         [data]="weightDistribution"
                                         valueFractionDigits="1">
                </app-body-side-bar-chart>
            </div>
            <div class="footer d-flex align-items-center justify-content-center">
                <div *ngIf="!isHeelTip">
                    <div *ngIf="sides.includes(sideTypes.LEFT)"
                         [style.color]="getSideColor(sideTypes.LEFT)"
                         class="d-inline-block me-2"
                    >
                        <span class="d-inline-block me-1">{{text('nordicHamstring.devices.left.hint')}}</span><b>{{weightDistributionLeftValueFormatted}}</b>
                    </div>
                    <div *ngIf="sides.includes(sideTypes.RIGHT)"
                         [style.color]="getSideColor(sideTypes.RIGHT)"
                         class="d-inline-block"
                    >
                        <span class="me-1">{{text('nordicHamstring.devices.right.hint')}}</span><b>{{weightDistributionRightValueFormatted}}</b>
                    </div>
                </div>
                <div *ngIf="isHeelTip">
                    <div *ngIf="sides.includes(sideTypes.LEFT)"
                         [style.color]="getSideColor(sideTypes.LEFT)"
                    >
                        <span>{{text('standingEvaluation.results.weightDistribution.heelfoot.title')}}</span>
                        <b>{{format('standingEvaluation.results.weightDistribution.heelfoot.value', weightDistributionLeftValueFormatted)}}</b>
                    </div>
                    <div *ngIf="sides.includes(sideTypes.RIGHT)"
                         [style.color]="getSideColor(sideTypes.RIGHT)"
                    >
                        <span>{{text('standingEvaluation.results.weightDistribution.tipfoot.title')}}</span>
                        <b>{{format('standingEvaluation.results.weightDistribution.tipfoot.value', weightDistributionRightValueFormatted)}}</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #sectionSecondRow>
    <div class="top-tables d-flex flex-row">
        <div class="resultant-cop-table">
            <app-table-header>{{text('standingEvaluation.results.resultant_cop')}}</app-table-header>
            <div class="d-flex flex-row">
                <div class="d-flex flex-column flex-grow-1">
                    <app-table-title *ngFor="let entry of resultantCopTable">{{entry.key}}</app-table-title>
                </div>
                <div class="d-flex flex-column flex-grow-1">
                    <app-table-value *ngFor="let entry of resultantCopTable">{{entry.value}}</app-table-value>
                </div>
            </div>
        </div>
        <div class="spacer m-3"></div>
        <div class="cop-per-foot-table">
            <div class="d-flex flex-row">
                <div class="flex-grow-1">
                    <app-table-header></app-table-header>
                    <app-table-title></app-table-title>
                    <app-table-title></app-table-title>
                    <app-table-title>{{text('standingEvaluation.results.mean position')}}</app-table-title>
                    <app-table-title>{{text('standingEvaluation.results.amplitude')}}</app-table-title>
                    <app-table-title>{{text('standingEvaluation.results.surface_mm')}}</app-table-title>
                </div>
                <div class="flex-grow-1">
                    <app-table-header>{{!this.isSingleLeg ? "CoP per Foot" : "CoP per part"}}</app-table-header>
                    <div class="d-flex flex-row flex-grow-1">
                        <div *ngFor="let side of copPerFootTitles | keyvalue" class="d-flex flex-column flex-grow-1">
                            <app-table-title centerText="true" class="flex-grow-1"
                                             [sideForBackgroundColor]="side.key">{{side.value}}</app-table-title>
                            <div class="d-flex flex-row flex-grow-1">
                                <app-table-title centerText="true" class="flex-grow-1">{{text('standingEvaluation.lateralStandardDeviation')}}</app-table-title>
                                <app-table-title centerText="true" class="flex-grow-1">{{text('standingEvaluation.longitudinalStandardDeviation')}}</app-table-title>
                            </div>
                            <ng-container *ngIf="side.key === sideTypes.LEFT">
                                <div class="d-flex flex-row flex-grow-1">
                                    <app-table-value [sideForColor]="side.key"
                                                     class="flex-grow-1">{{this.statistics.lateralAvgLeft.toFixed(1)}}</app-table-value>
                                    <app-table-value [sideForColor]="side.key"
                                                     class="flex-grow-1">{{this.statistics.longitudinalAvgLeft.toFixed(1)}}</app-table-value>
                                </div>
                                <div class="d-flex flex-row flex-grow-1">
                                    <app-table-value [sideForColor]="side.key"
                                                     class="flex-grow-1">{{this.statistics.lateralDeviationLeft.toFixed(1)}}</app-table-value>
                                    <app-table-value [sideForColor]="side.key"
                                                     class="flex-grow-1">{{this.statistics.longitudinalDeviationLeft.toFixed(1)}}</app-table-value>
                                </div>
                                <app-table-value [sideForColor]="side.key">{{this.statistics.surfaceLeft.toFixed(1)}}</app-table-value>
                            </ng-container>
                            <ng-container *ngIf="side.key === sideTypes.RIGHT">
                                <div class="d-flex flex-row flex-grow-1">
                                    <app-table-value [sideForColor]="side.key"
                                                     class="flex-grow-1">{{this.statistics.lateralAvgRight.toFixed(1)}}</app-table-value>
                                    <app-table-value [sideForColor]="side.key"
                                                     class="flex-grow-1">{{this.statistics.longitudinalAvgRight.toFixed(1)}}</app-table-value>
                                </div>
                                <div class="d-flex flex-row flex-grow-1">
                                    <app-table-value [sideForColor]="side.key"
                                                     class="flex-grow-1">{{this.statistics.lateralDeviationRight.toFixed(1)}}</app-table-value>
                                    <app-table-value [sideForColor]="side.key"
                                                     class="flex-grow-1">{{this.statistics.longitudinalDeviationRight.toFixed(1)}}</app-table-value>
                                </div>
                                <app-table-value [sideForColor]="side.key">{{this.statistics.surfaceRight.toFixed(1)}}</app-table-value>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #sectionDropLand>
    <div *ngIf="isDropLand" class="dropland row mt-4">
        <div class="text-center">
            <div class="title rounded-pill d-inline-block">
                {{text('standingEvaluation.results.copSurfacePerTime')}}
            </div>
        </div>
        <app-samples-line-chart class="graphs overflow-hidden"
                                [data]="sideAreaCoveredPerTime"
                                [total]="totalAreaCoveredPerTime"
        ></app-samples-line-chart>
    </div>
</ng-template>
