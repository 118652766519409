import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {ActivityLineChartFactory} from '../datasetProviders/activityLineChartFactory';
import {ExerciseType} from '../../../models/exerciseType';
import {ProgressOverTimeMetricType} from '../../../viewmodels/progressOverTimeMetricType';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {ProgressOverTimeFilter} from '../../../viewmodels/progressOverTimeFilter';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {BaseChartDirective, Label} from 'ng2-charts';
import {ActivityLineChartProvider} from '../datasetProviders/activityLineChartProvider';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {ProgressOverTimeMetric} from '../../../viewmodels/progressOverTimeMetric';
import {ActivityDto} from '../../../dtos/activity.dto';
import {JumpType} from '../../../dtos/jumpType';
import {BuiltInExerciseTemplates, BuiltInExerciseTemplateType} from '../../../models/builtInExerciseTemplateType';
import {ActivityAnalysisServiceResponseDto} from '../../../dtos/statistics/activityAnalysisServiceResponseDto';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {BuiltinProtocols} from '../../../models/builtinProtocols';
import {ProtocolsLineChartFactory} from '../datasetProviders/protocolsLineChartFactory';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {ProtocolsLineChartProvider} from '../datasetProviders/protocolsLineChartProvider';
import {ResultsComponent} from "../resultsComponent";
import {KeyValue} from "common";

@Component({
    selector: 'app-activities-or-protocols-over-time-results',
    templateUrl: './activities-or-protocols-over-time-results.component.html',
    styleUrls: ['./activities-or-protocols-over-time-results.component.scss']
})
export class ActivitiesOrProtocolsOverTimeResultsComponent extends ResultsComponent implements OnInit {
    public metrics: ProgressOverTimeMetric[];

    @Input()
    public filter: ProgressOverTimeFilter;
    public chartOptions: ChartOptions;
    public chartPlugins: any[];

    public datasets: ChartDataSets[];
    public labels: Label[];
    private provider: ActivityLineChartProvider | ProtocolsLineChartFactory;
    @ViewChild(BaseChartDirective) chartDirective;
    public printTitle: string;

    constructor() {
        super();
        this.chartPlugins = [pluginDataLabels];
    }

    ngOnInit(): void {
        if (this.activities) {
            const firstActivity = this.activities[0];

            // Filter
            if (!this.filter) {
                [this.metrics, this.filter] = ActivitiesOrProtocolsOverTimeResultsComponent.getAvailableMetricsAndDefaultFilterForActivities(firstActivity);
            }
            // chart data
            this.provider = ActivityLineChartFactory.createProvider(this.activities);
        } else if (this.protocols) {
            const firstProtocol = this.protocols[0];

            // Filter
            if (!this.filter) {
                [this.metrics, this.filter] = ActivitiesOrProtocolsOverTimeResultsComponent.getAvailableMetricsAndDefaultFilterForProtocols(firstProtocol);
            }
            // chart data
            this.provider = ProtocolsLineChartFactory.createProvider(this.protocols);
        } else {
            throw new Error('Unsupported');
        }
        // print
        this.printTitle = `${this.text(this.filter.metric.title)}`;
        if (this.filter.useDeficit) {
            this.printTitle += ` & ${this.text('report.filter.deficit.title')}`;
        }

        // chart data provider
        this.chartOptions = this.getChartOptions();

        this.updateResults();
    }

    private updateResults(): void {
        let results = new KeyValue<Label[], ChartDataSets[]>([], []);
        if (this.activities) {
            const provider = this.provider as ActivityLineChartProvider;
            results = provider.getDatasets(this.activities, this.analysis as ActivityAnalysisServiceResponseDto, this.filter, this.chartOptions, this.i18n);
        } else if (this.protocols) {
            const provider = this.provider as ProtocolsLineChartProvider;
            results = provider.getDatasets(this.protocols, this.analysis as ProtocolAnalysisServiceResponseDto, this.filter, this.chartOptions, this.i18n);
        } else {
            throw new Error('Unsupported over time provider');
        }

        this.datasets = results.value;
        this.labels = results.key;

        // update chart options. Only work using the chart object which becomes available after view has been initiated
        if (this.chartDirective) {
            this.chartDirective.chart.options = this.chartOptions;
        }
    }

    private static getAvailableMetricsAndDefaultFilterForActivities(activity: ActivityDto): [ProgressOverTimeMetric[], ProgressOverTimeFilter] {
        const exerciseType = activity.config.exerciseTypeEnum;
        const baseConfigCode = activity.config.baseConfigCode;

        const types: ProgressOverTimeMetric[] = [];
        const filter = new ProgressOverTimeFilter();
        switch (exerciseType) {
            case ExerciseType.METER:
            case ExerciseType.METER_ENDURANCE:
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.MAX_VALUE));
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.AVG_VALUE));

                filter.metric = types[0];
                filter.isDeficitAvailable = true;
                filter.useDeficit = true;
                break;
            case ExerciseType.STANDING_EVALUATION:
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.DISTRIBUTION));
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.COP_SURFACE));
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.LATERAL_AMPLITUDE));
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.LONGITUDINAL_AMPLITUDE));
                filter.metric = types[0];
                break;
            case ExerciseType.JUMP_ANALYSIS:
                const jumpType = activity.jumpType;
                if (jumpType === JumpType.DROP) {
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.JUMP_HEIGHT));
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.CONTACT_TIME));
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.RSI));
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.MAX_VALUE));
                    filter.metric = types[0];
                } else if (jumpType === JumpType.MULTIPLE_JUMPS) {
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.JUMP_COUNT));
                    filter.metric = types[0];
                } else if (baseConfigCode === BuiltInExerciseTemplateType.SKIPPING_DELTAS) {
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.SKIP_COUNT));
                    filter.metric = types[0];
                } else {
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.JUMP_HEIGHT));
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.RFD));
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.IMPULSION));
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.MAX_VALUE));
                    filter.metric = types[0];
                }
                break;
            case ExerciseType.DYNAMIC_DISTRIBUTION_EVALUATION:
                if (BuiltInExerciseTemplates.isDynamicDistributionSimple(baseConfigCode)) {
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.DISTRIBUTION));
                }
                filter.metric = types[0];
                break;
            case ExerciseType.TOTAL_EVALUATION:
                if (BuiltInExerciseTemplates.isBodyWeightCalculation(baseConfigCode)) {
                    types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.MAX_VALUE));
                    filter.metric = types[0];
                }
                break;
            default:
                break;
        }
        return [types, filter];
    }

    private static getAvailableMetricsAndDefaultFilterForProtocols(protocol: ProtocolDto): [ProgressOverTimeMetric[], ProgressOverTimeFilter] {
        const types: ProgressOverTimeMetric[] = [];
        const filter = new ProgressOverTimeFilter();
        const protocolbaseConfigCode = protocol.config.getBaseConfigCode();
        switch (protocolbaseConfigCode) {
            case BuiltinProtocols.IDENTIFIERS.MC_CALL:
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.MCCALL));
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.MCCALL_90));
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.MCCALL_30));
                filter.metric = types[0];
                break;
            case BuiltinProtocols.IDENTIFIERS.ADVANCED_STANDING_EVALUTATION:
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.COP_SURFACE));
                filter.metric = types[0];
                break;
            case BuiltinProtocols.IDENTIFIERS.ASH_IYT:
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.IYT));
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.IYT_I));
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.IYT_Y));
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.IYT_T));
                filter.metric = types[0];
                break;
            case BuiltinProtocols.IDENTIFIERS.KNEE_ANTAGONIST:
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.MAX_VALUE));
                filter.metric = types[0];
                break;
            case BuiltinProtocols.IDENTIFIERS.FORCE_VELOCITY:
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.MAX_VALUE));
                filter.metric = types[0];
                break;
            case BuiltinProtocols.IDENTIFIERS.DSI:
                types.push(new ProgressOverTimeMetric(ProgressOverTimeMetricType.MAX_VALUE));
                filter.metric = types[0];
                break;
            default:
                break;
        }
        return [types, filter];
    }

    private getChartOptions(): ChartOptions {
        return {
            animation: {
                duration: 0,
                animateScale: false,
                animateRotate: false
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            devicePixelRatio: 2,
            scales: {
                xAxes: [{
                    display: true,
                    stacked: false,
                    ticks: {
                        autoSkipPadding: 20,
                        autoSkip: true,
                        maxRotation: 0
                    },
                }],
                yAxes: [
                    {
                        ticks: {
                            min: 0
                        }
                    }
                ]
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 1
                }
            },
            tooltips: {
                enabled: false
            }

        };
    }

    public onMetricSelected($event: MatButtonToggleChange) {
        this.filter.metric = new ProgressOverTimeMetric($event.value);
        this.updateResults();
    }

    public onDeficitChange($event: MatSlideToggleChange) {
        this.filter.useDeficit = $event.checked;
        this.updateResults();
    }
}
