import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionViewModel} from "../../viewmodels/sessionViewModel";
import {format, isEqual} from "date-fns";
import {CrudType} from "common";

@Component({
    selector: 'app-sessions-list',
    templateUrl: './sessions-list.component.html',
    styleUrls: ['./sessions-list.component.scss']
})
export class SessionsListComponent implements OnInit {

    @Input()
    public sessions: SessionViewModel[];

    @Input()
    public selectedSession: SessionViewModel;

    @Output()
    public onSessionAction: EventEmitter<[SessionViewModel, CrudType]>;

    public crudType: typeof CrudType = CrudType;

    constructor() {
        this.onSessionAction = new EventEmitter<[SessionViewModel, CrudType]>();
    }

    ngOnInit(): void {
    }

    formatDate(session: SessionViewModel): string {
        return format(session.date, 'E dd/MM/yyyy HH:mm');
    }

    isSelected(session: SessionViewModel) {
        if (!this.selectedSession) {
            return false;
        }

        return isEqual(this.selectedSession.date, session.date);
    }
}
