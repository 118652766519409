import {MeterEnduranceActivityRepResultsModel} from "./meterEnduranceActivityRepResultsModel";
import {BodySideType, BodySideTypes} from "../../models/bodySideType";
import {ActivityResultsModel} from "./activityResultsModel";

export class MeterEnduranceActivityResultsModel extends ActivityResultsModel {
    public repResults: MeterEnduranceActivityRepResultsModel[];
    public maxPerSide: Map<BodySideType, number>;
    public meanPerSide: Map<BodySideType, number>;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.maxPerSide = new Map<BodySideType, number>();
        for (const sideData of input.maxPerSide as any) {
            const side = BodySideTypes.parse(sideData.side);
            const value = Number(sideData.value);
            if (!side || !value) {
                console.log(`Cannot parse max per side. Data: ${sideData}`);
                continue;
            }
            this.maxPerSide.set(side, value);
        }

        this.meanPerSide = new Map<BodySideType, number>();
        for (const sideData of input.meanPerSide as any) {
            const side = BodySideTypes.parse(sideData.side);
            const value = Number(sideData.value);
            if (!side || !value) {
                console.log(`Cannot parse mean per side. Data: ${sideData}`);
                continue;
            }
            this.meanPerSide.set(side, value);
        }

        this.repResults = this.deserializeArray(input.repResults, MeterEnduranceActivityRepResultsModel);
        return this;
    }
}
