import {ActivityConfigDto} from "../activityConfig.dto";
import {MeterEnduranceActivityResultsModel} from "./meterEnduranceActivityResultsModel";
import {DynamicDistributionEvaluationResultsModel} from "./dynamicDistributionEvaluationResultsModel";
import {StanceEvaluationActivityResultsModel} from "./stanceEvaluationActivityResultsModel";
import {ActivityResultsModel} from "./activityResultsModel";
import {ExerciseType} from "../../models/exerciseType";
import {JumpAnalysisActivityResultsModel} from "./jumpAnalysisActivityResultsModel";
import {BuiltInExerciseTemplates, BuiltInExerciseTemplateType} from "../../models/builtInExerciseTemplateType";
import {UnipodalStanceEvaluationActivityResultsModel} from "./unipodalStanceEvaluationActivityResultsModel";
import {Code, Dto} from "common";
import {UnipodalJumpAnalysisActivityResultsModel} from "./unipodalJumpAnalysisActivityResultsModel";
import {JumpType} from "../jumpType";
import {MultipleJumpsAnalysisActivityResultsModel} from "./multipleJumpsAnalysisActivityResultsModel";
import {SkippingExerciseResultsModel} from "./skippingExerciseResultsModel";
import {StaticDistributionActivityResultsModel} from "./staticDistributionActivityResultsModel";
import {BodyWeightActivityResultsModel} from "./bodyWeightActivityResultsModel";
import {IsometryActivityResultsModel} from "./isometryActivityResultsModel";
import {RepCountActivityResultsModel} from "./repCountActivityResultsModel";
import {TotalEvaluationActivityResultsModel} from "./totalEvaluationActivityResultsModel";
import {NordicHamstringResultsModel} from "./nordicHamstringResultsModel";
import {AdvancedNordicHamstringResultsModel} from "./advancedNordicHamstringResultsModel";
import {WomacResultsModel} from "./womacResultsModel";
import {IkdcResultsModel} from "./ikdcResultsModel";
import {AdvancedSquatAnalysisResultsModel} from "./advancedSquatAnalysisResultsModel";

export class ActivityAnalysisResultDto extends Dto {
    public activityCode: Code;
    public config: ActivityConfigDto;
    public activityResults: string;
    public activityResultsModel: ActivityResultsModel;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.config) {
            this.config = new ActivityConfigDto().deserialize(input.config);
        }

        const parsedActivityResults = JSON.parse(input.activityResults);
        let baseConfigCode = this.config.baseConfigCode;

        switch (this.config.exerciseTypeEnum) {
            case ExerciseType.METER:
            case ExerciseType.METER_ENDURANCE:
                this.activityResultsModel = new MeterEnduranceActivityResultsModel().deserialize(parsedActivityResults);
                break;
            case ExerciseType.STANDING_EVALUATION:
                // Custom single leg stance can not be differentiated from normal stance so in the case we infer if it's single leg from the results content
                const isCustomSingleLegStance = parsedActivityResults.resultsPerSide !== undefined;

                if (BuiltInExerciseTemplates.isUnipodalStanceWithOneSensor(baseConfigCode) ||
                    BuiltInExerciseTemplates.isUnipodalDropLand(baseConfigCode) ||
                    isCustomSingleLegStance
                ) {
                    this.activityResultsModel = new UnipodalStanceEvaluationActivityResultsModel().deserialize(parsedActivityResults);
                } else {
                    this.activityResultsModel = new StanceEvaluationActivityResultsModel().deserialize(parsedActivityResults);
                }
                break;
            case ExerciseType.DYNAMIC_DISTRIBUTION_EVALUATION:
                if (BuiltInExerciseTemplateType.ADVANCED_SQUAT_ANALYSIS_HEXAS === baseConfigCode || BuiltInExerciseTemplateType.ADVANCED_SQUAT_ANALYSIS_PLATES === baseConfigCode) {
                    this.activityResultsModel = new AdvancedSquatAnalysisResultsModel().deserialize(parsedActivityResults);
                } else if (BuiltInExerciseTemplates.isUnipodalCombinedAnalysis()) {
                    // Not implemented
                } else if (BuiltInExerciseTemplates.isDynamicDistributionSimple(baseConfigCode)) {
                    this.activityResultsModel = new DynamicDistributionEvaluationResultsModel().deserialize(parsedActivityResults);
                } else {
                    // Not implemented
                }
                break;
            case ExerciseType.JUMP_ANALYSIS:
                let jumpType = this.config.jumpType;
                if (jumpType === JumpType.MULTIPLE_JUMPS) {
                    this.activityResultsModel = new MultipleJumpsAnalysisActivityResultsModel().deserialize(parsedActivityResults);
                } else if (BuiltInExerciseTemplates.isUnipodalJump(baseConfigCode)) {
                    this.activityResultsModel = new UnipodalJumpAnalysisActivityResultsModel().deserialize(parsedActivityResults);
                } else if (baseConfigCode === BuiltInExerciseTemplateType.SKIPPING_DELTAS) {
                    this.activityResultsModel = new SkippingExerciseResultsModel().deserialize(parsedActivityResults);
                } else {
                    this.activityResultsModel = new JumpAnalysisActivityResultsModel().deserialize(parsedActivityResults);
                }
                break;
            case ExerciseType.STATIC_DISTRIBUTION_EXERCISE:
                this.activityResultsModel = new StaticDistributionActivityResultsModel().deserialize(parsedActivityResults);
                break;
            case ExerciseType.TOTAL_EVALUATION:
                if (BuiltInExerciseTemplates.isBodyWeightCalculation(baseConfigCode)) {
                    this.activityResultsModel = new BodyWeightActivityResultsModel().deserialize(parsedActivityResults);
                } else {
                    this.activityResultsModel = new TotalEvaluationActivityResultsModel().deserialize(parsedActivityResults);
                }
                break;
            case ExerciseType.ISOMETRIC:
                this.activityResultsModel = new IsometryActivityResultsModel().deserialize(parsedActivityResults);
                break;
            case ExerciseType.REP_COUNT:
                this.activityResultsModel = new RepCountActivityResultsModel().deserialize(parsedActivityResults);
                break;
            case ExerciseType.NORDIC_HAMSTRING:
                if (BuiltInExerciseTemplateType.ADVANCED_NORDIC_HAMSTRING === baseConfigCode) {
                    this.activityResultsModel = new AdvancedNordicHamstringResultsModel().deserialize(parsedActivityResults);
                } else {
                    this.activityResultsModel = new NordicHamstringResultsModel().deserialize(parsedActivityResults);
                }
                break;
            case ExerciseType.SENSORLESS:
                if (BuiltInExerciseTemplateType.WOMAC === baseConfigCode) {
                    this.activityResultsModel = new WomacResultsModel().deserialize(parsedActivityResults);
                } else if (BuiltInExerciseTemplateType.IKDC === baseConfigCode) {
                    this.activityResultsModel = new IkdcResultsModel().deserialize(parsedActivityResults);
                }
                break;
        }

        return this;
    }
}
