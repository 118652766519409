import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatNativeDateModule, MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatSliderModule} from '@angular/material/slider';
import {RouterModule} from "@angular/router";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {CommonModule as AngularCommonModule} from "@angular/common";
import {JwtModule} from '@auth0/angular-jwt';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {ConfirmEmailComponent} from './screens/confirm-email/confirm-email.component';
import {AcceptAccountAssociationComponent} from './screens/accept-account-association/accept-account-association.component';
import {AcceptParticipantShareComponent} from './screens/accept-participant-share/accept-participant-share.component';
import {UserHomeComponent} from './screens/user-home/user-home.component';
import {ParticipantsComponent} from './components/participants/participants.component';
import {ParticipantComponent} from './screens/participant/participant.component';
import {ProfileComponent} from './screens/profile/profile.component';
import {ProtocolCardComponent} from './components/protocol-card/protocol-card.component';
import {ParticipantProgressComponent} from './screens/participant-progress/participant-progress.component';
import {ProtocolResultsScreenComponent} from './screens/protocol-results-screen/protocol-results-screen.component';
import {SessionsListComponent} from './components/sessions-list/sessions-list.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {ResultsDirective} from './components/results/resultsDirective';
import {MeterEnduranceResultsComponent} from './components/results/meter-endurance-results/meter-endurance-results.component';
import {ChartsModule} from 'ng2-charts';
import {MaxPerSideChartComponent} from './components/charts/max-per-side-chart/max-per-side-chart.component';
import {MeterEnduranceStatisticsRowComponent} from './components/charts/meter-endurance-statistics-row-component/meter-endurance-statistics-row.component';
import {BodySideBarChartComponent} from './components/charts/body-side-bar-chart/body-side-bar-chart.component';
import {SamplesLineChartComponent} from './components/charts/samples-line-chart/samples-line-chart.component';
import {MeterEnduranceRowStatsComponent} from './components/charts/meter-endurance-row-stats/meter-endurance-row-stats.component';
import {ProtocolResultsActionsComponent} from './components/results/protocol-results-actions/protocol-results-actions.component';
import {ProtocolResultsExportHeaderComponent} from './components/results/protocol-results-export-header/protocol-results-export-header.component';
import {ProtocolResultsExportFooterComponent} from './components/results/protocol-results-export-footer/protocol-results-export-footer.component';
import {ResultsOverviewComponent} from './components/results-overview/results-overview.component';
import {StanceResultsComponent} from './components/results/stance-results/stance-results.component';
import {StanceIndicatorComponent} from './components/results/stance-indicator/stance-indicator.component';
import {TableHeaderComponent} from './components/table/table-header/table-header.component';
import {TableTitleComponent} from './components/table/table-title/table-title.component';
import {TableValueComponent} from './components/table/table-value/table-value.component';
import {JumpAnalysisResultsComponent} from './components/results/jump-analysis-results/jump-analysis-results.component';
import {ValueWidgetComponent} from './components/charts/value-widget/value-widget.component';
import {JumpAnalysisLineChartComponent} from './components/charts/jump-analysis-line-chart/jump-analysis-line-chart.component';
import {FooterComponent} from './components/footer/footer.component';
import {DynamicDistributionEvaluationResultsComponent} from './components/results/dynamic-distribution-evaluation-results/dynamic-distribution-evaluation-results.component';
import {PlatesDistributionResultsComponent} from './components/results/plates-distribution-results/plates-distribution-results.component';
import {BalanceComponent} from './components/balance/balance.component';
import {SelectExerciseTypeDialogComponent} from './components/dialogs/select-exercise-type-dialog/select-exercise-type-dialog.component';
import {I18nService} from './services/i18n/i18n.service';
import {ProPlusPromoComponent} from './screens/pro-plus-promo/pro-plus-promo.component';
import {StanceEvaluationSingleLegResultsComponent} from './components/results/stance-evaluation-single-leg-results/stance-evaluation-single-leg-results.component';
import {ResultsTitleComponent} from './components/results/results-title/results-title.component';
import {StanceMeanCopPositionComponent} from './components/results/stance-mean-cop-position/stance-mean-cop-position.component';
import {StanceEllipseStatisticsComponent} from './components/results/stance-ellipse-statistics/stance-ellipse-statistics.component';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {ActivitiesOrProtocolsOverTimeResultsComponent} from './components/results/activities-or-protocols-over-time-results/activities-or-protocols-over-time-results.component';
import {ProtocolFiltersComponent} from './components/protocol-filters/protocol-filters.component';
import {IconRegistryService} from './services/icon-registry.service';
import {OrderSelectionComponent} from './components/order-selection/order-selection.component';
import {DistributionBarChartComponent} from './components/charts/weight-distribution-bar-chart/distribution-bar-chart.component';
import {SingleLegJumpAnalysisComponent} from './components/results/single-leg-jump-analysis/single-leg-jump-analysis.component';
import {MultipleJumpsAnalysisComponent} from './components/results/multiple-jumps-analysis/multiple-jumps-analysis.component';
import {StackedBarWithLineChartComponent} from './components/charts/stacked-bar-with-line-chart/stacked-bar-with-line-chart.component';
import {SkippingAnalysisComponent} from './components/results/skipping-analysis/skipping-analysis.component';
import {ProtocolResultsComponent} from './components/results/protocol-results/protocol-results.component';
import {ProtocolSummaryGenericResultsComponent} from './components/results/protocol-summary-generic-results/protocol-summary-generic-results.component';
import {ProtocolSummaryMccallResultsComponent} from './components/results/protocol-summary-mccall-results/protocol-summary-mccall-results.component';
import {ProtocolSummaryAdvancedStandingEvaluationResultsComponent} from './components/results/protocol-summary-advanced-standing-evaluation-results/protocol-summary-advanced-standing-evaluation-results.component';
import {ProtocolSummaryIytResultsComponent} from './components/results/protocol-summary-iyt-results/protocol-summary-iyt-results.component';
import {ProtocolSummaryDsiResultsComponent} from './components/results/protocol-summary-dsi-results/protocol-summary-dsi-results.component';
import {SimpleBarchartComponent} from './components/charts/simple-barchart/simple-barchart.component';
import {ProtocolSummaryNeckReportResultsComponent} from './components/results/protocol-summary-neck-report-results/protocol-summary-neck-report-results.component';
import {ProtocolSummaryKneeAntagonistResultsComponent} from './components/results/protocol-summary-knee-antagonist-results/protocol-summary-knee-antagonist-results.component';
import {ProtocolSummaryForceVelocityResultsComponent} from './components/results/protocol-summary-force-velocity-results/protocol-summary-force-velocity-results.component';
import {ForceVelocityChartComponent} from './components/charts/force-velocity-chart/force-velocity-chart.component';
import {ScatterChartComponent} from './components/charts/scatter-chart/scatter-chart.component';
import {LinesChartComponent} from './components/charts/lines-chart/lines-chart.component';
import {ResultsComponent} from "./components/results/resultsComponent";
import {StaticDistributionResultsComponent} from './components/results/static-distribution-results/static-distribution-results.component';
import {BodyWeightCalculationResultsComponent} from './components/results/body-weight-calculation-results/body-weight-calculation-results.component';
import {IsometryResultsComponent} from './components/results/isometry-results/isometry-results.component';
import {ProtocolSummaryRowActivityInfoComponent} from './components/results/protocol-summary-row-activity-info/protocol-summary-row-activity-info.component';
import {RepCountResultsComponent} from './components/results/rep-count-results/rep-count-results.component';
import {NordicHamstringResultsComponent} from './components/results/nordic-hamstring-results/nordic-hamstring-results.component';
import {WomacResultsComponent} from './components/results/womac-results/womac-results.component';
import {IkdcResultsComponent} from './components/results/ikdc-results/ikdc-results.component';
import {AdvancedSquatAnalysisResultsComponent} from './components/results/advanced-squat-analysis-results/advanced-squat-analysis-results.component';
import {ValuePipe} from "./utils/pipes/value.pipe";
import {TextPipe} from "./utils/pipes/text.pipe";
import {ToValuePipe} from "./utils/pipes/toValue.pipe";
import {DevPlaygroundComponent} from "./components/dev-playground/dev-playground.component";
import {jwtTokenGetter} from "./utils/jwt-utils";
import {MatTableExporterModule} from "mat-table-exporter";
import {LoginComponent} from "./components/login/login.component";
import {BasicAuthInterceptor} from "./utils/basic-auth-interceptor";
import {BaseComponent} from "./components/base/base.component";
import {LeftRightTextComponent} from "./components/left-right-text/left-right-text.component";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {TagSelectionComponent} from "./components/tag-selection/tag-selection.component";
import {TagComponent} from "./components/tag/tag.component";
import {LoadingComponent} from "./components/loading/loading.component";
import {DeviceIconComponent} from "./components/device-icon/device-icon.component";
import {SideIconComponent} from "./components/side-icon/side-icon.component";
import {CommonModule, ModuleConfig, ServiceInjectorService} from "common";
import {environment} from "../environments/environment";

@NgModule({
    declarations: [
        AppComponent,
        BaseComponent,
        ToolbarComponent,
        LoginComponent,
        ConfirmEmailComponent,
        AcceptAccountAssociationComponent,
        AcceptParticipantShareComponent,
        UserHomeComponent,
        ParticipantsComponent,
        ParticipantComponent,
        ParticipantProgressComponent,
        ProfileComponent,
        ProtocolCardComponent,
        ProtocolResultsScreenComponent,
        SessionsListComponent,
        ResultsDirective,
        MeterEnduranceResultsComponent,
        MaxPerSideChartComponent,
        MeterEnduranceStatisticsRowComponent,
        BodySideBarChartComponent,
        SamplesLineChartComponent,
        MeterEnduranceRowStatsComponent,
        ProtocolResultsActionsComponent,
        ProtocolResultsExportHeaderComponent,
        ProtocolResultsExportFooterComponent,
        ResultsOverviewComponent,
        ResultsComponent,
        StanceResultsComponent,
        StanceIndicatorComponent,
        DevPlaygroundComponent,
        TableHeaderComponent,
        TableTitleComponent,
        TableValueComponent,
        JumpAnalysisResultsComponent,
        ValueWidgetComponent,
        JumpAnalysisLineChartComponent,
        FooterComponent,
        DynamicDistributionEvaluationResultsComponent,
        PlatesDistributionResultsComponent,
        BalanceComponent,
        SelectExerciseTypeDialogComponent,
        ProPlusPromoComponent,
        StanceEvaluationSingleLegResultsComponent,
        ResultsTitleComponent,
        StanceMeanCopPositionComponent,
        StanceEllipseStatisticsComponent,
        ActivitiesOrProtocolsOverTimeResultsComponent,
        ProtocolFiltersComponent,
        OrderSelectionComponent,
        DistributionBarChartComponent,
        SingleLegJumpAnalysisComponent,
        MultipleJumpsAnalysisComponent,
        StackedBarWithLineChartComponent,
        SkippingAnalysisComponent,
        ProtocolResultsComponent,
        ProtocolSummaryGenericResultsComponent,
        ProtocolSummaryMccallResultsComponent,
        ProtocolSummaryAdvancedStandingEvaluationResultsComponent,
        SimpleBarchartComponent,
        ProtocolSummaryAdvancedStandingEvaluationResultsComponent,
        ProtocolSummaryIytResultsComponent,
        ProtocolSummaryDsiResultsComponent,
        ProtocolSummaryNeckReportResultsComponent,
        ProtocolSummaryKneeAntagonistResultsComponent,
        ProtocolSummaryForceVelocityResultsComponent,
        ForceVelocityChartComponent,
        ScatterChartComponent,
        LinesChartComponent,
        BodyWeightCalculationResultsComponent,
        IsometryResultsComponent,
        ToValuePipe,
        TextPipe,
        StaticDistributionResultsComponent,
        BodyWeightCalculationResultsComponent,
        ProtocolSummaryRowActivityInfoComponent,
        RepCountResultsComponent,
        NordicHamstringResultsComponent,
        ValuePipe,
        WomacResultsComponent,
        IkdcResultsComponent,
        AdvancedSquatAnalysisResultsComponent,
        LeftRightTextComponent,
        PageNotFoundComponent,
        TagSelectionComponent,
        TagComponent,
        LoadingComponent,
        DeviceIconComponent,
        SideIconComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        JwtModule.forRoot({
            config: {
                authScheme: 'Basic',
                tokenGetter: jwtTokenGetter
            }
        }),
        MatToolbarModule,
        MatCardModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatSnackBarModule,
        HttpClientModule,
        RouterModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatTabsModule,
        MatListModule,
        FormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatOptionModule,
        MatTooltipModule,
        MatRadioModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatRippleModule,
        LazyLoadImageModule,
        ChartsModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatSliderModule,
        MatExpansionModule,
        MatSortModule,
        MatTableExporterModule,
        MatPaginatorModule,
        AngularCommonModule,
        BreadcrumbModule,
        CommonModule.forRoot(ModuleConfig.for(environment.apiUrl, environment.cdnUrl))
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (i18nService: I18nService) => () => {
                return i18nService.load(['assets/translations/base.json', 'assets/translations/i18n.json']);
            },
            deps: [I18nService],
            multi: true
        },
        {provide: APP_INITIALIZER, useFactory: (iconRegistry: IconRegistryService) => () => iconRegistry.load(), deps: [IconRegistryService], multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {
        ServiceInjectorService.injector = this.injector;
    }
}
