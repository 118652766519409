import {ActivityAnalysisResultDto} from "./activityAnalysisResultDto";
import {Dto} from "common";

export class ActivityAnalysisServiceResponseDto extends Dto {
    public activitiesResults: ActivityAnalysisResultDto[];

    deserialize(input: any): this {
        Object.assign(this, input);

        this.activitiesResults = this.deserializeArray(input.activitiesResults, ActivityAnalysisResultDto);

        return this;
    }

}
