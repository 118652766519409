import {JumpCycle} from "./jumpCycle";
import {ForceAndTimePacket} from "./forceAndTimePacket";
import {Dto} from "common";

export class MultipleJumpsAnalysisActivityResultsModel extends Dto {
    public duration: number; // sec
    public pace: number; // jumps/min
    public weight: number; // kg

    public jumps: Array<JumpCycle>;
    public fatigueIndex: number;
    public averagePower: number; // W/Kg
    public averageHeight: number; // in meters

    public averageForceLeft: number; // kg
    public averageForceRight: number; // kg
    public averageForceLeftPercent: number; // [0-100]
    public averageForceRightPercent: number; // [0-100]

    public leftForces: Array<ForceAndTimePacket>; // synced and filtered sum of left devices's channel values, mapped with
    public rightForces: Array<ForceAndTimePacket>; // synced and filtered sum of right devices's channel values, mapped with time
    public totalForces: Array<ForceAndTimePacket>; // sum of syncedForcesLeft and syncedForcesRight, mapped with time

    deserialize(input: any): this {
        Object.assign(this, input);

        this.jumps = this.deserializeArray(input.jumps, JumpCycle);
        this.leftForces = this.deserializeArray(input.leftForces, ForceAndTimePacket);
        this.rightForces = this.deserializeArray(input.rightForces, ForceAndTimePacket);
        this.totalForces = this.deserializeArray(input.totalForces, ForceAndTimePacket);

        return this;
    }
}
