<div class="component">
    <div class="info d-flex flex-row align-items-center">
        <div class="title flex-grow-1">{{title}}</div>
        <div class="devices">
            <app-device-icon class="device d-inline-block ps-2" *ngFor="let device of devices" [device]="device"></app-device-icon>
        </div>
    </div>
    <div *ngIf="activityConfig" class="configuration">
        <div class="title ">Configuration</div>

        <div class="d-flex flex-row align-items-center">
            <mat-icon class="me-2">tune</mat-icon>
            <div>{{configDescription}}</div>
        </div>

        <div *ngIf="activityConfig && displayTargets">
            <div *ngFor="let set of activityConfig.activityConfigSets" class="d-flex flex-row align-items-center">
                <app-side-icon class="sideIcon me-2" [side]="set.bodySideType"></app-side-icon>
                <div>{{getSetDescription(set)}}</div>
            </div>
        </div>
    </div>
</div>
