import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Code} from "common";
import {Observable} from "rxjs";
import {ParticipantTagConfigDto} from "../../dtos/participantTagConfig.dto";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ParticipantTagConfigService {

    constructor(private httpClient: HttpClient) {
    }

    public findByCodes(codes: Code[]): Observable<ParticipantTagConfigDto[]> {
        return this.httpClient.post<any>(environment.apiUrl + 'api/participantTagConfigs/v1/findByCodes', codes)
            .pipe(map(participantTagConfigData => {
                const tagConfigs: ParticipantTagConfigDto[] = [];
                for (const datum of (participantTagConfigData as any)) {
                    tagConfigs.push(new ParticipantTagConfigDto().deserialize(datum));
                }
                return tagConfigs;
            }));
    }
}
