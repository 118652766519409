export enum FilterPeriodType {
    ALL,
    YEAR,
    NINE_MONTHS,
    SIX_MONTHS,
    THREE_MONTHS
}

export class FilterPeriodTypes {
    public static title(type: FilterPeriodType): string {
        let title = '';
        switch (type) {
            case FilterPeriodType.ALL:
                title = 'All';
                break;
            case FilterPeriodType.YEAR:
                title = '12 Months';
                break;
            case FilterPeriodType.NINE_MONTHS:
                title = '9 Months';
                break;
            case FilterPeriodType.SIX_MONTHS:
                title = '6 Months';
                break;
            case FilterPeriodType.THREE_MONTHS:
                title = '3 Months';
                break;
        }
        return title;
    }
}
