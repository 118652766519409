import {Dto} from "common";
import {SensorUnit, UnitType} from "../../utils/values/unitType";

export class RepCountCycle extends Dto {
    public timestamp: number;
    public pace: number;
    public ordinal: number;
    public maxForce: number;
    public avgForce: number;
    public unitType: SensorUnit;

    deserialize(input: any): this {
        Object.assign(this, input);

        // Unit can be KG or ANGLES
        if (input.unitType === 'KG') {
            this.unitType = UnitType.KG;
        } else if (input.unitType === 'ANGLES') {
            this.unitType = UnitType.ANGLES;
        }

        return this;
    }
}
