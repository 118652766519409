<div class="component">
    <div *ngIf="analysis; else loadingView">
        <ng-container *ngIf="viewType === resultViewTypes.FULL">
            <ng-container *ngTemplateOutlet="sectionMaxAndKpis"></ng-container>
            <ng-container *ngTemplateOutlet="sectionForcesAndBars"></ng-container>
        </ng-container>
        <ng-container *ngIf="viewType === resultViewTypes.ROW">
            <div class="d-flex flex-row">
                <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
                <div class="side flex-grow-1">
                    <div class="row">
                        <div class="col-6 d-flex flex-column">
                            <app-value-widget class="align-self-center"
                                              [title]="text('widget.jump_height.title')"
                                              [iconType]="valueWidgetIcons.HEIGHT"
                                              [value]="jumpHeightValue" [valueFractionDigits]="0"></app-value-widget>
                            <div class="max-per-side-chart">
                                <app-max-per-side-chart class="chart mt-2 align-self-stretch overflow-hidden"
                                                        [maxPerSide]="maxPerSide"
                                ></app-max-per-side-chart>
                            </div>
                            <div *ngIf="maxTotal" class="align-self-center fw-bold">{{maxTotal.formatTo(this.userSettings.weightUnit, 1)}}</div>
                        </div>
                        <div class="col-6">
                            <ng-container *ngIf="jumpType !== jumpTypes.DROP">
                                <div class="d-flex flex-row flex-grow-1 ms-4">
                                    <div class="flex-grow-1 text-center fw-bold">{{totalRfd?.format(1)}}</div>
                                    <div class="flex-grow-1 text-center fw-bold">{{totalImpulsion?.format(1)}}</div>
                                </div>
                                <div>
                                    <app-body-side-bar-chart class="overflow-hidden"
                                                             [data]="rfdImpulsionData"
                                                             [dataTitles]="rfdImpulsionTitles"
                                                             valueFractionDigits="1"
                                    ></app-body-side-bar-chart>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="jumpType === jumpTypes.DROP">
                                <app-value-widget [iconType]="valueWidgetIcons.CONTACT_TIME"
                                                  [title]="text('contact_time')"
                                                  [value]="contactTimeValue">
                                </app-value-widget>
                                <app-value-widget [iconType]="valueWidgetIcons.REACTIVE_STRENGTH_INDEX"
                                                  [title]="text('reactive_strength_index')"
                                                  [value]="reactiveStrengthIndexValue"
                                                  valueFractionDigits="1"
                                >
                                </app-value-widget>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

        </ng-container>
    </div>
</div>

<ng-template #loadingView>
    Loading...
</ng-template>

<ng-template #sectionMaxAndKpis>
    <div class="section row">
        <div class="max-strength col-8">
            <div class="ratio ratio-16x9">
                <app-max-per-side-chart class="max-strength"
                                        [maxPerSide]="maxPerSide"
                ></app-max-per-side-chart>
            </div>
            <div *ngIf="maxTotal" class="max-value text-center">{{maxTotal.formatTo(this.userSettings.weightUnit, 1)}}</div>
        </div>
        <div class="col-4 d-flex flex-column justify-content-center">
            <app-value-widget class="mb-3"
                              [iconType]="valueWidgetIcons.TIMER"
                              [title]="text('jump_analysis.cmj.workout.cycle_time.title')"
                              [value]="timeOnAirValue">
            </app-value-widget>
            <app-value-widget [iconType]="valueWidgetIcons.HEIGHT"
                              [title]="text('widget.jump_height.title')"
                              [value]="jumpHeightValue"
                              valueFractionDigits="1"
            ></app-value-widget>
            <ng-container *ngIf="jumpType === jumpTypes.DROP">
                <app-value-widget [iconType]="valueWidgetIcons.CONTACT_TIME"
                                  [title]="text('contact_time')"
                                  [value]="contactTimeValue">
                </app-value-widget>
                <app-value-widget [iconType]="valueWidgetIcons.REACTIVE_STRENGTH_INDEX"
                                  [title]="text('reactive_strength_index')"
                                  [value]="reactiveStrengthIndexValue"
                                  valueFractionDigits="1"
                >
                </app-value-widget>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #sectionForcesAndBars>
    <div class="section row mt-4">
        <div class="col-8">
            <app-jump-analysis-line-chart [sidesSamples]="chartSideSamples"
                                          [rfdPoints]="rfdPoints"
                                          [timeOnAirPoints]="timeOnAirPoints">
            </app-jump-analysis-line-chart>
        </div>
        <div *ngIf="jumpType === jumpTypes.CMJ || jumpType === jumpTypes.SJ" class="rfdImpulsion col-4">
            <div class="d-flex flex-row ps-4">
                <b class="title flex-grow-1 text-center">{{totalRfd.format(1)}}</b>
                <b class="title flex-grow-1 text-center">{{totalImpulsion.format(1)}}</b>
            </div>
            <div>
                <app-body-side-bar-chart class="overflow-hidden"
                                         [data]="rfdImpulsionData"
                                         [dataTitles]="rfdImpulsionTitles"
                                         valueFractionDigits="1"
                ></app-body-side-bar-chart>
            </div>
        </div>
    </div>
</ng-template>
