import {ProtocolSummaryResultsModelDto} from "./protocolSummaryResultsModelDto";
import {Entry} from "./entry";

export class ForceVelocityProtocolSummaryResultsModelDto extends ProtocolSummaryResultsModelDto {
    public maxPower: number; // W/Kg
    public f0: number; // N/Kg
    public v0: number; // m/s

    public velocityR2: number;

    // force|power / velocity
    public relativeForceVelocityEntries: Array<Entry>; // y=N/Kg, x=m/s
    public powerVelocityEntries: Array<Entry>; // y=W/Kg, x=m/s

    // measured|optimal profile
    public measuredProfileEntries: Array<Entry>; // y=N/Kg, x=m/s
    public optimalProfileEntries: Array<Entry>;// y=N/Kg, x=m/s
    public optimalMeasuredProfileDeficit: number; // % [0-1]

    // real force/velocity values
    public realRelativeForceVelocityEntries: Array<Entry>; // y=N/Kg, x=m/s
    public forceVelocityPerJump: Array<Entry>; // y=N/Kg, x=m/s

    deserialize(input: any): this {
        Object.assign(this, input);

        this.relativeForceVelocityEntries = this.deserializeArray(input.relativeForceVelocityEntries, Entry);
        this.powerVelocityEntries = this.deserializeArray(input.powerVelocityEntries, Entry);
        this.measuredProfileEntries = this.deserializeArray(input.measuredProfileEntries, Entry);
        this.optimalProfileEntries = this.deserializeArray(input.optimalProfileEntries, Entry);
        this.realRelativeForceVelocityEntries = this.deserializeArray(input.realRelativeForceVelocityEntries, Entry);
        this.forceVelocityPerJump = this.deserializeArray(input.forceVelocityPerJump, Entry);

        return this;
    }
}
