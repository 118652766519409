<div class="container mt-4">
    <div *ngIf="loadingState === loadingStates.LOADED; else loadingView" class="row">
        <div class="top-info col-sm-6 offset-sm-3 col-12 d-flex flex-row mt-2 mb-5">
            <app-avatar class="avatar me-4" [imageBase64]="participant.photo"></app-avatar>
            <div class="flex-grow-1 align-self-center">
                <h1 class="name p-0">{{participant.fullName}}</h1>
                <div class="details">{{participantDescription()}}</div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="card-item col-6 offset-3 mb-4">
            <mat-card class="d-flex flex-column align-items-center" (click)="onProgressPress()">
                <img class="icon pt-1 pb-1" src="./assets/icons/bar_chart.svg" alt="Progress icon"/>
                <h2 class="title">Progress</h2>
            </mat-card>
        </div>
    </div>
</div>
<ng-template #loadingView>
    <app-loading class="d-flex align-items-center justify-content-center" [state]="loadingState"></app-loading>
</ng-template>
