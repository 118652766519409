import {Component, OnInit} from '@angular/core';
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {SkippingExerciseResultsModel} from "../../../dtos/statistics/skippingExerciseResultsModel";
import {ValueWidgetIcon} from "../../../viewmodels/valueWidgetIcons";
import {BodySideType} from "../../../models/bodySideType";
import {Sample} from "../../../utils/sample";
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {Resource} from "common";
import {ActivityImageResolverService} from "../../../services/activity-image-resolver.service";
import {ResultsComponent} from "../resultsComponent";
import {ActivityDto} from "../../../dtos/activity.dto";
import {UnitType, UnitTypes} from "../../../utils/values/unitType";
import {Value} from "../../../utils/values/value";
import {Kg} from "../../../utils/values/kg";
import {Seconds} from "../../../utils/values/seconds";
import {Percent} from "../../../utils/values/percent";

@Component({
    selector: 'app-skipping-analysis',
    templateUrl: './skipping-analysis.component.html',
    styleUrls: ['./skipping-analysis.component.scss']
})
export class SkippingAnalysisComponent extends ResultsComponent implements OnInit {
    public readonly valueWidgetIcons: typeof ValueWidgetIcon = ValueWidgetIcon;
    public readonly unitTypeUtils = UnitTypes;
    public readonly unitTypes = UnitType;

    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    private statistics: SkippingExerciseResultsModel;
    public image: Resource;
    public numberOfSkips: Value;
    public timeOnAir: Value;
    public duration: Value;
    public fatigueIndex: Value;
    public pace: Value;
    public power: Value;
    public groundReactionForcesPerRep: Map<BodySideType, Value>[];
    public averageDistributionLeftUnits: string;
    public averageDistributionLeftPercent: string;
    public averageDistributionRightUnits: string;
    public averageDistributionRightPercent: string;
    public avgForceLeft: string;
    public timeOnAirLeft: string;
    public contactTimeLeft: string;
    public avgForceRight: string;
    public timeOnAirRight: string;
    public contactTimeRight: string;
    public forces: Map<[BodySideType, UnitType], Sample[]>;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. SkippingAnalysisComponent`, this.analysis);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.activityAnalysis.activityResultsModel as SkippingExerciseResultsModel;
        const statistics = this.statistics;

        this.image = this.activityImageResolverService.do(this.activity.image);

        // Metrics
        this.numberOfSkips = new Value(statistics.totalSkips);
        this.timeOnAir = new Seconds(statistics.timeOnAirBothSides / 1000);
        this.duration = new Seconds(this.activityAnalysis.config.duration);
        this.power = new Value(statistics.averagePower, UnitType.WATT_PER_KG);
        this.pace = new Value(statistics.paceTotal, UnitType.PER_MINUTE);
        this.fatigueIndex = new Value(statistics.fatigueIndex);

        // Ground reaction forces
        this.averageDistributionLeftUnits = new Kg(statistics.leftAvgForce).format(1);
        this.averageDistributionLeftPercent = new Percent(statistics.avgForceLeftPercent).format(2);
        this.averageDistributionRightUnits = new Kg(statistics.rightAvgForce).format(1);
        this.averageDistributionRightPercent = new Percent(statistics.avgForceRightPercent).format(2);

        this.groundReactionForcesPerRep = [];
        for (const cycleData of statistics.averageForcesPerCycle) {
            const repData = new Map<BodySideType, Value>();
            repData.set(BodySideType.LEFT, new Kg(cycleData.leftForce));
            repData.set(BodySideType.RIGHT, new Kg(cycleData.rightForce));
            this.groundReactionForcesPerRep.push(repData);
        }

        this.avgForceLeft = new Value(statistics.leftAvgForce).format(1);
        this.avgForceRight = new Value(statistics.rightAvgForce).format(1);
        this.timeOnAirLeft = new Value(statistics.leftTimeOnAir / 1000 /* to sec*/).format(1);
        this.timeOnAirRight = new Value(statistics.rightTimeOnAir / 1000 /* to sec*/).format(1);
        this.contactTimeLeft = new Value(statistics.leftContactTime / 1000 /* to sec*/).format(1);
        this.contactTimeRight = new Value(statistics.rightContactTime / 1000 /* to sec*/).format(1);

        // Forces line graph
        this.forces = new Map<[BodySideType, UnitType], Sample[]>();
        this.forces.set([BodySideType.LEFT, UnitType.KG], statistics.leftForces.map(value => new Sample(value.time / 1000, value.force)));
        this.forces.set([BodySideType.RIGHT, UnitType.KG], statistics.rightForces.map(value => new Sample(value.time / 1000, value.force)));
    }

}
