import {StanceOval} from "./stanceOval";
import {Entry} from "./entry";
import {FrequencyAnalysisResults} from "./frequencyAnalysisResults";
import {ActivityResultsModel} from "./activityResultsModel";

export class StanceEvaluationActivityResultsModel extends ActivityResultsModel {
    public leftWeightDistribution: number;
    public rightWeightDistribution: number;
    public surface: number;
    public surfaceLeft: number;
    public surfaceRight: number;
    public avgVelocity: number;
    public longitudinalDeviation: number;
    public lateralDeviation: number;
    public longitudinalDeviationLeft: number;
    public lateralDeviationLeft: number;
    public longitudinalDeviationRight: number;
    public lateralDeviationRight: number;
    public totalCopDisplacement: number;
    public totalLateralRange: number;
    public lateralAvg: number;
    public lateralAvgLeft: number;
    public lateralAvgRight: number;
    public longitudinalAvg: number;
    public longitudinalAvgLeft: number;
    public longitudinalAvgRight: number;
    public longitudinalRange: number;
    public leftCoPXMean: number;
    public leftCoPYMean: number;
    public rightCoPXMean: number;
    public rightCoPYMean: number;
    public standardDeviation: number;
    public vfy: number;
    public oval: StanceOval;
    public gridCopCoordsInPercentage: Entry[];
    public leftGridCopCoordsInPercentage: Entry[];
    public rightGridCopCoordsInPercentage: Entry[];
    public copFrequenciesAxisX?: FrequencyAnalysisResults;
    public copFrequenciesAxisY?: FrequencyAnalysisResults;
    public weight: number;
    public areaCoveredPerTime: Array<Entry>;
    public areaCoveredPerTimeLeft: Array<Entry>;
    public areaCoveredPerTimeRight: Array<Entry>;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.oval = new StanceOval().deserialize(input.oval);

        this.gridCopCoordsInPercentage = this.deserializeArray(input.gridCopCoordsInPercentage, Entry);
        this.leftGridCopCoordsInPercentage = this.deserializeArray(input.leftGridCopCoordsInPercentage, Entry);
        this.rightGridCopCoordsInPercentage = this.deserializeArray(input.rightGridCopCoordsInPercentage, Entry);

        this.copFrequenciesAxisX = new FrequencyAnalysisResults().deserialize(input.copFrequenciesAxisX);
        this.copFrequenciesAxisY = new FrequencyAnalysisResults().deserialize(input.copFrequenciesAxisY);

        this.areaCoveredPerTime = this.deserializeArray(input.areaCoveredPerTime, Entry);
        this.areaCoveredPerTimeLeft = this.deserializeArray(input.areaCoveredPerTimeLeft, Entry);
        this.areaCoveredPerTimeRight = this.deserializeArray(input.areaCoveredPerTimeRight, Entry);

        return this;
    }
}
