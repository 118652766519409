import {BodySideType, BodySideTypes} from "../../models/bodySideType";
import {IsometryActivityRepResultsModel} from "./isometryActivityRepResultsModel";
import {Dto} from "common";

export class IsometryActivityResultsModel extends Dto {
    public repResults: IsometryActivityRepResultsModel[];
    public maxSuccessRatePerSide: { side: BodySideType; value: number }[];
    public meanSuccessRatePerSide: { side: BodySideType; value: number }[];

    deserialize(input: any): this {
        Object.assign(this, input);

        this.repResults = this.deserializeArray(input.repResults, IsometryActivityRepResultsModel);

        this.maxSuccessRatePerSide = [];
        if (input.maxSuccessRatePerSide) {
            for (const datum of input.maxSuccessRatePerSide as any) {
                this.maxSuccessRatePerSide.push({side: BodySideTypes.parse(datum.side), value: datum.value});
            }
        }

        this.meanSuccessRatePerSide = [];
        if (input.meanSuccessRatePerSide) {
            for (const datum of input.meanSuccessRatePerSide as any) {
                this.meanSuccessRatePerSide.push({side: BodySideTypes.parse(datum.side), value: datum.value});
            }
        }

        return this;
    }
}
