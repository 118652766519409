<div>
    <div class="section">
        <h2>{{text('force.max')}}</h2>
        <div class="row">
            <div class="col-6 text-center">{{text('deficit')}}: {{deg90DeficitFormatted}}</div>
            <div class="col-6 text-center">{{text('deficit')}}: {{deg30DeficitFormatted}}</div>
        </div>
        <div class="d-inline-block full-width">
            <app-body-side-bar-chart [dataTitles]="maxPerPositionTitles"
                                     [data]="maxPerPositionData"
                                     [valueFractionDigits]="1">
            </app-body-side-bar-chart>
        </div>
    </div>
    <div class="section section-repetitions">
        <h2>{{text('rep.analysis')}}</h2>
        <div class="repetition d-flex flex-row mb-4">
            <div class="repetition-title d-flex align-items-center">{{maxPerPositionTitles[0]}}</div>
            <app-meter-endurance-statistics-row class="flex-grow-1"
                                                [repetitionResults]="deg90RepsViewmodel"
                                                [displayRepetitionOrdinal]="false"></app-meter-endurance-statistics-row>
        </div>
        <div class="repetition d-flex flex-row">
            <div class="repetition-title d-flex align-items-center">{{maxPerPositionTitles[1]}}</div>
            <app-meter-endurance-statistics-row class="flex-grow-1"
                                                [repetitionResults]="deg30RepsViewmodel"
                                                [displayRepetitionOrdinal]="false"></app-meter-endurance-statistics-row>
        </div>
    </div>

</div>
