import {Component, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {validate as uuidValidate} from 'uuid';
import {ProtocolService} from '../../services/api/protocol.service';
import {ProtocolDto} from '../../dtos/protocol.dto';
import {ProtocolViewModel} from '../../viewmodels/protocolViewModel';
import {BuiltinProtocols} from '../../models/builtinProtocols';
import {ParticipantService} from '../../services/api/participant.service';
import {ParticipantDto} from '../../dtos/participant.dto';
import {forkJoin} from 'rxjs';
import {ProtocolFilteringService} from '../../services/protocol-filtering.service';
import {ProtocolGroupFilter} from '../../viewmodels/protocolGroupFilter';
import {SelectExerciseTypeDialogComponent} from '../../components/dialogs/select-exercise-type-dialog/select-exercise-type-dialog.component';
import {BreadcrumbInitializerService} from '../../services/breadcrumb-initializer.service';
import {OrderingType} from '../../viewmodels/orderingType';
import {ProtocolOrderingService} from '../../services/protocol-ordering.service';
import {Arrays, BrowserStorageService, Code, Dialogs, LoadingState} from "common";
import {BaseComponent} from "../../components/base/base.component";

@Component({
    selector: 'app-participant-progress',
    templateUrl: './participant-progress.component.html',
    styleUrls: ['./participant-progress.component.scss']
})
export class ParticipantProgressComponent extends BaseComponent implements OnInit {
    public static readonly PERSISTENCE_PROTOCOL_GROUP_FILTER_KEY = 'progress_screen_filter';

    public loadingState: LoadingState;
    public participantCode: string;
    public items: ProtocolViewModel[];
    public filteredItems: ProtocolViewModel[];
    private participant: ParticipantDto;
    public filter: ProtocolGroupFilter;
    public orderType: OrderingType;
    public orderOptions: OrderingType[];

    constructor(private route: ActivatedRoute,
                private protocolService: ProtocolService,
                private participantService: ParticipantService,
                private protocolFilteringService: ProtocolFilteringService,
                private breadcrumbInitializerService: BreadcrumbInitializerService,
                private protocolOrderingService: ProtocolOrderingService,
                private storage: BrowserStorageService) {
        super();
    }

    ngOnInit(): void {
        const previousFilter = this.storage.loadDto(ParticipantProgressComponent.PERSISTENCE_PROTOCOL_GROUP_FILTER_KEY, ProtocolGroupFilter);
        if (previousFilter !== null) {
            this.filter = previousFilter;
        } else {
            this.filter = new ProtocolGroupFilter();
        }

        this.orderType = OrderingType.DATE_DESC;
        this.orderOptions = [OrderingType.DATE_DESC, OrderingType.DATE_ASC, OrderingType.TITLE_ASC, OrderingType.TITLE_DESC];

        this.route.paramMap
            .pipe(takeUntil(this.destroySubject))
            .subscribe((params) => {
                const participantCode = params.get('code');
                if (!uuidValidate(participantCode)) {
                    this.navigation.goToHome();
                    return;
                }

                this.loadingState = LoadingState.LOADING;
                this.participantCode = participantCode;

                this.analyticsSwitchPage('Progress', undefined, '/participants/participant/progress');

                let participantObservable = this.participantService.get(participantCode)
                    .pipe(takeUntil(this.destroySubject));

                let protocolsObservable = this.protocolService.findByParticipantCode(participantCode)
                    .pipe(takeUntil(this.destroySubject));

                forkJoin([participantObservable, protocolsObservable])
                    .subscribe((data: [ParticipantDto, ProtocolDto[]]) => {
                        this.participant = data[0];
                        this.breadcrumbInitializerService.setParticipantName(this.participant.fullName);
                        this.items = this.createItems(data[1]);
                        this.filteredItems = this.items;
                        this.protocolOrderingService.order(this.filteredItems, this.orderType);
                        this.onFilterChanged(this.filter);
                        this.loadingState = LoadingState.LOADED;
                    }, error => {
                        this.notification.info('Something unexpected happened. Please try again.');
                        this.errorHandler.handle(error);
                        this.loadingState = LoadingState.ERROR;
                    });
            });
    }

    public onCardSelected(event: [Code, MouseEvent]) {
        const vm = this.items.find(i => i.code === event[0]);
        const inNewTab = event[1].button === 1;

        if (vm.isSingleActivity) {
            this.onActivitySelected(vm, inNewTab);
        } else {
            this.onProtocolSelected(vm, inNewTab);
        }
    }

    private createItems(protocols: ProtocolDto[]): ProtocolViewModel[] {
        return this.getViewModels(this.protocolFilteringService.group(protocols));
    }

    private getViewModels(groupedProtocols: Map<ProtocolGroupFilter, ProtocolDto[]>): Array<ProtocolViewModel> {
        const viewModels = new Array<ProtocolViewModel>();
        const favoriteMocks = this.participant.getFavoriteActivityConfigCodes();
        const favoriteProtocols = this.participant.getFavoriteProtocolConfigCodes();

        for (let entry of groupedProtocols.entries()) {
            const groupFilter = entry[0];
            const protocols = entry[1];
            const lastProtocol = protocols[protocols.length - 1];

            let vm: ProtocolViewModel;
            // For multiple jumps old protocol, we just care about its single activity inside it, to be grouped with our current single activity multiple jumps.
            // So force create it as a mock protocol so that it's grouped together
            const isMultipleJumpsProtocol = lastProtocol.config?.baseConfigCode === BuiltinProtocols.IDENTIFIERS.MULTIPLE_JUMPS;

            if (lastProtocol.config && !isMultipleJumpsProtocol) {
                const isFavorite = favoriteProtocols.includes(lastProtocol.config.baseConfigCode);
                vm = ProtocolViewModel.createForProtocol(this.i18n, lastProtocol, isFavorite);
            } else {
                // The mock protocols are grouped by the tag, so that i.e. meter, isometric, rep count, endurance are on the same card.
                // For each one of these different types, hold its config to get any info needed in the future
                const groupedConfigForActivities = protocols.map((e) => e.activities[0].config);
                const uniqueGroupedConfigsForActivities = Arrays.unique(groupedConfigForActivities, (it) => it.baseConfigCode!);
                // because of the grouping, mark as favorite when at least one of the grouped configs is a favorite one
                const isFavorite = uniqueGroupedConfigsForActivities.some((config) => favoriteMocks.includes(config.baseConfigCode!));
                vm = ProtocolViewModel.createForSingleActivity(this.i18n, lastProtocol, uniqueGroupedConfigsForActivities, isFavorite);
            }

            vm.filter = groupFilter;
            protocols.forEach(p => vm.protocols.push(p));
            viewModels.push(vm);
        }
        return viewModels;
    };

    private onActivitySelected(vm: ProtocolViewModel, inNewTab: boolean) {
        // Display an exercise type selection dialog when more than one type is present.
        const uniqueTypes = vm.exerciseTypes;
        if (uniqueTypes.length > 1) {
            Dialogs.display(this.dialog, SelectExerciseTypeDialogComponent, {data: {exerciseTypes: uniqueTypes}})
                .pipe(takeUntil(this.destroySubject))
                .subscribe(value => {
                    vm.filter.exerciseType = value;
                    this.navigation.goToParticipantActivityResults(this.participantCode, vm.filter.baseConfigCode, vm.protocols, vm.filter, inNewTab);
                });

        } else {
            // Display the activity
            let protocols = vm.protocols;
            let baseConfigCode = protocols[0].activities[0].config.baseConfigCode;

            // get all activities from protocols as well
            const otherProtocols = this.items.filter(vm => !vm.isSingleActivity).flatMap(vm => vm.protocols);
            let filteredProtocols = this.protocolFilteringService.applyFilter(otherProtocols, vm.filter);

            protocols = protocols.concat(filteredProtocols);

            this.navigation.goToParticipantActivityResults(this.participantCode, baseConfigCode, protocols, vm.filter, inNewTab);
        }
    }

    private onProtocolSelected(vm: ProtocolViewModel, inNewTab: boolean): void {
        let baseConfigCode = vm.protocols[0].config.getBaseConfigCode();
        this.navigation.goToParticipantProtocolResults(this.participantCode, baseConfigCode, vm.protocols, inNewTab);
    }

    public onFilterChanged(filter: ProtocolGroupFilter): void {
        this.filter = filter;
        this.filteredItems = this.protocolFilteringService.filterViewmodels(this.items, filter);
        this.protocolOrderingService.order(this.filteredItems, this.orderType);
        this.storage.saveDto(ParticipantProgressComponent.PERSISTENCE_PROTOCOL_GROUP_FILTER_KEY, this.filter);
    }

    public onOrderChanged(type: OrderingType): void {
        this.orderType = type;
        this.protocolOrderingService.order(this.filteredItems, type);
    }
}
