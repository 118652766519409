import {Component, Input, OnInit} from '@angular/core';
import {ProtocolDto} from "../../../dtos/protocol.dto";
import {ProtocolAnalysisServiceResponseDto} from "../../../dtos/statistics/protocolAnalysisServiceResponseDto";
import {Color, Title} from "common";
import {AdvancedStanceEvaluationProtocolSummaryResultsModel} from "../../../dtos/statistics/advancedStanceEvaluationProtocolSummaryResultsModel";
import {ActivityConfigSetMetaKeys} from "../../../models/activityConfigSetMetaKeys";
import {ActivityDto} from "../../../dtos/activity.dto";
import {BaseComponent} from "../../base/base.component";
import {Colors} from "../../../services/colors";
import {Value} from "../../../utils/values/value";
import {Percent} from "../../../utils/values/percent";

@Component({
    selector: 'app-protocol-summary-advanced-standing-evaluation-results',
    templateUrl: './protocol-summary-advanced-standing-evaluation-results.component.html',
    styleUrls: ['./protocol-summary-advanced-standing-evaluation-results.component.scss']
})
export class ProtocolSummaryAdvancedStandingEvaluationResultsComponent extends BaseComponent implements OnInit {
    public readonly colors = Colors;

    @Input()
    public protocol: ProtocolDto;

    @Input()
    public analysis: ProtocolAnalysisServiceResponseDto;

    public eyesOpenTitle: Title;
    public eyesOpenData: Value[];
    public eyesOpenBackgroundColor: Color;
    public eyesClosedTitle: Title;
    public eyesClosedData: Value[];
    public eyesClosedBackgroundColor: Color;
    public eyesChartMaxY: number;
    private eyesOpenActivities: ActivityDto[];
    private eyesClosedActivities: ActivityDto[];
    public eyesOpenStats: Value[][];
    public eyesClosedStats: Value[][];
    private results: AdvancedStanceEvaluationProtocolSummaryResultsModel;
    public eyesOpenTotalAverage: Value[];
    public eyesClosedTotalAverage: Value[];
    public eyesOpenColor: Color;
    public eyesClosedColor: Color;
    public rombergQuotient: Value;

    constructor() {
        super();
        this.eyesOpenBackgroundColor = 'gainsboro';
        this.eyesClosedBackgroundColor = 'black';
        this.eyesOpenColor = 'black';
        this.eyesClosedColor = 'white';
    }

    ngOnInit(): void {
        if (!(this.analysis.protocolsResults[0].protocolSummaryResultsModel instanceof AdvancedStanceEvaluationProtocolSummaryResultsModel)) {
            return;
        }

        this.results = this.analysis.protocolsResults[0].protocolSummaryResultsModel as AdvancedStanceEvaluationProtocolSummaryResultsModel;

        this.eyesOpenTitle = this.text('advancedStandingEvaluation.results.eyesOpened');
        this.eyesClosedTitle = this.text('advancedStandingEvaluation.results.eyesClosed');
        this.eyesOpenActivities = this.protocol.activities.filter(a => a.config.activityConfigSets.some(s => s.getMetaBoolean(ActivityConfigSetMetaKeys.EYES_OPEN) === true));
        this.eyesClosedActivities = this.protocol.activities.filter(a => !this.eyesOpenActivities.map(e => e.code).includes(a.code));

        // Top chart
        this.eyesOpenData = [];
        for (const activity of this.eyesOpenActivities) {
            const surface = this.results.surfaces.find(s => s.activityCode === activity.code);
            this.eyesOpenData.push(new Value(surface.surface));
        }
        this.eyesClosedData = [];
        for (const activity of this.eyesClosedActivities) {
            const surface = this.results.surfaces.find(s => s.activityCode === activity.code);
            this.eyesClosedData.push(new Value(surface.surface));
        }
        // pad with zeros until three values
        for (let i = this.eyesOpenData.length; i < 3; i++) {
            this.eyesOpenData.push(new Value(0));
        }
        for (let i = this.eyesClosedData.length; i < 3; i++) {
            this.eyesClosedData.push(new Value(0));
        }

        // 20% above max from all sets
        this.eyesChartMaxY = Math.max(0.1, ...this.eyesOpenData.map(v => v.value).concat(this.eyesClosedData.map(s => s.value))) * 1.2;

        // Table
        this.eyesOpenStats = [];
        for (let i = 0; i < 3; i++) {
            if (this.eyesOpenActivities.length - 1 < i) {
                this.eyesOpenStats.push([]);
                continue;
            }

            const stats: Value[] = [];
            stats.push(this.getLeftDistribution(this.eyesOpenActivities[i]));
            stats.push(this.getSurface(this.eyesOpenActivities[i]));
            stats.push(this.getMeanVelocity(this.eyesOpenActivities[i]));
            stats.push(this.getLongitudinalAmplitude(this.eyesOpenActivities[i]));
            stats.push(this.getLateralAmplitude(this.eyesOpenActivities[i]));
            this.eyesOpenStats.push(stats);
        }
        this.eyesClosedStats = [];
        for (let i = 0; i < 3; i++) {
            if (this.eyesClosedActivities.length - 1 < i) {
                this.eyesClosedStats.push([]);
                continue;
            }

            const stats: Value[] = [];
            stats.push(this.getLeftDistribution(this.eyesClosedActivities[i]));
            stats.push(this.getSurface(this.eyesClosedActivities[i]));
            stats.push(this.getMeanVelocity(this.eyesClosedActivities[i]));
            stats.push(this.getLongitudinalAmplitude(this.eyesClosedActivities[i]));
            stats.push(this.getLateralAmplitude(this.eyesClosedActivities[i]));
            this.eyesClosedStats.push(stats);
        }

        this.eyesOpenTotalAverage = [];
        this.eyesOpenTotalAverage.push(new Value(this.results.averageLeftDistributions.eyesOpen));
        this.eyesOpenTotalAverage.push(new Value(this.results.averageSurfaces.eyesOpen));
        this.eyesOpenTotalAverage.push(new Value(this.results.averageMeanVelocities.eyesOpen));
        this.eyesOpenTotalAverage.push(new Value(this.results.averageLongitudinalAmplitudes.eyesOpen));
        this.eyesOpenTotalAverage.push(new Value(this.results.averageLateralAmplitudes.eyesOpen));

        this.eyesClosedTotalAverage = [];
        this.eyesClosedTotalAverage.push(new Value(this.results.averageLeftDistributions.eyesClosed));
        this.eyesClosedTotalAverage.push(new Value(this.results.averageSurfaces.eyesClosed));
        this.eyesClosedTotalAverage.push(new Value(this.results.averageMeanVelocities.eyesClosed));
        this.eyesClosedTotalAverage.push(new Value(this.results.averageLongitudinalAmplitudes.eyesClosed));
        this.eyesClosedTotalAverage.push(new Value(this.results.averageLateralAmplitudes.eyesClosed));

        this.rombergQuotient = new Value(this.results.rombergQuotient);
    }

    private getLeftDistribution(activityDto: ActivityDto): Percent {
        return new Percent(this.results.leftDistributions.find(s => s.activityCode === activityDto.code).distribution);
    }

    private getSurface(activityDto: ActivityDto): Value {
        return new Value(this.results.surfaces.find(s => s.activityCode === activityDto.code).surface);
    }

    private getMeanVelocity(activityDto: ActivityDto): Value {
        return new Value(this.results.meanVelocities.find(s => s.activityCode === activityDto.code).meanVelocity);
    }

    private getLongitudinalAmplitude(activityDto: ActivityDto): Value {
        return new Value(this.results.longitudinalAmplitudes.find(s => s.activityCode === activityDto.code).longitudinalAmplitude);
    }

    private getLateralAmplitude(activityDto: ActivityDto): Value {
        return new Value(this.results.lateralAmplitudes.find(s => s.activityCode === activityDto.code).lateralAmplitude);
    }
}
