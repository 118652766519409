import {Injectable} from '@angular/core';
import {OrderingType} from '../viewmodels/orderingType';
import {ProtocolViewModel} from '../viewmodels/protocolViewModel';

@Injectable({
    providedIn: 'root'
})
export class ProtocolOrderingService {

    constructor() {
    }

    order(items: ProtocolViewModel[], type: OrderingType) {
        switch (type) {
            case OrderingType.DATE_ASC:
                items.sort(ProtocolOrderingService.orderByDateAsc);
                break;
            case OrderingType.DATE_DESC:
                items.sort(ProtocolOrderingService.orderByDateDesc);
                break;
            case OrderingType.TITLE_ASC:
                items.sort(ProtocolOrderingService.orderByTitleAsc);
                break;
            case OrderingType.TITLE_DESC:
                items.sort(ProtocolOrderingService.orderByTitleDesc);
                break;
        }

        // move not available items at the end
        items.sort(a => a.enabled ? -1 : 1);
    }

    private static orderByDateAsc(first: ProtocolViewModel, second: ProtocolViewModel): number {
        return first.date.getTime() - second.date.getTime();
    }

    private static orderByDateDesc(first: ProtocolViewModel, second: ProtocolViewModel): number {
        return -(first.date.getTime() - second.date.getTime());
    }

    private static orderByTitleAsc(first: ProtocolViewModel, second: ProtocolViewModel): number {
        return first.title.localeCompare(second.title);
    }

    private static orderByTitleDesc(first: ProtocolViewModel, second: ProtocolViewModel): number {
        return -first.title.localeCompare(second.title);
    }
}
