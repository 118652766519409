<mat-toolbar color="primary" *ngIf="toolbarVisible" class="toolbar">
    <a class="logo-wrapper" (click)="onTitlePress()">

    </a>
    <h2 class="title me-5"><a (click)="onTitlePress()">{{title}}</a></h2>
    <button mat-button (click)="onParticipantsPress()">{{text('homescreen.expertmode.title')}}</button>
    <div class="flex-grow-1"></div>
    <button *ngIf="!devGuard.isProduction()" mat-stroked-button (click)="onDevClick()">
        <mat-icon>code</mat-icon>
    </button>
    <button mat-button [matMenuTriggerFor]="userMenu">
        {{fullName}}
        <mat-icon>account_circle</mat-icon>
    </button>
</mat-toolbar>

<mat-menu #userMenu="matMenu" xPosition="before">
    <button mat-menu-item (click)="onProfileSelected()">Profile</button>
    <button mat-menu-item (click)="onLogoutSelected()">Log out</button>
</mat-menu>
