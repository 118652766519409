import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions} from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {BodySideType, BodySideTypes} from '../../../models/bodySideType';
import {Arrays, Title} from "common";
import {VerticalDistribution} from '../../../models/verticalDistribution';
import {BaseComponent} from "../../base/base.component";
import {Value} from "../../../utils/values/value";
import {YAxisDualTitleChartjsPlugin} from "../../../utils/charts/yAxisDualTitleChartjsPlugin";
import {Percent} from "../../../utils/values/percent";

@Component({
    selector: 'app-distribution-bar-chart',
    templateUrl: './distribution-bar-chart.component.html',
    styleUrls: ['./distribution-bar-chart.component.scss']
})
export class DistributionBarChartComponent extends BaseComponent implements OnInit {

    @Input()
    public data: Map<BodySideType, VerticalDistribution<Value>>[];

    @Input()
    public valueFractionDigits: number = 0;
    @Input()
    public topAreaTitle: Title;
    @Input()
    public bottomAreaTitle: Title;
    @Input()
    public dataTitles: string[];

    public datasets: ChartDataSets[];
    public chartOptions: ChartOptions;
    public chartPlugins: any[];
    private allValues: Value[];
    private yAxisTitlesPlugin: YAxisDualTitleChartjsPlugin;

    constructor() {
        super();
        this.chartPlugins = [pluginDataLabels];

        this.yAxisTitlesPlugin = new YAxisDualTitleChartjsPlugin();
        this.yAxisTitlesPlugin.yAxisId = 'y-axis-1';
        this.yAxisTitlesPlugin.fontSize = 14;
        this.chartPlugins.push(this.yAxisTitlesPlugin);
    }

    ngOnInit(): void {
        this.datasets = [];
        this.allValues = [];

        // get sides
        const sides = Arrays.uniqueValues(this.data.flatMap(v => Array.from(v.keys())));

        const dataPerSide = new Map<BodySideType, VerticalDistribution<Value>[]>();

        for (const side of sides) {
            const values = this.data.map(e => e.get(side)).filter(Arrays.withValue);
            this.datasets.push({
                data: [...values.map(v => v.top.value)],
                backgroundColor: BodySideTypes.lightColor(side),
                stack: side
            });
            this.datasets.push({
                data: values.map(v => v.bottom.value * -1),
                backgroundColor: BodySideTypes.color(side),
                stack: side
            });

            this.allValues.push(...values.map(v => v.top));
            this.allValues.push(...values.map(v => v.bottom));

            // Keep data for later
            dataPerSide.set(side, values);
        }

        // If there are no data titles (title for each set of side values) create them as ordinals based on the sides
        if (!this.dataTitles || this.dataTitles.length === 0) {
            this.dataTitles = [];
            const maxDataPerSide = Math.max(...Array.from(dataPerSide.values()).map(s => s.length));

            for (let i = 0; i < maxDataPerSide; i++) {
                this.dataTitles.push((i + 1).toString());
            }
        }

        this.chartOptions = this.getChartOptions();

        this.yAxisTitlesPlugin.positiveTitle = this.topAreaTitle;
        this.yAxisTitlesPlugin.negativeTitle = this.bottomAreaTitle;
    }

    public getChartOptions(): ChartOptions {
        const axisMax = Math.max(Math.abs(this.getAxisYMax()), Math.abs(this.getAxisYMin()));

        return {
            animation: {
                duration: 0,
                animateScale: false,
                animateRotate: false
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            devicePixelRatio: 2,
            tooltips: {
                enabled: false
            },
            hover: {
                mode: null
            },
            legend: {
                display: false,
            },
            scales: {
                xAxes: [{id: 'x-axis-1', stacked: true}],
                yAxes: [{
                    id: 'y-axis-1',
                    stacked: true,
                    ticks: {
                        min: -axisMax,
                        max: axisMax,
                        callback(value: number | string): string {
                            return DistributionBarChartComponent.axisYValueFormatter(value);
                        }
                    }
                }]
            },
            plugins: {
                datalabels: {
                    formatter: this.labelFormatter.bind(this),
                    color: 'black',
                    align: (value) => {
                        if (value.datasetIndex % 2) {
                            return 'start';
                        } else {
                            return 'end';
                        }
                    },
                    anchor: (value) => {
                        if (value.datasetIndex % 2) {
                            return 'start';
                        } else {
                            return 'end';
                        }
                    },
                    offset: 2
                },
            }
        };
    }

    private static axisYValueFormatter(value: number | string): string | undefined {
        // display values as positives and only when power of five
        value = Math.abs(Number(value));
        if (value % 5 === 0) {
            return new Percent(value).format(0);
        } else {
            return '';
        }
    }

    private labelFormatter = (value: number): string => {
        // find the value in the data by value and format it
        let foundValue: Value;
        for (const datum of this.allValues) {
            if (datum.value === Math.abs(value)) {
                foundValue = datum;
                break;
            }
        }

        if (foundValue) {
            return foundValue.format(this.valueFractionDigits);
        } else {
            return value.toFixed(this.valueFractionDigits);
        }
    };

    private getAxisYMax(): number {
        // 20% above max value, rounded
        const allDistributions = this.data.flatMap(i => Array.from(i.values()));
        return Math.round(Math.max(...allDistributions.map(d => d.top.value))) * 1.2;
    }

    private getAxisYMin(): number {
        // 20% bellow min value, rounded
        const allDistributions = this.data.flatMap(i => Array.from(i.values()));
        return Math.round(Math.max(...allDistributions.map(d => d.bottom.value))) * 1.2;
    }
}
