<div class="component d-flex align-items-center flex-column justify-content-center">
    <div *ngIf="state === loadingStates.LOADING && initialDelayElapsed">
        <img class="full-width"
             src="./assets/brand/loading2.svg"
             [alt]="text('loading.text')"
             [title]="text('loading.text')"
        />
    </div>
    <div *ngIf="state === loadingStates.ERROR" class="d-flex flex-column align-items-center">
        <mat-icon>warning</mat-icon>
        <div class="text-center text-muted">{{text('error_title')}}</div>
    </div>
</div>
