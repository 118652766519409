import {Component, OnInit} from '@angular/core';
import {ResultsComponent} from '../resultsComponent';
import {ActivityAnalysisServiceResponseDto} from '../../../dtos/statistics/activityAnalysisServiceResponseDto';
import {AdvancedSquatAnalysisResultsModel} from '../../../dtos/statistics/advancedSquatAnalysisResultsModel';
import {ActivityAnalysisResultDto} from '../../../dtos/statistics/activityAnalysisResultDto';
import {ActivityDto} from '../../../dtos/activity.dto';
import {ValueWidgetIcon} from '../../../viewmodels/valueWidgetIcons';
import {Range} from '../../../models/range';
import {VerticalDistribution} from '../../../models/verticalDistribution';
import {BodySideType} from '../../../models/bodySideType';
import {Color, KeyValue, Title} from "common";
import {Sample} from '../../../utils/sample';
import {SampleUtils} from '../../../utils/sampleUtils';
import {ActivityConfigDto} from '../../../dtos/activityConfig.dto';
import {Value} from "../../../utils/values/value";
import {Seconds} from "../../../utils/values/seconds";
import {UnitType} from "../../../utils/values/unitType";
import {Degrees} from "../../../utils/values/degrees";
import {MathUtils} from "../../../utils/mathUtils";
import {Percent} from "../../../utils/values/percent";

@Component({
    selector: 'app-advanced-squat-analysis-results',
    templateUrl: './advanced-squat-analysis-results.component.html',
    styleUrls: ['./advanced-squat-analysis-results.component.scss']
})
export class AdvancedSquatAnalysisResultsComponent extends ResultsComponent implements OnInit {
    public readonly valueWidgetIcons = ValueWidgetIcon;

    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: AdvancedSquatAnalysisResultsModel;
    public kpiSquats: Value;
    public kpiDuration: Seconds;
    public kpiPace: Value;
    public standardDeviation: Value;
    public rightDistributionPercent: number;
    public distributionTolerance: Range;
    public stdToDistribution: Range;
    public squatPhaseAvgData: Map<BodySideType, VerticalDistribution<Value>>[];
    public squatPhaseAvgPerRep: Map<BodySideType, VerticalDistribution<Value>>[];
    public squatMaxAverage: Map<BodySideType, Value>[];
    public squatMaxPerRep: Map<BodySideType, Value>[];
    public amplitudeAverage: Map<BodySideType, Value>[];
    public amplitudeAveragePerRep: Map<KeyValue<Title, Color>, Value[]>;
    public amplitudeAveragePerRepLabels: string[];
    public amplitudeAveragePace: KeyValue<Title, Value[]>;
    public forcesData: Map<[BodySideType, UnitType], Sample[]>;
    public forcesDataTotal: Sample[];
    public dynamicCopAnalysis: ActivityAnalysisServiceResponseDto;
    public distributionPerSideData: Map<BodySideType, Value>[];
    public distributionPerSideDataLabels: string[];

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            this.warn('Unsupported analysis');
            return;
        }
        const activityAnalysisResultDto = this.analysis.activitiesResults[0];

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = activityAnalysisResultDto.activityResultsModel as AdvancedSquatAnalysisResultsModel;
        let statistics = this.statistics;

        // KPIs
        this.kpiSquats = new Value(statistics.squatsDone.length);
        this.kpiDuration = new Seconds(statistics.duration);
        this.kpiPace = new Value(statistics.pace, UnitType.PER_MINUTE);

        // Dynamic distribution
        this.standardDeviation = new Value(statistics.standardDeviation);
        this.rightDistributionPercent = (100 - statistics.avgLeftDistribution) / 100;
        this.distributionTolerance = new Range();
        this.distributionTolerance.start = statistics.balanceDistributionConfig.targetDifficultyRangeLeft;
        this.distributionTolerance.end = statistics.balanceDistributionConfig.targetDifficultyRangeRight;
        this.stdToDistribution = new Range();
        this.stdToDistribution.start = this.rightDistributionPercent - statistics.standardDeviation * 2 / 100;
        this.stdToDistribution.end = this.rightDistributionPercent + statistics.standardDeviation * 2 / 100;

        // Distribution/Phase
        const squatPhaseAvgData = new Map<BodySideType, VerticalDistribution<Value>>();
        if (statistics.squatsDone.length > 0) {
            squatPhaseAvgData.set(BodySideType.LEFT, new VerticalDistribution(
                new Value(MathUtils.average(statistics.squatsDone.map(value => value.concentricDistributionLeft))),
                new Value(MathUtils.average(statistics.squatsDone.map(value => value.eccentricDistributionLeft)))));
            squatPhaseAvgData.set(BodySideType.RIGHT, new VerticalDistribution(
                new Value(MathUtils.average(statistics.squatsDone.map(value => value.concentricDistributionRight))),
                new Value(MathUtils.average(statistics.squatsDone.map(value => value.eccentricDistributionRight)))));
        } else {
            squatPhaseAvgData.set(BodySideType.LEFT, new VerticalDistribution(new Value(undefined), new Value(undefined)));
            squatPhaseAvgData.set(BodySideType.RIGHT, new VerticalDistribution(new Value(undefined), new Value(undefined)));
        }
        this.squatPhaseAvgData = [squatPhaseAvgData];

        this.squatPhaseAvgPerRep = [];
        for (const squatCycle of statistics.squatsDone) {
            let repPhase = new Map<BodySideType, VerticalDistribution<Value>>();
            repPhase.set(BodySideType.LEFT, new VerticalDistribution(new Value(squatCycle.concentricDistributionLeft), new Value(squatCycle.eccentricDistributionLeft)));
            repPhase.set(BodySideType.RIGHT, new VerticalDistribution(new Value(squatCycle.concentricDistributionRight), new Value(squatCycle.eccentricDistributionRight)));
            this.squatPhaseAvgPerRep.push(repPhase);
        }

        // Max per squat
        this.squatMaxAverage = [];
        const maxMap = new Map<BodySideType, Value>();
        maxMap.set(BodySideType.RIGHT, new Value(MathUtils.average(statistics.squatsDone.map(s => s.maxForce))));
        this.squatMaxAverage.push(maxMap);

        this.squatMaxPerRep = [];
        for (const squatCycle of statistics.squatsDone) {
            const map = new Map<BodySideType, Value>();
            map.set(BodySideType.RIGHT, new Value(squatCycle.maxForce));
            this.squatMaxPerRep.push(map);
        }

        // Amplitude
        this.amplitudeAverage = [];
        const avgAmplitude = new Map<BodySideType, Value>();
        avgAmplitude.set(BodySideType.LEFT, new Value(MathUtils.average(statistics.squatsDone.map(s => s.maxAngle))));
        this.amplitudeAverage.push(avgAmplitude);

        this.amplitudeAveragePerRep = new Map<KeyValue<Title, Color>, Value[]>();
        this.amplitudeAveragePerRepLabels = [];
        const amplitudesPerRep: Value[] = [];
        for (let i = 0; i < statistics.squatsDone.length; i++) {
            const squatCycle = statistics.squatsDone[i];
            amplitudesPerRep.push(new Degrees(squatCycle.maxAngle));
            this.amplitudeAveragePerRepLabels.push((i + 1).toString());
        }
        this.amplitudeAveragePerRep.set(KeyValue.of(this.format('charts.axis_amplitude_label', this.unitTypeUtils.title(UnitType.ANGLES)), this.colors.colorAccent), amplitudesPerRep);

        const pacePerRepValues: Value[] = [];
        for (const squatCycle of statistics.squatsDone) {
            pacePerRepValues.push(new Percent(squatCycle.pace));
        }
        this.amplitudeAveragePace = KeyValue.of(this.text('charts_yaxis_label.paces.rep_per_min'), pacePerRepValues);

        // Forces
        this.forcesData = new Map<[BodySideType, UnitType], Sample[]>();
        this.forcesData.set([BodySideType.LEFT, UnitType.KG], SampleUtils.createSampleCollection(statistics.timeStamps, statistics.leftForces));
        this.forcesData.set([BodySideType.RIGHT, UnitType.KG], SampleUtils.createSampleCollection(statistics.timeStamps, statistics.rightForces));
        this.forcesData.set([BodySideType.NONE, UnitType.ANGLES], SampleUtils.createSampleCollection(statistics.timeStamps, statistics.sensAngles));
        // Create Total manually. Should be included in the result model
        this.forcesDataTotal = [];
        for (let i = 0; i < Math.min(statistics.leftForces.length, statistics.rightForces.length, statistics.timeStamps.length); i++) {
            this.forcesDataTotal.push(Sample.of(statistics.timeStamps[i] / 1000, statistics.leftForces[i] + statistics.rightForces[i]));
        }

        // Dynamic cop
        // Create a mock analysis to feed to stance component
        const analysis = new ActivityAnalysisResultDto();
        analysis.activityResultsModel = statistics.stanceResults;
        analysis.config = new ActivityConfigDto();
        analysis.config.activityConfigDevices = [...this.activity.config.activityConfigDevices];

        this.dynamicCopAnalysis = new ActivityAnalysisServiceResponseDto();
        this.dynamicCopAnalysis.activitiesResults = [analysis];

        // Distribution per side
        this.distributionPerSideDataLabels = [
            this.text('advanced_squat_analysis.label.eccentric.title'),
            this.text('report.chartDataType.average'),
            this.text('advanced_squat_analysis.label.concentric.title')];

        this.distributionPerSideData = [];
        const distributionEccentric = new Map<BodySideType, Value>();
        distributionEccentric.set(BodySideType.LEFT, new Percent(squatPhaseAvgData.get(BodySideType.LEFT).bottom.value));
        distributionEccentric.set(BodySideType.RIGHT, new Percent(squatPhaseAvgData.get(BodySideType.RIGHT).bottom.value));
        const distributionConcentric = new Map<BodySideType, Value>();
        distributionConcentric.set(BodySideType.LEFT, new Percent(squatPhaseAvgData.get(BodySideType.LEFT).top.value));
        distributionConcentric.set(BodySideType.RIGHT, new Percent(squatPhaseAvgData.get(BodySideType.RIGHT).top.value));
        const distributionAverage = new Map<BodySideType, Value>();
        distributionAverage.set(BodySideType.LEFT, new Percent(statistics.avgLeftDistribution));
        distributionAverage.set(BodySideType.RIGHT, new Percent(100 - statistics.avgLeftDistribution));
        this.distributionPerSideData.push(distributionEccentric, distributionAverage, distributionConcentric);
    }
}
