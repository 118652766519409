import {Dto} from "common";

export class WomacResultsModel extends Dto {
    public score;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
