import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {ActivityDto} from '../../../dtos/activity.dto';
import {ActivityAnalysisResultDto} from '../../../dtos/statistics/activityAnalysisResultDto';
import {ResultsDirective} from '../resultsDirective';
import {DynamicResultsLoaderService} from '../../../services/dynamic-results-loader.service';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ResultsViewType} from '../resultsViewType';
import {BuiltinProtocols} from "../../../models/builtinProtocols";
import {ResultsComponent} from "../resultsComponent";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ViewMediumType} from "../viewMediumType";
import {KeyValue} from "common";

@Component({
    selector: 'app-generic-protocol-results',
    templateUrl: './protocol-results.component.html',
    styleUrls: ['./protocol-results.component.scss']
})
export class ProtocolResultsComponent extends ResultsComponent implements OnInit, AfterViewInit {
    public builtInProtocols = BuiltinProtocols;

    @Input()
    protocol: ProtocolDto;

    @Input()
    analysis: ProtocolAnalysisServiceResponseDto;

    @ViewChildren(ResultsDirective, {})
    public resultsHosts: QueryList<ResultsDirective>;

    public activityToAnalysisMap: KeyValue<ActivityDto, ActivityAnalysisServiceResponseDto>[];

    public initializedTabs: number[];

    public selectedTabIndex: number;

    constructor(private resultsLoader: DynamicResultsLoaderService,
                private changeDetector: ChangeDetectorRef) {
        super();
        this.selectedTabIndex = 0;
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ProtocolAnalysisServiceResponseDto)) {
            return;
        }

        const protocolAnalysisResultDto = this.analysis.protocolsResults[0];
        const activitiesResults = protocolAnalysisResultDto.activitiesResults.filter(r => r !== undefined);

        // Map activities and their results
        this.mapActivitiesToAnalysisResults(this.protocol.activities, activitiesResults);

        this.initializedTabs = [];
    }

    ngAfterViewInit(): void {
        this.initTab(this.selectedTabIndex);

        // We are updating the view here while angular is validating changes.
        // Let it know we did changes. https://angular.io/errors/NG0100
        this.changeDetector.detectChanges();
    }

    public onTabChanged(tabIndex: number) {
        // Skip summary tab
        if (tabIndex === 0) {
            return;
        }

        this.initTab(tabIndex);
    }

    public initTab(index: number): void {
        const resultsIndex = index - 1; // 0 = summary
        if (this.initializedTabs.includes(resultsIndex)) {
            return;
        }

        if (index === 0) {
            // Initialize summary
        } else {
            // Initialize activity
            let resultsHost = this.resultsHosts.get(resultsIndex);
            const {key: activity, value: analysis} = this.activityToAnalysisMap[resultsIndex];
            this.resultsLoader.forActivity(activity, resultsHost, analysis, ResultsViewType.FULL, ViewMediumType.SCREEN);
        }

        this.initializedTabs.push(resultsIndex);
    }

    private mapActivitiesToAnalysisResults(activities: ActivityDto[], activitiesResults: ActivityAnalysisResultDto[]) {
        this.activityToAnalysisMap = [];
        for (const activity of activities) {
            let result = activitiesResults.find(r => r.activityCode === activity.code);
            const mockServiceResponse = new ActivityAnalysisServiceResponseDto();
            mockServiceResponse.activitiesResults = [result];
            this.activityToAnalysisMap.push(KeyValue.of(activity, mockServiceResponse));
        }
    }
}
