import {UnitType, UnitTypes} from "./unitType";
import {UnitConverter} from "common";

/**
 * A numeric value supported by a unit. Eg 5kg, 3°, 4cm
 */
export class Value {
    public static EMPTY = new Value(undefined);

    constructor(public value: number, public unit: UnitType = UnitType.NONE) {
    }

    public static formatOrEmptyValue(value: Value, fractionDigits: number = 2) {
        if (value) {
            return value.format(fractionDigits)
        }
        return "-";
    }

    /**
     * Formats the value to a unit type. When no unit type, formats to the current one
     */
    public format(fractionDigits: number = 2): string {
        return this.formatImpl(this.unit, fractionDigits);
    }

    public formatTo(unitType?: UnitType, fractionDigits: number = 2): string {
        return this.formatImpl(unitType, fractionDigits);
    }

    private formatImpl(unit: UnitType, fractionDigits: number = 2): string {
        if (!isFinite(this.value)) {
            return '-';
        }

        const symbol = UnitTypes.symbol(unit);
        const convertedValue = Value.convert(this.value, this.unit, unit);
        const valueFormatted = convertedValue.toFixed(fractionDigits);

        let formatted: string;
        switch (unit) {
            case UnitType.NONE:
                formatted = valueFormatted;
                break;
            // Units with leading space
            case UnitType.KG:
            case UnitType.LBS:
            case UnitType.NEWTON:
            case UnitType.CM:
            case UnitType.MM:
            case UnitType.MINUTES:
            case UnitType.PER_MINUTE:
            case UnitType.MM2:
            case UnitType.NEWTON_SECOND:
            case UnitType.NEWTON_PER_SECOND:
            case UnitType.NEWTON_PER_KG:
            case UnitType.M_PER_SECOND:
            case UnitType.MM_PER_SECOND:
            case UnitType.WATT_PER_KG:
            case UnitType.NEWTON_METERS:
                formatted = `${valueFormatted} ${symbol}`;
                break;
            // Units without leading space
            case UnitType.ANGLES:
            case UnitType.PERCENT:
            case UnitType.SECONDS:
            case UnitType.KG_PER_SECOND:
            case UnitType.INCH:
                formatted = `${valueFormatted}${symbol}`;
                break;
            default:
                throw new Error('Cannot format value. Unsupported type');
        }

        return formatted;
    }

    private static convert(value: number, sourceType: UnitType, destType: UnitType): number {
        if (sourceType === destType) {
            return value;
        }

        let converted: number = value;
        if (sourceType === UnitType.KG) {
            if (destType === UnitType.LBS) {
                converted = UnitConverter.kgToLbs(value);
            } else if (destType === UnitType.NEWTON) {
                converted = UnitConverter.kgToNewton(value);
            }
        } else if (sourceType === UnitType.CM) {
            if (destType === UnitType.INCH) {
                converted = UnitConverter.cmToInch(value);
            }
        }

        return converted;
    }
}
