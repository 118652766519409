import {GraphicsType} from "../models/graphicsType";
import {GraphicsShadeType} from "../models/graphicsShadeType";
import {DeviceType, MatIconResource} from "common";

export class Device {
    private static ICONS_PNG: Map<DeviceType, string>;
    private static ICONS_SVG_COLOR: Map<DeviceType, string>;
    private static ICONS_SVG_GREY: Map<DeviceType, string>;
    private static ICONS_SVG_BLACK_WHITE: Map<DeviceType, string>;

    private static initialize = (() => {
        Device.ICONS_PNG = new Map<DeviceType, string>();
        Device.ICONS_PNG.set(DeviceType.GRIP, './assets/icons/deviceGrip_color.png');
        Device.ICONS_PNG.set(DeviceType.MUSCLE_TESTER, './assets/icons/deviceMuscle_color.png');
        Device.ICONS_PNG.set(DeviceType.PLATES, './assets/icons/devicePlates_color.png');
        Device.ICONS_PNG.set(DeviceType.SENS, './assets/icons/deviceSens_color.png');
        Device.ICONS_PNG.set(DeviceType.BUBBLE, './assets/icons/deviceBubble_color.png');
        Device.ICONS_PNG.set(DeviceType.LINK, './assets/icons/deviceLink_color.png');
        Device.ICONS_PNG.set(DeviceType.HEXAS, './assets/icons/deviceDeltas_color.png');

        Device.ICONS_SVG_COLOR = new Map<DeviceType, string>();
        Device.ICONS_SVG_COLOR.set(DeviceType.GRIP, './assets/icons/deviceGrip_color.svg');
        Device.ICONS_SVG_COLOR.set(DeviceType.MUSCLE_TESTER, './assets/icons/deviceMuscle_color.svg');
        Device.ICONS_SVG_COLOR.set(DeviceType.PLATES, './assets/icons/devicePlates_color.svg');
        Device.ICONS_SVG_COLOR.set(DeviceType.SENS, './assets/icons/deviceSens_color.svg');
        Device.ICONS_SVG_COLOR.set(DeviceType.BUBBLE, './assets/icons/deviceBubble_color.svg');
        Device.ICONS_SVG_COLOR.set(DeviceType.LINK, './assets/icons/deviceLink_color.svg');
        Device.ICONS_SVG_COLOR.set(DeviceType.HEXAS, './assets/icons/deviceDeltas_color.svg');

        Device.ICONS_SVG_GREY = new Map<DeviceType, string>();
        Device.ICONS_SVG_GREY.set(DeviceType.GRIP, './assets/icons/deviceGrip_grey.svg');
        Device.ICONS_SVG_GREY.set(DeviceType.MUSCLE_TESTER, './assets/icons/deviceMuscle_grey.svg');
        Device.ICONS_SVG_GREY.set(DeviceType.PLATES, './assets/icons/devicePlates_grey.svg');
        Device.ICONS_SVG_GREY.set(DeviceType.SENS, './assets/icons/deviceSens_grey.svg');
        Device.ICONS_SVG_GREY.set(DeviceType.BUBBLE, './assets/icons/deviceBubble_grey.svg');
        Device.ICONS_SVG_GREY.set(DeviceType.LINK, './assets/icons/deviceLink_grey.svg');
        Device.ICONS_SVG_GREY.set(DeviceType.HEXAS, './assets/icons/deviceDeltas_grey.svg');

        Device.ICONS_SVG_BLACK_WHITE = new Map<DeviceType, string>();
        Device.ICONS_SVG_BLACK_WHITE.set(DeviceType.GRIP, './assets/icons/deviceGrip_monochrome.svg');
        Device.ICONS_SVG_BLACK_WHITE.set(DeviceType.MUSCLE_TESTER, './assets/icons/deviceMuscle_monochrome.svg');
        Device.ICONS_SVG_BLACK_WHITE.set(DeviceType.PLATES, './assets/icons/devicePlates_monochrome.svg');
        Device.ICONS_SVG_BLACK_WHITE.set(DeviceType.SENS, './assets/icons/deviceSens_monochrome.svg');
        Device.ICONS_SVG_BLACK_WHITE.set(DeviceType.BUBBLE, './assets/icons/deviceBubble_monochrome.svg');
        Device.ICONS_SVG_BLACK_WHITE.set(DeviceType.LINK, './assets/icons/deviceLink_monochrome.svg');
        Device.ICONS_SVG_BLACK_WHITE.set(DeviceType.HEXAS, './assets/icons/deviceDeltas_monochrome.svg');
    })();

    constructor(public readonly type: DeviceType) {
    }

    public static of(type: DeviceType): Device {
        return new Device(type);
    }

    public getIcon(graphicsType: GraphicsType = GraphicsType.PNG, shade: GraphicsShadeType = GraphicsShadeType.COLOR): string {
        return Device.icon(this.type, graphicsType, shade);
    }

    public get title(): string {
        return Device.title(this.type);
    }

    public static icon(device: DeviceType, type: GraphicsType = GraphicsType.PNG, shade: GraphicsShadeType = GraphicsShadeType.COLOR): string {
        let icon = '';
        if (type === GraphicsType.PNG) {
            icon = this.ICONS_PNG.get(device);
        } else if (type === GraphicsType.SVG) {
            if (shade === GraphicsShadeType.COLOR) {
                icon = this.ICONS_SVG_COLOR.get(device);
            } else if (shade === GraphicsShadeType.GREY) {
                icon = this.ICONS_SVG_GREY.get(device);
            } else if (shade === GraphicsShadeType.BLACK_WHITE) {
                icon = this.ICONS_SVG_BLACK_WHITE.get(device);
            }
        }
        return icon;
    }

    public static title(device: DeviceType): string {
        let title: string;
        switch (device) {
            case DeviceType.GRIP:
                title = 'Grip';
                break;
            case DeviceType.MUSCLE_TESTER:
                title = 'Muscle';
                break;
            case DeviceType.PLATES:
                title = 'Plates';
                break;
            case DeviceType.SENS:
                title = 'Sens';
                break;
            case DeviceType.BUBBLE:
                title = 'Bubble';
                break;
            case DeviceType.LINK:
                title = 'Link';
                break;
            case DeviceType.HEXAS:
                title = 'Deltas';
                break;
        }
        return title;
    }

    public static matIcon(type: DeviceType, shadeType: GraphicsShadeType): MatIconResource {
        return `sensor${DeviceType[type].toString().toLowerCase()}${GraphicsShadeType[shadeType].toString().toLowerCase()}`;
    }
}
