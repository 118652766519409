import {Component, Input, OnInit} from '@angular/core';
import {Label} from "ng2-charts";
import {ChartDataSets, ChartOptions} from "chart.js";
import {Biomechanics} from "../../../utils/biomechanics";
import * as pluginDoughnutLabel from "chartjs-plugin-doughnutlabel"
import {BodySideType, BodySideTypes} from "../../../models/bodySideType";
import {BaseComponent} from "../../base/base.component";
import {Value} from "../../../utils/values/value";
import {UnitType} from "../../../utils/values/unitType";
import {Percent} from "../../../utils/values/percent";

@Component({
    selector: 'app-max-per-side-chart',
    templateUrl: './max-per-side-chart.component.html',
    styleUrls: ['./max-per-side-chart.component.scss']
})
export class MaxPerSideChartComponent extends BaseComponent implements OnInit {

    @Input()
    public maxPerSide: Map<BodySideType, Value>;

    @Input()
    public chartTitle?: String;

    @Input()
    public enforcedUnitType?: UnitType;


    // Doughnut
    public chartLabels: Label[];
    public datasets: ChartDataSets[];
    public chartPlugins: any[];
    public chartOptions: ChartOptions;

    // Side values
    public leftSideTitle: string;
    public leftSideValue: string;
    public rightSideTitle: string;
    public rightSideValue: string;

    constructor() {
        super();
        this.chartPlugins = [pluginDoughnutLabel];
    }

    ngOnInit(): void {
        // Doughnut is clockwise starting from midnight, when two values the first is displayed on the right.

        // chart labels. If both sides, first is the right
        if (this.maxPerSide.has(BodySideType.LEFT) && this.maxPerSide.has(BodySideType.RIGHT)) {
            this.chartLabels = [BodySideTypes.format(BodySideType.RIGHT), BodySideTypes.format(BodySideType.LEFT)];
        } else {
            this.chartLabels = [];
            for (const side of this.maxPerSide.keys()) {
                this.chartLabels.push(BodySideTypes.format(side));
            }
        }

        let doughnutCenterLabel = '';
        if (this.maxPerSide.has(BodySideType.LEFT) && this.maxPerSide.has(BodySideType.RIGHT)) {
            let deficit = Biomechanics.deficit(this.maxPerSide.get(BodySideType.LEFT), this.maxPerSide.get(BodySideType.RIGHT));
            if (!deficit ||isNaN(deficit.value)) {
                deficit = new Percent(0);
            }
            doughnutCenterLabel = `Deficit ${this.formatValue(deficit, 1)}`;
        } else if (this.maxPerSide.has(BodySideType.LEFT)) {
            doughnutCenterLabel = `  ${this.formatValue(this.maxPerSide.get(BodySideType.LEFT), 1)}  `;
        } else if (this.maxPerSide.has(BodySideType.RIGHT)) {
            doughnutCenterLabel = `  ${this.formatValue(this.maxPerSide.get(BodySideType.RIGHT), 1)}  `;
        }

        // Datasets
        const datasetData = [];
        // When left & right first add right because doughnut is clockwise starting at midnight
        if (this.maxPerSide.has(BodySideType.LEFT) && this.maxPerSide.has(BodySideType.RIGHT)) {
            datasetData.push(this.maxPerSide.get(BodySideType.RIGHT).value.toPrecision(3));
            datasetData.push(this.maxPerSide.get(BodySideType.LEFT).value.toPrecision(3));
        } else {
            for (let value of this.maxPerSide.values()) {
                datasetData.push(value.value.toPrecision(3));
            }
        }

        this.datasets = [{
            data: datasetData,
            borderWidth: 4,
        }];
        const backgroundColors = [];
        if (this.maxPerSide.has(BodySideType.RIGHT)) {
            backgroundColors.push(BodySideTypes.color(BodySideType.RIGHT));
        }
        if (this.maxPerSide.has(BodySideType.LEFT)) {
            backgroundColors.push(BodySideTypes.color(BodySideType.LEFT));
        }
        if (!this.maxPerSide.has(BodySideType.LEFT) && !this.maxPerSide.has(BodySideType.RIGHT)) {
            backgroundColors.push(BodySideTypes.color(BodySideType.RIGHT));
        }
        this.datasets[0].backgroundColor = backgroundColors;

        // Options
        this.chartOptions = {
            animation: {
                duration: 0,
                animateScale: false,
                animateRotate: false
            },
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1,
            legend: {
                display: false
            },
            devicePixelRatio: 2,
            plugins: {
                doughnutlabel: {
                    labels: [
                        {
                            text: doughnutCenterLabel,
                            font: {
                                size: '24'
                            },
                            color: 'black'
                        }
                    ]
                }
            }
        };

        // Side values
        if (this.maxPerSide.has(BodySideType.LEFT) && this.maxPerSide.has(BodySideType.RIGHT)) {
            this.leftSideTitle = BodySideTypes.format(BodySideType.LEFT);
            this.leftSideValue = this.enforcedUnitType ? this.maxPerSide.get(BodySideType.LEFT).formatTo(this.enforcedUnitType, 1) : this.formatValue(this.maxPerSide.get(BodySideType.LEFT), 1);
            this.rightSideTitle = BodySideTypes.format(BodySideType.RIGHT);
            this.rightSideValue = this.enforcedUnitType ? this.maxPerSide.get(BodySideType.RIGHT).formatTo(this.enforcedUnitType, 1) : this.formatValue(this.maxPerSide.get(BodySideType.RIGHT), 1);
        }
    }
}
