import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Point} from '../../../models/point';
import {Rect} from '../../../models/rect';
import {forkJoin} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Entry} from '../../../dtos/statistics/entry';
import {StanceOval} from '../../../dtos/statistics/stanceOval';
import PlatePhysicalDevice from '../../../models/platePhysicalDevice';
import {DeltasPhysicalDevice} from '../../../models/deltasPhysicalDevice';
import {BodySideType, BodySideTypes} from '../../../models/bodySideType';
import {PhysicalDevices} from '../../../models/physicalDevices';
import ResizeObserver from 'resize-observer-polyfill';
import {BaseComponent} from "../../base/base.component";
import {Value} from "../../../utils/values/value";
import {Drawing} from "../../../utils/graphics/drawing";
import {Colors} from "../../../services/colors";
import {ArrowDrawingParams} from "../../../utils/graphics/arrowDrawingParams";
import {TextDrawingParams} from "../../../utils/graphics/textDrawingParams";
import {DeviceType, KeyValue, RxUtils, UnitConverter} from "common";

@Component({
    selector: 'app-stance-indicator',
    templateUrl: './stance-indicator.component.html',
    styleUrls: ['./stance-indicator.component.scss']
})
export class StanceIndicatorComponent extends BaseComponent implements OnInit {
    // Canvas is scaled up before drawing, this makes result to be crystal clear, otherwise is blurry.
    // Bigger is better but more expensive
    private readonly scaleFactor = 2;

    @Input()
    public device: DeviceType.PLATES | DeviceType.HEXAS;
    @Input()
    public drawSensors: boolean = true;
    @Input()
    public sides: BodySideType.LEFT | BodySideType.RIGHT | BodySideType.BOTH;

    // When used with zoomToEllipse overlaps all points to each other
    @Input()
    public relative: boolean = false;

    // Cop points
    @Input()
    public copPoints: Entry[] = [];
    @Input()
    public leftCopPoints: Entry[] = [];
    @Input()
    public rightCopPoints: Entry[] = [];

    // Ovals
    @Input()
    public oval: StanceOval;
    @Input()
    public leftOval: StanceOval;
    @Input()
    public rightOval: StanceOval;

    // Deviations
    @Input()
    public lateralDeviation: Value;
    @Input()
    public longitudinalDeviation: Value;
    @Input()
    public leftLateralDeviation: Value;
    @Input()
    public leftLongitudinalDeviation: Value;
    @Input()
    public rightLateralDeviation: Value;
    @Input()
    public rightLongitudinalDeviation: Value;

    // Params
    @Input()
    public zoomToEllipse: boolean;
    @Input()
    public rotateLeft: boolean;

    @ViewChild('canvas', {static: true})
    canvasElement: ElementRef<HTMLCanvasElement>;
    private canvas: Rect;
    private ctx: CanvasRenderingContext2D;

    @ViewChild('leftFootIcon', {static: true})
    leftFootIcon: ElementRef<HTMLImageElement>;

    @ViewChild('rightFootIcon', {static: true})
    rightFootIcon: ElementRef<HTMLImageElement>;

    @ViewChild('kinventLogo', {static: true})
    kinventLogo: ElementRef<HTMLImageElement>;

    private plateArea: Rect; // Where the plates area is drawn in abs coords
    private zoomLevel: number;
    private canvasZoomTranform: DOMMatrix;
    private zoomPoint: Point;

    public ovalToDraw: StanceOval;
    public leftOvalToDraw: StanceOval;
    public rightOvalToDraw: StanceOval;
    public copPointsToDraw: Entry[];
    public leftCopPointsToDraw: Entry[];
    public rightCopPointsToDraw: Entry[];

    constructor(private host: ElementRef) {
        super();

        this.zoomLevel = 1;
    }

    ngOnInit(): void {
        // Handle resizing of component to trigger redraw
        const observer = new ResizeObserver(entries => {
            this.initDrawing();
            this.draw();
        });
        observer.observe(this.host.nativeElement);

        this.initDrawing();
        // to completely draw the footCanvas, all images need to be loaded. Draw it initially and redraw it once all is ready
        this.draw();
        forkJoin([RxUtils.onImageLoad(this.leftFootIcon.nativeElement), RxUtils.onImageLoad(this.rightFootIcon.nativeElement), RxUtils.onImageLoad(this.kinventLogo.nativeElement)])
            .pipe(takeUntil(this.destroySubject))
            .subscribe(() => {
                this.draw();
            }, error => {
                this.notification.info(`Could not load stance graph`);
                console.error(error);
            });
    }

    /**
     * Initializing the drawing. Should be called once
     */
    private initDrawing(): void {
        this.ctx = this.canvasElement.nativeElement.getContext('2d');
        let {width, height} = this.canvasElement.nativeElement.getBoundingClientRect();

        // set canvas scale
        width *= this.scaleFactor;
        height *= this.scaleFactor;

        // Create canvas
        this.canvas = new Rect(0, 0, width, height);

        // set canvas dimensions same as the dom element
        this.canvasElement.nativeElement.width = width;
        this.canvasElement.nativeElement.height = height;

        const ctx = this.ctx;

        // Start drawing
        ctx.save();

        // Calc plate grid area. When not zooming preserve some space on left and bottom for the axis
        let gridArea: Rect;
        if (!this.zoomToEllipse && !this.relative) {
            gridArea = Rect.fromPoints(Point.of(0.1, .005), Point.of(.995, .9));
        } else {
            gridArea = Rect.fromPoints(Point.of(0, 0), Point.of(1, 1));
        }

        // Calc the area within the canvas where the plateArea will be
        const gridAreaRelative = this.relativeRect(gridArea);
        let plateFittedArea: Rect;
        if (this.sides === BodySideType.BOTH) {
            plateFittedArea = Drawing.getFittedSquare(gridAreaRelative);
        } else {
            let singlePlateArea = new Rect(gridAreaRelative.top, gridAreaRelative.left, gridAreaRelative.width / 2, gridAreaRelative.height);
            plateFittedArea = Drawing.getFittedRectangle(gridAreaRelative, singlePlateArea);
        }
        this.plateArea = Drawing.center(plateFittedArea, gridAreaRelative);

        this.ovalToDraw = this.oval;
        this.copPointsToDraw = this.copPoints;
        this.leftOvalToDraw = this.leftOval;
        this.leftCopPointsToDraw = this.leftCopPoints;
        this.rightOvalToDraw = this.rightOval;
        this.rightCopPointsToDraw = this.rightCopPoints;

        // Zoom to ellipse
        if (this.zoomToEllipse && !this.relative) {
            // Get the position rect in percentage on the actual device
            let oval: Rect = this.ovalRectPercentage(this.oval, this.physicalDevice);

            this.zoomPoint = new Point(oval.left, oval.top);
            // when rotating the target point changes, move to that one
            if (this.rotateLeft) {
                this.zoomPoint = new Point(this.zoomPoint.y, 1 - this.zoomPoint.x);
            }

            // Calculate zoom level
            // The size of the oval on the canvas
            const ovalRelative = this.relativeRect(oval, this.plateArea);
            this.zoomLevel = this.plateArea.width * .25 / Math.max(ovalRelative.width, ovalRelative.height);

            this.applyZoom(this.zoomLevel, this.zoomPoint);
        } else if (this.relative && this.zoomToEllipse) {
            // Move all ovals and coop points to the center of the sensors
            const mainMoved = this.moveOvalAndCoopPointsToCenter(this.oval, this.copPoints);
            const leftMoved = this.moveOvalAndCoopPointsToCenter(this.leftOval, this.leftCopPoints);
            const rightMoved = this.moveOvalAndCoopPointsToCenter(this.rightOval, this.rightCopPoints);
            this.ovalToDraw = mainMoved?.key;
            this.copPointsToDraw = mainMoved?.value;
            this.leftOvalToDraw = leftMoved?.key;
            this.leftCopPointsToDraw = leftMoved?.value;
            this.rightOvalToDraw = rightMoved?.key;
            this.rightCopPointsToDraw = rightMoved?.value;

            // max oval width and height. The area where we need to zoom
            const maxOvalWidth = Math.max(this.leftOvalToDraw?.width || 0, this.rightOvalToDraw?.width || 0);
            const maxOvalHeight = Math.max(this.leftOvalToDraw?.height || 0, this.rightOvalToDraw?.height || 0);

            // Calc the offset from the center of the plates where the oval is, this is the offset that we need to translate
            this.zoomPoint = new Point(.5, .5);

            this.zoomLevel = Math.max(1, this.plateArea.width * .35 / Math.max(maxOvalWidth, maxOvalHeight));

            this.applyZoom(this.zoomLevel, this.zoomPoint);
        }
    }

    /**
     * Applies a zoom level. 1 = normal, 0 = zoomed out, positive numbers = zoom in
     */
    private applyZoom(zoomLevel: number, zoomPoint: Point): void {
        // area after zooming
        const zoomedArea = Rect.scale(this.canvas, zoomLevel);

        // Calc the offset from the center of the plates where the oval is, this is the offset that we need to translate
        let targetPointX = zoomPoint.x * this.plateArea.width + this.plateArea.left - this.plateArea.center.x;
        let targetPointY = zoomPoint.y * this.plateArea.height + this.plateArea.top - this.plateArea.center.y;
        // target offset from plates center
        const targetPointRelativeToPlateAreaCenter = Point.of(targetPointX, targetPointY);

        // zoom and translate canvas
        this.ctx.setTransform(zoomLevel, 0, 0, zoomLevel, -targetPointRelativeToPlateAreaCenter.x * zoomLevel + zoomedArea.left, -targetPointRelativeToPlateAreaCenter.y * zoomLevel + zoomedArea.top);
        this.canvasZoomTranform = this.ctx.getTransform();
    }

    private draw(): void {
        const ctx = this.ctx;

        ctx.save();

        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        ctx.setTransform(this.canvasZoomTranform);

        // Sensors
        if (this.drawSensors) {
            this.drawPlateCanvas();
        } else {
            // draw a rect around canvas
            this.drawOutline();
        }

        this.drawCopPath(this.leftCopPointsToDraw, Colors.colorAccent);
        this.drawCopPath(this.rightCopPointsToDraw, Colors.colorPrimary);
        this.drawCopPath(this.copPointsToDraw, 'black');

        // Draw ovals. Convert percent to relative to the grid area. The ovalRelative is not a rect, but the units are converted proportionally correctly
        if (this.ovalToDraw) {
            const ovalRelative = this.relativeRect(this.ovalRectPercentage(this.ovalToDraw, this.physicalDevice), this.plateArea);
            this.drawOval(ovalRelative, Colors.RED, 1);
        }
        if (this.leftOvalToDraw) {
            const ovalRelative = this.relativeRect(this.ovalRectPercentage(this.leftOvalToDraw, this.physicalDevice), this.plateArea);
            this.drawOval(ovalRelative, BodySideTypes.color(BodySideType.LEFT), 3);
        }
        if (this.rightOvalToDraw) {
            const ovalRelative = this.relativeRect(this.ovalRectPercentage(this.rightOvalToDraw, this.physicalDevice), this.plateArea);
            this.drawOval(ovalRelative, BodySideTypes.color(BodySideType.RIGHT), 3);
        }

        // end drawing
        ctx.restore();

        // Draw axis independently of zoom
        this.drawAxis();
    }

    /**
     * Converts a percentage point to a relative point
     */
    private relativePoint(point: Point, targetRect?: Rect): Point {
        if (targetRect) {
            return new Point(targetRect.left + targetRect.width * point.x, targetRect.top + targetRect.height * point.y);
        } else {
            return new Point(point.x * this.canvas.width, point.y * this.canvas.height);
        }
    }

    /**
     * Converts a percentage rect to a relative rect
     */
    private relativeRect(rect: Rect, targetRect?: Rect): Rect {
        if (targetRect) {
            return new Rect(targetRect.top + targetRect.height * rect.top,
                targetRect.left + targetRect.width * rect.left,
                rect.width * targetRect.width,
                rect.height * targetRect.height);
        } else {
            return new Rect(rect.top * this.canvas.height, rect.left * this.canvas.width,
                rect.width * this.canvas.width, rect.height * this.canvas.height);
        }
    }

    /**
     * Does the opposite from relative Rect. From a rect on dimensions, returns a rect of percentages
     */
    private percentageRect(rect: Rect): Rect {
        return new Rect(rect.top / this.canvas.height, rect.left / this.canvas.width,
            rect.width / this.canvas.width, rect.height / this.canvas.height);
    }

    private get physicalDevice(): PlatePhysicalDevice | DeltasPhysicalDevice {
        const physicalDevice = PhysicalDevices.forDevice(this.device);
        if (!(physicalDevice instanceof PlatePhysicalDevice || physicalDevice instanceof DeltasPhysicalDevice)) {
            throw new Error('Unsupported device');
        }
        return physicalDevice;
    }

    private drawArrow(startPercent: Point, endPercent: Point) {
        const params = new ArrowDrawingParams()
            .setPoints(this.relativePoint(startPercent), this.relativePoint(endPercent))
            .setTipSize(this.scaleSize(14))
            .setLineWidth(this.scaleSize(1))
            .setColor('black');

        Drawing.drawArrow(this.ctx, params);
    }

    private drawBidirectionalArrow(startPercent: Point, endPercent: Point, color: string) {
        const params = new ArrowDrawingParams()
            .setPoints(this.relativePoint(startPercent), this.relativePoint(endPercent))
            .setTipSize(this.scaleSize(12))
            .setLineWidth(this.scaleSize(1))
            .setColor(color)
            .setTipOnStart(true)
            .setTipOnEnd(true);

        Drawing.drawArrow(this.ctx, params);
    }


    /**
     * Draws the plate canvas
     */
    private drawPlateCanvas(): void {
        let ctx = this.ctx;
        let gridArea = this.plateArea;

        ctx.save();

        // Rotate
        if (this.rotateLeft && this.sides === BodySideType.BOTH) {
            Drawing.rotateAroundPoint(ctx, this.plateArea.center, UnitConverter.degreeToRad(-90));
        }

        // border
        ctx.globalAlpha = .3;
        ctx.strokeStyle = 'black';
        ctx.lineWidth = this.scaleSize(2);
        ctx.strokeRect(gridArea.left, gridArea.top, gridArea.width, gridArea.height);

        // checkers
        ctx.globalAlpha = .25;
        ctx.strokeStyle = Colors.colorAccent;
        ctx.lineWidth = this.scaleSize(1);
        const tenPercentSize = gridArea.width / 10;
        const horizontalLines = 9;
        const verticalLines = this.sides === BodySideType.BOTH ? 10 : 5;

        Drawing.drawHorizontalLines(ctx, gridArea, horizontalLines);
        Drawing.drawVerticalLines(ctx, gridArea, verticalLines);

        // axis over sensor
        ctx.globalAlpha = .3;
        ctx.strokeStyle = 'black';
        ctx.lineWidth = this.scaleSize(1);
        ctx.beginPath();
        if (this.sides === BodySideType.BOTH) {
            Drawing.drawHorizontalLines(ctx, gridArea, 1);
            Drawing.drawVerticalLines(ctx, gridArea, 1);
        } else if (this.sides === BodySideType.LEFT || this.sides === BodySideType.RIGHT) {
            Drawing.drawHorizontalLines(ctx, gridArea, 1);
        }
        ctx.stroke();

        // Feet
        this.ctx.globalAlpha = .08;
        if (this.sides === BodySideType.BOTH) {
            this.ctx.drawImage(this.leftFootIcon.nativeElement, gridArea.left, gridArea.top, gridArea.width / 2, gridArea.height);
            this.ctx.drawImage(this.rightFootIcon.nativeElement, gridArea.left + gridArea.width / 2, gridArea.top, gridArea.width / 2, gridArea.height);
        } else if (this.sides === BodySideType.LEFT) {
            this.ctx.drawImage(this.leftFootIcon.nativeElement, gridArea.left, gridArea.top, gridArea.width, gridArea.height);
        } else if (this.sides === BodySideType.RIGHT) {
            this.ctx.drawImage(this.rightFootIcon.nativeElement, gridArea.left, gridArea.top, gridArea.width, gridArea.height);
        }

        // Logo
        let logoRect = Rect.zero();
        if (this.sides === BodySideType.BOTH) {
            logoRect = new Rect(gridArea.top + gridArea.height * .9, gridArea.left + gridArea.width * .9, tenPercentSize * .8, tenPercentSize * .8);
        } else if (this.sides === BodySideType.LEFT || this.sides === BodySideType.RIGHT) {
            logoRect = new Rect(gridArea.top + gridArea.height * .9, gridArea.left + gridArea.width * .8, tenPercentSize * 1.6, tenPercentSize * 1.6);
        }
        this.ctx.globalAlpha = .45;
        this.ctx.drawImage(this.kinventLogo.nativeElement, logoRect.left, logoRect.top, logoRect.width, logoRect.height);

        ctx.restore();
    }

    /**
     * Scales all sizes according to the canvas scale
     */
    private scaleSize(size: number): number {
        return this.scaleFactor * size;
    }

    private drawCopPath(copPoints: Entry[], strokeStyle: string) {
        if (!copPoints || copPoints.length < 2) {
            return;
        }

        let ctx = this.ctx;
        ctx.save();

        if (this.rotateLeft) {
            Drawing.rotateAroundPoint(ctx, this.plateArea.center, UnitConverter.degreeToRad(-90));
        }

        ctx.globalAlpha = 1;
        ctx.lineWidth = this.scaleSize(.8 / this.zoomLevel);
        ctx.strokeStyle = strokeStyle;
        ctx.beginPath();
        const firstPoint = this.relativePoint(Point.of(copPoints[0].x, copPoints[0].y), this.plateArea);
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (let i = 1; i < copPoints.length; i++) {
            const entry = copPoints[i];
            const point = this.relativePoint(Point.of(entry.x, entry.y), this.plateArea);
            ctx.lineTo(point.x, point.y);
        }
        ctx.stroke();
        ctx.restore();
    }

    /**
     * Draws an oval described by a Rect. Inscribed ellipse in the rect
     */
    private drawOval(oval: Rect, strokeStyle: string, lineWidth: number) {
        let ctx = this.ctx;

        ctx.save();
        if (this.rotateLeft) {
            Drawing.rotateAroundPoint(ctx, this.plateArea.center, UnitConverter.degreeToRad(-90));
        }

        ctx.beginPath();
        ctx.strokeStyle = strokeStyle;

        ctx.lineWidth = this.scaleSize(lineWidth / this.zoomLevel);
        ctx.ellipse(oval.left, oval.top, oval.width, oval.height, 0, 0, Math.PI * 2);
        ctx.stroke();
        ctx.restore();
    }

    /**
     * Returns in percentage the oval position on a device.
     * @return a rect where left/top is the center of the oval and width,height are the radius on each axis
     */
    private ovalRectPercentage(oval: StanceOval, device: PlatePhysicalDevice | DeltasPhysicalDevice): Rect {
        // oval.cX and oval.cY is mm from the bottom/left of the plates and center for the deltas.
        // convert it to relative to grid and from the top/left

        const totalWidth = device.width * 2;

        // Offset from reference point
        let offsetXPercent = oval.cX / totalWidth;
        const offsetYPercent = oval.cY / device.height;

        let ovalCxPercent: number;
        let ovalCyPercent: number;
        if (device instanceof PlatePhysicalDevice) {
            ovalCxPercent = oval.cX / totalWidth;
            ovalCyPercent = ovalCyPercent = 1 - oval.cY / device.height;
        } else if (device instanceof DeltasPhysicalDevice) {
            if (this.sides === BodySideType.BOTH) {
                if (!this.relative) {
                    ovalCxPercent = oval.cX / totalWidth + .5;
                    ovalCyPercent = ovalCyPercent = 1 - oval.cY / device.height - .5;
                } else {
                    ovalCxPercent = offsetXPercent;
                    ovalCyPercent = offsetYPercent;
                }
            } else {
                ovalCxPercent = offsetXPercent + 1;
                ovalCyPercent = offsetYPercent + .5;
            }
        }

        // convert oval width-diameter to radius in percent
        const ovalRwPercent = (oval.width / totalWidth) / 2;
        const ovalRhPercent = (oval.height / device.height) / 2;
        return new Rect(ovalCyPercent, ovalCxPercent, ovalRwPercent, ovalRhPercent);
    }

    /**
     * Moves oval and cop points to center. Original cop points aren't altered, the translated copy is returned
     */
    private moveOvalAndCoopPointsToCenter(oval: StanceOval, copPoints: Entry[]): KeyValue<StanceOval, Entry[]> {
        if (!oval || !copPoints || copPoints.length === 0) {
            return;
        }

        // oval in percentage
        const origOvalPercent = this.ovalRectPercentage(oval, this.physicalDevice);
        // translate oval to the center of two physical devices
        const translatedOval = oval.clone();
        translatedOval.cX = this.physicalDevice.width;
        translatedOval.cY = this.physicalDevice.height * .5;
        // oval percentage on the new position
        const translatedOvalPercent = this.ovalRectPercentage(translatedOval, this.physicalDevice);
        // offset of the oval
        let offsetX: number;
        let offsetY: number;
        if (this.device === DeviceType.PLATES) {
            offsetX = translatedOvalPercent.left - origOvalPercent.left;
            offsetY = translatedOvalPercent.top - origOvalPercent.top;
        } else if (this.device === DeviceType.HEXAS) {
            offsetX = -origOvalPercent.left;
            offsetY = translatedOvalPercent.top - origOvalPercent.top - .5;
        }

        const translatedCopPonts = copPoints.map((value, index, array) => {
            return Entry.of(value.x + offsetX, value.y + offsetY);
        });
        return KeyValue.of(translatedOval, translatedCopPonts);
    }

    private drawAxis() {
        let ctx = this.ctx;

        // theming
        const fontStyle = `${this.scaleSize(.9)}em sans-serif`;
        const color = 'black';
        const textParams = new TextDrawingParams().setFontStyle(fontStyle).setColor(color);

        const lateralText = this.text('standingEvaluation.mean.lateral.title');
        const longitudinalText = this.text('standingEvaluation.mean.longitudinal.title');
        const textHeight = Drawing.measureTextHeight(ctx, lateralText, fontStyle);


        if (!this.zoomToEllipse) {
            const horizontalMarginAxisToLabel = .05;
            const verticalMarginAxisToLabel = .05;

            const plateAreaPercent = this.percentageRect(this.plateArea);

            // margins from plate area
            const marginLeft = plateAreaPercent.left - .035;
            const marginBottom = plateAreaPercent.bottom + .03;

            this.drawArrow(Point.of(marginLeft, marginBottom), Point.of(plateAreaPercent.right, marginBottom));
            this.drawArrow(Point.of(marginLeft, marginBottom), Point.of(marginLeft, plateAreaPercent.top));

            const lateralTextPoint = this.relativePoint(Point.of(plateAreaPercent.center.x, marginBottom + horizontalMarginAxisToLabel));
            const longitudinalTextPoint = this.relativePoint(Point.of(marginLeft - verticalMarginAxisToLabel, plateAreaPercent.center.y));

            Drawing.drawText(ctx, textParams.setText(lateralText).setPoint(lateralTextPoint));
            Drawing.drawText(ctx, textParams.setText(longitudinalText).setPoint(longitudinalTextPoint).setRotationRad(UnitConverter.degreeToRad(90)));
        } else {
            const arrowsPath = new Rect(.15, .15, .7, .7);
            const arrowsMargin = .1;
            const arrowToTextMargin = .01;

            const leftSideColor = BodySideTypes.color(BodySideType.LEFT);
            const rightSideColor = BodySideTypes.color(BodySideType.RIGHT);

            // top arrow and text
            if (this.leftLateralDeviation) {

                this.drawBidirectionalArrow(Point.of(arrowsPath.left, arrowsPath.top + arrowsMargin), Point.of(arrowsPath.left, arrowsPath.bottom - arrowsMargin), leftSideColor);
                let textPoint = this.relativePoint(Point.of(arrowsPath.center.x, arrowsPath.top - arrowToTextMargin));
                Drawing.drawText(ctx, textParams.setText(this.leftLateralDeviation.format(2)).setPoint(textPoint).setColor(leftSideColor));
                textPoint = new Point(textPoint.x, textPoint.y - textHeight);
                Drawing.drawText(ctx, textParams.setText(lateralText).setPoint(textPoint));
            }
            // left arrow and text
            if (this.leftLongitudinalDeviation) {
                this.drawBidirectionalArrow(Point.of(arrowsPath.left + arrowsMargin, arrowsPath.top), Point.of(arrowsPath.right - arrowsMargin, arrowsPath.top), leftSideColor);
                let textPoint = this.relativePoint(Point.of(arrowsPath.left - arrowToTextMargin, arrowsPath.center.y));
                textPoint = new Point(textPoint.x - textHeight * .7, textPoint.y);
                Drawing.drawText(ctx, textParams.setText(longitudinalText).setPoint(textPoint).setRotationRad(UnitConverter.degreeToRad(90)));
                textPoint = new Point(textPoint.x - textHeight, textPoint.y);
                Drawing.drawText(ctx, textParams.setText(this.leftLongitudinalDeviation.format(2)).setPoint(textPoint).setRotationRad(UnitConverter.degreeToRad(90)));
            }

            const bottomRightColor = this.rightLateralDeviation && this.rightLongitudinalDeviation ? rightSideColor : 'black';

            // bottom arrow and text
            const bottomValue = this.rightLateralDeviation || this.lateralDeviation;
            if (bottomValue) {
                this.drawBidirectionalArrow(Point.of(arrowsPath.left + arrowsMargin, arrowsPath.bottom), Point.of(arrowsPath.right - arrowsMargin, arrowsPath.bottom), bottomRightColor);
                let textPoint = this.relativePoint(Point.of(arrowsPath.center.x, arrowsPath.bottom));
                textPoint = new Point(textPoint.x, textPoint.y + textHeight * .85);
                Drawing.drawText(ctx, textParams.setText(lateralText).setPoint(textPoint).setRotationRad(0).setColor(bottomRightColor));
                textPoint = new Point(textPoint.x, textPoint.y + textHeight);
                Drawing.drawText(ctx, textParams.setText(bottomValue.format(2)).setPoint(textPoint));
            }

            // right arrow and text
            const rightValue = this.rightLongitudinalDeviation || this.longitudinalDeviation;
            if (rightValue) {
                this.drawBidirectionalArrow(Point.of(arrowsPath.right, arrowsPath.top + arrowsMargin), Point.of(arrowsPath.right, arrowsPath.bottom - arrowsMargin), bottomRightColor);
                let textPoint = this.relativePoint(Point.of(arrowsPath.right, arrowsPath.center.y));
                textPoint = new Point(textPoint.x + textHeight * .2, textPoint.y);
                Drawing.drawText(ctx, textParams.setText(rightValue.format(2)).setPoint(textPoint).setRotationRad(UnitConverter.degreeToRad(90)));
                textPoint = new Point(textPoint.x + textHeight, textPoint.y);
                Drawing.drawText(ctx, textParams.setText(longitudinalText).setPoint(textPoint).setRotationRad(UnitConverter.degreeToRad(90)));
            }
        }
    }

    /**
     * Draw an outline on the area of drawing
     */
    private drawOutline() {
        let transform = this.ctx.getTransform();
        this.ctx.resetTransform();
        this.ctx.save();
        this.ctx.strokeStyle = 'black';
        this.ctx.lineWidth = this.scaleSize(1);
        this.ctx.strokeRect(this.plateArea.left, this.plateArea.top, this.plateArea.width, this.plateArea.height);
        this.ctx.restore();
        this.ctx.setTransform(transform);
    }
}

