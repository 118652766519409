import {Dto} from "common";

export class FrequencyAnalysisResults extends Dto {
    public startingFrequency?: number;
    public frequencyStep?: number;
    public frequencyData?: number[];

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
