import {BodySideType, BodySideTypes} from "../../models/bodySideType";
import {StanceOval} from "./stanceOval";
import {Entry} from "./entry";
import {Dto} from "common";

export class UnipodalStanceEvaluationActivitySideResultsModel extends Dto {
    public side: BodySideType;
    public surface: number;
    public avgVelocity: number;
    public longitudinalDeviation: number;
    public lateralDeviation: number;
    public totalCopDisplacement: number;
    public totalLateralRange: number;
    public lateralAvg: number;
    public longitudinalAvg: number;
    public longitudinalRange: number;
    public heelValuePercent: number;
    public tipValuePercent: number;
    public oval: StanceOval;
    public gridCopCoordsInPercentage: Entry[];
    public copSurfacePerTime: Entry[];

    deserialize(input: any): this {
        Object.assign(this, input);
        this.side = BodySideTypes.parse(input.side);
        this.oval = new StanceOval().deserialize(input.oval);

        this.gridCopCoordsInPercentage = this.deserializeArray(input.gridCopCoordsInPercentage, Entry);
        this.copSurfacePerTime = this.deserializeArray(input.copSurfacePerTime, Entry);

        return this;
    }
}
