import {Value} from "./values/value";
import {Percent} from "./values/percent";
import {MathUtils} from "./mathUtils";

export class Biomechanics {

    /**
     * Returns the deficit between two values
     */
    public static deficit<Type extends number | Value>(first: Type, second: Type): Percent {
        const firstNumber: number | undefined = MathUtils.getNumberFromValueOrNumber(first);
        const secondNumber: number | undefined = MathUtils.getNumberFromValueOrNumber(second);
        if ((!firstNumber || isNaN(firstNumber)) && (!secondNumber || isNaN(secondNumber))) {
            return Value.EMPTY;
        }
        return new Percent(Math.abs(firstNumber - secondNumber) / Math.max(firstNumber, secondNumber) * 100);
    }

    /**
     * Returns the ratio between two values
     */
    public static ratio<Type extends number | Value | undefined>(first: Type, second: Type): Value {
        const firstNumber: number | undefined = MathUtils.getNumberFromValueOrNumber(first);
        const secondNumber: number | undefined = MathUtils.getNumberFromValueOrNumber(second);
        if ((!firstNumber || isNaN(firstNumber)) && (!secondNumber || isNaN(secondNumber))) {
            return Value.EMPTY;
        } else if (!secondNumber && firstNumber > 0) {
            return new Value(1);
        }
        return new Value(firstNumber / secondNumber);
    }
}
