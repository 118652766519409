import {Dto} from "common";

export class StaticDistributionRepResultsModel extends Dto {
    public ordinal: number;
    public points: number;
    public success: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
