import {Point} from "../../models/point";

export class ArrowDrawingParams {
    start: Point;
    end: Point;
    tipSize: number;
    lineWidth: number;
    color: string;
    tipOnStart: boolean;
    tipOnEnd: boolean;

    constructor() {
        this.start = Point.zero();
        this.end = Point.zero();
        this.tipSize = 16;
        this.color = 'black';
        this.tipOnStart = false;
        this.tipOnEnd = true;
    }

    public setPoints(start: Point, end: Point): ArrowDrawingParams {
        this.start = start;
        this.end = end;
        return this;
    }

    public setTipSize(tipSize: number): ArrowDrawingParams {
        this.tipSize = tipSize;
        return this;
    }

    public setLineWidth(lineWidth: number): ArrowDrawingParams {
        this.lineWidth = lineWidth;
        return this;
    }

    public setColor(color: string): ArrowDrawingParams {
        this.color = color;
        return this;
    }

    public setTipOnStart(tipOnStart: boolean): ArrowDrawingParams {
        this.tipOnStart = tipOnStart;
        return this;
    }

    public setTipOnEnd(tipOnEnd: boolean): ArrowDrawingParams {
        this.tipOnEnd = tipOnEnd;
        return this;
    }
}
