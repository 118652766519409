import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions} from "chart.js";
import {Point} from "../../../models/point";
import {TextUtils, Title} from "common";
import {BaseComponent} from "../../base/base.component";
import {Colors} from "../../../services/colors";

/**
 * Chart that displays scattered data (points) and optionally a trend line
 */
@Component({
    selector: 'app-scatter-chart',
    templateUrl: './scatter-chart.component.html',
    styleUrls: ['./scatter-chart.component.scss']
})
export class ScatterChartComponent extends BaseComponent implements OnInit {

    @Input()
    public dataPoints: Point[];

    @Input()
    public trendLinePoints: Point[];

    @Input()
    public xAxisTitle: Title;

    @Input()
    public yAxisTitle: Title;

    public datasets: ChartDataSets[];
    public chartPlugins: any[];
    public chartOptions: ChartOptions;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.datasets = [];
        for (const dataPoint of this.dataPoints) {
            this.datasets.push({
                data: this.dataPoints.map(p => {
                    return {x: p.x, y: p.y};
                }),
                pointBorderColor: Colors.colorPrimary,
                pointBackgroundColor: Colors.colorPrimary
            })
        }

        if (this.trendLinePoints?.length > 0) {
            this.datasets.push({
                type: 'line',
                data: this.trendLinePoints.map(v => {
                    return {x: v.x, y: v.y};
                }),
                backgroundColor: 'transparent',
                borderDash: [3],
                borderColor: Colors.colorPrimary,
                hoverBorderColor: Colors.colorPrimary,
                pointBackgroundColor: 'transparent',
                pointBorderColor: 'transparent'
            })
        }

        this.chartOptions = {
            animation: {
                duration: 0,
                animateScale: false,
                animateRotate: false
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            devicePixelRatio: 2,
            tooltips: {
                enabled: false
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    type: "linear",
                    position: 'bottom',
                    display: true,
                    stacked: false,
                    ticks: {},
                    scaleLabel: {labelString: this.xAxisTitle, display: !TextUtils.isEmpty(this.xAxisTitle)}
                }],
                yAxes: [
                    {
                        ticks: {},
                        scaleLabel: {labelString: this.yAxisTitle, display: !TextUtils.isEmpty(this.yAxisTitle)}
                    },
                ]
            },
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    font: {size: 16},
                },
            }
        };

        if (this.trendLinePoints.length === 2) {
            this.chartOptions.scales.xAxes[0].ticks.min = this.trendLinePoints[0].x;
            this.chartOptions.scales.xAxes[0].ticks.max = this.trendLinePoints[1].x;
        }
    }
}
