import {Component} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {BaseComponent} from "./components/base/base.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {
    _title = 'KInvent Connect';

    constructor(private _titleService: Title) {
        super();
        _titleService.setTitle(this._title);
    }

    public loggedIn(): boolean {
        return this.authenticationService.account !== null;
    }
}
