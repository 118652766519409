import {Dto} from "common";

export class RepForceCyclesViewModel extends Dto {
    public leftForce: number;
    public rightForce: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
