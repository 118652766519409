import {Component, OnInit} from '@angular/core';
import {JumpAnalysisActivityResultsModel} from '../../../dtos/statistics/jumpAnalysisActivityResultsModel';
import {BodySideType} from '../../../models/bodySideType';
import {ValueWidgetIcon} from '../../../viewmodels/valueWidgetIcons';
import {Sample} from '../../../utils/sample';
import {JumpType} from '../../../dtos/jumpType';
import {Resource} from "common";
import {ActivityImageResolverService} from "../../../services/activity-image-resolver.service";
import {ForceAndTimePacket} from "../../../dtos/statistics/forceAndTimePacket";
import {ResultsComponent} from "../resultsComponent";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {Value} from "../../../utils/values/value";
import {Kg} from "../../../utils/values/kg";
import {Seconds} from "../../../utils/values/seconds";
import {Centimeters} from "../../../utils/values/centimeters";
import {Percent} from "../../../utils/values/percent";
import {UnitType} from "../../../utils/values/unitType";

/**
 * Analysis for CMJ and Drop jump activities
 */
@Component({
    selector: 'app-cmj-results',
    templateUrl: './jump-analysis-results.component.html',
    styleUrls: ['./jump-analysis-results.component.scss']
})
export class JumpAnalysisResultsComponent extends ResultsComponent implements OnInit {
    public readonly valueWidgetIcons: typeof ValueWidgetIcon = ValueWidgetIcon;
    public readonly jumpTypes: typeof JumpType = JumpType;

    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: JumpAnalysisActivityResultsModel;

    public image: Resource;
    public jumpType: JumpType;
    public maxPerSide: Map<BodySideType, Value>;
    public maxTotal: Value;
    public timeOnAirValue: Value;
    public jumpHeightValue: Value;
    public contactTimeValue: Value;
    public reactiveStrengthIndexValue: Value;
    public chartSideSamples: Map<BodySideType, Sample[]>;
    public rfdPoints: Sample[];
    public timeOnAirPoints: Sample[];
    public rfdImpulsionData: Map<BodySideType, Value>[];
    public rfdImpulsionTitles: string[];
    public totalRfd: Value;
    public totalImpulsion: Value;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. JumpAnalysisResultsComponent`, this.analysis);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.activityAnalysis.activityResultsModel as JumpAnalysisActivityResultsModel;

        this.jumpType = this.activity.jumpType;
        const haveJump = this.statistics.jump !== null;
        this.image = this.activityImageResolverService.do(this.activity.image);

        // max per side
        this.maxPerSide = new Map<BodySideType, Value>();
        const maxLeft = haveJump ? this.statistics.jump.leftForceInMaxTotal : 0;
        const maxRight = haveJump ? this.statistics.jump.maxForce - this.statistics.jump.leftForceInMaxTotal : 0;
        this.maxPerSide.set(BodySideType.LEFT, new Kg(maxLeft));
        this.maxPerSide.set(BodySideType.RIGHT, new Kg(maxRight));
        this.maxTotal = haveJump ? new Kg(this.statistics.jump.maxForce) : undefined;

        // metrics
        this.timeOnAirValue = haveJump ? new Seconds(this.statistics.jump.timeOnAir / 1000) : new Seconds(0);
        this.jumpHeightValue = haveJump ? new Centimeters(this.statistics.jump.jumpHeight * 100) : new Centimeters(0);
        if (this.jumpType === JumpType.DROP) {
            this.contactTimeValue = new Seconds(this.statistics.contactTime);
            this.reactiveStrengthIndexValue = new Value(this.statistics.reactiveStrengthIndex);
        }

        let pointWhereJumpProcedureStarted = this.statistics.pointWhereJumpProcedureStarted.time;

        // line chart data
        // all forces are stripped before the pointWhereJumpProcedureStarted, and time is adjusted
        const usableLeftForces = this.shiftDataToStartOfJump(this.statistics.leftForces, pointWhereJumpProcedureStarted);
        const usableRightForces = this.shiftDataToStartOfJump(this.statistics.rightForces, pointWhereJumpProcedureStarted);

        this.chartSideSamples = new Map<BodySideType, Sample[]>();
        this.chartSideSamples.set(BodySideType.LEFT, usableLeftForces.map(value => new Sample(value.time, value.force)));
        this.chartSideSamples.set(BodySideType.RIGHT, usableRightForces.map(value => new Sample(value.time, value.force)));

        this.timeOnAirPoints = [];
        if (haveJump) {
            this.timeOnAirPoints.push(new Sample(this.statistics.jump.timestamp - this.statistics.jump.timeOnAir - pointWhereJumpProcedureStarted, 3));
            this.timeOnAirPoints.push(new Sample(this.statistics.jump.timestamp - pointWhereJumpProcedureStarted, 3));
        }

        // Rfd & Impulsion
        this.rfdPoints = [];
        this.rfdImpulsionData = [];
        if (this.jumpType === JumpType.CMJ || this.jumpType === JumpType.SJ) {
            this.rfdPoints.push(new Sample(this.statistics.minValuePair.time - pointWhereJumpProcedureStarted, this.statistics.minValuePair.force));
            this.rfdPoints.push(new Sample(this.statistics.maxValuePair.time - pointWhereJumpProcedureStarted, this.statistics.maxValuePair.force));

            this.rfdImpulsionTitles = ["RFD", "Impulsion"];
            let rfdValues = new Map<BodySideType, Value>();
            rfdValues.set(BodySideType.LEFT, new Percent(this.getLeftRfdPercentage()));
            rfdValues.set(BodySideType.RIGHT, new Percent(this.getRightRfdPercentage()));
            this.rfdImpulsionData.push(rfdValues);

            let impulsionValues = new Map<BodySideType, Value>();
            impulsionValues.set(BodySideType.LEFT, new Percent(this.statistics.leftImpulsionPercentage));
            impulsionValues.set(BodySideType.RIGHT, new Percent(this.statistics.rightImpulsionPercentage));
            this.rfdImpulsionData.push(impulsionValues);

            this.totalRfd = new Value(this.statistics.totalRfd, UnitType.NEWTON_PER_SECOND);
            this.totalImpulsion = new Value(this.statistics.impulsion, UnitType.NEWTON_SECOND);
        }
    }

    private static findClosestIndexByTime(values: number[], time: number): number {
        for (let i = 0; i < values.length; i++) {
            if (values[i] === time) {
                return i;
            }
            let currentTimeDif = Math.abs(values[i] - time);
            let nextTimeDif = values.length > i + 1 ? Math.abs(values[i + 1] - time) : Number.MAX_VALUE;
            if (nextTimeDif > currentTimeDif) {
                return i;
            }
        }
        return -1;
    }

    public getLeftRfdPercentage(): number {
        return this.statistics.totalRfd > 0 ? (this.statistics.leftRfd * 100) / this.statistics.totalRfd : 50;
    }

    public getRightRfdPercentage(): number {
        return 100 - this.getLeftRfdPercentage();
    }

    public shiftDataToStartOfJump(forces: Array<ForceAndTimePacket>, pointWhereJumpProcedureStarted: number): ForceAndTimePacket[] {
        const jumpIndex = JumpAnalysisResultsComponent.findClosestIndexByTime(forces.map(it => it.time), pointWhereJumpProcedureStarted);
        if (jumpIndex < 0) {
            // point not found
            return forces;
        }

        const shiftedForces: ForceAndTimePacket[] = [];
        for (let i = jumpIndex; i < forces.length; i++) {
            const packet = forces[i];
            const newPacket = new ForceAndTimePacket();
            newPacket.force = packet.force;
            newPacket.time = packet.time - pointWhereJumpProcedureStarted;
            shiftedForces.push(newPacket);
        }
        return shiftedForces;
    }
}
