<div class="component">
    <div *ngIf="analysis; else loadingView">
        <ng-container *ngIf="viewType === resultViewTypes.FULL">
            <ng-container *ngTemplateOutlet="sectionKpis"></ng-container>
            <ng-container *ngTemplateOutlet="sectionDistributionChart"></ng-container>
            <ng-container *ngTemplateOutlet="sectionCharts"></ng-container>
            <ng-container *ngTemplateOutlet="sectionForces"></ng-container>
            <div class="section dynamic-cop mt-4">
                <app-stance-results [activities]="[activity]" [analysis]="dynamicCopAnalysis" [viewType]="resultViewTypes.FULL"></app-stance-results>
            </div>
        </ng-container>
        <ng-container *ngIf="viewType === resultViewTypes.ROW">
            <div class="d-flex flex-row">
                <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
                <div class="max-per-side flex-grow-1">
                    <ng-container *ngTemplateOutlet="sectionCharts"></ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #sectionKpis>
    <div class="section section-kpis d-flex flex-row mb-4">
        <app-value-widget class="flex-grow-1 d-flex justify-content-center"
                          [title]="text('report.chartDataType.rep_count')"
                          [iconType]="valueWidgetIcons.REFRESH"
                          [value]="numberOfRepsValue"
                          [valueFractionDigits]="0"
        >
        </app-value-widget>
        <app-value-widget class="flex-grow-1 d-flex justify-content-center"
                          [title]="text('exercise.dynamic_distribution_evaluation.config.duration.title')"
                          [iconType]="valueWidgetIcons.TIMER"
                          [value]="durationValue"
                          [valueFractionDigits]="1"
        >
        </app-value-widget>
        <app-value-widget class="flex-grow-1 d-flex justify-content-center"
                          [title]="text('stats.hints.pace')"
                          [iconType]="valueWidgetIcons.PACE"
                          [valueFractionDigits]="1"
                          [value]="paceValue"></app-value-widget>
    </div>
</ng-template>

<ng-template #sectionDistributionChart>
    <div class="section section-distribution-graph d-flex flex-row mb-1 ms-2 me-2">
        <app-plates-distribution-results class="flex-grow-1"
                                         [avgLeftDistribution]="statistics.avgLeftDistribution"
                                         [avgRightDistribution]="statistics.avgRightDistribution"
                                         [avgRightDistributionPercent]="statistics.avgRightDistributionPercent"
                                         [targetTolerance]="targetTolerance"
                                         [stdToDistribution]="stdToDistribution"
                                         [stdValue]="stdValue"
                                         [maxLeftDistribution]="statistics.maxLeftDistribution"
                                         [minLeftDistribution]="statistics.minLeftDistribution"
                                         [maxRightDistribution]="statistics.maxRightDistribution"
                                         [minRightDistribution]="statistics.minRightDistribution"
        >
        </app-plates-distribution-results>
    </div>
</ng-template>

<ng-template #sectionCharts>
    <div class="section section-charts d-flex flex-column flex-lg-row mt-1 mb-3">
        <div class="maxStrength">
            <div>
                <app-max-per-side-chart [maxPerSide]="maxStrengthPerSide"
                ></app-max-per-side-chart>
            </div>
            <div class="text-center">
                <b class="totalValue">{{totalValue.formatTo(userSettings.weightUnit, 1)}}</b>
            </div>
        </div>
        <div class="spacer m-2"></div>
        <div class="forces">
            <div class="title rounded-pill pt-1 pb-1 ps-3 pe-3">{{text('dynamicDistribution.results.piecharts.title')}}</div>
            <div>
                <app-body-side-bar-chart [data]="forcesData"
                                         [dataTitles]="forcesDataTitles"
                                         [valueFractionDigits]="1">
                </app-body-side-bar-chart>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #sectionForces>
    <div class="section forcesGraph mt-4">
        <app-samples-line-chart [data]="forceSamples" [total]="forceSamplesTotal">
        </app-samples-line-chart>
    </div>
</ng-template>

<ng-template #loadingView>
    <app-loading [state]="loadingStates.LOADING"></app-loading>
</ng-template>
