/**
 * Describes a horizontal area that can be marked on a chart
 */
import {Color} from "common";

export class ChartAreaViewModel {
    public yMin: number;
    public yMax: number;
    public borderColor: Color;
    public borderWidth: number;
    public backgroundColor: Color;
}
