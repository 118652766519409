import {Component, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {validate as uuidValidate} from 'uuid';
import {ConfirmEmailDto} from '../../dtos/confirmEmail.dto';
import {BaseComponent} from "../../components/base/base.component";
import {EmailValidator} from "common";

enum ViewType {
    LOADING,
    SUCCESS,
    ERROR
}

@Component({
    selector: 'app-confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent extends BaseComponent implements OnInit {
    private viewType?: ViewType;

    constructor(private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        this.route.paramMap
            .pipe(takeUntil(this.destroySubject))
            .subscribe((params) => {
                // validate token is uuid
                const email = params.get('email');
                const token = params.get('token');
                if (token === null || email === null || !uuidValidate(token) || !EmailValidator.isValid(email.trim())) {
                    this.viewType = ViewType.ERROR;
                    return;
                }

                this.viewType = ViewType.LOADING;

                this.analyticsSwitchPage('Confirm email', '/confirmEmail', '/confirmEmail');

                const dto = new ConfirmEmailDto();
                dto.email = email;
                dto.token = token;
                this.authenticationService.confirmEmail(dto)
                    .pipe(takeUntil(this.destroySubject))
                    .subscribe(_response => {
                        this.onConfirmationSuccess();
                    }, error => {
                        this.onConfirmationError(error);
                    });
            });
    }

    private onConfirmationSuccess(): void {
        this.viewType = ViewType.SUCCESS;
    }

    private onConfirmationError(_error: any): void {
        this.viewType = ViewType.ERROR;
    }

    public viewTypes(): typeof ViewType {
        return ViewType;
    }

    public isViewType(viewType: ViewType): boolean {
        return this.viewType === viewType;
    }
}
