import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, forkJoin, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {BodySideTypes} from "../../models/bodySideType";
import {BodyParts} from "../../models/bodyPart";
import {TextPipe} from "../../utils/pipes/text.pipe";
import {UnitTypes} from "../../utils/values/unitType";

@Injectable({
    providedIn: 'root'
})
export class I18nService {
    private english: any;
    public readonly loaded: BehaviorSubject<boolean>;

    constructor(private readonly httpClient: HttpClient) {
        this.loaded = new BehaviorSubject<boolean>(false);
    }

    public load(assets: string[]): Promise<void> {
        const assetObservables: Observable<any>[] = [];
        for (const i18nAsset of assets) {
            assetObservables.push(this.httpClient.get<any>(i18nAsset));
        }

        return forkJoin(assetObservables)
            .pipe(map(value => {
                // Merge all translations to one object
                this.english = {};
                for (const translationJson of value) {
                    Object.assign(this.english, translationJson);
                }

                // Pass it to other services
                TextPipe.i18n = this;
                UnitTypes.i18n = this;
                BodySideTypes.i18n = this;
                BodyParts.i18n = this;

                this.loaded.next(true);
            }))
            .toPromise();
    }

    public text(key: string): string {
        return this.english[key] || key;
    }

    /**
     * Formats a string with the arguments. Replaces {0}, {1}, {i} etc with the formatArgs[i]
     */
    public format(key: string, ...formatArgs: string[]): string {
        return this.text(key)
            .replace(/{(\d+)}/g, (substring, args) => {
                return formatArgs[args] || '';
            });
    }
}
