<div>
    <div><b>{{text('standingEvaluation.results.ellipse_surface.title')}}</b></div>
    <div *ngIf="commonSurface">
        {{text('standingEvaluation.results.surface_no_colons.title')}}
        <b *ngIf="commonSurface" class="surface-value-common d-inline-block">{{this.commonSurface.format(1)}}</b>
    </div>
    <div *ngIf="leftSurface">
        {{leftSideLabel}}
        <b *ngIf="leftSurface" class="surface-value-left d-inline-block">{{this.leftSurface.format(1)}}</b>
    </div>
    <div *ngIf="rightSurface">
        {{rightSideLabel}}
        <b *ngIf="rightSurface" class="surface-value-right d-inline-block">{{this.rightSurface.format(1)}}</b>
    </div>
    <div *ngIf="leftSurface && rightSurface">
        {{text('unipodal_stance_summary_difference')}}
        <b>{{difference.format(1)}}</b>
    </div>
    <div *ngIf="!hidePopulation" class="text-muted">
        {{text('standingEvaluation.results.norm_no_colons.title')}}
        <b class="d-inline-block">
            {{text('standingEvaluation.area.covered.norm.value')}}
        </b>
    </div>
</div>
