import {ActivityConfigSetDto} from './activityConfigSet.dto';
import {ActivityConfigDeviceDto} from './activityConfigDevice.dto';
import {ExerciseType} from '../models/exerciseType';
import {ActivityType} from '../models/activityType';
import GameTypes, {GameType} from '../models/gameType';
import {ActivityConfigSetMetaKeys} from '../models/activityConfigSetMetaKeys';
import {ProtocolOrActivityConfigTag, ProtocolOrActivityConfigTagUtils} from '../models/protocolOrActivityConfigTag';
import {JumpType, JumpTypes} from "./jumpType";
import {Arrays, BaseDto, Code, DeviceType} from "common";

export class ActivityConfigDto extends BaseDto {
    userCode?: Code;
    name?: string;
    title?: string;
    configurable?: boolean;
    repetition?: number;
    duration?: number;
    preparationTime?: number;
    rest?: number;
    difficulty?: string;
    exerciseType?: string;
    activityType?: string;
    orientationType?: string;
    activityConfigSets?: Array<ActivityConfigSetDto>;
    activityConfigDevices?: Array<ActivityConfigDeviceDto>;
    tags?: string;
    antagonistCode?: string | null;
    baseConfigCode?: Code;
    buildIn?: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);

        // Handle migration from Meter to MeterEndurance
        if (this.exerciseTypeEnum === ExerciseType.METER) {
            this.exerciseType = ExerciseType.METER_ENDURANCE;
        }

        this.activityConfigSets = this.deserializeArray(input.activityConfigSets, ActivityConfigSetDto);
        this.activityConfigDevices = this.deserializeArray(input.activityConfigDevices, ActivityConfigDeviceDto);

        return this;
    }

    public get exerciseTypeEnum(): ExerciseType | undefined {
        return ExerciseType[this.exerciseType as keyof typeof ExerciseType];
    }

    public get activityTypeEnum(): ActivityType | undefined {
        return ActivityType[this.activityType as keyof typeof ActivityType];
    }

    public get jumpType(): JumpType | undefined {
        const jumpTypeMeta = this.activityConfigSets.flatMap(s => s.metaFields)
            .find(value => value.metaKey === ActivityConfigSetMetaKeys.JUMP_TYPE);
        let jumpType: JumpType;
        if (jumpTypeMeta) {
            jumpType = JumpTypes.parse(jumpTypeMeta.metaValue);
        } else {
            jumpType = JumpType.CMJ;
        }
        return jumpType;
    }

    public getDeviceTypes(): DeviceType[] {
        if (!this.activityConfigDevices) {
            return [];
        }
        return Array.from(this.activityConfigDevices).map(it => it.deviceTypeType);
    }

    public get gameTypeEnum(): GameType | undefined {
        if (!this.activityConfigSets || !this.activityConfigSets[0]) {
            return;
        }
        return GameTypes.parse(this.activityConfigSets![0].getMetaString(ActivityConfigSetMetaKeys.GAME_TYPE));
    }

    public hasMetaString(metaKey: string): boolean {
        if (!this.activityConfigSets) {
            return false;
        }

        for (let set of this.activityConfigSets) {
            let meta = set.getMetaString(metaKey);

            if (meta) {
                return true;
            }
        }

        return false;
    }

    public getTags(): ProtocolOrActivityConfigTag[] {
        if (!this.tags) {
            return [];
        }

        return this.tags.split(',')
            .map(ProtocolOrActivityConfigTagUtils.getTagEnum)
            .filter(Arrays.withValue);
    }
}
