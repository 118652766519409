import {ForceAndTimePacket} from "./forceAndTimePacket";
import {RepForceCyclesViewModel} from "./repForceCyclesViewModel";
import {Dto} from "common";

export class SkippingExerciseResultsModel extends Dto {
    public totalSkips: number;
    public averagePower: number; // W/Kg
    public fatigueIndex: number;

    public leftAvgForce: number;
    public rightAvgForce: number;
    public avgForceLeftPercent: number;
    public avgForceRightPercent: number;
    public averageForcesPerCycle: RepForceCyclesViewModel[];

    public leftTimeOnAir: number; // ms
    public rightTimeOnAir: number; // ms
    public timeOnAirBothSides: number; // ms

    public leftContactTime: number; // ms
    public rightContactTime: number; // ms

    public paceTotal: number; // skips / min
    public paceLeft: number; // /min
    public paceRight: number; // /min
    public paceFirstHalfDuration: number;
    public paceLastHalfDuration: number;

    public leftForces: ForceAndTimePacket[];
    public rightForces: ForceAndTimePacket[];

    deserialize(input: any): this {
        Object.assign(this, input);

        this.averageForcesPerCycle = this.deserializeArray(input.averageForcesPerCycle, RepForceCyclesViewModel);
        this.leftForces = this.deserializeArray(input.leftForces, ForceAndTimePacket);
        this.rightForces = this.deserializeArray(input.rightForces, ForceAndTimePacket);

        return this;
    }
}
