import {Point} from "../../models/point";
import {Dto} from "common";

export class StanceOval extends Dto {
    public cX: number;
    public cY: number;
    public width: number;
    public height: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    public get center(): Point {
        return Point.of(this.cX, this.cY);
    }

    public clone(): StanceOval {
        const c = new StanceOval();
        c.cX = this.cX;
        c.cY = this.cY;
        c.width = this.width;
        c.height = this.height;
        return c;
    }

    public toString(): string {
        return `cX: ${this.cX}, cY: ${this.cY}, width: ${this.width}, height: ${this.height}`;
    }

}
