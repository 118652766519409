/**
 * Immutable instance of device physical properties
 * Distance is in mm
 */
import {PhysicalDevice} from "./physicalDevice";

export class DeltasPhysicalDevice implements PhysicalDevice {
    constructor(public readonly width: number, public readonly height: number) {
    }

    public static classic(): DeltasPhysicalDevice {
        return new DeltasPhysicalDevice(460, 804.4)
    }

    public toString(): string {
        return `Deltas: width: ${this.width} height: ${this.height}`;
    }
}
