import {Dto} from "common";

export class NordicHamstringResultsModel extends Dto {
    public leftMaxForce: number;
    public rightMaxForce: number;

    public averageLeft: number;
    public averageRight: number;
    public average: number;
    public rfdLeft: number; // kg/s
    public rfdRight: number; // kg/s
    public rfd: number; // kg/s
    public timeToMaxLeft: number; // ms
    public timeToMaxRight: number; // ms
    public timeToMax: number; // ms
    public fatigueLeft: number; // kg/s
    public fatigueRight: number; // kg/s
    public fatigue: number; // kg/s

    public syncedTimestamps: number[]; // timestamps produced by syncer
    public syncedForcesLeft: number[]; // transformed and synced data
    public syncedForcesRight: number[]; // transformed and synced data

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
