import {OrientationType} from "../../models/orientationType";
import {Dto} from "common";

export class BalanceDistributionConfigViewModel extends Dto {
    public targetDifficultyRangeLeft: number; // [0,1], if shouldApplyTargetToleranceAccordingToTheTarget equals true, then it is calculated according to the target point, else irrelevantly
    public targetDifficultyRangeRight: number; // [0,1] same as targetDifficultyRangeLeft, reference BalanceComponent
    public targetDistribution: number; // [-1,1], -1 for total left 0 for equal, 1 for total right
    public orientationType: OrientationType;
    public shouldApplyTargetToleranceAccordingToTheTarget: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
