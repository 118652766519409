import {RepCountActivityRepResultsModel} from "./repCountActivityRepResultsModel";
import {Dto} from "common";

export class RepCountActivityResultsModel extends Dto {
    public repResults: RepCountActivityRepResultsModel[];

    deserialize(input: any): this {
        Object.assign(this, input);
        this.repResults = this.deserializeArray(input.repResults, RepCountActivityRepResultsModel);
        return this;
    }
}
