import {ActivityDto} from '../dtos/activity.dto';
import {BuiltInExerciseTemplates, BuiltInExerciseTemplateType} from '../models/builtInExerciseTemplateType';
import {ActivityMetaKeys} from '../models/activityMetaKeys';
import {ActivityConfigSetMetaKeys} from '../models/activityConfigSetMetaKeys';
import {I18nService} from '../services/i18n/i18n.service';
import {startOfDay} from 'date-fns';
import {UnipodalStanceEvaluationActivityResultsModel} from '../dtos/statistics/unipodalStanceEvaluationActivityResultsModel';
import {ActivityAnalysisResultDto} from '../dtos/statistics/activityAnalysisResultDto';
import {UnitType} from "./values/unitType";
import {DeviceType} from "common";

export class Activities {
    public static getTitle(i18n: I18nService, activity: ActivityDto): string {
        if (!activity) {
            return '';
        }
        let baseConfigCode = activity.config!.baseConfigCode;
        let title: string;
        if (baseConfigCode === BuiltInExerciseTemplateType.SJ_DYNAMIC_BW) {
            let initialWeight = activity.getMetaFloat(ActivityMetaKeys.BODY_WEIGHT);
            let target = activity.config!.activityConfigSets![0].getMetaFloat(ActivityConfigSetMetaKeys.TARGET);
            let targetPercentage: number;
            // sanity check
            if (initialWeight > 0 && target > 0) {
                targetPercentage = target / initialWeight;
            } else {
                targetPercentage = undefined;
            }

            let appendedTitle = targetPercentage !== undefined ? (targetPercentage * 100).toFixed(0) + '% ' : '';
            title = i18n.format(activity.config!.title, appendedTitle);
        } else if ([BuiltInExerciseTemplateType.STANDING_EVALUATION_HEXAS, BuiltInExerciseTemplateType.STANDING_EVALUATION]
                .includes(BuiltInExerciseTemplates.getTypeForCode(baseConfigCode)) &&
            activity.config && activity.config.hasMetaString(ActivityConfigSetMetaKeys.EYES_OPEN)) {
            let eyesOpen = activity.config.activityConfigSets[0].getMetaBoolean(ActivityConfigSetMetaKeys.EYES_OPEN);
            title = i18n.text(eyesOpen ? 'stance_eyes_open' : 'stance_eyes_closed');
        } else {
            title = i18n.text(activity.config.title);
        }

        return title;
    }

    public static groupByDay(activities: ActivityDto[]): Map<number, ActivityDto[]> {
        const groups = new Map<number, ActivityDto[]>();

        for (const activity of activities) {
            let date = startOfDay(activity.startTime);
            if (!groups.has(date.getTime())) {
                groups.set(date.getTime(), []);
            }
            groups.get(date.getTime()).push(activity);
        }

        return groups;
    }

    public static isStanceWithSingleLeg(analysis: ActivityAnalysisResultDto): boolean {
        return analysis.activityResultsModel instanceof UnipodalStanceEvaluationActivityResultsModel;
    }

    /**
     * For some activities it's possible to infer the unit type of the measurements without a MeasurementDto
     * Undefined is returned when it's not possible
     */
    public static getUnitType(activity: ActivityDto): UnitType | undefined {
        if (!activity.config.activityConfigDevices || activity.config.activityConfigDevices.length > 1) {
            return undefined;
        }

        this.getUnitFromDevice(activity.config.activityConfigDevices[0].deviceTypeType);
    }

    public static getUnitFromDevice(deviceType: DeviceType): UnitType | undefined {
        let unitType: UnitType = undefined;
        if (deviceType === DeviceType.SENS) {
            unitType = UnitType.ANGLES;
        } else {
            unitType = UnitType.KG;
        }

        return unitType;
    }
}
