import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivityConfigDto} from "../../dtos/activityConfig.dto";
import {ActivityConfigSetDto} from "../../dtos/activityConfigSet.dto";
import {ActivityConfigSetMetaKeys} from "../../models/activityConfigSetMetaKeys";
import {BodySideTypes} from "../../models/bodySideType";
import {ExerciseType} from "../../models/exerciseType";
import {Device} from "../../viewmodels/device";
import {Title} from "common";
import {BaseComponent} from "../base/base.component";
import {Kg} from "../../utils/values/kg";

@Component({
    selector: 'app-results-overview',
    templateUrl: './results-overview.component.html',
    styleUrls: ['./results-overview.component.scss']
})
export class ResultsOverviewComponent extends BaseComponent implements OnInit, OnChanges {

    @Input()
    public title: Title;

    @Input()
    public activityConfig: ActivityConfigDto;

    @Input()
    public exerciseType: ExerciseType;

    @Input()
    public devices: Device[];

    public configDescription: string;

    public displayTargets: boolean;

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    public getSetDescription(set: ActivityConfigSetDto): string {
        const targetValue = new Kg(set.getMetaFloat(ActivityConfigSetMetaKeys.TARGET));
        return `${BodySideTypes.format(set.bodySideType)} ${this.formatValue(targetValue)}`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        let activityConfigChanges = changes['activityConfig'];
        if (activityConfigChanges) {
            const curr = activityConfigChanges.currentValue as ActivityConfigDto;
            if (curr) {
                let description: string;
                switch (this.exerciseType) {
                    case ExerciseType.METER:
                    case ExerciseType.METER_ENDURANCE:
                    case ExerciseType.ISOMETRIC:
                        description = this.format('reps.rest', this.activityConfig.repetition.toString(), this.activityConfig.duration.toString(), this.activityConfig.rest.toString());
                        this.displayTargets = true;
                        break;
                    case ExerciseType.STANDING_EVALUATION:
                    case ExerciseType.DYNAMIC_DISTRIBUTION_EVALUATION:
                    case ExerciseType.STATIC_DISTRIBUTION_EXERCISE:
                    case ExerciseType.JUMP_ANALYSIS:
                        description = this.format('report.activity.plates.duration.activityConfig.format', this.activityConfig.duration.toString());
                        break;
                }
                this.configDescription = description;
            } else {
                this.configDescription = '';
            }
        }
    }
}
