import {ActivityLineChartProvider} from "./activityLineChartProvider";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {I18nService} from "../../../services/i18n/i18n.service";
import {Label} from "ng2-charts";
import {ProgressOverTimeFilter} from "../../../viewmodels/progressOverTimeFilter";
import {Activities} from "../../../utils/activities";
import {format} from "date-fns";
import {ProgressOverTimeMetric} from "../../../viewmodels/progressOverTimeMetric";
import {ChartDataSets} from "chart.js";
import {ProgressOverTimeMetricType} from "../../../viewmodels/progressOverTimeMetricType";
import {BodySideType, BodySideTypes} from "../../../models/bodySideType";
import {JumpAnalysisActivityResultsModel} from "../../../dtos/statistics/jumpAnalysisActivityResultsModel";
import {JumpCycle} from "../../../dtos/statistics/jumpCycle";
import {ChartUtils} from "../../../utils/chartUtils";
import {Centimeters} from "../../../utils/values/centimeters";
import {Value} from "../../../utils/values/value";
import {UnitType} from "../../../utils/values/unitType";
import {DateFormat, Dates, KeyValue} from "common";

export class JumpAnalysisLineChartProvider extends ActivityLineChartProvider {
    private lastMetric: ProgressOverTimeMetricType;

    getDatasets(activities: ActivityDto[], analysis: ActivityAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: Chart.ChartOptions, i18n: I18nService): KeyValue<Label[], Chart.ChartDataSets[]> {
        const activitiesByDay = Activities.groupByDay(activities);

        const days = Array.from(activitiesByDay.keys()).sort();

        const labels: Label[] = days.map(day => format(day, Dates.getFormat(DateFormat.DATE_SHORTER)));

        const datasets = this.createDatasets(activitiesByDay, analysis, filter.metric, days, i18n);

        let maxValue = 0;
        for (const dataset of datasets) {
            maxValue = Math.max(maxValue, ...(dataset.data as number[]).filter(value => !Number.isNaN(value))) * 1.2;
        }
        chartOptions.scales.yAxes[0].ticks = {min: 0, max: Math.max(maxValue, 0.01)};

        // Chart options
        if (!chartOptions.plugins) {
            chartOptions.plugins = {};
        }
        chartOptions.plugins.datalabels = {
            formatter: this.labelFormatter.bind(this),
            anchor: 'end',
            align: 'end',
        };

        return KeyValue.of(labels, datasets);
    }

    private createDatasets(activitiesPerDay: Map<number, ActivityDto[]>, analysis: ActivityAnalysisServiceResponseDto, metric: ProgressOverTimeMetric, days: number[], i18n: I18nService) {
        const datasets: ChartDataSets[] = [];
        if (metric.type === ProgressOverTimeMetricType.JUMP_HEIGHT) {
            const dataset: ChartDataSets = {};
            dataset.data = [];
            dataset.label = i18n.text(metric.title);
            dataset.spanGaps = true;
            dataset.lineTension = 0;
            datasets.push(dataset);
        } else {
            for (const side of [BodySideType.LEFT, BodySideType.RIGHT, BodySideType.BOTH]) {
                const dataset: ChartDataSets = {};
                dataset.data = [];
                dataset.label = BodySideTypes.format(side);
                dataset.spanGaps = true;
                dataset.lineTension = 0;
                datasets.push(dataset);
            }
        }

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value: number = NaN;
            // Create values for metrics.
            // Add NaN when there is no value for the day except for the last day, where we add the previous value
            if (activitiesPerDay.has(day)) {
                let activities = activitiesPerDay.get(day);
                let activitiesToResults = this.combineActivitiesToResults<JumpAnalysisActivityResultsModel>(activities, analysis);
                let results = activities.map(a => activitiesToResults.get(a));

                if (metric.type === ProgressOverTimeMetricType.JUMP_HEIGHT) {
                    value = this.getMetricFromActivities(results, metric.type);
                    datasets[0].data.push(value);
                } else {
                    const sides = [BodySideType.LEFT, BodySideType.RIGHT, BodySideType.BOTH];
                    for (let j = 0; j < sides.length; j++) {
                        value = this.getMetricFromActivities(results, metric.type, sides[j]);
                        datasets[j].data.push(value);
                    }
                }
            }

            if (metric.type === ProgressOverTimeMetricType.JUMP_HEIGHT) {
                ChartUtils.formatDatasetPrimary(datasets[0]);
            } else {
                ChartUtils.formatDatasetPrimary(datasets[0]);
                ChartUtils.formatDatasetSecondary(datasets[1]);
                ChartUtils.formatDatasetNeutral(datasets[2]);
            }
        }

        this.lastMetric = metric.type;

        return datasets;
    }

    private getMetricFromActivities(results: JumpAnalysisActivityResultsModel[], type: ProgressOverTimeMetricType, side?: BodySideType) {
        let value: number = NaN;
        switch (type) {
            case ProgressOverTimeMetricType.JUMP_HEIGHT:
                value = Math.max(0, ...results.filter(a => a.jump !== null).map(a => a.jump.jumpHeight * 100 /* to cm */));
                break;
            case ProgressOverTimeMetricType.RFD:
                if (side === BodySideType.LEFT) {
                    value = Math.max(...results.map(a => a.leftRfd));
                } else if (side === BodySideType.RIGHT) {
                    value = Math.max(...results.map(a => a.rightRfd));
                } else if (side === BodySideType.BOTH) {
                    value = Math.max(...results.map(a => a.totalRfd));
                }
                break;
            case ProgressOverTimeMetricType.IMPULSION:
                if (side === BodySideType.LEFT) {
                    value = Math.max(0, ...results.map(a => a.impulsion * a.leftImpulsionPercentage / 100));
                } else if (side === BodySideType.RIGHT) {
                    value = Math.max(0, ...results.map(a => a.impulsion * a.rightImpulsionPercentage / 100));
                } else if (side === BodySideType.BOTH) {
                    value = Math.max(0, ...results.map(a => a.impulsion));
                }
                break;
            case ProgressOverTimeMetricType.MAX_VALUE:
                const maxJump = JumpAnalysisLineChartProvider.getMaxJump(results.filter(a => a.jump !== null).map(a => a.jump));

                if (maxJump === null) {
                    value = 0;
                } else if (side === BodySideType.BOTH) {
                    value = maxJump.maxForce;
                } else if (side === BodySideType.LEFT) {
                    value = maxJump.leftForceInMaxTotal;
                } else if (side === BodySideType.RIGHT) {
                    value = maxJump.maxForce - maxJump.leftForceInMaxTotal;
                }
                break;
            case ProgressOverTimeMetricType.CONTACT_TIME:
                value = Math.max(0, ...results.map(a => a.contactTime));
                break;
            case ProgressOverTimeMetricType.RSI:
                value = Math.max(0, ...results.map(a => a.reactiveStrengthIndex));
                break;
        }
        return value;
    }

    private labelFormatter(value: number): string {
        // default
        let formatted = value.toFixed(1);

        switch (this.lastMetric) {
            case ProgressOverTimeMetricType.JUMP_HEIGHT:
                formatted = new Centimeters(value).format(0);
                break;
            case ProgressOverTimeMetricType.RFD:
                formatted = new Value(value, UnitType.NEWTON_PER_SECOND).format(0);
                break;
            case ProgressOverTimeMetricType.IMPULSION:
                formatted = new Value(value, UnitType.NEWTON_SECOND).format(0);
                break;
            case ProgressOverTimeMetricType.MAX_VALUE:
                formatted = new Value(value, UnitType.NONE).format(0);
                break;
        }
        return formatted;
    }

    public static getMaxJump(jumps: JumpCycle[]): JumpCycle {
        if (jumps.length === 0) {
            return null;
        }
        let currentMaxJumpCycle = jumps[0];
        for (let jumpCycle of jumps) {
            if (jumpCycle.maxForce > currentMaxJumpCycle.maxForce) {
                currentMaxJumpCycle = jumpCycle;
            }
        }
        return currentMaxJumpCycle;
    }
}
