import {Pipe, PipeTransform} from '@angular/core';
import {UnitType} from "../values/unitType";
import {Value} from "../values/value";

@Pipe({
    name: 'toValue',
    pure: true
})
export class ToValuePipe implements PipeTransform {
    /**
     * Formats a number as value
     * @param value The value to format
     * @param unitType The unitType of value
     * @param fractionDigits
     * @param formatUnitType The target unit to use for formatting
     */
    transform(value: number, unitType: UnitType = UnitType.NONE, fractionDigits: number = 2, formatUnitType?: UnitType): string {
        return new Value(value, unitType).formatTo(unitType, fractionDigits);
    }
}
