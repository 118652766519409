import {Component, OnInit} from '@angular/core';
import {MeterEnduranceActivityResultsModel} from '../../../dtos/statistics/meterEnduranceActivityResultsModel';
import {ActivityImageResolverService} from '../../../services/activity-image-resolver.service';
import {BodySideType} from '../../../models/bodySideType';
import {MeterEnduranceStatisticsRowViewModel} from '../../../viewmodels/meterEnduranceStatisticsRowViewModel';
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {DeviceType, Resource, Title} from "common";
import {ResultsComponent} from "../resultsComponent";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ViewMediumType} from "../viewMediumType";
import {ExerciseType} from "../../../models/exerciseType";
import {TotalEvaluationActivityResultsModel} from "../../../dtos/statistics/totalEvaluationActivityResultsModel";
import {MeterEnduranceActivityRepResultsModel} from "../../../dtos/statistics/meterEnduranceActivityRepResultsModel";
import {TotalEvaluationRepResultsModel} from "../../../dtos/statistics/totalEvaluationRepResultsModel";
import {ActivityConfigSetMetaKeys} from "../../../models/activityConfigSetMetaKeys";
import {Value} from "../../../utils/values/value";
import {UnitTypes} from "../../../utils/values/unitType";
import {Kg} from "../../../utils/values/kg";
import {Ordinal} from "../../../utils/types";

/**
 * Supports meter and total evaluation activities
 */
@Component({
    selector: 'app-meter-endurance-results',
    templateUrl: './meter-endurance-results.component.html',
    styleUrls: ['./meter-endurance-results.component.scss']
})
export class MeterEnduranceResultsComponent extends ResultsComponent implements OnInit {
    public activity: ActivityDto;
    public exerciseType: ExerciseType.METER_ENDURANCE | ExerciseType.TOTAL_EVALUATION;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: MeterEnduranceActivityResultsModel | TotalEvaluationActivityResultsModel;

    public repetitionResults: Map<Ordinal, MeterEnduranceStatisticsRowViewModel[]>;
    public repetitionTargets: Map<Ordinal, number>;
    public repetitionsTitle: string;
    public maxPerSide: Map<BodySideType, Value>;
    public meanPerSide: Map<BodySideType, Value>[];
    public maxPerRep: Map<BodySideType, Value>[];
    public maxPerRepTitles: Title[];
    public image: Resource;
    public compact: boolean;
    public displayRepetitionRowStats: boolean;
    public displayTotal: boolean;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
        this.maxPerSide = new Map<BodySideType, Value>();
        this.repetitionResults = new Map<number, MeterEnduranceStatisticsRowViewModel[]>();
        this.repetitionTargets = new Map<Ordinal, number>();
        this.displayRepetitionRowStats = true;
        this.displayTotal = false;
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. MeterEnduranceResultsComponent`, this.analysis);
            return;
        }

        this.activity = this.activities[0];
        const exerciseType = this.activity.config.exerciseTypeEnum;
        if (exerciseType !== ExerciseType.METER_ENDURANCE && exerciseType !== ExerciseType.TOTAL_EVALUATION) {
            console.log(`Unsupported activity. MeterEnduranceResultsComponent`, exerciseType);
            return;
        }
        this.exerciseType = exerciseType;
        this.activityAnalysis = this.analysis.activitiesResults[0];
        if (exerciseType === ExerciseType.METER_ENDURANCE) {
            this.statistics = this.analysis.activitiesResults[0].activityResultsModel as MeterEnduranceActivityResultsModel;
        } else if (exerciseType === ExerciseType.TOTAL_EVALUATION) {
            this.statistics = this.analysis.activitiesResults[0].activityResultsModel as TotalEvaluationActivityResultsModel;
        }
        this.image = this.activityImageResolverService.do(this.activity.image);
        this.compact = this.mediumType === ViewMediumType.PRINT;
        this.loadStatistics();
    }

    private loadStatistics() {
        let configDevices = this.activityAnalysis.config.activityConfigDevices;
        if (configDevices.length === 1 && configDevices.find(value => value.deviceTypeType === DeviceType.SENS)) {
            this.repetitionsTitle = this.text('meterExercise.results.repetitionStatisticsTitle.degrees');
        } else {
            this.repetitionsTitle = this.format('meterExercise.results.repetitionStatisticsTitle.kg', UnitTypes.symbol(this.userSettings.weightUnit));
        }

        this.maxPerSide = this.getMaxPerSide();

        // Meter has mean per side
        this.meanPerSide = undefined;
        if (this.exerciseType === ExerciseType.METER_ENDURANCE) {
            const meanPerSideMap = this.getMeanPerSide();
            if (meanPerSideMap.size > 0) {
                this.meanPerSide = [this.getMeanPerSide()];
            }
        }

        // Total evaluation has max per side and repetition
        if (this.exerciseType === ExerciseType.TOTAL_EVALUATION) {
            this.maxPerRep = []; // side, value
            this.maxPerRepTitles = [];
            const statistics = this.statistics as TotalEvaluationActivityResultsModel;
            for (const repResult of statistics.repResults) {
                const sideData = new Map<BodySideType, Value>();
                sideData.set(BodySideType.LEFT, new Value(repResult.maxLeftValue));
                sideData.set(BodySideType.RIGHT, new Value(repResult.maxValue - repResult.maxLeftValue));
                this.maxPerRep.push(sideData);
                this.maxPerRepTitles.push(this.format('meterExercise.results.repetitionGraph.xAxisValue', repResult.repOrdinal.toString()));
            }
        }

        // Repetitions
        let bodyPart = this.activityAnalysis.config.activityConfigSets[0].bodyPartType;
        this.repetitionResults.clear();
        this.repetitionTargets.clear();
        for (const result of this.statistics.repResults) {
            let ordinal = result.repOrdinal;
            if (!this.repetitionResults.has(ordinal)) {
                this.repetitionResults.set(ordinal, []);
            }
            if (result instanceof MeterEnduranceActivityRepResultsModel) {
                const viewModel = MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(result);
                viewModel.bodyPart = bodyPart;
                this.repetitionResults.get(ordinal).push(viewModel);
            } else if (result instanceof TotalEvaluationRepResultsModel) {
                // Create two viewmodels, one for each side

                const left = new MeterEnduranceStatisticsRowViewModel();
                const right = new MeterEnduranceStatisticsRowViewModel();
                left.side = BodySideType.LEFT;
                right.side = BodySideType.RIGHT;
                left.repOrdinal = result.repOrdinal;
                right.repOrdinal = result.repOrdinal;

                left.maxValue = result.maxLeftValue;
                left.forces = result.forcesLeft;

                right.maxValue = result.maxValue - result.maxLeftValue;
                right.forces = result.forcesRight;

                left.deltas = result.deltas;
                right.deltas = result.deltas;

                this.repetitionResults.get(ordinal).push(left);
                this.repetitionResults.get(ordinal).push(right);
                this.displayRepetitionRowStats = false;
                this.displayTotal = true;

                // target
                let target = this.activity.config.activityConfigSets[0].getMetaFloat(ActivityConfigSetMetaKeys.TARGET);
                this.repetitionTargets.set(ordinal, Math.max(target, result.maxValue));
            }
        }

    }

    public getMaxPerSide(): Map<BodySideType, Value> {
        const maxPerSideMap = new Map<BodySideType, Value>();
        if (this.exerciseType === ExerciseType.METER_ENDURANCE) {
            const statistics = this.statistics as MeterEnduranceActivityResultsModel;
            for (const key of statistics.maxPerSide.keys()) {
                maxPerSideMap.set(key, new Kg(statistics.maxPerSide.get(key)));
            }
        } else if (this.exerciseType === ExerciseType.TOTAL_EVALUATION) {
            const statistics = this.statistics as TotalEvaluationActivityResultsModel;
            for (const sideDatum of statistics.maxPerSide) {
                maxPerSideMap.set(sideDatum.side, new Kg(sideDatum.value));
            }
        }

        return maxPerSideMap;
    }

    public getMeanPerSide(): Map<BodySideType, Value> {
        if (this.exerciseType !== ExerciseType.METER_ENDURANCE) {
            return undefined;
        }
        const statistics = this.statistics as MeterEnduranceActivityResultsModel;
        const meanPerSideMap = new Map<BodySideType, Value>();
        for (const key of statistics.meanPerSide.keys()) {
            // display mean for a side only if there are at least two repetitions for the side
            if (statistics.repResults.filter(r => r.bodySideType === key).length >= 2) {
                meanPerSideMap.set(key, new Kg(statistics.meanPerSide.get(key)));
            }
        }
        return meanPerSideMap;
    }
}
