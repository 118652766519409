import {BodySideType, BodySideTypes} from "../../models/bodySideType";
import {RepCountCycle} from "./repCountCycle";
import {Dto} from "common";

export class RepCountActivityRepResultsModel extends Dto {
    public repCode: string;
    public average: number; // Kg
    public max: number; // Kg
    public pace: number; // reps/min
    public successRate: number; // % [0 - 1]
    public target: number; // Kg
    public repCycles: RepCountCycle[];
    public side: BodySideType;

    deserialize(input: any): this {
        Object.assign(this, input);
        this.repCycles = this.deserializeArray(input.repCycles, RepCountCycle);
        this.side = BodySideTypes.parse(input.side);
        return this;
    }
}
