import {ActivityLineChartProvider} from "./activityLineChartProvider";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {I18nService} from "../../../services/i18n/i18n.service";
import {Label} from "ng2-charts";
import {ProgressOverTimeFilter} from "../../../viewmodels/progressOverTimeFilter";
import {Activities} from "../../../utils/activities";
import {format} from "date-fns";
import {ProgressOverTimeMetric} from "../../../viewmodels/progressOverTimeMetric";
import {ChartDataSets} from "chart.js";
import {ProgressOverTimeMetricType} from "../../../viewmodels/progressOverTimeMetricType";
import {SkippingExerciseResultsModel} from "../../../dtos/statistics/skippingExerciseResultsModel";
import {ChartUtils} from "../../../utils/chartUtils";
import {DateFormat, Dates, KeyValue} from "common";

export class SkippingLineChartProvider extends ActivityLineChartProvider {
    getDatasets(activities: ActivityDto[], analysis: ActivityAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: Chart.ChartOptions, i18n: I18nService): KeyValue<Label[], Chart.ChartDataSets[]> {
        const activitiesByDay = Activities.groupByDay(activities);
        const days = Array.from(activitiesByDay.keys()).sort();
        const labels: Label[] = days.map(day => format(day, Dates.getFormat(DateFormat.DATE_SHORTER)));

        const datasets = this.createDatasets(activitiesByDay, analysis, filter.metric, days, i18n);

        const maxValue = datasets.reduce((previousValue, currentValue) => Math.max(previousValue, ...(currentValue.data as number[])), 0);

        chartOptions.scales.yAxes[0].ticks = {min: 0, max: Math.max(maxValue * 1.2, 0.01)};

        // Chart options
        if (!chartOptions.plugins) {
            chartOptions.plugins = {};
        }
        chartOptions.plugins.datalabels = {
            anchor: 'end',
            align: 'end',
        };
        return KeyValue.of(labels, datasets);
    }

    private createDatasets(activitiesByDay: Map<number, ActivityDto[]>, analysis: ActivityAnalysisServiceResponseDto, metric: ProgressOverTimeMetric, days: number[], i18n: I18nService): ChartDataSets[] {
        const dataset: ChartDataSets = {data: [], label: i18n.text(metric.title), spanGaps: true, lineTension: 0};
        ChartUtils.formatDatasetPrimary(dataset);

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value = NaN;

            // Create values for metrics.
            // Add NaN when there is no value for the day
            if (activitiesByDay.has(day)) {
                const activities = activitiesByDay.get(day);
                const activitiesToResults = this.combineActivitiesToResults<SkippingExerciseResultsModel>(activities, analysis);
                const results = activities.map(a => activitiesToResults.get(a));
                value = this.getMetricForResults(results, metric.type);
            }
            dataset.data.push(value);
        }

        return Array.of(dataset);
    }

    private getMetricForResults(results: SkippingExerciseResultsModel[], type: ProgressOverTimeMetricType) {
        let value = NaN;
        switch (type) {
            case ProgressOverTimeMetricType.SKIP_COUNT:
                value = Math.max(0, ...results.map(r => r.averageForcesPerCycle.length));
                break;
        }
        return value;
    }
}
