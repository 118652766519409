import {Injectable} from '@angular/core';
import {ProtocolGroupFilter} from '../viewmodels/protocolGroupFilter';
import {NavigationExtras, Router} from '@angular/router';
import {ProtocolDto} from '../dtos/protocol.dto';
import {Code} from "common";

/**
 * Navigation wrapper service
 */
@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(private router: Router) {
    }

    public toolbarVisible: boolean = true;

    public goToLogin() {
        return this.router.navigate(['/auth/login']);
    }

    public goToHome(): Promise<boolean> {
        return this.router.navigate(['/']);
    }

    public goToProfile(): Promise<boolean> {
        return this.router.navigate(['/profile',]);
    }

    public goToParticipant(participantCode: string): Promise<boolean> {
        return this.router.navigate(['/participants', participantCode]);
    }

    public goToParticipantProgress(participantCode: string): Promise<boolean> {
        return this.router.navigate([`/participants/${participantCode}/progress`]);
    }

    public goToParticipantActivityResults(participantCode: string, protocolBaseCode: string, protocols: ProtocolDto[], filter: ProtocolGroupFilter, inNewTab: boolean = false): Promise<boolean> {
        // Query params are all filter fields except baseConfigCode which is already in the url

        return this.navigate([`/participants/${participantCode}/progress/activity/${protocolBaseCode}`], {
            state: {'protocols': protocols},
            queryParams: {
                devices: filter.devices,
                gameType: filter.gameType,
                exerciseType: filter.exerciseType,
                baseConfigGroup: filter.baseConfigGroup
            }
        }, inNewTab);
    }

    public goToParticipantProtocolResults(participantCode: Code, protocolBaseCode: string, protocols: ProtocolDto[], inNewTab: boolean): Promise<boolean> {
        return this.navigate([`/participants/${participantCode}/progress/protocol/`, protocolBaseCode], {
            state: {'protocols': protocols},
        }, inNewTab);
    }

    public goToProPlusPromo() {
        return this.router.navigate(['features/proPlus']);
    }

    public goToDevPlayground() {
        return this.router.navigate(['/dev']);
    }

    private navigate(command: any[], extras: NavigationExtras, inNewTab: boolean): Promise<boolean> {
        if (!inNewTab) {
            return this.router.navigate(command, extras);
        } else {
            const url = this.router.serializeUrl(
                this.router.createUrlTree(command, extras)
            );
            window.open(url, '_blank');
        }
    }
}
