<div class="component d-flex flex-column ">
    <div class="d-flex justify-content-center mb-4">
        <div class="title rounded-pill d-inline-block pt-1 pb-1 ps-3 pe-3">
            {{text('dynamicDistribution.results.balance_component.title')}}
        </div>
    </div>
    <div class="header d-flex">
        <div class="legend d-flex flex-column ">
            <div class="item d-flex flex-row">
                <div class="square lightGreen me-1"></div>
                <div>{{text('exercise.dynamic_distribution_evaluation.balance_component_first_legend_title')}}</div>
            </div>
            <div class="item d-flex flex-row">
                <div class="square green me-1"></div>
                <div>{{text('exercise.dynamic_distribution_evaluation.balance_component_sd_legend_title')}}</div>
            </div>
        </div>
        <div class="avgDistribution d-flex flex-column align-items-center">
            <b>{{text('exercise.dynamic_distribution_evaluation.balance_component_average_title')}}</b>
            <app-left-right-text [leftValue]="avgLeftDistribution" [rightValue]="avgRightDistribution">
            </app-left-right-text>
        </div>
        <div class="d-flex flex-column"></div>
    </div>
    <div>
        <app-balance [value]="avgRightDistributionPercent"
                     [targetTolerance]="targetTolerance"
                     [areaOfInterest]="stdToDistribution"
                     [maxLeftDistribution]="maxLeftDistribution"
                     [minLeftDistribution]="minLeftDistribution"
                     [maxRightDistribution]="maxRightDistribution"
                     [minRightDistribution]="minRightDistribution"
        >
        </app-balance>
    </div>
    <div class="std text-center mt-3 mb-3">
        <b>{{text('exercise.dynamic_distribution_evaluation.balance_component_minus_sd_title')}}</b>
        <span class="ms-1 me-2">{{stdValue.format(1)}}</span>
        <b>{{text('exercise.dynamic_distribution_evaluation.balance_component_plus_sd_title')}}</b>
        <span class="ms-1">{{stdValue.format(1)}}</span>
    </div>
</div>
