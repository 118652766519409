<div class="mb-4" *ngIf="viewType === resultViewTypes.FULL">
    <div class="section row">
        <div class="col-3">{{'instructions' | text}}</div>
        <div class="col-3">
            <app-value-widget [title]="'sets' | text"
                              [iconType]="valueWidgetIcons.REPEAT"
                              [value]="sets"
                              [valueFractionDigits]="0">
            </app-value-widget>
        </div>
        <div class="col-3">
            <app-value-widget [title]="'reps' | text"
                              [iconType]="valueWidgetIcons.REPEAT"
                              [value]="reps"
                              [valueFractionDigits]="0">
            </app-value-widget>
        </div>
        <div class="col-3">
            <app-value-widget [title]="'set_rest' | text"
                              [iconType]="valueWidgetIcons.PAUSE"
                              [value]="rest"
                              [valueFractionDigits]="0">
            </app-value-widget>
        </div>
    </div>
    <mat-divider class="mt-3"></mat-divider>
    <div class="section section-charts row mt-4">
        <div class="col-6 offset-3">
            <div class="section d-flex flex-column" *ngFor="let set of bodyChartData; index as i">
                <h3 class="fw-bold">{{'set.number' | text: (i + 1).toString() }}</h3>
                <app-body-side-bar-chart class="chart flex-grow-1"
                                         [data]="set"
                                         [groupBy]="'BodySide'"
                                         [annotations]="targets"
                >
                </app-body-side-bar-chart>
            </div>
        </div>
    </div>
    <div class="section row mt-4">
        <div class="col-8 offset-2 d-flex flex-row">
            <div class="d-flex flex-column flex-grow-1">
                <app-table-header>{{'repCoutResults.sites.title'|text}}</app-table-header>
                <app-table-title *ngFor="let set of bodyChartData; index as i" class="text-center">{{i + 1}}</app-table-title>
            </div>
            <div class="d-flex flex-column flex-grow-1">
                <app-table-header>{{'pace.per_min' | text}}</app-table-header>
                <div class="d-flex flex-row" *ngFor="let paceData of paces; index as i">
                    <app-table-value class="flex-grow-1"
                                     *ngFor="let sideData of paceData.value"
                                     [color]="sideData.value"
                    >{{sideData.key.format(1)}}
                    </app-table-value>
                </div>
            </div>
            <div class="d-flex flex-column flex-grow-1">
                <app-table-header>{{'rate.success' | text: '%'}}</app-table-header>
                <div class="d-flex flex-row" *ngFor="let successRateData of successRates; index as i">
                    <app-table-value class="flex-grow-1"
                                     *ngFor="let sideData of successRateData.value"
                                     [color]="sideData.value"
                    >{{sideData.key.format(0)}}
                    </app-table-value>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="viewType === resultViewTypes.ROW">
    <div class=" d-flex flex-row">
        <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
        <div class="flex-grow-1 overflow-auto d-flex flex-row mt-3">
            <div class="flex-grow-1 text-center">
                <app-table-header>{{'data'|text}}</app-table-header>
                <app-table-title>{{'report.chartDataType.average'|text}} ({{unitTypeUtils.symbol(unitTypes.KG)}})</app-table-title>
                <app-table-title>{{'pace.per_min'|text}}</app-table-title>
                <app-table-title>{{'rate.success'|text:unitTypeUtils.symbol(unitTypes.PERCENT)}}</app-table-title>
            </div>
            <div class="flex-grow-1 text-center"
                 *ngFor="let sideStats of rowStats | keyvalue"
            >
                <app-table-header [backgroundColor]="formatSideAttribute(sideStats.key, sideTypeAttributes.COLOR)"
                                  color="white">{{formatSideAttribute(sideStats.key, sideTypeAttributes.TITLE)}}</app-table-header>
                <app-table-value [sideForColor]="sideStats.key">{{sideStats.value[0] | toValue: unitTypes.NONE: 1}}</app-table-value>
                <app-table-value [sideForColor]="sideStats.key">{{sideStats.value[1] | toValue: unitTypes.NONE: 1}}</app-table-value>
                <app-table-value [sideForColor]="sideStats.key">{{sideStats.value[2] | toValue: unitTypes.NONE: 1}}</app-table-value>
            </div>
        </div>
    </div>
</ng-container>
