import {Component, Input, OnInit} from '@angular/core';
import {ParticipantDto} from "../../../dtos/participant.dto";
import {BaseComponent} from "../../base/base.component";
import {DateFormat, Dates, GenderUtils} from "common";

@Component({
    selector: 'app-protocol-results-export-header',
    templateUrl: './protocol-results-export-header.component.html',
    styleUrls: ['./protocol-results-export-header.component.scss']
})
export class ProtocolResultsExportHeaderComponent extends BaseComponent implements OnInit {

    @Input()
    public participant: ParticipantDto;

    @Input()
    public painScaleValue?: number;

    @Input()
    public comments?: string;

    @Input()
    public dateStart: Date;
    @Input()
    public dateEnd?: Date;

    public title: string;
    public participantInfo: string;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!this.dateEnd || this.dateStart.getTime() === this.dateEnd.getTime()) {
            this.title = `Report - ${Dates.format(this.dateStart, DateFormat.DATE_LONG)}`;
        } else {
            this.title = `Report - ${Dates.format(this.dateStart, DateFormat.DATE_LONG)} - ${Dates.format(this.dateEnd, DateFormat.DATE_LONG)}`;
        }

        if (this.participant) {
            const gender = GenderUtils.format(this.participant.genderType);
            const age = this.participant.age;
            this.participantInfo = `${gender}, ${age} yo`;
        } else {
            this.participantInfo = '';
        }
    }
}
