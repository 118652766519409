import GameTypes, {GameType} from './gameType';
import {I18nService} from '../services/i18n/i18n.service';
import {Title} from "common";

export enum ExerciseType {
    UNKNOWN = 'UNKNOWN',
    METER = 'METER',
    KINESTHESIA = 'KINESTHESIA',
    MAX_EVALUATION = 'MAX_EVALUATION',
    STATIC_DISTRIBUTION_EXERCISE = 'STATIC_DISTRIBUTION_EXERCISE',
    STABILITY = 'STABILITY',
    STANDING_EVALUATION = 'STANDING_EVALUATION',
    DYNAMIC_DISTRIBUTION_EVALUATION = 'DYNAMIC_DISTRIBUTION_EVALUATION',
    JUMP_ANALYSIS = 'JUMP_ANALYSIS',
    MOTION_EVALUATION = 'MOTION_EVALUATION',
    NORDIC_HAMSTRING = 'NORDIC_HAMSTRING',
    REP_COUNT = 'REP_COUNT',
    METER_ENDURANCE = 'METER_ENDURANCE',
    ISOMETRIC = 'ISOMETRIC',
    KINESTHESIA_OBSTACLES = 'KINESTHESIA_OBSTACLES',
    TOTAL_EVALUATION = 'TOTAL_EVALUATION',
    SENSORLESS = 'SENSORLESS'
}

export class ExerciseTypes {
    public static title(i18n: I18nService, exerciseType: ExerciseType, gameType?: GameType): Title {
        let title;
        switch (exerciseType) {
            case ExerciseType.METER:
            case ExerciseType.TOTAL_EVALUATION:
            case ExerciseType.METER_ENDURANCE:
                title = i18n.text('exercise.type.maxEvaluation.title');
                break;
            case ExerciseType.MAX_EVALUATION:
                title = i18n.text('exercise_template_builtin_generic_title_max_declination');
                break;
            case ExerciseType.ISOMETRIC:
            case ExerciseType.STATIC_DISTRIBUTION_EXERCISE:
                title = i18n.text('exercise_template_builtin_generic_title_isometric_declination');
                break;
            case ExerciseType.REP_COUNT:
            case ExerciseType.NORDIC_HAMSTRING:
            case ExerciseType.DYNAMIC_DISTRIBUTION_EVALUATION:
                title = i18n.text('repetition.count.title');
                break;
            case ExerciseType.KINESTHESIA_OBSTACLES:
                title = GameTypes.getTitle(i18n, gameType);
                break;
            default:
                title = '';
                break;
        }
        return title;
    }

    public static icon(exerciseType: ExerciseType): string {
        let resource: string;
        switch (exerciseType) {
            case ExerciseType.UNKNOWN:
                resource = undefined;
                break;
            case ExerciseType.METER:
            case ExerciseType.METER_ENDURANCE:
            case ExerciseType.TOTAL_EVALUATION:
                resource = './assets/icons/ic_symbol_max_evaluation.png';
                break;
            case ExerciseType.ISOMETRIC:
            case ExerciseType.STATIC_DISTRIBUTION_EXERCISE:
                resource = './assets/icons/ic_symbol_isometric.png';
                break;
            case ExerciseType.REP_COUNT:
            case ExerciseType.NORDIC_HAMSTRING:
            case ExerciseType.DYNAMIC_DISTRIBUTION_EVALUATION:
                resource = './assets/icons/ic_symbol_rep_count.png';
                break;
            case ExerciseType.JUMP_ANALYSIS:
                resource = './assets/icons/ic_symbol_jump_detail.png';
                break;
            case ExerciseType.STANDING_EVALUATION:
                resource = './assets/icons/ic_symbol_stance_detail.png';
                break;
            default:
                resource = undefined;
                break;
        }
        return resource;
    }
}
