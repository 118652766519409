<div class="d-block position-relative full-width full-height">
    <div class="full-width full-height">
        <canvas baseChart
                [datasets]="datasets"
                [labels]="dataTitles"
                [options]="chartOptions"
                [plugins]="chartPlugins"
                chartType="bar"
        >
        </canvas>
    </div>
    <div *ngIf="annotations" class="annotations d-flex flex-row justify-content-end">
        <div class="annotation ms-4 position-relative" *ngFor="let annotation of annotations"
             [style.color]="annotation.color" [style.border-color]="'red'"
        >
            {{annotation.title}}
        </div>
    </div>
</div>
