import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderingType, OrderingTypes} from '../../viewmodels/orderingType';
import {BaseComponent} from "../base/base.component";
import {KeyValue} from "common";

@Component({
    selector: 'app-order-selection',
    templateUrl: './order-selection.component.html',
    styleUrls: ['./order-selection.component.scss']
})
export class OrderSelectionComponent extends BaseComponent implements OnInit {

    @Input()
    public defaultOrder: OrderingType;

    @Input()
    public orderOptions: OrderingType[];

    @Output()
    public orderChanged: EventEmitter<OrderingType>;

    public items: Array<KeyValue<OrderingType, string>>;

    constructor() {
        super();

        this.orderChanged = new EventEmitter<OrderingType>();
    }

    ngOnInit(): void {
        this.items = [];
        for (const orderingType of this.orderOptions) {
            this.items.push(KeyValue.of(orderingType, OrderingTypes.title(this.i18n, orderingType)));
        }
    }

    onValueChanged($event: any) {
        this.orderChanged.emit($event);
    }
}
