import {Pipe, PipeTransform} from '@angular/core';
import {Value} from "../values/value";
import {UnitType} from "../values/unitType";

/**
 * Formats a Value type
 */
@Pipe({
    name: 'value',
    pure: true
})
export class ValuePipe implements PipeTransform {

    transform(value: Value, fractionDigits: number = 2, formatUnitType?: UnitType): string {
        if (!formatUnitType) {
            return value.format(fractionDigits);
        } else {
            return value.formatTo(formatUnitType, fractionDigits);
        }
    }

}
