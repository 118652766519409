<div class="component d-flex flex-row full-width full-height">
    <div *ngIf="displayRepetitionOrdinal" class="repetition-index d-flex align-items-center d-inline-block">
        {{repetitionResults[0].repOrdinal}}
    </div>
    <div class="flex-grow-1 d-flex flex-row">
        <app-body-side-bar-chart class="max-per-side overflow-hidden"
                                 [data]="maxPerSideData"
                                 valueFractionDigits="1"
        ></app-body-side-bar-chart>
        <app-meter-endurance-row-stats *ngIf="showStatsTable" class="stats-table ms-3 me-3 overflow-hidden"
                                       [repetitionResults]="repetitionResults"
                                       [mediumType]="mediumType"
        ></app-meter-endurance-row-stats>
        <app-samples-line-chart class="graphs overflow-hidden"
                                [data]="samplesData"
                                [total]="totalData"
                                [weight]="target"
        ></app-samples-line-chart>
    </div>
</div>
