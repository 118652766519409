import {ChartLayoutPaddingObject} from 'chart.js';
import {Drawing} from '../graphics/drawing';
import {Color, Title} from "common";

export class YAxisDualTitleChartjsPlugin {
    public positiveTitle: Title;
    public negativeTitle: Title;
    public yAxisId: string;
    public color: Color;
    public fontSize: number;
    public fontFamily: string;
    private initialized: boolean;
    private padding: number;

    constructor() {
        this.initialized = false;
        this.color = 'black';
        this.fontSize = 13;
    }

    afterInit?(chart): void {
        if (!this.positiveTitle && !this.negativeTitle) {
            return;
        }

        const titlePadding = 7;
        this.padding = Drawing.measureTextHeight(chart.chart.ctx, this.positiveTitle + this.negativeTitle, `${this.fontSize}px`) + titlePadding;
        (chart.chart.options.layout.padding as ChartLayoutPaddingObject).left = this.padding;
    }

    afterDraw(chart) {
        if (!this.positiveTitle && !this.negativeTitle) {
            return;
        }

        const ctx = chart.chart.ctx;
        const yAxis = chart.scales[this.yAxisId];
        const yZero = yAxis.getPixelForValue(0);
        const fontSize = this.fontSize;

        ctx.save();
        ctx.fillStyle = this.color;
        ctx.textAlign = 'center';
        ctx.font = `${this.fontSize}px ${this.fontFamily}`;
        ctx.rotate(-Math.PI / 2);
        ctx.fillText(this.positiveTitle, yZero / -2, fontSize);
        ctx.fillText(this.negativeTitle, (yAxis.bottom + yZero) / -2, fontSize);
        ctx.restore();

        // Horizontal line
        ctx.save();
        ctx.strokeStyle = this.color;
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(0, yZero);
        ctx.lineTo(this.padding + 3, yZero);
        ctx.stroke();
        ctx.restore();
    }
}
