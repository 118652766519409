import {Component, OnInit} from '@angular/core';
import {ResultsComponent} from "../resultsComponent";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {StaticDistributionActivityResultsModel} from "../../../dtos/statistics/staticDistributionActivityResultsModel";
import {Percent} from "../../../utils/values/percent";

@Component({
    selector: 'app-static-distribution-results',
    templateUrl: './static-distribution-results.component.html',
    styleUrls: ['./static-distribution-results.component.scss']
})
export class StaticDistributionResultsComponent extends ResultsComponent implements OnInit {
    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: StaticDistributionActivityResultsModel;

    public percentFormatted: string[];

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. SingleLegJumpAnalysisComponent`, this.analysis);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.analysis.activitiesResults[0].activityResultsModel as StaticDistributionActivityResultsModel;
        const statistics = this.statistics;

        this.percentFormatted = [];
        for (const result of this.statistics.repResults) {
            this.percentFormatted.push(new Percent(result.success).format(1));
        }
    }
}
