import {ChartDataSets, ChartOptions} from 'chart.js';
import {ProgressOverTimeFilter} from '../../../viewmodels/progressOverTimeFilter';
import {Label} from 'ng2-charts';
import {format} from 'date-fns';
import {I18nService} from '../../../services/i18n/i18n.service';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {ProtocolsLineChartProvider} from './protocolsLineChartProvider';
import {Color, DateFormat, Dates, KeyValue} from "common";
import {ActivityConfigSetMetaKeys} from '../../../models/activityConfigSetMetaKeys';
import {StanceEvaluationActivityResultsModel} from '../../../dtos/statistics/stanceEvaluationActivityResultsModel';
import {Protocols} from '../../../utils/protocols';
import {MathUtils} from "../../../utils/mathUtils";
import {Colors} from "../../../services/colors";
import {ChartUtils} from "../../../utils/chartUtils";
import {Kg} from "../../../utils/values/kg";

export class AdvancedStandingEvaluationLineChartProvider extends ProtocolsLineChartProvider {
    private datasets: Map<boolean, ChartDataSets>;

    constructor() {
        super();
        this.datasets = new Map<boolean, ChartDataSets>();
    }

    getDatasets(protocols: ProtocolDto[], analysis: ProtocolAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: ChartOptions, i18n: I18nService): KeyValue<Label[], ChartDataSets[]> {
        this.datasets.clear();
        const protocolsByDay = Protocols.groupByDay(protocols);
        // Due to how charts lib works, labels are shared among all datasets, and where a dataset doesn't have a value, they have a NaN value
        const days = Array.from(protocolsByDay.keys()).sort();

        // create labels
        const labels: Label[] = days.map(day => format(day, Dates.getFormat(DateFormat.DATE_SHORTER)));

        const datasets: ChartDataSets[] = [];
        let maxValue = 0;

        const datasetOpen = this.createActivitySideDataset(true, protocolsByDay, analysis, days, i18n);
        maxValue = Math.max(maxValue, ...(datasetOpen.data as number[]).filter(value => !Number.isNaN(value)));
        datasets.push(datasetOpen);

        const datasetClosed = this.createActivitySideDataset(false, protocolsByDay, analysis, days, i18n);
        maxValue = Math.max(maxValue, ...(datasetClosed.data as number[]).filter(value => !Number.isNaN(value)));
        datasets.push(datasetClosed);

        chartOptions.scales.yAxes[0].ticks = {min: 0, max: maxValue * 1.2};

        // Chart options
        if (!chartOptions.plugins) {
            chartOptions.plugins = {};
        }
        chartOptions.plugins.datalabels = {
            formatter: this.labelFormatter.bind(this),
            anchor: 'end',
            align: 'end',
        };

        return KeyValue.of(labels, datasets);
    }

    private createActivitySideDataset(eyesOpen: boolean, protocolsPerDay: Map<number, ProtocolDto[]>, analysis: ProtocolAnalysisServiceResponseDto, days: number[], i18n: I18nService): ChartDataSets {
        const dataset: ChartDataSets = {};
        dataset.label = eyesOpen ? i18n.format("stance_surface_eyes_open" ) : i18n.format("stance_surface_eyes_closed" );

        dataset.data = [];

        dataset.spanGaps = true;
        dataset.lineTension = 0;

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value: number = NaN;
            // Create dataset with values for metrics.
            // Add NaN when there is no value for the day except for the last day, where we add the previous value
            if (protocolsPerDay.has(day)) {
                let activities = protocolsPerDay.get(day)
                    .flatMap(p => p.activities)
                    .filter(a => a.config.activityConfigSets.some(s => s.getMetaBoolean(ActivityConfigSetMetaKeys.EYES_OPEN) === eyesOpen));
                const activitiesToResults = this.combineActivitiesToResults<StanceEvaluationActivityResultsModel>(activities, analysis);
                const activitiesResults = activities.map(a => activitiesToResults.get(a)).filter(r => r !== undefined);
                const surfaces = activitiesResults.flatMap(r => r.surface);
                value = MathUtils.maxOrDefault(surfaces, NaN);
            }
            dataset.data.push(value);
        }

        let color: Color;
        color = eyesOpen ? Colors.colorAccent : Colors.colorPrimary;

        this.datasets.set(eyesOpen, dataset);

        ChartUtils.formatDatasetWithColor(dataset, color);
        return dataset;
    }

    private labelFormatter(value: number): string {
        // default to integer
        let formatted: string = value.toFixed(0);

        // find the value in the datasets
        let found = false;
        for (const dataset of Array.from(this.datasets.values())) {
            for (const datum of dataset.data) {
                if (datum === value) {
                    formatted = new Kg(value).format(1);
                    found = true;
                    break;
                }
            }
            if (found) {
                break;
            }
        }
        return formatted;
    }
}
