<div class="d-flex flex-column justify-content-center">
    <div class="image me-3">
        <app-image [image]="image" [lazily]="false" [description]="activityTitle(activity)"></app-image>
    </div>
    <div *ngIf="displayConfig" class="config">
        <div class="title">{{text('protocolResults.summary.configuration.title')}}</div>
        <div class="params ps-2">
            <div class="param">{{text('protocolResults.summary.configuration.repTitle')}}{{this.activityAnalysis.config.repetition}}</div>
            <div class="param">{{text('protocolResults.summary.configuration.durationTitle')}}{{this.activityAnalysis.config.duration}}</div>
            <div class="param">{{text('protocolResults.summary.configuration.restTitle')}}{{this.activityAnalysis.config.rest}}</div>
        </div>
    </div>
</div>
