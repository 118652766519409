import {Component, OnInit} from '@angular/core';
import {BodySideType} from "../../../models/bodySideType";
import {UnipodalJumpAnalysisActivityResultsModel} from "../../../dtos/statistics/unipodalJumpAnalysisActivityResultsModel";
import {ValueWidgetIcon} from "../../../viewmodels/valueWidgetIcons";
import {Sample} from "../../../utils/sample";
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {Resource} from "common";
import {ActivityImageResolverService} from "../../../services/activity-image-resolver.service";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ResultsComponent} from "../resultsComponent";
import {UnitType, UnitTypes} from "../../../utils/values/unitType";
import {Value} from "../../../utils/values/value";
import {Seconds} from "../../../utils/values/seconds";
import {Percent} from "../../../utils/values/percent";
import {Kg} from "../../../utils/values/kg";

@Component({
    selector: 'app-single-leg-jump-analysis',
    templateUrl: './single-leg-jump-analysis.component.html',
    styleUrls: ['./single-leg-jump-analysis.component.scss']
})
export class SingleLegJumpAnalysisComponent extends ResultsComponent implements OnInit {
    public readonly valueWidgetIcons: typeof ValueWidgetIcon = ValueWidgetIcon;
    public readonly unitTypeUtils = UnitTypes;
    public readonly unitTypes = UnitType;

    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: UnipodalJumpAnalysisActivityResultsModel;

    public image: Resource;
    public maxPerSide: Map<BodySideType, Value>;
    public hasLeft: boolean;
    public hasRight: boolean;

    public timeOnAirLeft: Seconds;
    public jumpHeightLeft: Value;
    public timeOnAirRight: Seconds;
    public jumpHeightRight: Value;
    public maxForceLeft: Value;
    public rfdLeft: Value;
    public impulsionLeft: Value;
    public maxForceRight: Value;
    public rfdRight: Value;
    public impulsionRight: Value;
    public jumpHeightDeficit: Percent;
    public impulsionDeficit: Percent;
    public maxForceDeficit: Percent;
    public rfdDeficit: Percent;
    public samplesLeft: Map<BodySideType, Sample[]>;
    public rfdPointsLeft: Sample[];
    public timeOnAirPointsLeft: Sample[];
    public samplesRight: Map<BodySideType, Sample[]>;
    public rfdPointsRight: Sample[];
    public timeOnAirPointsRight: Sample[];
    public weight: number;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. SingleLegJumpAnalysisComponent`, this.analysis);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.analysis.activitiesResults[0].activityResultsModel as UnipodalJumpAnalysisActivityResultsModel;
        const statistics = this.statistics;

        // common
        this.image = this.activityImageResolverService.do(this.activity.image);

        // Max per side
        this.maxPerSide = new Map<BodySideType, Value>();
        for (const sideResults of statistics.resultsPerSide) {
            this.maxPerSide.set(sideResults.side, new Kg(sideResults.maxForce));
        }
        this.hasLeft = this.maxPerSide.has(BodySideType.LEFT);
        this.hasRight = this.maxPerSide.has(BodySideType.RIGHT);
        const resultsLeft = statistics.resultsPerSide.find(r => r.side === BodySideType.LEFT);
        const resultsRight = statistics.resultsPerSide.find(r => r.side === BodySideType.RIGHT);

        // Metrics
        if (this.hasLeft) {
            this.timeOnAirLeft = new Seconds(resultsLeft.timeOnAir);
            this.jumpHeightLeft = new Value(resultsLeft.maxJumpHeight);
        }
        if (this.hasRight) {
            this.timeOnAirRight = new Seconds(resultsRight.timeOnAir);
            this.jumpHeightRight = new Value(resultsRight.maxJumpHeight);
        }

        // Table
        if (this.hasLeft) {
            this.maxForceLeft = new Value(resultsLeft.maxForce);
            this.rfdLeft = new Value(resultsLeft.totalRfd);
            this.impulsionLeft = new Value(resultsLeft.impulsion);
        }
        if (this.hasRight) {
            this.maxForceRight = new Value(resultsRight.maxForce);
            this.rfdRight = new Value(resultsRight.totalRfd);
            this.impulsionRight = new Value(resultsRight.impulsion);
        }
        if (this.hasLeft && this.hasRight) {
            this.jumpHeightDeficit = new Percent(statistics.heightDeficit);
            this.maxForceDeficit = new Percent(statistics.maxForcesDeficit);
            this.rfdDeficit = new Percent(statistics.rfdsDeficit);
            this.impulsionDeficit = new Percent(statistics.impulsionsDeficit);
        }

        // Charts
        if (this.hasLeft) {
            this.samplesLeft = new Map<BodySideType, Sample[]>();
            let jumpTime = resultsLeft.pointWhereJumpProcedureStarted.time;
            this.samplesLeft.set(BodySideType.LEFT, resultsLeft.forces
                .filter(value => value.time >= jumpTime)
                .map(value => new Sample(value.time - jumpTime, value.force)));
            this.rfdPointsLeft = [];
            this.rfdPointsLeft.push(new Sample(resultsLeft.minValuePair.time - jumpTime, resultsLeft.minValuePair.force));
            this.rfdPointsLeft.push(new Sample(resultsLeft.maxValuePair.time - jumpTime, resultsLeft.maxValuePair.force));
            this.timeOnAirPointsLeft = [];
            if (resultsLeft.jump) {
                this.timeOnAirPointsLeft.push(new Sample(resultsLeft.jump.timestamp - resultsLeft.jump.timeOnAir - jumpTime, 3));
                this.timeOnAirPointsLeft.push(new Sample(resultsLeft.jump.timestamp - jumpTime, 3));
            }
        }
        if (this.hasRight) {
            this.samplesRight = new Map<BodySideType, Sample[]>();
            let jumpTime = resultsRight.pointWhereJumpProcedureStarted.time;
            this.samplesRight.set(BodySideType.RIGHT, resultsRight.forces
                .filter(value => value.time >= jumpTime)
                .map(value => new Sample(value.time - jumpTime, value.force)));
            this.rfdPointsRight = [];
            this.rfdPointsRight.push(new Sample(resultsRight.minValuePair.time - jumpTime, resultsRight.minValuePair.force));
            this.rfdPointsRight.push(new Sample(resultsRight.maxValuePair.time - jumpTime, resultsRight.maxValuePair.force));
            this.timeOnAirPointsRight = [];
            if (resultsRight.jump) {
                this.timeOnAirPointsRight.push(new Sample(resultsRight.jump.timestamp - resultsRight.jump.timeOnAir - jumpTime, 3));
                this.timeOnAirPointsRight.push(new Sample(resultsRight.jump.timestamp - jumpTime, 3));
            }
        }
        this.weight = statistics.averageWeight;
    }
}
