import PlatePhysicalDevice from "./platePhysicalDevice";
import {DeltasPhysicalDevice} from "./deltasPhysicalDevice";
import {PhysicalDevice} from "./physicalDevice";
import {DeviceType} from "common";

export class PhysicalDevices {
    public static forDevice(device: DeviceType): PhysicalDevice {
        let physicalDevice: PhysicalDevice;
        if (device === DeviceType.PLATES) {
            physicalDevice = PlatePhysicalDevice.classic();
        } else if (device === DeviceType.HEXAS) {
            physicalDevice = DeltasPhysicalDevice.classic();
        }
        return physicalDevice;
    }
}
