import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../components/base/base.component";

@Component({
    selector: 'app-pro-plus-promo',
    templateUrl: './pro-plus-promo.component.html',
    styleUrls: ['./pro-plus-promo.component.scss']
})
export class ProPlusPromoComponent extends BaseComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.analyticsSwitchPage('ProPlus paywall', undefined, '/features/proPlus');
    }

}
