<div>
    <ng-container *ngIf="viewType === resultViewTypes.FULL">
        <ng-container *ngTemplateOutlet="resultsTable"></ng-container>
    </ng-container>
    <ng-container *ngIf="viewType === resultViewTypes.ROW">
        <div class=" d-flex flex-row">
            <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
            <div class="flex-grow-1 d-flex align-items-center justify-content-center">
                <ng-container *ngTemplateOutlet="resultsTable"></ng-container>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #resultsTable>
    <div class="col-8 offset-2 d-flex flex-row mb-5">
        <div class="flex-grow-1">
            <app-table-header [backgroundColor]="formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.COLOR)" [compact]="viewType !== resultViewTypes.FULL" color="white">
                {{text('dataTable.header.repetition')}}
            </app-table-header>
            <app-table-value *ngFor="let rep of statistics.repResults" [compact]="viewType !== resultViewTypes.FULL">{{rep.ordinal}}</app-table-value>
        </div>
        <div class="flex-grow-1">
            <app-table-header [backgroundColor]="formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.COLOR)" [compact]="viewType !== resultViewTypes.FULL" color="white">
                {{text('dataTable.header.points')}}
            </app-table-header>
            <app-table-value *ngFor="let rep of statistics.repResults" [compact]="viewType !== resultViewTypes.FULL">{{rep.points}}</app-table-value>
        </div>
        <div class="flex-grow-1">
            <app-table-header [backgroundColor]="formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.COLOR)" [compact]="viewType !== resultViewTypes.FULL" color="white">
                {{text('dataTable.header.percentage')}}
            </app-table-header>
            <app-table-value *ngFor="let percent of percentFormatted" [compact]="viewType !== resultViewTypes.FULL">{{percent}}</app-table-value>
        </div>
    </div>
</ng-template>
