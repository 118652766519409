import {Injectable} from '@angular/core';
import {LengthUnit, UnitType, WeightUnit} from "../utils/values/unitType";

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {

    public weightUnit: WeightUnit;
    public lengthUnit: LengthUnit;

    constructor() {
        this.weightUnit = UnitType.KG;
        this.lengthUnit = UnitType.CM;
    }
}
