import {Component, Input, OnInit} from '@angular/core';
import {Range} from "../../models/range";
import {BaseComponent} from "../base/base.component";

@Component({
    selector: 'app-balance',
    templateUrl: './balance.component.html',
    styleUrls: ['./balance.component.scss']
})
export class BalanceComponent extends BaseComponent implements OnInit {
    @Input()
    public targetTolerance: Range;

    @Input()
    public areaOfInterest: Range;

    @Input()
    public value: number; // [0,1]

    @Input()
    public maxLeftDistribution: number;
    @Input()
    public minLeftDistribution: number;
    @Input()
    public maxRightDistribution: number;
    @Input()
    public minRightDistribution: number;

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    public scale(): number[] {
        return [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    }
}
