import {ActivityAnalysisResultDto} from "./activityAnalysisResultDto";
import {Code, Dto} from "common";
import {ProtocolSummaryResultsModelDto} from "./protocolSummaryResultsModelDto";
import {BuiltinProtocols} from "../../models/builtinProtocols";
import {AdvancedStanceEvaluationProtocolSummaryResultsModel} from "./advancedStanceEvaluationProtocolSummaryResultsModel";
import {ForceVelocityProtocolSummaryResultsModelDto} from "./forceVelocityProtocolSummaryResultsModelDto";
import {DynamicStrengthIndexSummaryResultsModelDto} from "./dynamicStrengthIndexSummaryResultsModelDto";

export class ProtocolAnalysisResultDto extends Dto {
    public protocolCode: Code;
    public activitiesResults: ActivityAnalysisResultDto[];
    public protocolSummaryResults: string;
    public protocolSummaryResultsModel: ProtocolSummaryResultsModelDto;
    public protocolConfigBaseConfigCode: Code;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.activitiesResults = this.deserializeArray(input.activitiesResults, ActivityAnalysisResultDto);

        const parsedResults = JSON.parse(input.protocolSummaryResults);
        switch (input.protocolConfigBaseConfigCode) {
            case BuiltinProtocols.IDENTIFIERS.DSI:
                this.protocolSummaryResultsModel = new DynamicStrengthIndexSummaryResultsModelDto().deserialize(parsedResults);
                break;
            case BuiltinProtocols.IDENTIFIERS.ADVANCED_STANDING_EVALUTATION:
                this.protocolSummaryResultsModel = new AdvancedStanceEvaluationProtocolSummaryResultsModel().deserialize(parsedResults);
                break;
            case BuiltinProtocols.IDENTIFIERS.FORCE_VELOCITY:
                this.protocolSummaryResultsModel = new ForceVelocityProtocolSummaryResultsModelDto().deserialize(parsedResults);
                break;
        }
        return this;
    }
}
