import {Component} from '@angular/core';
import {Value} from "../../utils/values/value";
import {UnitType, UnitTypes} from "../../utils/values/unitType";
import {AccountDto, CommonBaseComponent, DeviceType, LogEntry, LoggerService, LogLevel, ServiceInjectorService, Stack, Title} from "common";
import {MatDialog} from "@angular/material/dialog";
import {Colors} from '../../services/colors';
import {ExerciseType} from "../../models/exerciseType";
import {BodySideType, BodySideTypes} from "../../models/bodySideType";
import {BodySideAttribute} from "../../viewmodels/bodySideAttribute";
import {I18nService} from "../../services/i18n/i18n.service";
import {AuthenticationService} from "../../services/api/authentication.service";
import {UserSettingsService} from "../../services/user-settings.service";
import {NavigationService} from "../../services/navigation.service";
import {ActivityDto} from "../../dtos/activity.dto";
import {Activities} from "../../utils/activities";
import {environment} from "../../../environments/environment";

/**
 * Base component for all our component, where it makes sense. Simple ones might not need this.
 */
@Component({
    selector: 'app-base',
    template: '',
    styles: [''],
})
export class BaseComponent extends CommonBaseComponent {
    // Enums
    public readonly exerciseTypes = ExerciseType;
    public readonly deviceTypes = DeviceType;
    public readonly sideTypes = BodySideType;
    public readonly sideTypeAttributes = BodySideAttribute;
    public readonly colors = Colors;
    public readonly unitTypes = UnitType;
    public readonly unitTypeUtils = UnitTypes;

    // Services
    protected readonly i18n: I18nService;
    protected readonly authenticationService: AuthenticationService;
    public readonly userSettings: UserSettingsService;
    protected readonly navigation: NavigationService;
    private readonly logger: LoggerService;
    protected readonly dialog: MatDialog;
    private readonly className: string;

    constructor() {
        super();
        this.i18n = ServiceInjectorService.injector.get(I18nService);
        this.authenticationService = ServiceInjectorService.injector.get(AuthenticationService);
        this.userSettings = ServiceInjectorService.injector.get(UserSettingsService);
        this.navigation = ServiceInjectorService.injector.get(NavigationService);
        this.dialog = ServiceInjectorService.injector.get(MatDialog);
        this.logger = ServiceInjectorService.injector.get(LoggerService);

        this.className = this.constructor["name"].replace('Component', '');
    }

    /**
     * Translates a text to user language
     */
    public text(key: string): string {
        return this.i18n.text(key);
    }

    /**
     * Translates a text to user language and formats it with the passed values
     */
    public format(key: string, ...formatArgs: string[]): string {
        return this.i18n.format(key, ...formatArgs);
    }

    /**
     * Formats a value to the user's preferred units
     */
    public formatValue(value: Value, fractionDigits: number = 2): string {
        let formatted: string;
        if (UnitTypes.isLengthUnit(value.unit)) {
            formatted = value.formatTo(this.userSettings.lengthUnit, fractionDigits);
        } else if (UnitTypes.isWeightUnit(value.unit)) {
            formatted = value.formatTo(this.userSettings.weightUnit, fractionDigits);
        } else {
            formatted = value.format(fractionDigits);
        }
        return formatted;
    }

    /**
     * Returns the currently logged in account
     */
    public get userAccount(): AccountDto {
        return this.authenticationService.account;
    }

    public formatSideAttribute(side: BodySideType, attr: BodySideAttribute): string {
        // TODO: Convert to pipe
        let value: string;
        if (attr === BodySideAttribute.COLOR) {
            value = BodySideTypes.color(side)
        } else if (attr === BodySideAttribute.TITLE) {
            value = BodySideTypes.format(side);
        } else if (attr === BodySideAttribute.ICON) {
            value = BodySideTypes.icon(side);
        }
        return value;
    }

    public activityTitle(activity: ActivityDto): Title {
        return Activities.getTitle(this.i18n, activity);
    }

    public debug(message: string): void {
        this.logger.debug(LogEntry.of(LogLevel.DEBUG, `${this.className}.${Stack.getCallee(1)}`, message));
    }

    public info(message: string): void {
        this.logger.info(LogEntry.of(LogLevel.INFO, `${this.className}.${Stack.getCallee(1)}`, message));
    }

    public log(message: string): void {
        // Shorthand for info
        this.logger.info(LogEntry.of(LogLevel.INFO, `${this.className}.${Stack.getCallee(1)}`, message));
    }

    public warn(message: string): void {
        this.logger.warn(LogEntry.of(LogLevel.WARN, `${this.className}.${Stack.getCallee(1)}`, message));
    }

    public error(message: string): void {
        this.logger.error(LogEntry.of(LogLevel.ERROR, `${this.className}.${Stack.getCallee(1)}`, message));
    }

    public fatal(message: string): void {
        this.logger.fatal(LogEntry.of(LogLevel.FATAL, `${this.className}.${Stack.getCallee(1)}`, message));
    }

    protected analyticsSwitchPage(title: string, url: string, path: string): void {
        this.analytics.switchPage(environment.googleAnalytics.measurementId, title, url, path);
    }
}
