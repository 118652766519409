import {ProtocolAnalysisResultDto} from "./protocolAnalysisResultDto";
import {Dto} from "common";

export class ProtocolAnalysisServiceResponseDto extends Dto {
    public protocolsResults: ProtocolAnalysisResultDto[];

    deserialize(input: any): this {
        Object.assign(this, input);
        this.protocolsResults = this.deserializeArray(input.protocolsResults, ProtocolAnalysisResultDto);

        return this;
    }
}
