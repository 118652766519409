import {Component, Input, OnInit} from '@angular/core';
import {timer} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {BaseComponent} from "../base/base.component";
import {LoadingState} from "common";

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent extends BaseComponent implements OnInit {
    private static INITIAL_DELAY_MS = 1500;
    public initialDelayElapsed: boolean;

    @Input()
    public state: LoadingState;

    constructor() {
        super();

        this.initialDelayElapsed = false;
    }

    ngOnInit(): void {
        // Display the loading state after an initial delay
        timer(LoadingComponent.INITIAL_DELAY_MS)
            .pipe(takeUntil(this.destroySubject))
            .subscribe(_avoid => {
                this.initialDelayElapsed = true;
            })
    }

}
