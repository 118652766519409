/**
 * Immutable point on a 2d surface
 */
export class Point {
    constructor(public readonly x: number, public readonly y: number) {
    }

    public static of(x: number, y: number): Point {
        return new Point(x, y);
    }

    public static zero(): Point {
        return new Point(0, 0);
    }

    public static clone(other: Point): Point {
        return new Point(other.x, other.y);
    }

    toString(): string {
        return `x:${this.x.toFixed(2)}, y:${this.y.toFixed(2)}`;
    }
}
