export enum GraphicsShadeType {
    COLOR,
    GREY,
    BLACK_WHITE
}

export class GraphicsShadeTypes {
    public static all() {
        return [GraphicsShadeType.COLOR, GraphicsShadeType.GREY, GraphicsShadeType.BLACK_WHITE];
    }
}
