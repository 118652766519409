<div class="d-inline">
    <ng-container *ngIf="leftValue && rightValue; else noValue">
        <ng-container *ngIf="displayPercent">
            <span class="left">{{format('plates_distribution_results_component.side.left.value_percent', leftValue.toFixed(1))}}</span>
            <span class="ms-1 me-1"> / </span>
            <span class="right">{{format('plates_distribution_results_component.side.right.value_percent', rightValue.toFixed(1))}}</span>
        </ng-container>
        <ng-container *ngIf="!displayPercent">
            <span class="left">{{leftValue.toFixed(1)}}</span>
            <span>/</span>
            <span class="right">{{rightValue.toFixed(1)}}</span>
        </ng-container>
    </ng-container>
</div>

<ng-template #noValue>
    -
</ng-template>
