import {ChartDataSets, ChartOptions} from 'chart.js';
import {ActivityDto} from '../../../dtos/activity.dto';
import {ProgressOverTimeFilter} from '../../../viewmodels/progressOverTimeFilter';
import {MeterEnduranceActivityResultsModel} from '../../../dtos/statistics/meterEnduranceActivityResultsModel';
import {BodySideType, BodySideTypes} from '../../../models/bodySideType';
import {ProgressOverTimeMetricType} from '../../../viewmodels/progressOverTimeMetricType';
import {Label} from 'ng2-charts';
import {format} from 'date-fns';
import {I18nService} from '../../../services/i18n/i18n.service';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {ProtocolsLineChartProvider} from './protocolsLineChartProvider';
import {BuiltinProtocols} from '../../../models/builtinProtocols';
import {Code, Color, DateFormat, Dates, KeyValue} from "common";
import {Protocols} from '../../../utils/protocols';
import {MathUtils} from "../../../utils/mathUtils";
import {ChartUtils} from "../../../utils/chartUtils";
import {Colors} from "../../../services/colors";
import {Kg} from "../../../utils/values/kg";

export class IytLineChartProvider extends ProtocolsLineChartProvider {
    private activitiesDatasets: ChartDataSets[];

    constructor() {
        super();
        this.activitiesDatasets = [];
    }

    getDatasets(protocols: ProtocolDto[], analysis: ProtocolAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: ChartOptions, i18n: I18nService): KeyValue<Label[], ChartDataSets[]> {
        this.activitiesDatasets = [];

        const protocolsByDay = Protocols.groupByDay(protocols);
        // Due to how charts lib works, labels are shared among all datasets, and where a dataset doesn't have a value, they have a NaN value
        const days = Array.from(protocolsByDay.keys()).sort();

        // create labels
        const labels: Label[] = days.map(day => format(day, Dates.getFormat(DateFormat.DATE_SHORTER)));

        const datasets: ChartDataSets[] = [];
        const activities = protocols.flatMap(p => p.activities);
        const filteredActivities = this.getActivitiesFilter(activities, filter.metric.type);

        const activitiesToResults = this.combineActivitiesToResults<MeterEnduranceActivityResultsModel>(filteredActivities, analysis);
        const activitiesResults = Array.from(activitiesToResults.values()).filter(r => r !== undefined);
        const resultsSides = activitiesResults.flatMap(activitiesResult => activitiesResult.repResults.map(r => r.bodySideType));
        const sides = new Set<BodySideType>(resultsSides);

        const uniqueActivitiesCodes = new Set(filteredActivities.map(activity => activity.config.baseConfigCode));
        let maxValue = 0;
        for (const activity of uniqueActivitiesCodes) {
            for (const side of sides) {
                const dataset = this.createActivitySideDataset(activity, side, protocolsByDay, activitiesToResults, days);
                maxValue = Math.max(maxValue, ...(dataset.data as number[]).filter(value => !Number.isNaN(value)));
                this.activitiesDatasets.push(dataset);
                datasets.push(dataset);
            }
        }

        chartOptions.scales.yAxes[0].ticks = {min: 0, max: maxValue * 1.2};

        // Chart options
        if (!chartOptions.plugins) {
            chartOptions.plugins = {};
        }
        chartOptions.plugins.datalabels = {
            formatter: this.labelFormatter.bind(this),
            anchor: 'end',
            align: 'end',
        };

        return KeyValue.of(labels, datasets);
    }

    private createActivitySideDataset(activityCode: string, side: BodySideType, protocolsPerDay: Map<number, ProtocolDto[]>, activitiesToResults: Map<ActivityDto, MeterEnduranceActivityResultsModel>, days: number[]): ChartDataSets {
        const dataset: ChartDataSets = {};

        dataset.data = [];

        dataset.spanGaps = true;
        dataset.lineTension = 0;

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value: number = NaN;
            // Create dataset with values for metrics.
            // Add NaN when there is no value for the day except for the last day, where we add the previous value
            if (protocolsPerDay.has(day)) {
                const activities = protocolsPerDay.get(day).flatMap(p => p.activities).filter(a => a.config.baseConfigCode === activityCode);
                const activitiesResults = activities.map(a => activitiesToResults.get(a)).filter(r => r !== undefined);
                const reps = activitiesResults.flatMap(r => r.repResults);
                const sideReps = reps.filter(rep => rep.bodySideType === side);
                if (sideReps.length > 0) {
                    const maxValues = sideReps.map(r => r?.maxValue).filter(value => !Number.isNaN(value));
                    value = MathUtils.maxOrDefault(maxValues, NaN);
                } else if (i === days.length - 1 && days.length > 1) {
                    value = dataset.data[i - 1] as number;
                }
            }
            dataset.data.push(value);
        }

        let color: Color = this.getActivityCodeSideColor(activityCode, side);

        ChartUtils.formatDatasetWithColor(dataset, color);
        if (activityCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_I) {
            dataset.label = `I - ${BodySideTypes.format(side)}`;
        } else if (activityCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_Y) {
            dataset.label = `Y - ${BodySideTypes.format(side)}`;
            dataset.borderDash = [2];
        } else if (activityCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_T) {
            dataset.label = `T - ${BodySideTypes.format(side)}`;
            dataset.borderDash = [5];
        }
        return dataset;
    }

    private getActivityCodeSideColor(activityCode: Code, side: BodySideType) {
        let color: Color;
        if (activityCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_I && side === BodySideType.LEFT) {
            color = Colors.AMBER;
        } else if (activityCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_I && side === BodySideType.RIGHT) {
            color = Colors.SOFT_BLUE;
        } else if (activityCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_Y && side === BodySideType.LEFT) {
            color = Colors.ORANGE;
        } else if (activityCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_Y && side === BodySideType.RIGHT) {
            color = Colors.BLUE;
        } else if (activityCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_T && side === BodySideType.LEFT) {
            color = Colors.BROWN;
        } else if (activityCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_T && side === BodySideType.RIGHT) {
            color = Colors.DARK_BLUE;
        }
        return color;
    }

    private getActivitiesFilter(activities: ActivityDto[], metric: ProgressOverTimeMetricType): ActivityDto[] {
        switch (metric) {
            case ProgressOverTimeMetricType.IYT:
                return activities;
            case ProgressOverTimeMetricType.IYT_I:
                return activities.filter(Activity => Activity.config.baseConfigCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_I);
            case ProgressOverTimeMetricType.IYT_Y:
                return activities.filter(Activity => Activity.config.baseConfigCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_Y);
            case ProgressOverTimeMetricType.IYT_T:
                return activities.filter(Activity => Activity.config.baseConfigCode === BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_T);
        }
        return activities;
    }

    private labelFormatter(value: number): string {
        // default to integer
        let formatted: string = value.toFixed(0);

        // find the value in the datasets
        let found = false;
        for (const dataset of this.activitiesDatasets) {
            for (const datum of dataset.data) {
                if (datum === value) {
                    formatted = new Kg(value).format(1);
                    found = true;
                    break;
                }
            }
            if (found) {
                break;
            }
        }

        return formatted;
    }
}
