<div class="component">
    <ng-container *ngIf="analysis; else loadingView">
        <ng-container *ngIf="viewType === resultViewTypes.FULL">
            <ng-container *ngTemplateOutlet="sectionMaxPerSide"></ng-container>
            <ng-container *ngTemplateOutlet="sectionMeanPerRep"></ng-container>
            <ng-container *ngIf="exerciseType === exerciseTypes.TOTAL_EVALUATION">
                <ng-container *ngTemplateOutlet="sectionMaxPerRep"></ng-container>
            </ng-container>
            <ng-container *ngTemplateOutlet="sectionReps"></ng-container>

        </ng-container>
        <ng-container *ngIf="viewType === resultViewTypes.ROW">
            <div class=" d-flex flex-row">
                <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
                <div class="max-per-side flex-grow-1 d-flex flex-row justify-content-center">
                    <app-max-per-side-chart class="chart" [maxPerSide]="maxPerSide"></app-max-per-side-chart>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #loadingView>
    Loading...
</ng-template>

<ng-template #sectionMaxPerSide>
    <div class="section section-top-row d-flex flex-row">
        <div class="image me-3">
            <app-image [image]="image" [lazily]="false" [fallback]="true" [description]="activityTitle(activity)"></app-image>
        </div>
        <div class="max-per-side flex-grow-1 d-flex flex-column">
            <app-max-per-side-chart class="chart"
                                    [maxPerSide]="maxPerSide"
            ></app-max-per-side-chart>
        </div>
    </div>
</ng-template>

<ng-template #sectionMeanPerRep>
    <div *ngIf="meanPerSide" class="section section-mean">
        <h3 class="title mb-0">{{text('meterExercise.results.meanMainTitle')}}</h3>
        <app-body-side-bar-chart class="overflow-hidden"
                                 [data]="meanPerSide"
                                 valueFractionDigits="1"
        ></app-body-side-bar-chart>
    </div>
</ng-template>

<ng-template #sectionReps>
    <div class="section section-repetitions mt-3">
        <h3 class="title mb-0">{{repetitionsTitle}}</h3>
        <div *ngFor="let repetition of repetitionResults | keyvalue" class="repetition mt-2">
            <app-meter-endurance-statistics-row class="section d-inline-block full-width"
                                                [repetitionResults]="repetition.value"
                                                [mediumType]="mediumType"
                                                [showStatsTable]="displayRepetitionRowStats"
                                                [displayTotal]="displayTotal"
                                                [target]="repetitionTargets.get(repetition.key)"
            >
            </app-meter-endurance-statistics-row>
            <mat-divider [class.mt-4]="!compact" [class.mb-4]="!compact"></mat-divider>
        </div>
    </div>
</ng-template>

<ng-template #sectionMaxPerRep>
    <div class="section mt-4">
        <h2>{{'meterExercise.results.maxPerSideTitle.kg' | text: unitTypeUtils.symbol(unitTypes.KG)}}</h2>
        <app-body-side-bar-chart [data]="maxPerRep" [dataTitles]="maxPerRepTitles" [valueFractionDigits]="1"></app-body-side-bar-chart>
    </div>
</ng-template>
