import {MeterEnduranceActivityRepResultsModel} from "../dtos/statistics/meterEnduranceActivityRepResultsModel";
import {BodyPart} from "../models/bodyPart";
import {BodySideType} from "../models/bodySideType";
import {BluetoothDeviceType} from "../models/bluetoothDeviceType";

export class MeterEnduranceStatisticsRowViewModel {
    public bodyPart: BodyPart;
    public side: BodySideType;

    public repOrdinal: number;
    public maxValue: number;
    public averageValue: number;
    public rfdToMax: number;
    public rfdFromMax: number;
    public timeToMax: number;

    public forces: number[];
    public deltas: number[];
    public device: BluetoothDeviceType;

    constructor() {
    }

    public static fromMeterEnduranceActivityRepResultsModel(resultsModel: MeterEnduranceActivityRepResultsModel): MeterEnduranceStatisticsRowViewModel {
        const model = new MeterEnduranceStatisticsRowViewModel();
        model.side = resultsModel.bodySideType;
        model.repOrdinal = resultsModel.repOrdinal;
        model.maxValue = resultsModel.maxValue;
        model.averageValue = resultsModel.averageValue;
        model.rfdToMax = resultsModel.rfdToMax;
        model.rfdFromMax = resultsModel.rfdFromMax;
        model.timeToMax = resultsModel.timeToMax;
        model.forces = resultsModel.forces;
        model.deltas = resultsModel.deltas;
        model.device = resultsModel.deviceType;
        return model;
    }
}
