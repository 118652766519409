import {Injectable} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {GraphicsType} from "../models/graphicsType";
import {GraphicsShadeTypes} from "../models/graphicsShadeType";
import {ProgressOverTimeMetricType} from "../viewmodels/progressOverTimeMetricType";
import {ProgressOverTimeMetric} from "../viewmodels/progressOverTimeMetric";
import {Device} from "../viewmodels/device";
import {DeviceTypes} from "common";

@Injectable({
    providedIn: 'root'
})
export class IconRegistryService {

    constructor(private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer) {
    }

    public load(): void {
        // Devices
        for (const deviceType of DeviceTypes.all()) {
            let device = Device.of(deviceType);
            for (const shadeType of GraphicsShadeTypes.all()) {
                this.matIconRegistry.addSvgIcon(Device.matIcon(deviceType, shadeType), this.domSanitizer.bypassSecurityTrustResourceUrl(device.getIcon(GraphicsType.SVG, shadeType)));
            }
        }

        // ProgressOverTimeMetricType
        const progressOverTimeTypes: ProgressOverTimeMetricType[] = [ProgressOverTimeMetricType.MAX_VALUE, ProgressOverTimeMetricType.AVG_VALUE, ProgressOverTimeMetricType.PERCENTAGE];
        for (const type of progressOverTimeTypes) {
            const metric = new ProgressOverTimeMetric(type);
            this.matIconRegistry.addSvgIcon(metric.matIcon, this.domSanitizer.bypassSecurityTrustResourceUrl(metric.icon));
        }
    }
}
