/**
 * Branding colors. Should be identical to file colors.scss
 */

export class Colors {
    public static readonly KINVENT_BLUE = '#00456E';
    public static readonly KINVENT_BLUE_LIGHT = '#21638B';
    public static readonly KINVENT_ORANGE = '#F7941E';
    public static readonly RED = '#cc0000';
    public static readonly GREEN = '#66cc00';
    public static readonly GREEN_PASTEL = '#baed91';
    public static readonly WHITE = '#ffffff';
    public static readonly GRAY_DISABLED = '#707070';
    public static readonly GRAY_600 = '#999999';

    // Shades. 00 = transparent, FF = opaque

    // KInvent Oranges
    public static readonly KINVENT_ORANGE_LIGHT = '#FDB344';
    public static readonly KINVENT_ORANGE_LIGHTER = '#FBC88F';
    public static readonly KINVENT_ORANGE_LIGHTEST = '#FCDAB4';

    //protocols overtime results colors
    public static readonly AMBER = '#FFBF00';
    public static readonly SOFT_BLUE = '#6693F5';
    public static readonly ORANGE = '#FC6600';
    public static readonly BLUE = '#0018F9';
    public static readonly BROWN = '#B1560F';
    public static readonly DARK_BLUE = '#000080';

    public static get colorPrimary(): string {
        return this.KINVENT_BLUE;
    }

    public static get colorAccent(): string {
        return this.KINVENT_ORANGE;
    }

}
