import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {JwtHelperService} from "@auth0/angular-jwt";
import {AuthenticationService} from "../services/api/authentication.service";
import {environment} from "../../environments/environment";

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(private _authenticationService: AuthenticationService, public _jwtHelper: JwtHelperService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const user = this._authenticationService.account;
        const haveToken = user?.authToken;
        const isTokenExpired = haveToken && this._jwtHelper.isTokenExpired(user.authToken);

        const isApiUrl = request.url.startsWith(environment.apiUrl + 'api');

        // include the auth token on API calls
        if (haveToken && !isTokenExpired && isApiUrl) {
            request = request.clone({
                setHeaders: {'X-Auth-Token': user.authToken}
            });
        }

        return next.handle(request);
    }
}
