import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../base/base.component";

/**
 * Text representation of a percent left to right
 * eg: L 27% / R 73% or 27/73
 */
@Component({
    selector: 'app-left-right-text',
    templateUrl: './left-right-text.component.html',
    styleUrls: ['./left-right-text.component.scss']
})
export class LeftRightTextComponent extends BaseComponent implements OnInit {

    @Input()
    public leftValue: number;

    @Input()
    public rightValue: number;

    @Input()
    public displayPercent: boolean = true;

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

}
