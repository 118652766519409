export enum ValueWidgetIcon {
    MAX,
    TIMER,
    HEIGHT,
    CONTACT_TIME,
    REACTIVE_STRENGTH_INDEX,
    REFRESH,
    PACE,
    FATIGUE_INDEX,
    FORCE,
    SPEED,
    REPEAT,
    PAUSE,
}

export class ValueWidgetIcons {
    public static icon(iconType: ValueWidgetIcon): string {
        let iconSrc: string;
        switch (iconType) {
            case ValueWidgetIcon.MAX:
                iconSrc = './assets/icons/ic_flash_monochrome.png';
                break;
            case ValueWidgetIcon.TIMER:
                iconSrc = './assets/icons/ic_timer_monochrome.png';
                break;
            case ValueWidgetIcon.HEIGHT:
                iconSrc = './assets/icons/ic_height_monochrome.png';
                break;
            case ValueWidgetIcon.CONTACT_TIME:
                iconSrc = './assets/icons/ic_legend_toggle_monochrome.png';
                break;
            case ValueWidgetIcon.REACTIVE_STRENGTH_INDEX:
                iconSrc = './assets/icons/ic_jump_suite_monochrome.png';
                break;
            case ValueWidgetIcon.REFRESH:
                iconSrc = './assets/icons/ic_refresh_monochrome.png';
                break;
            case ValueWidgetIcon.PACE:
                iconSrc = './assets/icons/ic_pace_timer_monochrome.png';
                break;
            case ValueWidgetIcon.FATIGUE_INDEX:
                iconSrc = './assets/icons/ic_fatigue_rate_monochrome.png';
                break;
            case ValueWidgetIcon.FORCE:
                iconSrc = '/assets/icons/ic_biceps_force_monochrome.png';
                break;
            case ValueWidgetIcon.SPEED:
                iconSrc = '/assets/icons/ic_velocity_monochrome.png';
                break;
            case ValueWidgetIcon.REPEAT:
                iconSrc = '/assets/icons/ic_repeat_monochrome.png';
                break;
            case ValueWidgetIcon.PAUSE:
                iconSrc = '/assets/icons/ic_pause_monochrome.png';
                break;
            default:
                iconSrc = '';
                break;
        }
        return iconSrc;
    }
}
