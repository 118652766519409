import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ExerciseType, ExerciseTypes} from "../../../models/exerciseType";
import {MatSelectionList} from "@angular/material/list";
import {Title} from "common";
import {BaseComponent} from "../../base/base.component";

@Component({
    selector: 'app-select-exercise-type-dialog',
    templateUrl: './select-exercise-type-dialog.component.html',
    styleUrls: ['./select-exercise-type-dialog.component.scss']
})
export class SelectExerciseTypeDialogComponent extends BaseComponent implements OnInit {

    @ViewChild('itemList')
    itemsList: MatSelectionList;

    public availableTypes: ExerciseType[];

    constructor(public dialogRef: MatDialogRef<SelectExerciseTypeDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        super();
    }

    ngOnInit(): void {
        this.availableTypes = this.data.exerciseTypes;
    }

    public getExerciseTitle(exerciseType: ExerciseType): Title {
        return ExerciseTypes.title(this.i18n, exerciseType);
    }

    onSelectItem() {
        let selectedItem = this.itemsList.selectedOptions.selected[0]?.value;
        this.dialogRef.close(selectedItem);
    }
}
