import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from "../../base/base.component";

@Component({
    selector: 'app-protocol-results-actions',
    templateUrl: './protocol-results-actions.component.html',
    styleUrls: ['./protocol-results-actions.component.scss']
})
export class ProtocolResultsActionsComponent extends BaseComponent implements OnInit {

    @Input()
    public supportsExportPdf: boolean;

    @Input()
    public supportsExportCsv: boolean;

    @Output()
    public onExportPdf: EventEmitter<void>;

    @Output()
    public onExportCsv: EventEmitter<void>;

    constructor() {
        super();
        this.onExportPdf = new EventEmitter<void>();
        this.onExportCsv = new EventEmitter<void>();
    }

    ngOnInit(): void {
    }

}
