import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, forkJoin, Observable, of, Subject} from 'rxjs';
import {map, mergeMap, takeUntil} from 'rxjs/operators';
import {PerformType} from '../../viewmodels/performType';
import {SessionViewModel} from '../../viewmodels/sessionViewModel';
import {ResultsDirective} from '../../components/results/resultsDirective';
import {ProtocolService} from '../../services/api/protocol.service';
import {ProtocolDto} from '../../dtos/protocol.dto';
import {DynamicResultsLoaderService} from '../../services/dynamic-results-loader.service';
import {ParticipantDto} from '../../dtos/participant.dto';
import {ParticipantService} from '../../services/api/participant.service';
import {ProtocolAnalysisServiceResponseDto} from '../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {ActivityConfigDto} from '../../dtos/activityConfig.dto';
import {ExerciseType} from '../../models/exerciseType';
import {ProtocolGroupFilter} from '../../viewmodels/protocolGroupFilter';
import {ProtocolFilteringService} from '../../services/protocol-filtering.service';
import {ActivityDto} from '../../dtos/activity.dto';
import {ActivityAnalysisServiceResponseDto} from '../../dtos/statistics/activityAnalysisServiceResponseDto';
import {BreadcrumbInitializerService} from '../../services/breadcrumb-initializer.service';
import {ResultsComponent} from '../../components/results/resultsComponent';
import {Device} from '../../viewmodels/device';
import {ResultsComponentFactoryService} from '../../components/results/results-component-factory.service';
import {CsvConverterFactory} from '../../services/csv/csv-converter-factory';
import {Activities} from '../../utils/activities';
import {ResultsViewType} from '../../components/results/resultsViewType';
import {Protocols} from '../../utils/protocols';
import {ViewMediumType} from "../../components/results/viewMediumType";
import {CrudType, CsvService, DateFormat, Dates, LoadingState, PdfService} from "common";
import {BaseComponent} from "../../components/base/base.component";
import {ActivityCode, ProtocolCode} from "../../utils/types";

/**
 * Protocol or Activity results
 */
@Component({
    selector: 'app-protocol-results-screen',
    templateUrl: './protocol-results-screen.component.html',
    styleUrls: ['./protocol-results-screen.component.scss']
})
export class ProtocolResultsScreenComponent extends BaseComponent implements OnInit {

    public screenLoadingState: LoadingState;
    public resultsLoadingState: LoadingState;
    public resultsLoadingCancelSubject: Subject<string>;

    @ViewChildren(ResultsDirective, {})
    public resultsHosts!: QueryList<ResultsDirective>;

    private performType: PerformType;
    private baseCode: string;
    private protocols: ProtocolDto[];
    private participantCode: string;
    public participant: ParticipantDto;
    private filter: ProtocolGroupFilter;

    public title: string;
    public sessions: SessionViewModel[];
    public devices: Device[];

    public selectedPeriodStart: Date;
    public selectedPeriodEnd: Date | null;
    public selectedSession: SessionViewModel = null;
    public selectedAnalysisServiceResponse: ActivityAnalysisServiceResponseDto | ProtocolAnalysisServiceResponseDto;
    public selectedActivityConfig: ActivityConfigDto;
    public selectedExerciseType: ExerciseType;
    private resultsComponent: ResultsComponent;

    public supportsExportPdf: boolean;
    public supportsExportCsv: boolean;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private resultsLoader: DynamicResultsLoaderService,
                private participantService: ParticipantService,
                private protocolService: ProtocolService,
                private pdfService: PdfService,
                private csvService: CsvService,
                private protocolGroupService: ProtocolFilteringService,
                private breadcrumbInitializerService: BreadcrumbInitializerService) {
        super();
        this.sessions = [];
        this.protocols = [];
        this.resultsLoadingCancelSubject = new Subject<string>();

        // Get passed data
        let state = this.router.getCurrentNavigation().extras.state;
        if (state?.protocols) {
            this.protocols = state.protocols;
        }
        if (state?.participant) {
            this.participant = state.participant;
        }
    }

    ngOnInit(): void {
        combineLatest([this.route.url, this.route.paramMap, this.route.queryParams])
            .pipe(takeUntil(this.destroySubject))
            .pipe(mergeMap(value => {
                this.screenLoadingState = LoadingState.LOADING;

                const url = value[0];
                const paramMap = value[1];
                const queryParams = value[2];

                // Get Activity or Protocol
                let urlPath = url[0].path;
                if (urlPath === 'activity') {
                    this.performType = PerformType.Activity;
                    this.analyticsSwitchPage('Activity Results', undefined, '/participants/participant/progress/activity');
                } else if (urlPath === 'protocol') {
                    this.performType = PerformType.Protocol;
                    this.analyticsSwitchPage('Protocol Results', undefined, '/participants/participant/progress/protocol');
                } else {
                    this.navigation.goToHome();
                }

                // get url params
                this.participantCode = paramMap.get('code');
                this.baseCode = paramMap.get('baseCode');
                this.filter = new ProtocolGroupFilter().deserialize(queryParams);
                this.filter.baseConfigCode = this.baseCode;

                // use passed protocols or retrieve them
                let protocolCodesObservable: Observable<ProtocolDto[]>;
                if (this.protocols.length > 0) {
                    protocolCodesObservable = of(this.protocols);
                } else {
                    protocolCodesObservable = this.protocolService.findByParticipantCode(this.participantCode)
                        .pipe(takeUntil(this.destroySubject))
                        .pipe(map(protocols => {
                            return this.protocolGroupService.applyFilter(protocols, this.filter);
                        }));
                }

                let participantObservable: Observable<ParticipantDto>;
                if (this.participant) {
                    participantObservable = of(this.participant);
                } else {
                    participantObservable = this.participantService.get(this.participantCode);
                }

                return forkJoin([protocolCodesObservable, participantObservable]);
            }))
            .subscribe(data => {
                this.protocols = data[0];
                this.participant = data[1];

                this.breadcrumbInitializerService.setParticipantName(this.participant.fullName);
                this.breadcrumbInitializerService.setResultsTitle(this.text(this.protocols[0].getTitle(this.i18n)));

                this.generateSessions();
                const sessionDates = this.sessions.map(s => s.date.getTime());
                this.selectedPeriodStart = new Date(Math.min(...sessionDates));
                this.selectedPeriodEnd = new Date(Math.max(...sessionDates));
                if (this.supportsResultsOverTime()) {
                    this.loadResultsOverTime();
                } else {
                    // Select first session
                    this.handleSessionAction([this.sessions[0], CrudType.READ]);
                }
                this.screenLoadingState = LoadingState.LOADED;
            }, error => {
                this.notification.info('Something unexpected happened. Please try again.');
                this.errorHandler.handle(error);
                this.screenLoadingState = LoadingState.ERROR;
            });
    }

    private generateSessions() {
        if (this.performType === PerformType.Activity) {
            const filteredActivities = this.protocolGroupService.filterActivities(this.protocols, this.filter);
            for (const filteredActivity of filteredActivities) {
                const protocol = filteredActivity.key;
                const activity = filteredActivity.value;
                if (!protocol.getStartTime()) {
                    continue;
                }
                this.sessions.push(new SessionViewModel(protocol, activity, new Date(protocol.getStartTime())));
            }
        } else if (this.performType === PerformType.Protocol) {
            for (const protocol of this.protocols) {
                if (!protocol.getStartTime()) {
                    continue;
                }
                this.sessions.push(new SessionViewModel(protocol, undefined, new Date(protocol.getStartTime())));
            }
        }
    }

    public handleSessionAction(event: [SessionViewModel, CrudType]): void {
        const session = event[0];
        const action = event[1];

        if (action === CrudType.READ) {
            this.screenLoadingState = LoadingState.LOADING;

            if (this.selectedSession?.protocol.code === session.protocol.code && this.supportsResultsOverTime()) {
                // Deselect session
                this.selectedSession = null;
                this.selectedAnalysisServiceResponse = null;

                const sessionDates = this.sessions.map(s => s.date.getTime());
                this.selectedPeriodStart = new Date(Math.min(...sessionDates));
                this.selectedPeriodEnd = new Date(Math.max(...sessionDates));

                this.loadResultsOverTime();
            } else {
                // Select session
                this.selectedSession = session;

                this.selectedPeriodStart = this.selectedSession.date;
                this.selectedPeriodEnd = null;

                this.loadResultsForSession();
            }

            this.screenLoadingState = LoadingState.LOADED;
        } else if (action === CrudType.DELETE) {
            this.notification.info('Not available yet');
        }
    }

    private loadResultsForSession() {
        const protocol = this.selectedSession.protocol;
        const activity = this.selectedSession.activity;
        if (!protocol.getTitle(this.i18n)) {
            console.log(`Here's what wrong`);
            console.dir(protocol);
        }

        this.selectedExerciseType = protocol.activities[0].config.exerciseTypeEnum;
        if (this.performType === PerformType.Activity) {
            this.loadActivityResults(protocol, activity);
            this.supportsExportPdf = true;
        } else if (this.performType === PerformType.Protocol) {
            this.loadProtocolResults(protocol);
            this.supportsExportPdf = true;
        }

        this.supportsExportCsv = this.getSupportsExportCsv();
    }

    private loadResultsOverTime() {
        if (this.sessions.length === 0) {
            return;
        }

        if (this.performType === PerformType.Activity) {
            let protocols = this.sessions.map(s => s.protocol);
            let protocolActivities = new Map<ProtocolDto, ActivityDto[]>();
            for (const protocol of protocols) {
                let activities = this.sessions.filter(s => s.protocol.code === protocol.code).map(s => s.activity);
                protocolActivities.set(protocol, activities);
            }

            this.loadResultsOverTimeForActivities(protocolActivities);
        } else if (this.performType === PerformType.Protocol) {
            this.loadResultsOverTimeForProtocol(this.sessions.map(s => s.protocol));
        }

        this.supportsExportCsv = false;
        this.supportsExportPdf = true;
    }

    private loadActivityResults(protocol: ProtocolDto, activity: ActivityDto): void {
        this.resultsLoadingCancelSubject.next();
        this.resultsLoadingState = LoadingState.LOADING;
        // Load analysis for activity
        let requestData = new Map<ProtocolCode, ActivityCode[]>();
        requestData.set(protocol.code, [activity.code]);
        this.protocolService.analyseActivities(requestData)
            .pipe(takeUntil(this.destroySubject))
            .pipe(takeUntil(this.resultsLoadingCancelSubject))
            .subscribe(serviceResponse => {
                this.title = this.activityTitle(activity);

                this.devices = [];
                for (const activityConfigDevice of activity.config.activityConfigDevices) {
                    this.devices.push(Device.of(activityConfigDevice.deviceTypeType));
                }

                this.resultsComponent = this.resultsLoader.forActivity(activity, this.resultsHosts.first, serviceResponse, ResultsViewType.FULL, ViewMediumType.SCREEN);
                this.selectedAnalysisServiceResponse = serviceResponse;
                this.selectedActivityConfig = serviceResponse.activitiesResults[0].config;
                this.resultsLoadingState = LoadingState.LOADED;
            }, error => {
                this.errorHandler.handle(error);
                this.notification.info(`Could not load results: ${error.statusText} (${error.status}`);
                this.resultsLoadingState = LoadingState.ERROR;
            });
    }

    private loadProtocolResults(protocol: ProtocolDto) {
        this.resultsLoadingCancelSubject.next();
        this.resultsLoadingState = LoadingState.LOADING;
        this.protocolService.analyseProtocols([protocol.code])
            .pipe(takeUntil(this.destroySubject))
            .pipe(takeUntil(this.resultsLoadingCancelSubject))
            .subscribe(serviceResponse => {
                this.title = this.text(protocol.getTitle(this.i18n));

                this.resultsComponent = this.resultsLoader.forProtocol(protocol, this.resultsHosts.first, serviceResponse, ViewMediumType.SCREEN);
                this.selectedAnalysisServiceResponse = serviceResponse;
                this.selectedActivityConfig = undefined;
                this.resultsLoadingState = LoadingState.LOADED;
            }, error => {
                this.errorHandler.handle(error);
                this.notification.info(`Could not load results: ${error.statusText} (${error.status}`);
                this.resultsLoadingState = LoadingState.ERROR;
            });
    }

    private loadResultsOverTimeForActivities(protocolActivities: Map<ProtocolDto, ActivityDto[]>) {
        this.resultsLoadingCancelSubject.next();
        this.resultsLoadingState = LoadingState.LOADING;

        const requestData = new Map<ProtocolCode, ActivityCode[]>();
        const activities: ActivityDto[] = [];
        for (const entry of protocolActivities.entries()) {
            requestData.set(entry[0].code, entry[1].map(a => a.code));
            activities.push(...entry[1]);
        }

        this.protocolService.analyseActivities(requestData)
            .pipe(takeUntil(this.destroySubject))
            .pipe(takeUntil(this.resultsLoadingCancelSubject))
            .subscribe(serviceResponse => {

                const firstActivity = activities[0];
                this.title = this.activityTitle(firstActivity);

                this.devices = [];
                for (const activityConfigDevice of firstActivity.config.activityConfigDevices) {
                    this.devices.push(Device.of(activityConfigDevice.deviceTypeType));
                }

                this.resultsComponent = this.resultsLoader.forActivitiesOverTime(activities, this.resultsHosts.first, serviceResponse, ViewMediumType.SCREEN);
                this.selectedAnalysisServiceResponse = serviceResponse;
                this.selectedActivityConfig = undefined;
                this.resultsLoadingState = LoadingState.LOADED;
            }, error => {
                this.errorHandler.handle(error);
                this.notification.info(`Could not load results: ${error.statusText} (${error.status})`);
                this.resultsLoadingState = LoadingState.ERROR;
            });
    }

    private loadResultsOverTimeForProtocol(protocols: ProtocolDto[]) {
        this.resultsLoadingCancelSubject.next();
        this.resultsLoadingState = LoadingState.LOADING;
        this.protocolService.analyseProtocols(protocols.map(protocol => protocol.code))
            .pipe(takeUntil(this.destroySubject))
            .pipe(takeUntil(this.resultsLoadingCancelSubject))
            .subscribe(serviceResponse => {
                const firstProtocol = protocols[0];
                this.title = this.text(firstProtocol.getTitle(this.i18n));

                this.resultsComponent = this.resultsLoader.forProtocolsOverTime(protocols, this.resultsHosts.first, serviceResponse, ViewMediumType.SCREEN);
                this.selectedAnalysisServiceResponse = serviceResponse;
                this.selectedActivityConfig = undefined;
                this.resultsLoadingState = LoadingState.LOADED;
            }, error => {
                this.errorHandler.handle(error);
                this.notification.info(`Could not load results: ${error.statusText} (${error.status})`);
                this.resultsLoadingState = LoadingState.ERROR;
            });
    }

    public onExportPdf(): void {
        let haveMarkup = false;
        if (this.selectedAnalysisServiceResponse instanceof ActivityAnalysisServiceResponseDto) {
            // Create the results and add them to the second resultsHost place
            if (this.selectedSession) { // activity
                this.resultsLoader.forActivity(this.selectedSession.activity, this.resultsHosts.last, this.selectedAnalysisServiceResponse, ResultsViewType.FULL, ViewMediumType.PRINT, this.resultsComponent);
                haveMarkup = true;
            } else { // over time
                this.resultsLoader.forActivitiesOverTime(this.sessions.map(s => s.activity), this.resultsHosts.last, this.selectedAnalysisServiceResponse, ViewMediumType.PRINT, this.resultsComponent);
                haveMarkup = true;
            }
        } else if (this.selectedAnalysisServiceResponse instanceof ProtocolAnalysisServiceResponseDto) {
            if (this.selectedSession) {
                this.resultsLoader.forProtocol(this.selectedSession.protocol, this.resultsHosts.last, this.selectedAnalysisServiceResponse, ViewMediumType.PRINT, this.resultsComponent);
                haveMarkup = true;
            } else {
                this.resultsLoader.forProtocolsOverTime(this.sessions.map(s => s.protocol), this.resultsHosts.last, this.selectedAnalysisServiceResponse, ViewMediumType.PRINT, this.resultsComponent);
                haveMarkup = true;
            }
        }

        if (haveMarkup) {
            // Get the markup from the resultsHost and print it
            const markup = this.resultsHosts.last.viewContainerRef.element.nativeElement.parentElement;
            this.pdfService.downloadPdf(markup, this.getExportFilename(), this.destroySubject);
        }
    }

    private getExportFilename(): string {
        let filename: string;
        if (!this.selectedPeriodEnd) {
            const timestamp = Dates.format(this.selectedPeriodStart, DateFormat.DATE_TIME_CONDENSED);
            filename = `${this.participant.fullName},${this.title},${timestamp}`;
        } else {
            const startTimestamp = Dates.format(this.selectedPeriodStart, DateFormat.DATE_TIME_CONDENSED);
            const endTimestamp = Dates.format(this.selectedPeriodEnd, DateFormat.DATE_TIME_CONDENSED);
            filename = `${this.participant.fullName},${this.title},${startTimestamp}-${endTimestamp}`;
        }
        return filename;
    }

    private supportsResultsOverTime() {
        let supports = true;
        try {
            if (this.performType === PerformType.Activity) {
                let activities = this.sessions.map(s => s.activity);

                // there should be at least two days of results
                if (Activities.groupByDay(activities).size < 2) {
                    throw new Error();
                }
                new ResultsComponentFactoryService().forActivities(activities);
            } else if (this.performType === PerformType.Protocol) {
                const protocols = this.sessions.map(s => s.protocol);

                // there should be at least two days of results
                if (Protocols.groupByDay(protocols).size < 2) {
                    throw new Error();
                }
                new ResultsComponentFactoryService().forProtocols(protocols);
            }
        } catch (e) {
            supports = false;
        }
        return supports;
    }

    public onExportCsv() {
        if (!this.selectedSession || !this.selectedAnalysisServiceResponse || !(this.selectedAnalysisServiceResponse instanceof ActivityAnalysisServiceResponseDto)) {
            return;
        }

        let activity = this.selectedSession.activity;
        let converter = CsvConverterFactory.getFor(activity);

        this.csvService.downloadCsv(converter.convert(activity, this.selectedAnalysisServiceResponse.activitiesResults[0]), this.getExportFilename());
    }

    public getSupportsExportCsv() {
        if (this.selectedSession === null || this.selectedAnalysisServiceResponse === null) {
            return false;
        }

        let supports = true;
        try {
            CsvConverterFactory.getFor(this.selectedSession.activity);
        } catch (e) {
            supports = false;
        }
        return supports;
    }
}
