<div>
    <ng-container *ngIf="viewType === resultViewTypes.FULL">
        <div class="section section-top-row d-flex flex-row">
            <div class="image me-3">
                <app-image [image]="image" [lazily]="false" [fallback]="true" [description]="activityTitle(activity)"></app-image>
            </div>
            <div class="max-per-side flex-grow-1 d-flex flex-column">
                <app-max-per-side-chart class="chart"
                                        [maxPerSide]="maxPerSide"
                ></app-max-per-side-chart>
            </div>
        </div>
        <div class="section row mt-3">
            <div class="col-6">
                <div class="section overflow-auto d-flex flex-row">
                    <div class="flex-grow-1">
                        <app-table-header>{{'data'|text}}</app-table-header>
                        <app-table-title>{{'exercise.endurance.results.averageTitle'|text: unitTypeUtils.symbol(unitTypes.KG)}}</app-table-title>
                        <app-table-title>{{'exercise.endurance.results.rfdTitle'|text: unitTypeUtils.symbol(unitTypes.KG)}}</app-table-title>
                        <app-table-title>{{'exercise.endurance.results.timeToMaxTitle'|text}}</app-table-title>
                        <app-table-title>{{'exercise.endurance.results.fatigueTitle'|text: unitTypeUtils.symbol(unitTypes.KG)}}</app-table-title>
                    </div>
                    <div class="flex-grow-1">
                        <app-table-header>{{'graphs.lineSet.title.total'|text}}</app-table-header>
                        <app-table-value *ngFor="let value of tableTotalValues">{{value|value:2}}</app-table-value>
                    </div>
                    <div class="flex-grow-1">
                        <app-table-header [backgroundColor]="formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.COLOR)"
                                          color="white">
                            {{formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.TITLE)}}
                        </app-table-header>
                        <app-table-value *ngFor="let value of tableLeftValues">{{value|value:2}}</app-table-value>
                    </div>
                    <div *ngIf="isAdvancedNordic" class="flex-grow-1">
                        <app-table-header>{{'meterExercise.results.deficitTitle'|text}} {{unitTypeUtils.symbol(unitTypes.PERCENT)}}</app-table-header>
                        <app-table-value *ngFor="let value of tableDeficitValues">{{value|value:2}}</app-table-value>
                    </div>
                    <div class="flex-grow-1">
                        <app-table-header [backgroundColor]="formatSideAttribute(sideTypes.RIGHT, sideTypeAttributes.COLOR)"
                                          color="white">
                            {{formatSideAttribute(sideTypes.RIGHT, sideTypeAttributes.TITLE)}}
                        </app-table-header>
                        <app-table-value *ngFor="let value of tableRightValues">{{value|value:2}}</app-table-value>
                    </div>

                </div>
            </div>
            <div class="col-6">
                <app-samples-line-chart [data]="samples" [total]="totalForces"></app-samples-line-chart>
            </div>
        </div>
        <div *ngIf="isAdvancedNordic" class="section section-advanced row mt-3">
            <div class="col-12 title pt-2 pb-2 ps-3 pe-3">{{'advancedNordicHamstring.results.advancedResultsTitle'|text}}</div>
            <div class="col-4 d-flex flex-column align-items-center justify-content-center">
                <app-value-widget class="mb-5"
                                  [title]="'advancedNordicHamstring.results.relativeForce'|text"
                                  [value]="maxRelativeForce"></app-value-widget>
                <app-value-widget [title]="'advancedNordicHamstring.results.maxTorque'|text"
                                  [value]="maxTorque"></app-value-widget>
            </div>
            <div class="col-4">
                <h3 class="text-center m-0">{{'advancedNordicHamstring.results.forcePerAngles'|text}}</h3>
                <div>
                    <app-samples-line-chart [data]="forcesPerDegreesData" [total]="forcesPerDegreesTotal" [xAxisMin]="0"></app-samples-line-chart>
                </div>
            </div>
            <div class="col-4">
                <h3 class="text-center m-0">{{'advancedNordicHamstring.results.torquePerAngles'|text}}</h3>
                <div>
                    <app-samples-line-chart [data]="torquePerDegreesData" [total]="torquePerDegreesTotal" [xAxisMin]="0"></app-samples-line-chart>
                </div>
            </div>
        </div>
        <div *ngIf="isAdvancedNordic" class="section row mt-3">
            <div class="col-6 d-flex flex-column justify-content-center">
                <div class="d-flex flex-row">
                    <app-table-header class="flex-grow-1">{{'advancedNordicHamstring.results.maxTorque_angle'|text}}</app-table-header>
                    <app-table-value class="flex-grow-1">{{this.maxTorqueAngle|value: 1}}</app-table-value>
                </div>
                <div class="d-flex flex-row mt-3">
                    <div class="flex-grow-1 d-flex flex-column">
                        <app-table-header>{{'data'|text}}</app-table-header>
                        <app-table-title>{{'advancedNordicHamstring.results.torque_table.average'|text}}</app-table-title>
                        <app-table-title>{{'exercise.endurance.results.timeToMaxTitle'|text}}</app-table-title>
                    </div>
                    <div class="flex-grow-1">
                        <app-table-header [backgroundColor]="formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.COLOR)"
                                          color="white">{{formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.TITLE)}}</app-table-header>
                        <app-table-value [sideForColor]="sideTypes.LEFT">{{averageTorqueLeft|value}}</app-table-value>
                        <app-table-value [sideForColor]="sideTypes.RIGHT">{{timeToMaxLeft|value}}</app-table-value>
                    </div>
                    <div class="flex-grow-1">
                        <app-table-header [backgroundColor]="formatSideAttribute(sideTypes.RIGHT, sideTypeAttributes.COLOR)"
                                          color="white">{{formatSideAttribute(sideTypes.RIGHT, sideTypeAttributes.TITLE)}}</app-table-header>
                        <app-table-value [sideForColor]="sideTypes.RIGHT">{{averageTorqueRight|value}}</app-table-value>
                        <app-table-value [sideForColor]="sideTypes.RIGHT">{{timeToMaxRight|value}}</app-table-value>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div>
                    <app-max-per-side-chart class="d-inline-block chart"
                                            [maxPerSide]="maxTorquePerSide"
                                            [chartTitle]="'advancedNordicHamstring.results.maxTorque'|text"
                    ></app-max-per-side-chart>
                </div>
                <h3 class="text-center m-0">{{maxTorque|value}}</h3>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="viewType === resultViewTypes.ROW">
        <div class="d-flex flex-row">
            <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
            <div class="max-per-side flex-grow-1 d-flex flex-column">
                <app-max-per-side-chart class="chart"
                                        [maxPerSide]="maxPerSide"
                ></app-max-per-side-chart>
            </div>
        </div>
    </ng-container>
</div>
