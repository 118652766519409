import {Component, OnInit} from '@angular/core';
import {StanceEvaluationActivityResultsModel} from '../../../dtos/statistics/stanceEvaluationActivityResultsModel';
import {BuiltInExerciseTemplates, BuiltInExerciseTemplateType} from '../../../models/builtInExerciseTemplateType';
import {BodySideType, BodySideTypes} from '../../../models/bodySideType';
import {Sample} from "../../../utils/sample";
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {DeviceType, KeyValue, Resource} from "common";
import {ActivityImageResolverService} from "../../../services/activity-image-resolver.service";
import {ResultsComponent} from "../resultsComponent";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {Value} from "../../../utils/values/value";
import {UnitType} from "../../../utils/values/unitType";
import {Millimeters} from "../../../utils/values/millimeters";
import {MillimetersSquared} from "../../../utils/values/millimetersSquared";
import {Percent} from "../../../utils/values/percent";

@Component({
    selector: 'app-stance-results',
    templateUrl: './stance-results.component.html',
    styleUrls: ['./stance-results.component.scss']
})
export class StanceResultsComponent extends ResultsComponent implements OnInit {
    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: StanceEvaluationActivityResultsModel;
    public device: DeviceType.PLATES | DeviceType.HEXAS;
    public image: Resource;
    public sides: BodySideType[];
    public isSingleLeg: boolean;
    public isUnipodalSingleSensor: boolean;
    public isHeelTip: boolean;
    public isDropLand: boolean;
    public weightDistribution: Map<BodySideType, Value>[];
    public lateralDeviation: Value;
    public longitudinalDeviation: Value;
    public longitudinalAvg: Value;
    public lateralAvg: Value;
    public surfaceValues: KeyValue<BodySideType, Value>[];
    public showPopulation: boolean;
    public resultantCopTable: KeyValue<string, string>[];
    public copPerFootTitles: Map<BodySideType, string>;
    public weightDistributionLeftValueFormatted: string;
    public weightDistributionRightValueFormatted: string;
    public totalAreaCoveredPerTime: Sample[];
    public sideAreaCoveredPerTime: Map<[BodySideType, UnitType], Sample[]>;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. StanceResultsComponent`, this.analysis);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.activityAnalysis.activityResultsModel as StanceEvaluationActivityResultsModel;
        const activityBaseConfig = this.activity.config.baseConfigCode;

        const builtInType = BuiltInExerciseTemplates.getTypeForCode(activityBaseConfig);

        // common
        this.image = this.activityImageResolverService.do(this.activity.image);
        let deviceType = this.activityAnalysis.config.activityConfigDevices[0].deviceTypeType;
        if (deviceType !== DeviceType.PLATES && deviceType !== DeviceType.HEXAS) {
            throw new Error('Unsupported device');
        }
        this.device = deviceType;

        this.sides = [];
        if (this.statistics.gridCopCoordsInPercentage.length > 0) {
            this.sides.push(BodySideType.LEFT);
        }
        if (this.statistics.rightGridCopCoordsInPercentage.length > 0) {
            this.sides.push(BodySideType.RIGHT);
        }

        this.isSingleLeg = BuiltInExerciseTemplates.isUnipodalStance(activityBaseConfig);
        this.isUnipodalSingleSensor = BuiltInExerciseTemplates.isUnipodalStanceWithOneSensor(activityBaseConfig);

        this.isDropLand = BuiltInExerciseTemplates.isDropLand(activityBaseConfig);
        this.showPopulation = !this.isSingleLeg && !this.isDropLand;

        // Mapping
        this.lateralDeviation = new Millimeters(this.statistics.lateralDeviation);
        this.longitudinalDeviation = new Millimeters(this.statistics.longitudinalDeviation);
        this.longitudinalAvg = new Millimeters(this.statistics.longitudinalAvg);
        this.lateralAvg = new Millimeters(this.statistics.lateralAvg);

        // Confidence ellipse
        this.isHeelTip = (this.isSingleLeg && !this.isUnipodalSingleSensor) || builtInType === BuiltInExerciseTemplateType.LATERAL_STANCE;
        this.surfaceValues = [];
        if (this.sides.includes(BodySideType.LEFT) && this.sides.includes(BodySideType.RIGHT)) {
            this.surfaceValues.push(KeyValue.of(BodySideType.BOTH, new MillimetersSquared(this.statistics.surface)));
        } else {
            this.surfaceValues.push(KeyValue.of(this.sides[0], new MillimetersSquared(this.statistics.surface)));
        }

        // weight distribution
        const weightDistribution = new Map<BodySideType, Value>();
        if (this.sides.includes(BodySideType.LEFT)) {
            const value = new Percent(this.statistics.leftWeightDistribution);
            weightDistribution.set(BodySideType.LEFT, value);
            this.weightDistributionLeftValueFormatted = value.format(1);
        }
        if (this.sides.includes(BodySideType.RIGHT)) {
            const value = new Percent(this.statistics.rightWeightDistribution);
            weightDistribution.set(BodySideType.RIGHT, value);
            this.weightDistributionRightValueFormatted = value.format(1);
        }
        this.weightDistribution = [weightDistribution];

        // Resultant cop table
        this.resultantCopTable = [];
        this.resultantCopTable.push(KeyValue.of(this.text('standingEvaluation.results.surface_mm'), this.statistics.surface.toFixed(1)));
        this.resultantCopTable.push(KeyValue.of(this.text('standingEvaluation.results.total_displacement'), this.statistics.totalCopDisplacement?.toFixed(1)));
        this.resultantCopTable.push(KeyValue.of(this.text('standingEvaluation.results.mean_velocity'), this.statistics.avgVelocity?.toFixed(1)));
        this.resultantCopTable.push(KeyValue.of(this.text('standingEvaluation.results.standard_devation'), this.statistics.standardDeviation.toFixed(1)));
        this.resultantCopTable.push(KeyValue.of(this.text('standingEvaluation.results.vfy'), this.statistics.vfy.toFixed(1)));
        this.resultantCopTable.push(KeyValue.of(this.text('standingEvaluation.results.longitudinal amplitude'), this.statistics.longitudinalDeviation.toFixed(1)));
        this.resultantCopTable.push(KeyValue.of(this.text('standingEvaluation.results.lateral amplitude'), this.statistics.lateralDeviation.toFixed(1)));

        // Cop per foot/part
        this.copPerFootTitles = new Map<BodySideType, string>();
        if (!this.isHeelTip) {
            for (const side of this.sides) {
                this.copPerFootTitles.set(side, BodySideTypes.format(side));
            }
        } else {
            this.copPerFootTitles.set(BodySideType.LEFT, 'Heel');
            this.copPerFootTitles.set(BodySideType.RIGHT, 'Tip');
        }

        this.totalAreaCoveredPerTime = [];
        this.sideAreaCoveredPerTime = new Map<[BodySideType, UnitType], Sample[]>();
        if (this.isDropLand) {
            const total = this.statistics.areaCoveredPerTime;
            const left = this.statistics.areaCoveredPerTimeLeft;
            const right = this.statistics.areaCoveredPerTimeRight;
            if (total.length !== left.length || total.length !== right.length) {
                console.log(`Area over covered per time cannot be displayed, malformed data`);
                return;
            }

            const leftSamples: Sample[] = [];
            const rightSamples: Sample[] = [];
            for (let i = 0; i < total.length; i++) {
                const totalDatum = total[i];
                const leftDatum = left[i];
                const rightDatum = right[i];
                this.totalAreaCoveredPerTime.push(Sample.of(totalDatum.x / 1000, totalDatum.y));
                leftSamples.push(Sample.of(leftDatum.x / 1000, leftDatum.y));
                rightSamples.push(Sample.of(rightDatum.x / 1000, rightDatum.y));
            }

            this.sideAreaCoveredPerTime.set([BodySideType.LEFT, UnitType.KG], leftSamples);
            this.sideAreaCoveredPerTime.set([BodySideType.RIGHT, UnitType.KG], rightSamples);
        }
    }

    public getSideColor(side: BodySideType): string {
        return BodySideTypes.color(side);
    }
}
