import {ActivityLineChartProvider} from "./activityLineChartProvider";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {Label} from "ng2-charts";
import {I18nService} from "../../../services/i18n/i18n.service";
import {ProgressOverTimeFilter} from "../../../viewmodels/progressOverTimeFilter";
import {Activities} from "../../../utils/activities";
import {format} from "date-fns";
import {ChartDataSets} from "chart.js";
import {BodySideType, BodySideTypes} from "../../../models/bodySideType";
import {ProgressOverTimeMetric} from "../../../viewmodels/progressOverTimeMetric";
import {ProgressOverTimeMetricType} from "../../../viewmodels/progressOverTimeMetricType";
import {StanceEvaluationActivityResultsModel} from "../../../dtos/statistics/stanceEvaluationActivityResultsModel";
import {BuiltInExerciseTemplates} from "../../../models/builtInExerciseTemplateType";
import {UnipodalJumpAnalysisActivityResultsModel} from "../../../dtos/statistics/unipodalJumpAnalysisActivityResultsModel";
import {UnipodalStanceEvaluationActivityResultsModel} from "../../../dtos/statistics/unipodalStanceEvaluationActivityResultsModel";
import {UnipodalStanceEvaluationActivitySideResultsModel} from "../../../dtos/statistics/unipodalStanceEvaluationActivitySideResultsModel";
import {ChartUtils} from "../../../utils/chartUtils";
import {MathUtils} from "../../../utils/mathUtils";
import {Arrays, DateFormat, Dates, KeyValue} from "common";

export class StanceEvaluationLineChatProvider extends ActivityLineChartProvider {
    constructor() {
        super();
    }

    getDatasets(activities: ActivityDto[], analysis: ActivityAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: Chart.ChartOptions, i18n: I18nService): KeyValue<Label[], Chart.ChartDataSets[]> {
        const firstActivity = activities[0];
        const firstActivityResult = analysis.activitiesResults[0];
        const firstBaseConfigCode = firstActivity.config.baseConfigCode;

        const activitiesByDay = Activities.groupByDay(activities);

        const days = Array.from(activitiesByDay.keys()).sort();
        const labels: Label[] = days.map(day => format(day, Dates.getFormat(DateFormat.DATE_SHORTER)));

        let datasets: ChartDataSets[] = [];
        const isCustomSingleLegStance = !firstActivity.config.buildIn && Activities.isStanceWithSingleLeg(firstActivityResult);
        if (BuiltInExerciseTemplates.isUnipodalStanceWithOneSensor(firstBaseConfigCode) ||
            BuiltInExerciseTemplates.isUnipodalDropLand(firstBaseConfigCode) ||
            isCustomSingleLegStance) {
            datasets = this.createDatasetsForSingleLeg(activitiesByDay, analysis, filter.metric, days, i18n);
        } else {
            // Stance with two legs
            datasets = this.createDatasetsForTwoLegs(activitiesByDay, analysis, filter.metric, days, i18n);
        }

        let maxValue = 0;
        if (filter.metric.type === ProgressOverTimeMetricType.DISTRIBUTION) {
            maxValue = 100;
        } else {
            maxValue = Math.max(maxValue, ...(datasets[0].data as number[]).filter(value => !Number.isNaN(value))) * 1.2;
        }

        chartOptions.scales.yAxes[0].ticks = {min: 0, max: maxValue};

        // Chart options
        if (!chartOptions.plugins) {
            chartOptions.plugins = {};
        }
        chartOptions.plugins.datalabels = {
            formatter: this.labelFormatter.bind(this),
            anchor: 'end',
            align: 'end',
        };

        return KeyValue.of(labels, datasets);
    }

    private createDatasetsForTwoLegs(activitiesPerDay: Map<number, ActivityDto[]>, analysis: ActivityAnalysisServiceResponseDto, metric: ProgressOverTimeMetric, days: number[], i18n: I18nService): ChartDataSets[] {
        // Create the dataset. Distribution has two datasets. First is left/tip and second is right/heel
        const datasets: ChartDataSets[] = [];
        if (metric.type === ProgressOverTimeMetricType.DISTRIBUTION) {
            for (const side of [BodySideType.LEFT, BodySideType.RIGHT]) {
                const dataSet: ChartDataSets = {};
                dataSet.data = [];
                dataSet.label = BodySideTypes.format(side);
                dataSet.spanGaps = true;
                dataSet.lineTension = 0;
                datasets.push(dataSet);
            }
        } else {
            const dataset: ChartDataSets = {};
            dataset.data = [];
            dataset.label = i18n.text(metric.title);
            dataset.spanGaps = true;
            dataset.lineTension = 0;
            datasets.push(dataset);
        }

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value: number = NaN;
            // Create values for metrics.
            // Add NaN when there is no value for the day except for the last day, where we add the previous value
            if (activitiesPerDay.has(day)) {
                let activities = activitiesPerDay.get(day);
                let activitiesToResults = this.combineActivitiesToResults<StanceEvaluationActivityResultsModel>(activities, analysis);
                let results = activities.map(a => activitiesToResults.get(a)).filter(r => r !== undefined);
                value = this.getMetricFromTwoLegActivities(results, metric.type);
                datasets[0].data.push(value);

                // on distribution, calculate the right side manually.
                if (metric.type === ProgressOverTimeMetricType.DISTRIBUTION) {
                    datasets[1].data.push(100 - value);
                }
            }
        }

        // Theme datasets
        if (metric.type === ProgressOverTimeMetricType.DISTRIBUTION) {
            ChartUtils.formatDatasetForSide(datasets[0], BodySideType.LEFT);
            ChartUtils.formatDatasetForSide(datasets[1], BodySideType.RIGHT);
        } else {
            ChartUtils.formatDatasetPrimary(datasets[0]);
        }

        return datasets;
    }

    private createDatasetsForSingleLeg(activitiesPerDay: Map<number, ActivityDto[]>, analysis: ActivityAnalysisServiceResponseDto, metric: ProgressOverTimeMetric, days: number[], i18n: I18nService): ChartDataSets[] {
        const datasets: Map<BodySideType, ChartDataSets> = new Map<BodySideType, ChartDataSets>();
        const allSides = Arrays.uniqueValues(analysis.activitiesResults.map(a => a.activityResultsModel as UnipodalJumpAnalysisActivityResultsModel)
            .flatMap(r => r.resultsPerSide).flatMap(r => r.side));
        for (const side of allSides) {
            const dataset: ChartDataSets = {data: [], label: BodySideTypes.format(side), spanGaps: true, lineTension: 0};
            ChartUtils.formatDatasetForSide(dataset, side);
            datasets.set(side, dataset);
        }

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value: number = NaN;

            if (!activitiesPerDay.has(day)) {
                continue;
            }

            let activities = activitiesPerDay.get(day);
            let activitiesToResults = this.combineActivitiesToResults<UnipodalStanceEvaluationActivityResultsModel>(activities, analysis);
            let results = activities.map(a => activitiesToResults.get(a));
            const sides = Arrays.uniqueValues(results.flatMap(r => r.resultsPerSide).map(r => r.side));
            for (const side of sides) {
                const sideResults = results.flatMap(r => r.resultsPerSide).filter(r => r.side === side);
                value = this.getMetricFromSingleLegActivities(sideResults, metric.type);
                datasets.get(side).data.push(value);
            }

            // Remaining sides should have zeros
            allSides.filter(a => !sides.includes(a)).forEach(e => datasets.get(e).data.push(0));
        }

        return Array.from(datasets.values());
    }

    private getMetricFromTwoLegActivities(results: StanceEvaluationActivityResultsModel[], type: ProgressOverTimeMetricType) {
        let value: number;
        switch (type) {
            case ProgressOverTimeMetricType.DISTRIBUTION:
                value = results.map(it => MathUtils.round(it.leftWeightDistribution, 1))
                    .reduce((acc, cur) => acc + cur, 0) / results.length;
                break;
            case ProgressOverTimeMetricType.LATERAL_AMPLITUDE:
                value = results.map(it => MathUtils.round(it.lateralDeviation, 1))
                    .reduce((acc, cur) => acc + cur, 0) / results.length;
                break;
            case ProgressOverTimeMetricType.LONGITUDINAL_AMPLITUDE:
                value = results.map(it => MathUtils.round(it.longitudinalDeviation, 1))
                    .reduce((acc, cur) => acc + cur, 0) / results.length;
                break;
            case ProgressOverTimeMetricType.COP_SURFACE:
                value = results.map(it => MathUtils.round(it.surface, 1))
                    .reduce((acc, cur) => acc + cur, 0) / results.length;
                break;
        }

        return value;
    }

    private getMetricFromSingleLegActivities(results: UnipodalStanceEvaluationActivitySideResultsModel[], type: ProgressOverTimeMetricType) {
        let value: number;
        switch (type) {
            case ProgressOverTimeMetricType.DISTRIBUTION:
                value = results.map(r => r.heelValuePercent).reduce((acc, cur) => acc + cur, 0) / results.length;
                break;
            case ProgressOverTimeMetricType.LATERAL_AMPLITUDE:
                value = results.map(r => r.lateralDeviation).reduce((acc, cur) => acc + cur, 0) / results.length;
                break;
            case ProgressOverTimeMetricType.LONGITUDINAL_AMPLITUDE:
                value = results.map(r => r.longitudinalDeviation).reduce((acc, cur) => acc + cur, 0) / results.length;
                break;
            case ProgressOverTimeMetricType.COP_SURFACE:
                value = results.map(r => r.surface).reduce((acc, cur) => acc + cur, 0) / results.length;
                break;
        }

        return value;
    }

    private labelFormatter(value: number): string {
        return MathUtils.round(value, 1).toFixed(1);
    }
}
