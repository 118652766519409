<div>
    <ng-container *ngIf="viewType === resultViewTypes.FULL">
        <ng-container *ngTemplateOutlet="weight"></ng-container>
    </ng-container>
    <ng-container *ngIf="viewType === resultViewTypes.ROW">
        <div class=" d-flex flex-row">
            <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
            <div class="flex-grow-1 d-flex align-items-center justify-content-center">
                <ng-container *ngTemplateOutlet="weight"></ng-container>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #weight>
    <div class="d-flex align-items-center justify-content-center mb-5">
        <img class="weight-icon me-4" src="./assets/icons/ic_weight.png" alt="weight icon"/>
        <div class="weight-value">{{weightFormatted}}</div>
    </div>
</ng-template>
