import {ActivityLineChartProvider} from "./activityLineChartProvider";
import {ChartDataSets, ChartOptions} from "chart.js";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ProgressOverTimeFilter} from "../../../viewmodels/progressOverTimeFilter";
import {MeterEnduranceActivityResultsModel} from "../../../dtos/statistics/meterEnduranceActivityResultsModel";
import {BodySideType, BodySideTypes} from "../../../models/bodySideType";
import {ProgressOverTimeMetricType} from "../../../viewmodels/progressOverTimeMetricType";
import {Activities} from "../../../utils/activities";
import {Label} from "ng2-charts";
import {format} from "date-fns";
import {I18nService} from "../../../services/i18n/i18n.service";
import {MeterEnduranceActivityRepResultsModel} from "../../../dtos/statistics/meterEnduranceActivityRepResultsModel";
import {Biomechanics} from "../../../utils/biomechanics";
import {ProgressOverTimeMetric} from "../../../viewmodels/progressOverTimeMetric";
import {ChartUtils} from "../../../utils/chartUtils";
import {MathUtils} from "../../../utils/mathUtils";
import {Colors} from "../../../services/colors";
import {Kg} from "../../../utils/values/kg";
import {Percent} from "../../../utils/values/percent";
import {DateFormat, Dates, KeyValue} from "common";

export class MeterExerciseLineChartProvider extends ActivityLineChartProvider {
    private static AXIS_DEFICIT_ID = 'deficit';

    private sideDatasets: Map<BodySideType, ChartDataSets>;
    private deficitDataset: ChartDataSets;

    constructor() {
        super();
        this.sideDatasets = new Map<BodySideType, ChartDataSets>();
    }

    getDatasets(activities: ActivityDto[], analysis: ActivityAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: ChartOptions, i18n: I18nService): KeyValue<Label[], ChartDataSets[]> {
        this.sideDatasets.clear();
        this.deficitDataset = undefined;

        const activitiesByDay = Activities.groupByDay(activities);

        // Due to how charts lib works, labels are shared among all datasets, and where a dataset doesn't have a value, they have a NaN value
        const days = Array.from(activitiesByDay.keys()).sort();

        // create labels
        const labels: Label[] = days.map(day => format(day, Dates.getFormat(DateFormat.DATE_SHORTER)));

        const datasets: ChartDataSets[] = [];
        const sides = new Set<BodySideType>(analysis.activitiesResults.flatMap(a => (a.activityResultsModel as MeterEnduranceActivityResultsModel).repResults.map(r => r.bodySideType)));
        let maxValue = 0;
        for (const side of sides) {
            const dataset = this.createSideDataset(side, activitiesByDay, analysis, filter.metric, days);
            maxValue = Math.max(maxValue, ...(dataset.data as number[]).filter(value => !Number.isNaN(value)));
            this.sideDatasets.set(side, dataset);
            datasets.push(dataset);
        }
        chartOptions.scales.yAxes[0].ticks = {min: 0, max: maxValue * 1.2};

        // Deficit
        if (filter.useDeficit && sides.has(BodySideType.LEFT) && sides.has(BodySideType.RIGHT)) {
            let leftDataset = this.sideDatasets.get(BodySideType.LEFT);
            let rightDataset = this.sideDatasets.get(BodySideType.RIGHT);
            this.deficitDataset = this.createDeficitDataset(leftDataset, rightDataset, i18n);
            datasets.push(this.deficitDataset);

            chartOptions.scales.yAxes.push({id: MeterExerciseLineChartProvider.AXIS_DEFICIT_ID, display: false, ticks: {min: 0, max: 100}});
        }

        // Chart options
        if (!chartOptions.plugins) {
            chartOptions.plugins = {};
        }
        chartOptions.plugins.datalabels = {
            formatter: this.labelFormatter.bind(this),
            anchor: 'end',
            align: 'end',
        };

        return KeyValue.of(labels, datasets);
    }

    private createSideDataset(side: BodySideType, activitiesPerDay: Map<number, ActivityDto[]>, analysis: ActivityAnalysisServiceResponseDto, metric: ProgressOverTimeMetric, days: number[]): ChartDataSets {
        const dataset: ChartDataSets = {};
        dataset.data = [];
        dataset.label = BodySideTypes.format(side);
        dataset.spanGaps = true;
        dataset.lineTension = 0;

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value: number = NaN;
            // Create dataset with values for metrics.
            // Add NaN when there is no value for the day except for the last day, where we add the previous value
            if (activitiesPerDay.has(day)) {
                let activities = activitiesPerDay.get(day);
                let activitiesToResults = this.combineActivitiesToResults<MeterEnduranceActivityResultsModel>(activities, analysis);
                let reps = activities.map(a => activitiesToResults.get(a)).flatMap(r => r.repResults);
                let sideReps = reps.filter(r => r.bodySideType === side);
                if (sideReps.length > 0) {
                    value = this.getMetricFromActivities(reps, side, metric.type);
                } else if (i === days.length - 1 && days.length > 1) {
                    value = dataset.data[i - 1] as number;
                }
            }
            dataset.data.push(value);
        }

        ChartUtils.formatDatasetForSide(dataset, side);

        return dataset;
    }

    private getMetricFromActivities(reps: MeterEnduranceActivityRepResultsModel[], side: BodySideType, metric: ProgressOverTimeMetricType): number {
        const defaultValue = NaN;

        let value: number;
        switch (metric) {
            case ProgressOverTimeMetricType.MAX_VALUE:
                const maxValues = reps.filter(r => r.bodySideType === side).map(r => r.maxValue).filter(value => !Number.isNaN(value));
                value = MathUtils.maxOrDefault(maxValues, defaultValue);
                break;
            case ProgressOverTimeMetricType.AVG_VALUE:
                const avgValues = reps.filter(r => r.bodySideType === side).map(r => r.averageValue).filter(value => !Number.isNaN(value));
                value = MathUtils.maxOrDefault(avgValues, defaultValue);
                break;
        }
        return value;
    }

    private createDeficitDataset(leftDataset: ChartDataSets, rightDataset: ChartDataSets, i18n: I18nService): ChartDataSets {
        const dataset: ChartDataSets = {};
        dataset.data = [];
        dataset.label = i18n.text('report.filter.deficit.title');
        dataset.spanGaps = true;
        dataset.lineTension = 0;

        // traverse datasets and create a deficit when both sides exist. Sides have same number of entries
        for (let i = 0; i < leftDataset.data.length; i++) {
            const leftValue = leftDataset.data[i] as number;
            const rightValue = rightDataset.data[i] as number;
            const deficit = Biomechanics.deficit(leftValue, rightValue);
            if (Number.isNaN(leftValue) || Number.isNaN(rightValue) || !deficit) {
                dataset.data.push(NaN);
            } else {
                dataset.data.push(deficit.value);
            }
        }

        dataset.borderWidth = 1;
        dataset.borderColor = Colors.KINVENT_ORANGE_LIGHT;
        dataset.backgroundColor = Colors.KINVENT_ORANGE_LIGHTEST;
        dataset.pointRadius = 3;
        dataset.pointBackgroundColor = Colors.KINVENT_ORANGE_LIGHT;
        dataset.pointBorderColor = Colors.KINVENT_ORANGE_LIGHT;
        dataset.pointHoverRadius = 5;
        dataset.pointHoverBorderColor = Colors.KINVENT_ORANGE_LIGHT;
        dataset.pointHoverBackgroundColor = 'white';
        dataset.pointHoverRadius = 5;
        dataset.pointHoverBorderWidth = 3;
        dataset.pointStyle = "rectRot";
        dataset.yAxisID = MeterExerciseLineChartProvider.AXIS_DEFICIT_ID;

        return dataset;
    }

    private labelFormatter(value: number): string {

        // default to integer
        let formatted: string = value.toFixed(0);

        // find the value in the datasets
        let found = false;
        for (const dataset of Array.from(this.sideDatasets.values())) {
            for (const datum of dataset.data) {
                if (datum === value) {
                    formatted = new Kg(value).format(1);
                    found = true;
                    break;
                }
            }
            if (found) {
                break;
            }
        }

        if (!found && this.deficitDataset !== undefined) {
            for (const datum of this.deficitDataset.data) {
                if (datum === value) {
                    formatted = new Percent(value).format(0);
                    found = true;
                    break;
                }
            }
        }
        return formatted;
    }
}
