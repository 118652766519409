import {BaseDto, DeviceType, DeviceTypes} from "common";

export class ActivityConfigDeviceDto extends BaseDto {
    deviceType?: string;

    public get deviceTypeType(): DeviceType {
        return DeviceTypes.parse(this.deviceType);
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
