import {Component, Input, OnInit} from '@angular/core';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {BodySideType} from '../../../models/bodySideType';
import {BuiltinProtocols} from '../../../models/builtinProtocols';
import {MeterEnduranceActivityResultsModel} from '../../../dtos/statistics/meterEnduranceActivityResultsModel';
import {Biomechanics} from '../../../utils/biomechanics';
import {MeterEnduranceStatisticsRowViewModel} from '../../../viewmodels/meterEnduranceStatisticsRowViewModel';
import {Protocols} from '../../../utils/protocols';
import {BaseComponent} from "../../base/base.component";
import {Value} from "../../../utils/values/value";
import {Degrees} from "../../../utils/values/degrees";
import {Kg} from "../../../utils/values/kg";

@Component({
    selector: 'app-protocol-summary-mccall-results',
    templateUrl: './protocol-summary-mccall-results.component.html',
    styleUrls: ['./protocol-summary-mccall-results.component.scss']
})
export class ProtocolSummaryMccallResultsComponent extends BaseComponent implements OnInit {
    @Input()
    public protocol: ProtocolDto;

    @Input()
    public analysis: ProtocolAnalysisServiceResponseDto;

    public maxPerPositionTitles: string[];
    public maxPerPositionData: Map<BodySideType, Value>[];
    public deg90DeficitFormatted: string;
    public deg30DeficitFormatted: string;

    public deg90RepsViewmodel: MeterEnduranceStatisticsRowViewModel[];
    public deg30RepsViewmodel: MeterEnduranceStatisticsRowViewModel[];

    constructor() {
        super();
    }

    ngOnInit(): void {
        // Max force per position
        this.maxPerPositionTitles = [];
        this.maxPerPositionTitles.push(new Degrees(90).format(0));
        this.maxPerPositionTitles.push(new Degrees(30).format(0));
        let deg90Left = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.MCCALL_HAMSTRINGS_90_DEGREES, BodySideType.LEFT);
        let deg90Right = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.MCCALL_HAMSTRINGS_90_DEGREES, BodySideType.RIGHT);
        let deg30Left = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.MCCALL_HAMSTRINGS_30_DEGREES, BodySideType.LEFT);
        let deg30Right = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.MCCALL_HAMSTRINGS_30_DEGREES, BodySideType.RIGHT);

        const deg90Data = new Map<BodySideType, Value>();
        deg90Data.set(BodySideType.LEFT, new Kg(this.getActivityMax(deg90Left, BodySideType.LEFT) || 0));
        deg90Data.set(BodySideType.RIGHT, new Kg(this.getActivityMax(deg90Right, BodySideType.RIGHT) || 0));
        const deg30Data = new Map<BodySideType, Value>();
        deg30Data.set(BodySideType.LEFT, new Kg(this.getActivityMax(deg30Left, BodySideType.LEFT) || 0));
        deg30Data.set(BodySideType.RIGHT, new Kg(this.getActivityMax(deg30Right, BodySideType.RIGHT) || 0));
        this.maxPerPositionData = [deg90Data, deg30Data];

        this.deg90DeficitFormatted = Value.formatOrEmptyValue(Biomechanics.deficit(deg90Data.get(BodySideType.LEFT), deg90Data.get(BodySideType.RIGHT)), 2);
        this.deg30DeficitFormatted = Value.formatOrEmptyValue(Biomechanics.deficit(deg30Data.get(BodySideType.LEFT), deg30Data.get(BodySideType.RIGHT)), 2);

        // Reps
        // Activities are performed separately, combine them to one result for display
        this.deg90RepsViewmodel = [];
        if (deg90Left) {
            this.deg90RepsViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(deg90Left.repResults[0]));
        }
        if (deg90Right) {
            this.deg90RepsViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(deg90Right.repResults[0]));
        }
        this.deg30RepsViewmodel = [];
        if (deg30Left) {
            this.deg30RepsViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(deg30Left.repResults[0]));
        }
        if (deg30Right) {
            this.deg30RepsViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(deg30Right.repResults[0]));
        }
    }

    private getActivityMax(activity: MeterEnduranceActivityResultsModel, side: BodySideType): number | undefined {
        if (!activity) {
            return undefined;
        }
        return activity.maxPerSide.get(side);
    }

}
