import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ProtocolDto} from "../../dtos/protocol.dto";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";
import {ProtocolAnalysisServiceResponseDto} from "../../dtos/statistics/protocolAnalysisServiceResponseDto";
import {ActivityAnalysisServiceResponseDto} from '../../dtos/statistics/activityAnalysisServiceResponseDto';
import {Code} from "common";
import {AnalyseProtocolActivitiesRequestDto} from "../../dtos/analyseProtocolActivitiesRequest.dto";

@Injectable({
    providedIn: 'root'
})
export class ProtocolService {

    constructor(private httpClient: HttpClient) {
    }

    public findByCode(protocolCode: string): Observable<ProtocolDto> {
        return this.httpClient.get<any>(environment.apiUrl + `api/protocols/common/v1/${protocolCode}`)
            .pipe(map(data => {
                return new ProtocolDto().deserialize(data);
            }));
    }

    public findByParticipantCode(participantCode: string): Observable<ProtocolDto[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('participantCode', participantCode);

        return this.httpClient.post<any>(environment.apiUrl + 'api/protocols/common/v1/findByParticipantCode' + `?participantCode=${participantCode}`, {
            params: httpParams
        }).pipe(map(protocolsData => {
            const protocols: ProtocolDto[] = [];
            for (const datum of (protocolsData as any)) {
                protocols.push(new ProtocolDto().deserialize(datum));
            }
            return protocols;
        }));
    }

    public analyseProtocols(protocolCodes: string[]): Observable<ProtocolAnalysisServiceResponseDto> {
        return this.httpClient.post<any>(environment.apiUrl + 'api/protocols/v1/analyzeProtocols', protocolCodes)
            .pipe(map(resultsData => {
                return new ProtocolAnalysisServiceResponseDto().deserialize(resultsData);
            }));
    }

    public analyseActivities(activitiesFromProtocols: Map<Code, Code[]>): Observable<ActivityAnalysisServiceResponseDto> {
        const dto = new AnalyseProtocolActivitiesRequestDto();
        dto.protocolActivities = activitiesFromProtocols;

        return this.httpClient.post<any>(environment.apiUrl + 'api/protocols/v1/analyzeActivities', dto.toSerializableObject())
            .pipe(map(resultsData => {
                return new ActivityAnalysisServiceResponseDto().deserialize(resultsData);
            }));
    }
}
