<div class="component d-flex flex-column">
    <div class="organization d-flex flex-row">
        <div class="left-container flex-grow-1 d-flex pt-1 pb-1 pe-2">
            <div class="organization-logo d-flex align-items-center justify-content-center">
                <img *ngIf="account.organization?.logo"
                     [src]="'data:image/jpg;base64,' + account.organization.logo"
                     alt="Organization logo"/>
            </div>
            <div class="info" *ngIf="account.organization">
                <strong class="title">{{account.organization.name}}</strong>
                <div>{{account.organization.address}}</div>
                <div>{{account.organization.phone}}</div>
                <div>{{account.organization.email}}</div>
            </div>
        </div>
        <div class="professional flex-grow-1 d-flex flex-column justify-content-center ps-3">
            <strong>{{account.fullName}}</strong>
            <div>{{getSpeciality()}}</div>
            <strong>Signature</strong>
        </div>
    </div>
    <img class="kinvent-logo align-self-end mt-1" src="./assets/brand/kinvent_name.png" alt="Kinvent"/>
</div>
