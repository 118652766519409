import {Injectable} from '@angular/core';
import {CdnService} from './cdn.service';
import {Encoding, Resource} from "common";

/**
 * Resolves the image data from an activity image to a <img/> source without a fallback.
 */
@Injectable({
    providedIn: 'root'
})
export class ActivityImageResolverService {
    public static readonly ASSET_ACTIVITIES_PATH = './assets/activities/';

    constructor(private cdnService: CdnService) {
    }

    public do(data: string): string {
        if (!data) {
            return '';
        }

        // source can be blob, url or asset name
        let resolved: string;
        if (data.toLowerCase().startsWith('https://')) {
            resolved = data;
        } else if (data.toLowerCase().includes('cdn')) {
            resolved = this.cdnService.getPath(data);
        } else if (data.length > 500 && Encoding.canBeBase64(data)) {
            // A tiny jpeg with the worst quality is more than that

            // blob. Assume jpg
            resolved = 'data:image/jpg;base64,' + data;
        } else {
            // most likely an activity asset
            resolved = this.getActivityAsset(data);
        }
        return resolved;
    }

    private getActivityAsset(assetName: string): Resource {
        // default file extension is jpg, add it if necessary
        if (assetName.indexOf('.') < 0) {
            assetName = assetName + '.jpg';
        }

        return `${ActivityImageResolverService.ASSET_ACTIVITIES_PATH}${assetName}`;
    }

}
