<div class="component">
    <ng-container *ngIf="analysis; else loadingView">
        <ng-container *ngIf="viewType === resultViewTypes.FULL">
            <ng-container *ngTemplateOutlet="sectionKpis"></ng-container>
            <ng-container *ngTemplateOutlet="sectionTables"></ng-container>
            <ng-container *ngTemplateOutlet="sectionForces"></ng-container>
        </ng-container>
        <ng-container *ngIf="viewType === resultViewTypes.ROW">
            <div class="d-flex flex-row">
                <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
                <div class="max-per-side flex-grow-1">
                    <ng-container *ngTemplateOutlet="sectionTables"></ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #sectionKpis>
    <div class="section first-section d-flex flex-row">
        <div class="chart flex-grow-1">
            <app-max-per-side-chart class="chart" [maxPerSide]="maxPerSide"></app-max-per-side-chart>
        </div>
        <div class="metrics ms-4 flex-grow-1 d-flex flex-row justify-content-between align-self-center">
            <div>
                <div class="spacer">&nbsp;</div>
                <app-value-widget [title]="text('stats.hints.timeOnAir')" [iconType]="valueWidgetIcons.TIMER"></app-value-widget>
                <app-value-widget [title]="text('widget.jump_height.title')" [iconType]="valueWidgetIcons.HEIGHT"></app-value-widget>
            </div>
            <div *ngIf="hasLeft" class="left d-flex flex-column">
                <div class="title">{{formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.TITLE)}}</div>
                <div class="value">{{timeOnAirLeft.format(2)}}</div>
                <div class="value">{{jumpHeightLeft.formatTo(unitTypes.CM, 0)}}</div>
            </div>
            <div *ngIf="hasRight" class="right d-flex flex-column">
                <div class="title">{{formatSideAttribute(sideTypes.RIGHT, sideTypeAttributes.TITLE)}}</div>
                <div class="value">{{timeOnAirRight.format(2)}}</div>
                <div class="value">{{jumpHeightRight.formatTo(unitTypes.CM, 0)}}</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #sectionTables>
    <div class="section mt-4 row">
        <div class="col-10 offset-1">
            <div class="d-flex flex-row">
                <div class="flex-grow-1">
                    <app-table-title>&nbsp;</app-table-title>
                    <app-table-title>{{text('height')}}</app-table-title>
                    <app-table-title>{{format('fmax_kg', unitTypeUtils.symbol(unitTypes.KG))}}</app-table-title>
                    <app-table-title>{{text('exercise.jump_analysis.rfd')}} ({{unitTypeUtils.symbol(unitTypes.NEWTON_PER_SECOND)}})</app-table-title>
                    <app-table-title>{{text('exercise.jump_analysis.impulsion')}} ({{unitTypeUtils.symbol(unitTypes.NEWTON_SECOND)}})</app-table-title>
                </div>
                <div *ngIf="hasLeft" class="flex-grow-1">
                    <app-table-title centerText="true"
                                     [sideForBackgroundColor]="sideTypes.LEFT">{{formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.TITLE)}}</app-table-title>
                    <app-table-value [sideForColor]="sideTypes.LEFT">{{jumpHeightLeft.format(0)}}</app-table-value>
                    <app-table-value [sideForColor]="sideTypes.LEFT">{{maxForceLeft.format(0)}}</app-table-value>
                    <app-table-value [sideForColor]="sideTypes.LEFT">{{rfdLeft.format(0)}}</app-table-value>
                    <app-table-value [sideForColor]="sideTypes.LEFT">{{impulsionLeft.format(0)}}</app-table-value>
                </div>
                <div *ngIf="hasLeft && hasRight" class="flex-grow-1">
                    <app-table-title centerText="true">{{text('deficit')}}</app-table-title>
                    <app-table-value>{{jumpHeightDeficit.format(1)}}</app-table-value>
                    <app-table-value>{{maxForceDeficit.format(1)}}</app-table-value>
                    <app-table-value>{{rfdDeficit.format(1)}}</app-table-value>
                    <app-table-value>{{impulsionDeficit.format(1)}}</app-table-value>
                </div>

                <div *ngIf="hasRight" class="flex-grow-1">
                    <app-table-title centerText="true"
                                     [sideForBackgroundColor]="sideTypes.RIGHT">{{formatSideAttribute(sideTypes.RIGHT, sideTypeAttributes.TITLE)}}</app-table-title>
                    <app-table-value [sideForColor]="sideTypes.RIGHT">{{jumpHeightRight.format(0)}}</app-table-value>
                    <app-table-value [sideForColor]="sideTypes.RIGHT">{{maxForceRight.format(0)}}</app-table-value>
                    <app-table-value [sideForColor]="sideTypes.RIGHT">{{rfdRight.format(0)}}</app-table-value>
                    <app-table-value [sideForColor]="sideTypes.RIGHT">{{impulsionRight.format(0)}}</app-table-value>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #sectionForces>
    <div class="section mt-4">
        <app-jump-analysis-line-chart *ngIf="hasLeft"
                                      [sidesSamples]="samplesLeft"
                                      [rfdPoints]="rfdPointsLeft"
                                      [timeOnAirPoints]="timeOnAirPointsLeft"
                                      [weight]="weight"
        >
        </app-jump-analysis-line-chart>
        <app-jump-analysis-line-chart *ngIf="hasRight"
                                      [sidesSamples]="samplesRight"
                                      [rfdPoints]="rfdPointsRight"
                                      [timeOnAirPoints]="timeOnAirPointsRight"
                                      [weight]="weight"
        >
        </app-jump-analysis-line-chart>
    </div>
</ng-template>

<ng-template #loadingView>
    <app-loading [state]="loadingStates.LOADING"></app-loading>
</ng-template>
