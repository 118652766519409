import {Component, Input, OnInit} from '@angular/core';
import {BodySideType} from "../../../models/bodySideType";
import {Sample} from "../../../utils/sample";
import {SampleUtils} from "../../../utils/sampleUtils";
import {MeterEnduranceStatisticsRowViewModel} from "../../../viewmodels/meterEnduranceStatisticsRowViewModel";
import {ViewMediumType} from "../../results/viewMediumType";
import {BluetoothDeviceTypeUtils} from "../../../models/bluetoothDeviceType";
import {Activities} from "../../../utils/activities";
import {UnitType} from "../../../utils/values/unitType";
import {Value} from "../../../utils/values/value";

/**
 * Stats for a repetition. Used by MeterEndurance and TotalEvaluation.
 * Displays:
 * - a bar chart with max per side on the left,
 * - optionally a table with stats in the middle,
 * - forces and optionally total and max on the right
 */
@Component({
    selector: 'app-meter-endurance-statistics-row',
    templateUrl: './meter-endurance-statistics-row.component.html',
    styleUrls: ['./meter-endurance-statistics-row.component.scss']
})
export class MeterEnduranceStatisticsRowComponent implements OnInit {

    /**
     * The results to display. All should be the same ordinal and different body sides
     */
    @Input()
    public repetitionResults: MeterEnduranceStatisticsRowViewModel[];

    @Input()
    public displayRepetitionOrdinal: boolean = true;

    @Input()
    public mediumType: ViewMediumType = ViewMediumType.SCREEN;

    @Input()
    public showStatsTable: boolean = true;

    @Input()
    public displayTotal: boolean = false;

    @Input()
    public target: number;

    public maxPerSideData: Map<BodySideType, Value>[];
    public samplesData: Map<[BodySideType, UnitType], Sample[]>;
    public totalData: Sample[];

    constructor() {
        this.samplesData = new Map<[BodySideType, UnitType], Sample[]>();
        this.totalData = [];
    }

    ngOnInit(): void {
        this.maxPerSideData = [];

        // Keep special case data
        let leftSideData: Sample[] = undefined;
        let rightSideData: Sample[] = undefined;

        for (const result of this.repetitionResults) {
            let unitType = Activities.getUnitFromDevice(BluetoothDeviceTypeUtils.toDeviceType(result.device));
            const valueForSide = new Map<BodySideType, Value>();
            valueForSide.set(result.side, new Value(result.maxValue, UnitType.KG));
            this.maxPerSideData.push(valueForSide);
            let samples = SampleUtils.createSampleCollection(result.deltas, result.forces);
            this.samplesData.set([result.side, unitType], samples);

            if (result.side === BodySideType.LEFT) {
                leftSideData = samples
            } else if (result.side === BodySideType.RIGHT) {
                rightSideData = samples;
            }
        }

        if (this.displayTotal) {
            if (leftSideData && rightSideData && this.samplesData.size === 2) {
                this.totalData = leftSideData.map((v, i) => new Sample(v.timestamp / 1000, (rightSideData.length > i ? rightSideData[i].value : 0) + v.value));
            }
        }
    }
}
