<div class="component mb-4">
    <div class="balanceArea">
        <div class="targetArea"
             [ngStyle]="{'left': targetTolerance.start * 100 + '%',
              'width': (targetTolerance.length * 100) + '%'}">
        </div>
        <div class="areaOfInterest"
             [ngStyle]="{'left': areaOfInterest.start * 100 + '%',
              'width': (areaOfInterest.length * 100) + '%'}">
        </div>
        <div class="value"
             [ngStyle]="{'left': value * 100 + '%'}"
        >
        </div>
        <div class="maxLeft ms-3 d-flex flex-column p-2 align-items-center">
            <b class="mb-1">{{text('exercise.dynamic_distribution_evaluation.balance_component_max_left_title')}}</b>
            <app-left-right-text [leftValue]="maxLeftDistribution" [rightValue]="minRightDistribution">
            </app-left-right-text>
        </div>
        <div class="maxRight me-3 d-flex flex-column p-2 align-items-center">
            <b class="mb-1">{{text('exercise.dynamic_distribution_evaluation.balance_component_max_right_title')}}</b>
            <app-left-right-text [leftValue]="minLeftDistribution" [rightValue]="maxRightDistribution">
            </app-left-right-text>
        </div>
    </div>
    <div class="ruler d-flex flex-row">
        <div *ngFor="let index of scale()" class="item d-flex flex-column" [ngStyle]="{'left': index + '%'}">
            <div class="hatchMark"></div>
            <div class="values">
                <span class="left me-1">{{100 - index}}</span>
                <span class="right">{{index}}</span>
            </div>
        </div>
    </div>
</div>
