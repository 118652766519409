import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Value} from "../../../utils/values/value";

@Component({
    selector: 'app-stance-mean-cop-position',
    templateUrl: './stance-mean-cop-position.component.html',
    styleUrls: ['./stance-mean-cop-position.component.scss']
})
export class StanceMeanCopPositionComponent extends BaseComponent implements OnInit {

    @Input()
    public lateralAverage: Value;

    @Input()
    public longitudinalAverage: Value;

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

}
