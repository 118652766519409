import {Component, Input, OnInit} from '@angular/core';
import {Device} from "../../viewmodels/device";
import {Title} from "common";

@Component({
    selector: 'app-device-icon',
    templateUrl: './device-icon.component.html',
    styleUrls: ['./device-icon.component.scss']
})
export class DeviceIconComponent implements OnInit {

    @Input()
    public device: Device;

    @Input()
    public displayTitle = true;

    public iconSrc: string;
    public iconAlt: string;
    public title: Title;

    constructor() {
    }

    ngOnInit(): void {
        this.iconSrc = this.device.getIcon();
        this.iconAlt = `${this.device.title} sensor`;
        this.title = this.device.title;
    }
}
