import {ChartDataSets, ChartOptions} from 'chart.js';
import {ActivityDto} from '../../../dtos/activity.dto';
import {ProgressOverTimeFilter} from '../../../viewmodels/progressOverTimeFilter';
import {MeterEnduranceActivityResultsModel} from '../../../dtos/statistics/meterEnduranceActivityResultsModel';
import {BodySideType, BodySideTypes} from '../../../models/bodySideType';
import {Label} from 'ng2-charts';
import {format} from 'date-fns';
import {I18nService} from '../../../services/i18n/i18n.service';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {ProtocolsLineChartProvider} from './protocolsLineChartProvider';
import {BuiltinProtocols} from '../../../models/builtinProtocols';
import {Color, DateFormat, Dates, KeyValue} from "common";
import {Biomechanics} from '../../../utils/biomechanics';
import {Protocols} from '../../../utils/protocols';
import {MathUtils} from "../../../utils/mathUtils";
import {ChartUtils} from "../../../utils/chartUtils";
import {Kg} from "../../../utils/values/kg";
import {Colors} from "../../../services/colors";
import {Value} from "../../../utils/values/value";

export class KneeAntagonistLineChartProvider extends ProtocolsLineChartProvider {
    private static AXIS_RATIO_ID = 'ratio';

    private flexionActivitySideDatasets: Map<BodySideType, ChartDataSets>;
    private extensionActivitySideDatasets: Map<BodySideType, ChartDataSets>;
    private ratioSideDatasets: Map<BodySideType, ChartDataSets>;

    constructor() {
        super();
        this.flexionActivitySideDatasets = new Map<BodySideType, ChartDataSets>();
        this.extensionActivitySideDatasets = new Map<BodySideType, ChartDataSets>();
        this.ratioSideDatasets = new Map<BodySideType, ChartDataSets>();
    }

    getDatasets(protocols: ProtocolDto[], analysis: ProtocolAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: ChartOptions, i18n: I18nService): KeyValue<Label[], ChartDataSets[]> {
        this.flexionActivitySideDatasets.clear();
        this.extensionActivitySideDatasets.clear();
        this.ratioSideDatasets.clear();

        const activities = protocols.flatMap(p => p.activities);
        const protocolsByDay = Protocols.groupByDay(protocols);
        // Due to how charts lib works, labels are shared among all datasets, and where a dataset doesn't have a value, they have a NaN value
        const days = Array.from(protocolsByDay.keys()).sort();

        // create labels
        const labels: Label[] = days.map(day => format(day, Dates.getFormat(DateFormat.DATE_SHORTER)));

        const datasets: ChartDataSets[] = [];

        const activitiesToResults = this.combineActivitiesToResults<MeterEnduranceActivityResultsModel>(activities, analysis);
        const activitiesResults = Array.from(activitiesToResults.values()).filter(r => r !== undefined);
        const resultsSides = activitiesResults.flatMap(activitiesResult => activitiesResult.repResults.map(r => r.bodySideType));
        const sides = new Set<BodySideType>(resultsSides);

        let maxValue = 0;
        const uniqueActivitiesCodes = new Set(activities.map(activity => activity.config.baseConfigCode));
        for (const activityCode of uniqueActivitiesCodes) {
            for (const side of sides) {
                const dataset = this.createActivitySideDataset(activityCode, side, protocolsByDay, activitiesToResults, days, i18n);
                maxValue = Math.max(maxValue, ...(dataset.data as number[]).filter(value => !Number.isNaN(value)));
                datasets.push(dataset);
            }
        }

        chartOptions.scales.yAxes[0].ticks = {min: 0, max: maxValue * 1.2};

        let maxRatio = 0;
        for (const side of sides) {
            const dataset = this.createRatioSideDataset(side, this.flexionActivitySideDatasets, this.extensionActivitySideDatasets, days, i18n);
            maxRatio = Math.max(maxRatio, ...(dataset.data as number[]).filter(value => !Number.isNaN(value)));
            this.ratioSideDatasets.set(side, dataset);
            datasets.push(dataset);
        }

        chartOptions.scales.yAxes.push({id: KneeAntagonistLineChartProvider.AXIS_RATIO_ID, position: 'right', display: true, ticks: {min: 0, max: maxRatio * 1.2}});

        // Chart options
        if (!chartOptions.plugins) {
            chartOptions.plugins = {};
        }
        chartOptions.plugins.datalabels = {
            formatter: this.labelFormatter.bind(this),
            anchor: 'end',
            align: 'end',
        };

        return KeyValue.of(labels, datasets);
    }

    private createActivitySideDataset(activityCode: string, side: BodySideType, protocolsByDay: Map<number, ProtocolDto[]>, activitiesToResults: Map<ActivityDto, MeterEnduranceActivityResultsModel>, days: number[], i18n: I18nService): ChartDataSets {
        const dataset: ChartDataSets = {};

        dataset.data = [];
        dataset.spanGaps = true;
        dataset.lineTension = 0;

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value: number = NaN;
            // Create dataset with values for metrics.
            // Add NaN when there is no value for the day except for the last day, where we add the previous value
            if (protocolsByDay.has(day)) {
                const activities = protocolsByDay.get(day).flatMap(p => p.activities).filter(a => a.config.baseConfigCode === activityCode);
                const activitiesResults = activities.map(a => activitiesToResults.get(a)).filter(r => r !== undefined);
                const reps = activitiesResults.flatMap(r => r.repResults);
                const sideReps = reps.filter(rep => rep.bodySideType === side);
                if (sideReps.length > 0) {
                    const maxValues = sideReps.map(r => r?.maxValue).filter(value => !Number.isNaN(value));
                    value = MathUtils.maxOrDefault(maxValues, NaN);
                } else if (i === days.length - 1 && days.length > 1) {
                    value = dataset.data[i - 1] as number;
                }
            }
            dataset.data.push(value);
        }

        ChartUtils.formatDatasetForSide(dataset, side);

        const isKneeFlexion = BuiltinProtocols.KNEE_ANTAGONIST_FLEXION_CODES.includes(activityCode);
        const isKneeExtension = BuiltinProtocols.KNEE_ANTAGONIST_EXTENSION_CODES.includes(activityCode);

        if (isKneeFlexion) {
            dataset.label = `${i18n.format("flexion")} ${BodySideTypes.format(side)}`;
            dataset.borderDash = [2];
            this.flexionActivitySideDatasets.set(side, dataset);
        } else if (isKneeExtension) {
            dataset.label = `${i18n.format("extension")} ${BodySideTypes.format(side)}`;
            this.extensionActivitySideDatasets.set(side, dataset);
        }

        return dataset;
    }

    private createRatioSideDataset(side: BodySideType, flexionActivitiesDataSets: Map<BodySideType, ChartDataSets>, extensionActivitiesDataSets: Map<BodySideType, ChartDataSets>, days: number[], i18n: I18nService): ChartDataSets {
        const dataset: ChartDataSets = {};
        dataset.label = `${i18n.format("protocolResults.multiple.export.antagonist.ratioTitle")} ${BodySideTypes.format(side)}`;

        dataset.data = [];

        dataset.spanGaps = true;
        dataset.lineTension = 0;

        let value: number = NaN;

        for (let i = 0; i < days.length; i++) {
            const maxFlexion = flexionActivitiesDataSets.get(side).data[i];
            const maxExtension = extensionActivitiesDataSets.get(side).data[i];
            const ratio = Biomechanics.ratio(Number(maxFlexion), Number(maxExtension));
            if (ratio) {
                value = ratio.value;
            }
            dataset.data.push(value);
        }
        const color: Color = side === BodySideType.LEFT ? Colors.RED : Colors.GREEN;
        ChartUtils.formatDatasetWithColor(dataset, color);
        dataset.yAxisID = KneeAntagonistLineChartProvider.AXIS_RATIO_ID;
        return dataset;
    }

    private labelFormatter(value: number): string {
        // default to integer
        let formatted: string = value.toFixed(0);

        // find the value in the datasets
        let found = false;
        const activitiesDatasets = Array.from(this.flexionActivitySideDatasets.values()).concat(Array.from(this.extensionActivitySideDatasets.values()));
        for (const dataset of activitiesDatasets) {
            for (const datum of dataset.data) {
                if (datum === value) {
                    formatted = new Kg(value).format(1);
                    found = true;
                    break;
                }
            }
            if (found) {
                break;
            }
        }
        if (!found) {
            for (const dataset of Array.from(this.ratioSideDatasets.values())) {
                for (const datum of dataset.data) {
                    if (datum === value) {
                        formatted = new Value(value).format(1);
                        found = true;
                        break;
                    }
                }
                if (found) {
                    break;
                }
            }
        }
        return formatted;
    }
}
