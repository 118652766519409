import {Pipe, PipeTransform} from '@angular/core';
import {I18nService} from "../../services/i18n/i18n.service";

@Pipe({
    name: 'text',
    pure: true
})
export class TextPipe implements PipeTransform {
    public static i18n: I18nService;

    transform(value: string, ...formatArgs: string[]): string {
        if (formatArgs.length === 0) {
            return TextPipe.i18n.text(value);
        } else {
            return TextPipe.i18n.format(value, ...formatArgs);
        }
    }
}
