import {Injectable} from '@angular/core';
import {BreadcrumbService} from "xng-breadcrumb";

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbInitializerService {

    constructor(private breadcrumbService: BreadcrumbService) {
    }

    public setParticipantName(name: string): void {
        this.breadcrumbService.set('@participantCode', name);
    }

    public setResultsTitle(name: string): void {
        this.breadcrumbService.set("@protocolTitle", name);
    }
}
