import {BaseDto, Code} from "common";

export class ParticipantMetaDto extends BaseDto {
    metaKey: string;
    metaValue: string;
    code: Code;
    createdOn: number;
    deleted: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
