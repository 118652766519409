/**
 * Filter to be applied to groups of protocols or ProtocolViewModel
 */
import {GameType} from '../models/gameType';
import {ExerciseType} from '../models/exerciseType';
import {PerformType} from './performType';
import {ProtocolOrActivityConfigTag} from '../models/protocolOrActivityConfigTag';
import {ActivityType} from '../models/activityType';
import {FilterPeriodType} from './filterPeriodType';
import {DeviceType, Dto, TextUtils} from "common";

export class ProtocolGroupFilter extends Dto {
    public query: string = '';
    public activityTypes: ActivityType[] = [];
    public performType: PerformType[] = [];
    public baseConfigGroup: string;
    public baseConfigCode: string;
    public devices: DeviceType[] = [];
    public tags: ProtocolOrActivityConfigTag[] = [];
    public gameType: GameType;
    public exerciseType: ExerciseType;
    public period: FilterPeriodType = FilterPeriodType.ALL;

    public deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    public hasQuery(): boolean {
        return !TextUtils.isEmpty(this.query);
    }

    public hasFilters(): boolean {
        return this.hasQuery() || this.performType.length > 0 || !TextUtils.isEmpty(this.baseConfigGroup) ||
            !TextUtils.isEmpty(this.baseConfigCode) || this.devices.length > 0 ||
            this.gameType !== undefined || this.exerciseType !== undefined || this.tags.length > 0 ||
            this.activityTypes.length > 0 || (this.period !== undefined && this.period !== FilterPeriodType.ALL);
    }

    public getFilterId(): string {
        let haveGroup = !TextUtils.isEmpty(this.baseConfigGroup);
        let baseId = haveGroup ? this.baseConfigGroup : this.baseConfigCode;
        let activityType = this.activityTypes.join();
        let exerciseType = !haveGroup ? this.exerciseType : '';
        let gameType = !TextUtils.isEmpty(this.gameType) ? this.gameType : '';

        return baseId + this.devices.sort().join() + gameType + exerciseType + activityType;
    }
}
