import {Component, Input, OnInit} from '@angular/core';
import {Color} from "common";

@Component({
    selector: 'app-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

    @Input()
    public color: Color;

    @Input()
    public backgroundColor: Color;

    @Input()
    public compact: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

}
