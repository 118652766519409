import {Component, OnInit} from '@angular/core';
import {ParticipantDto} from "../../dtos/participant.dto";
import {ParticipantsFilter} from "../../viewmodels/participantsFilter";
import {ParticipantsFilteringService} from "../../services/participants-filtering.service";
import {OrderingType} from "../../viewmodels/orderingType";
import {ParticipantOrderingService} from "../../services/participant-ordering.service";
import {map, mergeMap, takeUntil} from "rxjs/operators";
import {ParticipantService} from "../../services/api/participant.service";
import {ParticipantTagConfigService} from "../../services/api/participant-tag-config.service";
import {ParticipantTagConfigDto} from "../../dtos/participantTagConfig.dto";
import {Code, Gender, LoadingState} from "common";
import {Observable, of} from "rxjs";
import {BaseComponent} from "../base/base.component";

@Component({
    selector: 'app-participants',
    templateUrl: './participants.component.html',
    styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent extends BaseComponent implements OnInit {
    public participants: ParticipantDto[];
    public participantTagConfigs: ParticipantTagConfigDto[];
    public participantTags: Map<Code, ParticipantTagConfigDto[]>;

    public loadingState: LoadingState;

    // Ordering & Filters
    public filter: ParticipantsFilter;
    public filteredParticipants: ParticipantDto[];
    public currentOrder: OrderingType;
    public orderOptions: OrderingType[];

    constructor(private filteringService: ParticipantsFilteringService,
                private orderingService: ParticipantOrderingService,
                private participantService: ParticipantService,
                private participantTagConfigService: ParticipantTagConfigService
    ) {
        super();
        this.filter = new ParticipantsFilter();
    }

    ngOnInit(): void {
        this.loadingState = LoadingState.LOADING;

        this.orderOptions = [OrderingType.RELEVANCE, OrderingType.NAME_ASC, OrderingType.NAME_DESC, OrderingType.LASTNAME_ASC, OrderingType.LASTNAME_DESC, OrderingType.DATE_DESC, OrderingType.DATE_ASC];
        this.currentOrder = OrderingType.LASTNAME_ASC;

        this.participantService.getAll()
            .pipe(takeUntil(this.destroySubject))
            .pipe(map(participants => {
                // keep participants
                this.participants = participants;
                this.filteredParticipants = participants;
                this.orderChanged(this.currentOrder);

                // unique participant tags
                return Array.from(new Set(this.participants.flatMap(p => p.tags)
                    .filter(t => !t.deleted)
                    .map(t => t.participantTagConfigCode)));
            }))
            .pipe(mergeMap(value => {
                let tagsRequest: Observable<ParticipantTagConfigDto[]>;
                if (value.length > 0) {
                    tagsRequest = this.participantTagConfigService.findByCodes(value);
                } else {
                    // skip the request when no user has tags
                    tagsRequest = of([]);
                }
                return tagsRequest;
            }))
            .pipe(map(tagConfigs => {
                this.participantTagConfigs = tagConfigs.filter(t => !t.deleted);

                // Keep tags for each participant
                this.participantTags = new Map<Code, ParticipantTagConfigDto[]>();
                for (const participant of this.participants) {
                    const participantTagConfigCodes = participant.tags.filter(t => !t.deleted).map(t => t.participantTagConfigCode);
                    const participantTags = this.participantTagConfigs.filter(t => participantTagConfigCodes.includes(t.code));
                    this.participantTags.set(participant.code, participantTags);
                }
            }))
            .subscribe(value => {
                this.loadingState = LoadingState.LOADED;
            }, error => {
                this.errorHandler.handle(error);
                this.loadingState = LoadingState.ERROR;
            });
    }

    onParticipantSelected(participant: ParticipantDto) {
        this.navigation.goToParticipant(participant.code);
    }

    onSearchChanged(query: string) {
        this.filter.query = query;
        this.updateParticipants();
        if (this.filter.hasQuery()) {
            this.orderChanged(OrderingType.RELEVANCE);
        } else {
            this.orderChanged(OrderingType.LASTNAME_ASC);
        }
    }

    onGenderChanged(gender: Gender) {
        this.filter.gender = gender;
        this.updateParticipants();
    }

    private updateParticipants(): void {
        this.filteredParticipants = this.filteringService.applyFilter(this.participants, this.filter);
    }

    orderChanged(type: OrderingType) {
        this.currentOrder = type;
        this.orderingService.order(this.filteredParticipants, type);
        this.filteredParticipants = [...this.filteredParticipants];
    }

    public selectedTagsChange() {
        this.updateParticipants();
    }
}
