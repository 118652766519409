<div class="d-flex flex-column align-items-center">
    <div class="section full-width">
        <h2 class="section-title">{{text("neck_mobility")}}</h2>
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="d-flex flex-column">
                <div class="fw-bold d-flex flex-column align-items-center color-accent pb-5">
                    <span>{{text("rotation.left")}}</span>
                    <span>{{formatValue(neckRotationLeftValue, 1)}}</span>
                    <span class="norm-value">{{format("norm.title", "70")}}</span>
                </div>
                <div class="fw-bold d-flex flex-column align-items-center color-accent pb-5">
                    <span>{{text("tilt.left")}}</span>
                    <span>{{formatValue(neckTiltLeftValue, 1)}}</span>
                    <span class="norm-value">{{format("norm.title", "35")}}</span>
                </div>
            </div>
            <div class="d-flex flex-column">
                <div class="fw-bold d-flex flex-column align-items-center color-primary">
                    <span>{{text("flexion")}}</span>
                    <span>{{formatValue(neckFlexionValue, 1)}}</span>
                    <span class="norm-value">{{format("norm.title", "45")}}</span>
                </div>
                <div class="image">
                    <app-image [image]="neckAnglesImage" [lazily]="false" description="neckAnglesImage"></app-image>
                </div>
                <div class="fw-bold d-flex flex-column align-items-center color-accent">
                    <span>{{text("extension")}}</span>
                    <span>{{formatValue(neckExtensionValue, 1)}}</span>
                    <span class="norm-value">{{format("norm.title", "35")}}</span>
                </div>
            </div>
            <div class="d-flex flex-column">
                <div class="fw-bold d-flex flex-column align-items-center color-primary pb-5">
                    <span>{{text("rotation.right")}}</span>
                    <span>{{formatValue(neckRotationRightValue, 1)}}</span>
                    <span class="norm-value">{{format("norm.title", "70")}}</span>
                </div>
                <div class="fw-bold d-flex flex-column align-items-center color-primary pb-5">
                    <span>{{text("tilt.right")}}</span>
                    <span>{{formatValue(neckTiltRightValue, 1)}}</span>
                    <span class="norm-value">{{format("norm.title", "35")}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="section full-width">
        <h2 class="section-title">{{text("neck.strength")}}</h2>
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="fw-bold d-flex flex-column align-items-center color-accent">
                <span>{{text("tilt.left")}}</span>
                <span>{{formatValue(neckLinkTiltLeftValue, 1)}}</span>
            </div>
            <div class="d-flex flex-column">
                <div class="fw-bold d-flex flex-column align-items-center color-primary">
                    <span>{{text("flexion")}}</span>
                    <span>{{formatValue(neckLinkFlexionValue, 1)}}</span>
                </div>
                <div class="image">
                    <app-image [image]="neckForceImage" [lazily]="false" description="neckForceImage"></app-image>
                </div>
                <div class="fw-bold d-flex flex-column align-items-center color-accent">
                    <span>{{text("extension")}}</span>
                    <span>{{formatValue(neckLinkExtensionValue, 1)}}</span>
                </div>
            </div>
            <div class="fw-bold d-flex flex-column align-items-center color-primary">
                <span>{{text("tilt.right")}}</span>
                <span>{{formatValue(neckLinkTiltRightValue, 1)}}</span>
            </div>
        </div>
    </div>
</div>
