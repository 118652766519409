import {ForceAndTimePacket} from "./forceAndTimePacket";
import {JumpCycle} from "./jumpCycle";
import {BodySideType, BodySideTypes} from "../../models/bodySideType";
import {Dto} from "common";

export class UnipodalJumpAnalysisActivitySideResultsModel extends Dto {
    public side: BodySideType;
    public maxJumpHeight: number; // cm
    public maxForce: number;
    public totalRfd: number;
    public impulsion: number;
    public timeOnAir: number; // seconds

    public pointWhereJumpProcedureStarted: ForceAndTimePacket;

    public minValuePair: ForceAndTimePacket; // the min value (and the time that it happened) before giving force on the feet to jump
    public maxValuePair: ForceAndTimePacket; // the max value (and the time that it happened) reached before landing on ground

    public jump: JumpCycle | null;
    public weight: number;

    public forces: Array<ForceAndTimePacket>;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.side = BodySideTypes.parse(input.side);
        this.pointWhereJumpProcedureStarted = new ForceAndTimePacket().deserialize(input.pointWhereJumpProcedureStarted);
        this.minValuePair = new ForceAndTimePacket().deserialize(input.minValuePair);
        this.maxValuePair = new ForceAndTimePacket().deserialize(input.maxValuePair);
        if (input.jump) {
            this.jump = new JumpCycle().deserialize(input.jump);
        }

        this.forces = this.deserializeArray(input.forces, ForceAndTimePacket);

        return this;
    }

}
