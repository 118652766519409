import {Component, Input, OnInit} from '@angular/core';
import {BodySideType, BodySideTypes} from "../../models/bodySideType";

@Component({
    selector: 'app-side-icon',
    templateUrl: './side-icon.component.html',
    styleUrls: ['./side-icon.component.scss']
})
export class SideIconComponent implements OnInit {

    @Input()
    public side: BodySideType;

    public sideSrc: string;
    public sideAlt: string;

    constructor() {
    }

    ngOnInit(): void {
        this.sideSrc = BodySideTypes.icon(this.side);
        this.sideAlt = `${BodySideTypes.format(this.side)} icon`;
    }
}
