import {Dto} from "common";

export class DynamicStrengthIndexSummaryResultsModelDto extends Dto {
    public dsi: number | null; // % [0-1]

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
