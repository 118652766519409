import {I18nService} from '../services/i18n/i18n.service';

export enum OrderingType {
    RELEVANCE,
    DATE_ASC,
    DATE_DESC,
    TITLE_ASC,
    TITLE_DESC,
    NAME_ASC,
    NAME_DESC,
    LASTNAME_ASC,
    LASTNAME_DESC,
}

export class OrderingTypes {
    public static title(i18n: I18nService, type: OrderingType): string {
        let title = '';
        switch (type) {
            case OrderingType.RELEVANCE:
                title = i18n.text('orderingType.relevance');
                break;
            case OrderingType.DATE_ASC:
                title = i18n.text('orderingType.date_asc');
                break;
            case OrderingType.DATE_DESC:
                title = i18n.text('orderingType.date_desc');
                break;
            case OrderingType.TITLE_ASC:
                title = i18n.text('orderingType.title_asc');
                break;
            case OrderingType.TITLE_DESC:
                title = i18n.text('orderingType.title_desc');
                break;
            case OrderingType.NAME_ASC:
                title = i18n.text('orderingType.name_asc');
                break;
            case OrderingType.NAME_DESC:
                title = i18n.text('orderingType.name_desc');
                break;
            case OrderingType.LASTNAME_ASC:
                title = i18n.text('orderingType.lastName_asc');
                break;
            case OrderingType.LASTNAME_DESC:
                title = i18n.text('orderingType.lastName_desc');
                break;
        }
        return title;
    }
}
