<div class="component d-block position-relative full-width full-height">
    <div class="filters print-hidden d-flex flex-row align-items-baseline mt-2 mb-3">
        <mat-button-toggle-group [value]="filter.metric.type" (change)="onMetricSelected($event)">
            <mat-button-toggle *ngFor="let metric of metrics" [value]="metric.type">
                <mat-icon *ngIf="metric.matIcon" [svgIcon]="metric.matIcon"></mat-icon>
                {{text(metric.title)}}
            </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-slide-toggle *ngIf="filter.isDeficitAvailable" class="deficitToggle ms-3" [checked]="filter.useDeficit" (change)="onDeficitChange($event)"
                          color="primary">{{text('report.filter.deficit.title')}}</mat-slide-toggle>
    </div>
    <h2 class="print-title print-visible">
        {{printTitle}}
    </h2>
    <div class="chart">
        <canvas baseChart
                [labels]="labels"
                [datasets]="datasets"
                [options]="chartOptions"
                [plugins]="chartPlugins"
                chartType="line">
        </canvas>
    </div>
</div>
