<div class="container">
    <h1>{{'acceptParticipantShare.title'|text}} </h1>
    <div class="content">
        <div *ngIf="isLoadingState(loadingStates.LOADING)">
            {{'acceptParticipantShare.verifying'|text}}
        </div>
        <div *ngIf="isLoadingState(loadingStates.LOADED)" class="success">
            <p>
                {{'acceptParticipantShare.congratulations'|text}}
                <br/>
                <br/>
                {{'acceptParticipantShare.success'|text}}
            </p>
        </div>
        <div *ngIf="isLoadingState(loadingStates.ERROR)">
            <p>
                {{'acceptParticipantShare.error'|text}} <a href="mailto:info@k-invent.com" target="_blank">info@k-invent.com</a>
            </p>
        </div>

    </div>
</div>

