import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {Percent} from "../../utils/values/percent";
import {ValueWidgetIcon} from "../../viewmodels/valueWidgetIcons";

@Component({
    selector: 'app-dev-playground',
    templateUrl: './dev-playground.component.html',
    styleUrls: ['./dev-playground.component.scss']
})
export class DevPlaygroundComponent extends BaseComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    getValue() {
        return new Percent(87);
    }

    getIcon() {
        return ValueWidgetIcon.REPEAT;
    }
}
