import {Component, Input, OnInit} from '@angular/core';
import {BodySideType, BodySideTypes} from "../../../models/bodySideType";
import {BaseComponent} from "../../base/base.component";
import {Value} from "../../../utils/values/value";
import {Percent} from "../../../utils/values/percent";
import {KeyValue} from "common";

@Component({
    selector: 'app-stance-ellipse-statistics',
    templateUrl: './stance-ellipse-statistics.component.html',
    styleUrls: ['./stance-ellipse-statistics.component.scss']
})
export class StanceEllipseStatisticsComponent extends BaseComponent implements OnInit {

    @Input()
    public surfaces: KeyValue<BodySideType, Value>[];

    @Input()
    public hidePopulation: boolean;

    public commonSurface: Value;
    public leftSurface: Value;
    public rightSurface: Value;

    public leftSideLabel: string;
    public rightSideLabel: string;
    public difference: Percent;

    constructor() {
        super();
    }

    ngOnInit(): void {
        for (const surfaceDatum of this.surfaces) {
            if (surfaceDatum.key === BodySideType.BOTH) {
                this.commonSurface = surfaceDatum.value;
            } else if (surfaceDatum.key === BodySideType.LEFT) {
                this.leftSideLabel = BodySideTypes.format(BodySideType.LEFT);
                this.leftSurface = surfaceDatum.value;
            } else if (surfaceDatum.key === BodySideType.RIGHT) {
                this.rightSideLabel = BodySideTypes.format(BodySideType.RIGHT);
                this.rightSurface = surfaceDatum.value;
            }
        }

        if (this.leftSurface && this.rightSurface) {
            const left = this.leftSurface.value;
            const right = this.rightSurface.value;

            // this.difference = new Percent(Math.abs((left - right) / ((left + right) / 2)) * 100);
            // This calculation is weird. Have requested validation from the team
            this.difference = new Percent(Math.abs((left - right) / (left + right)) * 100);
        }
    }
}
