import {Component, OnInit} from '@angular/core';
import {DynamicDistributionEvaluationResultsModel} from '../../../dtos/statistics/dynamicDistributionEvaluationResultsModel';
import {ValueWidgetIcon} from '../../../viewmodels/valueWidgetIcons';
import {Range} from '../../../models/range';
import {BodySideType} from '../../../models/bodySideType';
import {Sample} from '../../../utils/sample';
import {SampleUtils} from '../../../utils/sampleUtils';
import {ActivityAnalysisResultDto} from '../../../dtos/statistics/activityAnalysisResultDto';
import {ActivityConfigDto} from '../../../dtos/activityConfig.dto';
import {Resource} from "common";
import {ActivityImageResolverService} from "../../../services/activity-image-resolver.service";
import {ResultsComponent} from "../resultsComponent";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ActivityDto} from "../../../dtos/activity.dto";
import {Activities} from "../../../utils/activities";
import {Value} from "../../../utils/values/value";
import {UnitType} from "../../../utils/values/unitType";
import {Seconds} from "../../../utils/values/seconds";
import {Percent} from "../../../utils/values/percent";
import {Kg} from "../../../utils/values/kg";

@Component({
    selector: 'app-dynamic-distribution-evaluation-results',
    templateUrl: './dynamic-distribution-evaluation-results.component.html',
    styleUrls: ['./dynamic-distribution-evaluation-results.component.scss']
})
export class DynamicDistributionEvaluationResultsComponent extends ResultsComponent implements OnInit {
    public readonly valueWidgetIcons: typeof ValueWidgetIcon = ValueWidgetIcon;
    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: DynamicDistributionEvaluationResultsModel;
    public image: Resource;

    public numberOfRepsValue: Value;
    public durationValue: Value;
    public paceValue: Value;

    public targetTolerance: Range;
    public stdToDistribution: Range;
    public stdValue: Value;
    public maxStrengthPerSide: Map<BodySideType, Value>;
    public totalValue: Value;
    public forcesData: Map<BodySideType, Value>[];
    public forcesDataTitles: string[];
    public forceSamples: Map<[BodySideType, UnitType], Sample[]>;
    public forceSamplesTotal: Sample[];
    public dynamicCopAnalysis: ActivityAnalysisServiceResponseDto;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. DynamicDistributionEvaluationResultsComponent`, this.analysis);
            return;
        }
        const activityAnalysisResultDto = this.analysis.activitiesResults[0];

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = activityAnalysisResultDto.activityResultsModel as DynamicDistributionEvaluationResultsModel;
        let statistics = this.statistics;

        // common
        this.image = this.activityImageResolverService.do(this.activity.image);

        // Top metrics
        this.numberOfRepsValue = new Value(statistics.squats.length);
        this.durationValue = new Seconds(statistics.duration);
        this.paceValue = new Value(statistics.pace, UnitType.PER_MINUTE);

        // Balance component
        this.targetTolerance = new Range();
        this.targetTolerance.start = statistics.balanceDistributionConfig.targetDifficultyRangeLeft;
        this.targetTolerance.end = statistics.balanceDistributionConfig.targetDifficultyRangeRight;

        this.stdToDistribution = new Range();
        this.stdToDistribution.start = statistics.avgRightDistributionPercent - statistics.standardDeviation * 2 / 100;
        this.stdToDistribution.end = statistics.avgRightDistributionPercent + statistics.standardDeviation * 2 / 100;

        this.stdValue = new Value(statistics.standardDeviation);

        // Max strength
        this.maxStrengthPerSide = new Map<BodySideType, Value>();
        this.maxStrengthPerSide.set(BodySideType.LEFT, new Kg(statistics.leftMaxForce));
        this.maxStrengthPerSide.set(BodySideType.RIGHT, new Kg(statistics.rightMaxForce));
        this.totalValue = new Kg(statistics.leftMaxForce + statistics.rightMaxForce);

        // Forces
        this.forcesDataTitles = [this.text('dynamicDistribution.results.piecharts.low_title'),
            this.text('dynamicDistribution.results.piecharts.normal_title'),
            this.text('dynamicDistribution.results.piecharts.high_title')];
        const smallForces = new Map<BodySideType, Value>();
        const normalForces = new Map<BodySideType, Value>();
        const highForces = new Map<BodySideType, Value>();
        smallForces.set(BodySideType.LEFT, new Percent(statistics.smallLoadsLeftLimbRatio * 100));
        smallForces.set(BodySideType.RIGHT, new Percent(statistics.smallLoadsRightLimbRatio * 100));
        normalForces.set(BodySideType.LEFT, new Percent(statistics.normalLoadsLeftLimbRatio * 100));
        normalForces.set(BodySideType.RIGHT, new Percent(statistics.normalLoadsRightLimbRatio * 100));
        highForces.set(BodySideType.LEFT, new Percent(statistics.bigLoadsLeftLimbRatio * 100));
        highForces.set(BodySideType.RIGHT, new Percent(statistics.bigLoadsRightLimbRatio * 100));
        this.forcesData = [smallForces, normalForces, highForces];

        // Force samples
        let unitType = Activities.getUnitType(this.activity);
        this.forceSamples = new Map<[BodySideType, UnitType], Sample[]>();
        this.forceSamples.set([BodySideType.LEFT, unitType], SampleUtils.createSampleCollection(statistics.syncedTimestamps, statistics.syncedForcesLeft));
        this.forceSamples.set([BodySideType.RIGHT, unitType], SampleUtils.createSampleCollection(statistics.syncedTimestamps, statistics.syncedForcesRight));
        this.forceSamplesTotal = SampleUtils.createSampleCollection(statistics.syncedTimestamps, statistics.syncedSumForces);

        // CoP
        if (this.statistics.stanceResults) {
            // Create a mock analysis to feed to stance component
            const analysis = new ActivityAnalysisResultDto();
            analysis.activityResultsModel = this.statistics.stanceResults;
            analysis.config = new ActivityConfigDto();
            analysis.config.activityConfigDevices = [...this.activity.config.activityConfigDevices];

            this.dynamicCopAnalysis = new ActivityAnalysisServiceResponseDto();
            this.dynamicCopAnalysis.activitiesResults = [analysis];
        }
    }

}
