import {I18nService} from "../../services/i18n/i18n.service";

export enum UnitType {
    NONE,
    // Weight
    KG,
    LBS,
    NEWTON,

    // Angles
    ANGLES, // Degrees

    // Percent
    PERCENT,

    // Length
    CM,
    MM,
    INCH,

    // Time
    MINUTES,
    SECONDS,
    PER_MINUTE,

    // Speed
    M_PER_SECOND,
    MM_PER_SECOND,

    // Surface
    MM2, //mm²

    // Other
    NEWTON_SECOND, // Impulse. N*s
    KG_PER_SECOND, // Kg/s
    NEWTON_PER_SECOND, // N / s
    NEWTON_PER_KG, // N/Kg
    NEWTON_METERS, // N*m
    WATT_PER_KG, // W / Kg
}

/**
 * Declare unit based on the measuring medium.
 * Source: https://stackoverflow.com/a/62770357/245495
 */
const LengthUnit = [UnitType.CM, UnitType.MM, UnitType.INCH] as const;
const WeightUnit = [UnitType.KG, UnitType.LBS, UnitType.NEWTON] as const;
const TimeUnit = [UnitType.SECONDS, UnitType.MINUTES] as const;
const SensorUnit = [UnitType.KG, UnitType.ANGLES] as const; // Units produced by our sensors
export type LengthUnit = typeof LengthUnit[number];
export type WeightUnit = typeof WeightUnit[number];
export type TimeUnit = typeof TimeUnit[number];
export type SensorUnit = typeof SensorUnit[number];

export class UnitTypes {
    public static i18n: I18nService;

    public static isLengthUnit(unit: UnitType): boolean {
        return LengthUnit.indexOf(unit as LengthUnit) != -1;
    }

    public static isWeightUnit(unit: UnitType): boolean {
        return WeightUnit.indexOf(unit as WeightUnit) != -1;
    }

    public static symbol(unit: UnitType): string {
        let symbol: string;
        switch (unit) {
            case UnitType.KG:
                symbol = 'kg';
                break;
            case UnitType.LBS:
                symbol = this.i18n.text('unit.lbs');
                break;
            case UnitType.NEWTON:
                symbol = 'N';
                break;
            case UnitType.ANGLES:
                symbol = '\u00B0';
                break;
            case UnitType.PERCENT:
                symbol = '%';
                break;
            case UnitType.SECONDS:
                symbol = 's';
                break;
            case UnitType.CM:
                symbol = 'cm';
                break;
            case UnitType.MM:
                symbol = 'mm';
                break;
            case UnitType.INCH:
                symbol = 'in';
                break;
            case UnitType.MINUTES:
                symbol = 'min';
                break;
            case UnitType.PER_MINUTE:
                symbol = '/ min';
                break;
            case UnitType.M_PER_SECOND:
                symbol = 'm/s';
                break;
            case UnitType.MM_PER_SECOND:
                symbol = 'mm/s';
                break;
            case UnitType.MM2:
                symbol = 'mm²';
                break;
            case UnitType.KG_PER_SECOND:
                symbol = 'Kg/s';
                break;
            case UnitType.NEWTON_SECOND:
                symbol = 'N • s';
                break;
            case UnitType.NEWTON_PER_SECOND:
                symbol = 'N / s';
                break;
            case UnitType.NEWTON_PER_KG:
                symbol = 'N/Kg';
                break;
            case UnitType.NEWTON_METERS:
                symbol = 'N•m';
                break;
            case UnitType.WATT_PER_KG:
                symbol = 'W/Kg';
                break;
            default:
                symbol = '';
                break;
        }
        return symbol;
    }

    /**
     * Title of the unit. Plural is used. Eg Kilos, Newtons.
     * @param unit
     */
    public static title(unit: UnitType): string {
        let title: string;
        switch (unit) {
            case UnitType.NONE:
                title = '';
                break;
            case UnitType.KG:
                title = 'Kilograms';
                break;
            case UnitType.LBS:
                title = 'Pounds';
                break;
            case UnitType.NEWTON:
                title = 'Newtons';
                break;
            case UnitType.ANGLES:
                title = 'Degrees';
                break;
            case UnitType.PERCENT:
                title = 'Percent';
                break;
            case UnitType.CM:
                title = 'Centimeters';
                break;
            case UnitType.MM:
                title = 'Millimeters';
                break;
            case UnitType.INCH:
                title = 'Inches';
                break;
            case UnitType.MINUTES:
                title = 'Minutes';
                break;
            case UnitType.SECONDS:
                title = 'Seconds';
                break;
            case UnitType.PER_MINUTE:
                title = 'Per Minute';
                break;
            case UnitType.M_PER_SECOND:
                title = 'Per Second';
                break;
            case UnitType.MM_PER_SECOND:
                title = 'Millimeters per Second';
                break;
            case UnitType.MM2:
                title = 'Millimeters Squared';
                break;
            case UnitType.NEWTON_SECOND:
                title = 'Newton Seconds';
                break;
            case UnitType.KG_PER_SECOND:
                title = 'Kilograms per Second';
                break;
            case UnitType.NEWTON_PER_SECOND:
                title = 'Newtons per Second';
                break;
            case UnitType.NEWTON_PER_KG:
                title = 'Newtons per Kilogram';
                break;
            case UnitType.NEWTON_METERS:
                title = 'Newton meters';
                break;
            case UnitType.WATT_PER_KG:
                title = 'Watts per Kilogram';
                break;
        }
        return title;
    }
}
