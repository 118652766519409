<div class="container login-page">
    <div class="content d-flex flex-column">
        <img class="logo align-self-center pb-3" src="./assets/kinvent_logo_500x500.png" alt="Kinvent Logo"/>
        <h1>{{text('login.title')}}</h1>
        <mat-card class="login-card">
            <mat-progress-bar *ngIf="loadingState === loadingStates.LOADING" class="loading" mode="indeterminate"></mat-progress-bar>
            <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
                <mat-card-content>
                    <mat-form-field class="full-width">
                        <mat-label>Email</mat-label>
                        <input matNativeControl type="email" placeholder="example@k-invent.com" formControlName="email">
                    </mat-form-field>
                    <br/>
                    <mat-form-field class="full-width">
                        <mat-label>Password</mat-label>
                        <input matNativeControl type="password" formControlName="password">
                    </mat-form-field>

                </mat-card-content>
                <mat-card-actions>
                    <button class="full-width" color="primary" mat-raised-button type="submit" [disabled]="loginForm.invalid">
                        {{text('loginScreen.loginbutton.text')}}
                    </button>
                </mat-card-actions>
            </form>
        </mat-card>
        <div class="links d-flex flex-row justify-content-end mt-3">
            <div><a class="text-muted me-3" href="https://k-invent.com/contact/" target="_blank">{{text('login.contactKinvent')}}</a></div>
            <div><a class="text-muted me-3" href="https://k-invent.com/privacy-policy/" target="_blank">{{text('login.privacyPolicy')}}</a></div>
            <div><a class="text-muted" href="https://k-invent.com/term-of-service/" target="_blank">{{text('login.termsOfService')}}</a></div>
        </div>
    </div>
</div>
