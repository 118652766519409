import {ActivityLineChartProvider} from "./activityLineChartProvider";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ProgressOverTimeFilter} from "../../../viewmodels/progressOverTimeFilter";
import {I18nService} from "../../../services/i18n/i18n.service";
import {Label} from "ng2-charts";
import {ChartDataSets} from "chart.js";
import {Activities} from "../../../utils/activities";
import {format} from "date-fns";
import {ProgressOverTimeMetric} from "../../../viewmodels/progressOverTimeMetric";
import {UnipodalJumpAnalysisActivityResultsModel} from "../../../dtos/statistics/unipodalJumpAnalysisActivityResultsModel";
import {BodySideType, BodySideTypes} from "../../../models/bodySideType";
import {ProgressOverTimeMetricType} from "../../../viewmodels/progressOverTimeMetricType";
import {UnipodalJumpAnalysisActivitySideResultsModel} from "../../../dtos/statistics/unipodalJumpAnalysisActivitySideResultsModel";
import {ChartUtils} from "../../../utils/chartUtils";
import {Centimeters} from "../../../utils/values/centimeters";
import {Value} from "../../../utils/values/value";
import {UnitType} from "../../../utils/values/unitType";
import {Arrays, DateFormat, Dates, KeyValue} from "common";

export class SingleLegJumpAnalysisLineChatProvider extends ActivityLineChartProvider {
    private lastMetric: ProgressOverTimeMetricType;

    getDatasets(activities: ActivityDto[], analysis: ActivityAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: Chart.ChartOptions, i18n: I18nService): KeyValue<Label[], ChartDataSets[]> {
        const activitiesByDay = Activities.groupByDay(activities);
        const days = Array.from(activitiesByDay.keys()).sort();
        const labels: Label[] = days.map(day => format(day, Dates.getFormat(DateFormat.DATE_SHORTER)));

        const datasets = this.createDatasets(activitiesByDay, analysis, filter.metric, days, i18n);

        let maxValue = 0;
        for (const dataset of datasets) {
            maxValue = Math.max(maxValue, ...(dataset.data as number[]).filter(value => !Number.isNaN(value))) * 1.2;
        }
        chartOptions.scales.yAxes[0].ticks = {min: 0, max: Math.max(maxValue, 0.01)};

        // Chart options
        if (!chartOptions.plugins) {
            chartOptions.plugins = {};
        }
        chartOptions.plugins.datalabels = {
            formatter: this.labelFormatter.bind(this),
            anchor: 'end',
            align: 'end',
        };

        return KeyValue.of(labels, datasets);
    }

    private createDatasets(activitiesPerDay: Map<number, ActivityDto[]>, analysis: ActivityAnalysisServiceResponseDto, metric: ProgressOverTimeMetric, days: number[], i18n: I18nService) : ChartDataSets[]{
        const datasets: Map<BodySideType, ChartDataSets> = new Map<BodySideType, ChartDataSets>();
        const allSides = Arrays.uniqueValues(analysis.activitiesResults.map(a => a.activityResultsModel as UnipodalJumpAnalysisActivityResultsModel)
            .flatMap(r => r.resultsPerSide).flatMap(r => r.side));
        for (const side of allSides) {
            const dataset: ChartDataSets = {data: [], label: BodySideTypes.format(side), spanGaps: true, lineTension: 0};
            ChartUtils.formatDatasetForSide(dataset, side);
            datasets.set(side, dataset);
        }

        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            let value = NaN;

            // Create values for metrics.
            // Add NaN when there is no value for the day except for the last day, where we add the previous value
            if (activitiesPerDay.has(day)) {
                const activities = activitiesPerDay.get(day);
                const activitiesToResults = this.combineActivitiesToResults<UnipodalJumpAnalysisActivityResultsModel>(activities, analysis);
                const results = activities.map(a => activitiesToResults.get(a));
                const sides = Arrays.uniqueValues(results.flatMap(r => r.resultsPerSide).map(r => r.side));
                for (const side of sides) {
                    const sideResults = results.flatMap(r => r.resultsPerSide).filter(r => r.side === side);
                    value = this.getMetricFromActivities(sideResults, metric.type, side);
                    datasets.get(side).data.push(value);
                }
            }
        }

        this.lastMetric = metric.type;

        return Array.from(datasets.values());
    }

    private getMetricFromActivities(results: UnipodalJumpAnalysisActivitySideResultsModel[], type: ProgressOverTimeMetricType, side: BodySideType) {
        let value: number = NaN;

        switch (type) {
            case ProgressOverTimeMetricType.JUMP_HEIGHT:
                value = Math.max(0, ...results.filter(r => r.jump !== null).map(r => r.jump.jumpHeight * 100 /* to cm*/));
                break;
            case ProgressOverTimeMetricType.RFD:
                value = Math.max(...results.map(r => r.totalRfd));
                break;
            case ProgressOverTimeMetricType.IMPULSION:
                value = Math.max(...results.map(r => r.impulsion));
                break;
            case ProgressOverTimeMetricType.MAX_VALUE:
                value = Math.max(...results.map(r => r.maxForce));
                break;
        }

        return value;
    }

    private labelFormatter(value: number): string {
        // default
        let formatted = value.toFixed(1);
        switch (this.lastMetric) {
            case ProgressOverTimeMetricType.JUMP_HEIGHT:
                formatted = new Centimeters(value).format(0);
                break;
            case ProgressOverTimeMetricType.RFD:
                formatted = new Value(value, UnitType.NEWTON_PER_SECOND).format(0);
                break;
            case ProgressOverTimeMetricType.IMPULSION:
                formatted = new Value(value, UnitType.NEWTON_SECOND).format(0);
                break;
            case ProgressOverTimeMetricType.MAX_VALUE:
                formatted = new Value(value, UnitType.NONE).format(0);
                break;
        }
        return formatted;
    }
}
