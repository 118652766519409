import {Dto} from "common";

export class SquatCycle extends Dto {
    public timestamp: number;
    public pace: number;
    public ordinal: number;
    public maxForce: number;
    public avgForce: number;
    public maxLeftForce: number;
    public maxRightForce: number;

    // advanced squat properties
    public maxAngle: number;
    public depth: number;
    public distribution: number;
    public eccentricDistributionLeft: number;
    public concentricDistributionLeft: number;
    public eccentricDistributionRight: number;
    public concentricDistributionRight: number;
    public eccentricForce: number;
    public concentricForce: number;
    public eccentricPower: number;
    public concentricPower: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
