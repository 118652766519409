<ng-container *ngIf="viewType === resultViewTypes.FULL">
    <div class="view-type-full row">
        <div class="col-4 offset-4 d-flex flex-column align-items-center">
            <div class="text-center">{{'ikdc.results.score'|text}}</div>
            <div class="score text-center m-3">{{statistics.score}}</div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="viewType === resultViewTypes.ROW">
    <div class="view-type-row d-flex flex-row">
        <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis" [displayConfig]="false"></app-protocol-summary-row-activity-info>
        <div class="flex-grow-1 row">
            <div class="col-6 offset-3 d-flex flex-column align-items-center">
                <div class="text-center">{{'ikdc.results.score'|text}}</div>
                <div class="score text-center m-3">{{statistics.score}}</div>
            </div>
        </div>
    </div>
</ng-container>
