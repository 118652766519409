import {Component, OnInit} from '@angular/core';
import {ResultsComponent} from "../resultsComponent";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {IkdcResultsModel} from "../../../dtos/statistics/ikdcResultsModel";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";

@Component({
    selector: 'app-ikdc-results',
    templateUrl: './ikdc-results.component.html',
    styleUrls: ['./ikdc-results.component.scss']
})
export class IkdcResultsComponent extends ResultsComponent implements OnInit {
    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: IkdcResultsModel;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            this.warn(`Unsupported analysis`);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.activityAnalysis.activityResultsModel as IkdcResultsModel;
    }

}
