/**
 * A data sample from a sensor.
 */
export class Sample {
    constructor(public timestamp: number, public value: number) {
    }

    public static of(timestamp: number, value: number): Sample {
        return new Sample(timestamp, value);
    }
}
