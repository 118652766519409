<div>
    <div class="section d-flex flex-column">
        <h2 class="p-2 align-self-center">{{text("activity.max.peak")}}</h2>
        <div class="full-width d-flex flex-row justify-content-evenly row">
            <div class="d-flex flex-column flex-grow-1 align-items-center col-6">
                <app-max-per-side-chart [maxPerSide]="maxPerSideJump" [chartTitle]="activitiesTitles[0]"
                                        [enforcedUnitType]="pieValuesUnitType" class="donut"></app-max-per-side-chart>
                <span class="fw-bold">{{jumpTotalForceFormated}}</span>
            </div>
            <div class="d-flex flex-column flex-grow-1 align-items-center col-6">
                <app-max-per-side-chart [maxPerSide]="maxPerSideImtp" [chartTitle]="activitiesTitles[1]"
                                        [enforcedUnitType]="pieValuesUnitType" class="donut"></app-max-per-side-chart>
                <span class="fw-bold">{{imptTotalForceFormated}}</span>
            </div>
        </div>
    </div>
    <div class="section d-flex flex-column align-items-center mt-5">
        <h2 class="p-2">{{text("dsi.details")}}</h2>
        <div class="row full-width">
            <div class="col-8 offset-2 d-flex flex-row">
                <div class="d-flex flex-column table-column">
                    <app-table-title centerText="true" [backgroundColor]="colors.colorPrimary" [color]="colors.WHITE">
                        {{text("cmj.force")}}
                    </app-table-title>
                    <app-table-value>{{jumpTotalForceFormated}}</app-table-value>
                </div>
                <div class="d-flex flex-column table-column">
                    <app-table-title centerText="true" [backgroundColor]="colors.colorPrimary" [color]="colors.WHITE">
                        {{text("imtp.force")}}
                    </app-table-title>
                    <app-table-value>{{imptTotalForceFormated}}</app-table-value>
                </div>
                <div class="d-flex flex-column table-column">
                    <app-table-title centerText="true" [backgroundColor]="colors.colorAccent" [color]="colors.WHITE">
                        {{text("dsi")}}
                    </app-table-title>
                    <app-table-value class="fw-bold"> {{dsiScore}} </app-table-value>
                </div>
            </div>
        </div>
    </div>

    <div class="section d-flex flex-column align-items-center row full-width pt-4">
        <div class="d-flex flex-row">
            <div class="d-flex flex-column table-column">
                <app-table-title centerText="true"
                                 [color]="colors.WHITE">{{text("protocolResults.summary.score")}}</app-table-title>
                <app-table-value [backgroundColor]="getScoreBackgroundColor(dsiCategory.LOW)"
                                 [color]="getScoreColor(dsiCategory.LOW)">{{text("dynamicDistribution.results.piecharts.low_title")}}
                </app-table-value>
                <app-table-value [backgroundColor]="getScoreBackgroundColor(dsiCategory.MODERATE)"
                                 [color]="getScoreColor(dsiCategory.MODERATE)">{{text("moderate")}}
                </app-table-value>
                <app-table-value [backgroundColor]="getScoreBackgroundColor(dsiCategory.HIGH)"
                                 [color]="getScoreColor(dsiCategory.HIGH)">{{text("dynamicDistribution.results.piecharts.high_title")}}
                </app-table-value>
            </div>
            <div class="d-flex flex-column table-column">
                <app-table-title centerText="true" [color]="colors.WHITE">{{text("score.dsi")}}</app-table-title>
                <app-table-value [backgroundColor]="getScoreBackgroundColor(dsiCategory.LOW)"
                                 [color]="getScoreColor(dsiCategory.LOW)">< 0,60
                </app-table-value>
                <app-table-value [backgroundColor]="getScoreBackgroundColor(dsiCategory.MODERATE)"
                                 [color]="getScoreColor(dsiCategory.MODERATE)">0,60 - 0,80
                </app-table-value>
                <app-table-value [backgroundColor]="getScoreBackgroundColor(dsiCategory.HIGH)"
                                 [color]="getScoreColor(dsiCategory.HIGH)">> 0,80
                </app-table-value>
            </div>
            <div class="d-flex flex-column table-column">
                <app-table-title centerText="true" [backgroundColor]="colors.colorAccent"
                                 [color]="colors.WHITE">{{text("training.recommendation")}}
                </app-table-title>
                <app-table-value [backgroundColor]="getScoreBackgroundColor(dsiCategory.LOW)"
                                 [color]="getScoreColor(dsiCategory.LOW)">{{text("strength.training.ballistic")}}
                </app-table-value>
                <app-table-value [backgroundColor]="getScoreBackgroundColor(dsiCategory.MODERATE)"
                                 [color]="getScoreColor(dsiCategory.MODERATE)">{{text("Concurrent_training")}}
                </app-table-value>
                <app-table-value [backgroundColor]="getScoreBackgroundColor(dsiCategory.HIGH)"
                                 [color]="getScoreColor(dsiCategory.HIGH)">{{text("strength.training")}}
                </app-table-value>
            </div>
        </div>
    </div>

</div>
