<div class="component">
    <ng-container *ngIf="loadingState === loadingStates.LOADED; else loadingView">
        <ng-container *ngIf="viewType === resultViewTypes.FULL">
            <ng-container *ngTemplateOutlet="viewTypeFull"></ng-container>
        </ng-container>
        <ng-container *ngIf="viewType === resultViewTypes.ROW">
            <ng-container *ngTemplateOutlet="viewTypeRow"></ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template #viewTypeFull>
    <ng-container *ngTemplateOutlet="firstSection"></ng-container>
    <div class="section">
        <div class="row mt-5">
            <div *ngIf="haveLeft && haveRight" class="col">
                <ng-container *ngTemplateOutlet="weightDistributionChart"></ng-container>
            </div>
            <div class="col">
                <ng-container *ngTemplateOutlet="tableResults"></ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="isDropLand" class="section row mt-4">
        <div class="text-center">
            <div class="title rounded-pill d-inline-block">
                {{text('standingEvaluation.results.copSurfacePerTime')}}
            </div>
        </div>
        <app-samples-line-chart class="graphs overflow-hidden" [data]="sideAreaCoveredPerTime"></app-samples-line-chart>
    </div>
</ng-template>

<ng-template #viewTypeRow>
    <div class="d-flex flex-row">
        <app-protocol-summary-row-activity-info [activity]="activity" [activityAnalysis]="activityAnalysis"></app-protocol-summary-row-activity-info>
        <div class="max-per-side flex-grow-1">
            <ng-container *ngTemplateOutlet="firstSection"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #loadingView>
    <app-loading class="d-flex align-items-center justify-content-center" [state]="loadingState"></app-loading>
</ng-template>

<ng-template #firstSection>
    <div class="section firstSection">
        <div class="row mb-4">
            <app-results-title class="text-center" [title]="text('unipodal_stance_results_main_title')"></app-results-title>
        </div>
        <div class="row">
            <div class="col-4">
                <ng-container *ngIf="haveLeft">
                    <ng-container *ngTemplateOutlet="leftCopTitle"></ng-container>
                </ng-container>

                <ng-container *ngIf="!haveLeft && haveRight">
                    <ng-container *ngTemplateOutlet="rightCopTitle"></ng-container>
                </ng-container>
            </div>

            <div class="col-4 text-center d-flex flex-column justify-content-end">
                <div class="dimmed-title">{{text('standingEvaluation.results.plateGraphConfidenceEllipseTitle')}}</div>
            </div>

            <div class="col-4">
                <ng-container *ngIf="haveLeft && haveRight">
                    <ng-container *ngTemplateOutlet="rightCopTitle"></ng-container>
                </ng-container>
                <ng-container *ngIf="!(haveLeft && haveRight)">
                    <div class="text-center">
                        <div class="dimmed-title">{{text('standingEvaluation.results.plateGraphMappingTitle')}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <ng-container *ngIf="haveLeft">
                    <ng-container *ngTemplateOutlet="leftSensorStance"></ng-container>
                </ng-container>
                <ng-container *ngIf="!haveLeft && haveRight">
                    <ng-container *ngTemplateOutlet="rightSensorStance"></ng-container>
                </ng-container>
            </div>
            <div class="col-4">
                <div class="ratio ratio-1x1">
                    <app-stance-indicator
                        [sides]="sideTypes.BOTH"
                        [device]="device"
                        [drawSensors]="false"
                        [leftCopPoints]="leftSideResultsModel?.gridCopCoordsInPercentage"
                        [rightCopPoints]="rightSideResultsModel?.gridCopCoordsInPercentage"
                        [leftOval]="leftSideResultsModel?.oval"
                        [rightOval]="rightSideResultsModel?.oval"
                        [zoomToEllipse]="true"
                        [relative]="true"
                        [leftLateralDeviation]="leftVm?.latDeviationUnits"
                        [leftLongitudinalDeviation]="leftVm?.longDeviationUnits"
                        [rightLateralDeviation]="rightVm?.latDeviationUnits"
                        [rightLongitudinalDeviation]="rightVm?.longDeviationUnits"
                    ></app-stance-indicator>
                </div>
            </div>
            <div class="col-4">
                <ng-container *ngIf="haveLeft && haveRight">
                    <ng-container *ngTemplateOutlet="rightSensorStance"></ng-container>
                </ng-container>
                <ng-container *ngIf="!haveLeft || !haveRight">
                    <ng-container *ngTemplateOutlet="weightDistributionChart"></ng-container>
                </ng-container>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <ng-container *ngIf="haveLeft">
                    <ng-container *ngTemplateOutlet="leftSensorStanceStats"></ng-container>
                </ng-container>
                <ng-container *ngIf="!haveLeft && haveRight">
                    <ng-container *ngTemplateOutlet="rightSensorStanceStats"></ng-container>
                </ng-container>
            </div>
            <div class="col-4">
                <app-stance-ellipse-statistics class="d-flex flex-column align-items-center"
                                               [surfaces]="ellipseSurfaces"
                                               [hidePopulation]="true"
                ></app-stance-ellipse-statistics>
            </div>
            <div class="col-4">
                <ng-container *ngIf="haveLeft && haveRight">
                    <ng-container *ngTemplateOutlet="rightSensorStanceStats"></ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #leftCopTitle>
    <div class="text-center">
        <div class="side side-left">{{formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.TITLE)}}</div>
        <div class="dimmed-title">{{text('standingEvaluation.results.plateGraphMappingTitle')}}</div>
    </div>
</ng-template>

<ng-template #rightCopTitle>
    <div class="text-center">
        <div class="side side-right">{{formatSideAttribute(sideTypes.RIGHT, sideTypeAttributes.TITLE)}}</div>
        <div class="dimmed-title">{{text('standingEvaluation.results.plateGraphMappingTitle')}}</div>
    </div>
</ng-template>

<ng-template #leftSensorStance>
    <div class="ratio ratio-1x1">
        <app-stance-indicator
            [sides]="sideTypes.LEFT"
            [device]="device"
            [leftCopPoints]="leftVm?.sideGridCopPercentage"
            [zoomToEllipse]="false"
            [oval]="leftVm?.sideOval"
        ></app-stance-indicator>
    </div>
</ng-template>

<ng-template #rightSensorStance>
    <div class="ratio ratio-1x1">
        <app-stance-indicator
            [sides]="sideTypes.RIGHT"
            [device]="device"
            [rightCopPoints]="rightVm?.sideGridCopPercentage"
            [oval]="rightVm?.sideOval"
            [zoomToEllipse]="false"
        ></app-stance-indicator>
    </div>
</ng-template>

<ng-template #leftSensorStanceStats>
    <app-stance-mean-cop-position class="d-flex flex-column align-items-center"
                                  [lateralAverage]="leftVm?.lateralAvg"
                                  [longitudinalAverage]="leftVm?.longitudinalAvg"></app-stance-mean-cop-position>
</ng-template>

<ng-template #rightSensorStanceStats>
    <app-stance-mean-cop-position class="d-flex flex-column align-items-center"
                                  [lateralAverage]="rightVm?.lateralAvg"
                                  [longitudinalAverage]="rightVm?.longitudinalAvg"></app-stance-mean-cop-position>
</ng-template>

<ng-template #weightDistributionChart>
    <div class="weight-distribution">
        <app-distribution-bar-chart
            [data]="distributionData"
            [topAreaTitle]="'standingEvaluation.tipOfTheFootTitle'|text"
            [bottomAreaTitle]="'standingEvaluation.heelOfTheFootTitle'|text"
            [valueFractionDigits]="1"
        ></app-distribution-bar-chart>
    </div>
</ng-template>

<ng-template #tableResults>
    <div class="tableResults">
        <app-table-header class="full-width">{{text('unipodal_stance_summary_table_title')}}</app-table-header>
        <div class="d-flex flex-row">
            <div class="d-flex flex-column flex-grow-1">
                <app-table-value></app-table-value>
                <app-table-title>{{text('standingEvaluation.results.surface_mm')}}</app-table-title>
                <app-table-title>{{text('standingEvaluation.results.total_displacement')}}</app-table-title>
                <app-table-title>{{text('standingEvaluation.results.mean_velocity')}}</app-table-title>
                <app-table-title>{{text('standingEvaluation.results.longitudinal amplitude')}}</app-table-title>
                <app-table-title>{{text('standingEvaluation.results.lateral amplitude')}}</app-table-title>
            </div>
            <div *ngIf="haveLeft" class="d-flex flex-column flex-grow-1">
                <app-table-title centerText="true"
                                 [sideForBackgroundColor]="sideTypes.LEFT">{{formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.TITLE)}}</app-table-title>
                <app-table-value>{{leftVm?.surface.format(1)}}</app-table-value>
                <app-table-value>{{leftVm?.totalDisplacement.format(1)}}</app-table-value>
                <app-table-value>{{leftVm?.meanVelocity.format(1)}}</app-table-value>
                <app-table-value>{{leftVm?.longDeviation.format(1)}}</app-table-value>
                <app-table-value>{{leftVm?.latDeviation.format(1)}}</app-table-value>
            </div>
            <div *ngIf="haveRight" class="d-flex flex-column flex-grow-1">
                <app-table-title centerText="true"
                                 [sideForBackgroundColor]="sideTypes.RIGHT">{{formatSideAttribute(sideTypes.RIGHT, sideTypeAttributes.TITLE)}}</app-table-title>
                <app-table-value>{{rightVm?.surface.format(1)}}</app-table-value>
                <app-table-value>{{rightVm?.totalDisplacement.format(1)}}</app-table-value>
                <app-table-value>{{rightVm?.meanVelocity.format(1)}}</app-table-value>
                <app-table-value>{{rightVm?.longDeviation.format(1)}}</app-table-value>
                <app-table-value>{{rightVm?.latDeviation.format(1)}}</app-table-value>
            </div>
        </div>
    </div>
</ng-template>
