import {Component, OnInit} from '@angular/core';
import {ResultsComponent} from '../resultsComponent';
import {ActivityAnalysisServiceResponseDto} from '../../../dtos/statistics/activityAnalysisServiceResponseDto';
import {ActivityDto} from '../../../dtos/activity.dto';
import {ActivityAnalysisResultDto} from '../../../dtos/statistics/activityAnalysisResultDto';
import {WomacResultsModel} from '../../../dtos/statistics/womacResultsModel';
import {ActivityImageResolverService} from '../../../services/activity-image-resolver.service';
import {ImageResource} from "common";

@Component({
    selector: 'app-womac-results',
    templateUrl: './womac-results.component.html',
    styleUrls: ['./womac-results.component.scss']
})
export class WomacResultsComponent extends ResultsComponent implements OnInit {
    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: WomacResultsModel;
    public scaleImage: ImageResource;
    public scaleDescription: string;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            this.warn(`Unsupported analysis`);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.activityAnalysis.activityResultsModel as WomacResultsModel;

        if (this.statistics.score === 0) {
            this.scaleImage = 'womac/none.png';
            this.scaleDescription = this.i18n.text('womac.scale.none');
        } else if (this.statistics.score < 25) {
            this.scaleImage = 'womac/mild.png';
            this.scaleDescription = this.i18n.text('womac.scale.mild');
        } else if (this.statistics.score < 49) {
            this.scaleImage = 'womac/moderate.png';
            this.scaleDescription = this.i18n.text('womac.scale.moderate');
        } else if (this.statistics.score < 72) {
            this.scaleImage = 'womac/severe.png';
            this.scaleDescription = this.i18n.text('womac.scale.severe');
        } else {
            this.scaleImage = 'womac/extreme.png';
            this.scaleDescription = this.i18n.text('womac.scale.extreme');
        }
        this.scaleImage = this.activityImageResolverService.do(this.scaleImage);
    }

}
