import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ParticipantDto} from "../../dtos/participant.dto";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ParticipantService {
    constructor(private httpClient: HttpClient) {
    }

    public getAll(): Observable<ParticipantDto[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('includeDeleted', 'false');

        return this.httpClient.get<any>(environment.apiUrl + 'api/participants/v1', {params: httpParams})
            .pipe(map(participantsData => {
                const participants: ParticipantDto[] = [];
                for (const datum of (participantsData as any)) {
                    participants.push(new ParticipantDto().deserialize(datum));
                }
                return participants;
            }));
    }

    public get(participantCode: string): Observable<ParticipantDto> {
        return this.httpClient.get<any>(environment.apiUrl + 'api/participants/v1/' + participantCode)
            .pipe(map(data => {
                return new ParticipantDto().deserialize(data);
            }))
    }
}
