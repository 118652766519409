import {Component, Input, OnInit} from '@angular/core';
import {BodySideType, BodySideTypes} from "../../../models/bodySideType";
import {Color} from "common";

@Component({
    selector: 'app-table-title',
    templateUrl: './table-title.component.html',
    styleUrls: ['./table-title.component.scss']
})
export class TableTitleComponent implements OnInit {

    @Input()
    public centerText: boolean = false;

    @Input()
    public color: Color;

    @Input()
    public backgroundColor: Color;

    @Input()
    public sideForBackgroundColor: BodySideType;

    @Input()
    public compact: boolean;

    constructor() {
    }

    ngOnInit(): void {
        if (this.sideForBackgroundColor) {
            this.backgroundColor = BodySideTypes.color(this.sideForBackgroundColor);
        }
    }

}
