import {Component, OnInit} from '@angular/core';
import {Resource} from "common";
import {BaseComponent} from "../base/base.component";

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends BaseComponent implements OnInit {
    public art: Resource;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.analyticsSwitchPage('404 Not found', undefined, undefined);
        this.art = '/assets/brand/pageNotFoundArt.svg';
    }

}
