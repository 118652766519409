import {ChartDataSets} from "chart.js";
import {Colors} from "../services/colors";
import {BodySideType} from "../models/bodySideType";
import {Color} from "common";

export class ChartUtils {
    public static formatDatasetPrimary(dataset: ChartDataSets): void {
        this.defaultFormat(dataset);

        dataset.borderColor = Colors.colorPrimary;
        dataset.pointBorderColor = Colors.colorPrimary;
        dataset.pointHoverBorderColor = Colors.colorPrimary;
    }

    public static formatDatasetSecondary(dataset: ChartDataSets): void {
        this.defaultFormat(dataset);

        dataset.borderColor = Colors.colorAccent;
        dataset.pointBorderColor = Colors.colorAccent;
        dataset.pointHoverBorderColor = Colors.colorAccent;
    }

    public static formatDatasetForSide(dataset: ChartDataSets, side: BodySideType): void {
        if (side === BodySideType.LEFT) {
            this.formatDatasetSecondary(dataset);
        } else {
            this.formatDatasetPrimary(dataset);
        }
    }

    private static defaultFormat(dataset: ChartDataSets): void {
        dataset.backgroundColor = 'transparent';
        dataset.borderWidth = 2;
        dataset.pointRadius = 3;
        dataset.pointBackgroundColor = 'white';
        dataset.pointHoverBorderWidth = 4;
        dataset.pointHoverRadius = 5;
    }

    public static formatDatasetNeutral(dataset: ChartDataSets) {
        this.defaultFormat(dataset);

        dataset.borderColor = 'black';
        dataset.pointBorderColor = 'black';
        dataset.pointHoverBorderColor = 'black';
    }

    public static formatDatasetWithColor(dataset: ChartDataSets, color: Color) {
        this.defaultFormat(dataset);

        dataset.borderColor = color;
        dataset.pointBorderColor = color;
        dataset.pointHoverBorderColor = color;
    }
}
