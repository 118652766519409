export class ActivityConfigsOfBuiltinProtocols {
    /** @deprecated  */
    public static UNIPODAL_CMJ_PLATES_LEFT = '03560204-e4bb-48f2-be75-9988da3ab260';
    /** @deprecated */
    public static UNIPODAL_CMJ_PLATES_RIGHT = 'd0f983d6-dd9b-4fa3-9492-71a66b8f9399';
    /** @deprecated */
    public static UNIPODAL_CMJ_HEXAS_LEFT = '675d5bf3-83ff-4d12-86d1-6a0b80ac99d3';
    /** @deprecated */
    public static UNIPODAL_CMJ_HEXAS_RIGHT = 'f2e39e13-171b-4238-8e10-8cf6b0b62d2d';
    /** @deprecated  */
    public static UNIPODAL_SJ_PLATES_LEFT = 'fa42b8f5-cf1c-4cc7-b623-9d33b3d65adf';
    /** @deprecated */
    public static UNIPODAL_SJ_PLATES_RIGHT = 'a7d78d01-615a-4917-9b6f-ba336bc82ede';
    /** @deprecated */
    public static UNIPODAL_SJ_HEXAS_LEFT = 'f481f14e-3f54-48b2-ada2-d19d157d3e9d';
    /** @deprecated */
    public static UNIPODAL_SJ_HEXAS_RIGHT = '3fc7942a-74e3-40b7-8c66-1b097e7f0e23';

    /** @deprecated Has been converted to built in activity with same code */
    public static UNIPODAL_STANCE_LEFT = '35ba1f70-bba0-4a23-8489-20771a315340';
    /** @deprecated */
    public static UNIPODAL_STANCE_RIGHT = '4daf1ae2-8222-4483-80da-6f6f19561671';
}
