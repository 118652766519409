import {BodyPartGroupType} from './bodyPartGroupType';
import {protocolOrActivityConfigTagCategory} from './protocolOrActivityConfigTagCategory';

export enum ProtocolOrActivityConfigTag {
    // Type
    FUNCTIONAL = 'FUNCTIONAL',
    BALANCE = 'BALANCE',
    STRENGTH = 'STRENGTH',
    MOTION = 'MOTION',
    FORM = 'FORM',

    // body parts
    NECK = 'NECK',
    TRUNK = 'TRUNK',
    BACK = 'BACK',
    UPPER_LIMB = 'UPPER_LIMB',
    SHOULDER = 'SHOULDER',
    ELBOW = 'ELBOW',
    WRIST_HAND = 'WRIST_HAND',
    LOWER_LIMB = 'LOWER_LIMB',
    HIP = 'HIP',
    KNEE = 'KNEE',
    ANKLE = 'ANKLE',

    // Muscle
    HAMSTRINGS = 'HAMSTRINGS',
    QUADRICEPS = 'QUADRICEPS',
    ABDUCTORS = 'ABDUCTORS',
    ADDUCTORS = 'ADDUCTORS',
    CALF = 'CALF',
    TIBIAL_ANTERIOR = 'TIBIAL_ANTERIOR',
    GLUTEUS = 'GLUTEUS',
    LUMBAR = 'LUMBAR',
    ABDOMINALS = 'ABDOMINALS',
    BICEPS = 'BICEPS',
    TRICEPS = 'TRICEPS',
    INFRASPINAL = 'INFRASPINAL',
    CHEST = 'CHEST',
    CERVICALS = 'CERVICALS',
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    R1 = 'R1',
    R2 = 'R2',
    R3 = 'R3',

    // Movement
    FLEXION = 'FLEXION',
    EXTENSION = 'EXTENSION',
    ABDUCTION = 'ABDUCTION',
    ADDUCTION = 'ADDUCTION',
    INCLINATION = 'INCLINATION',
    ROTATION = 'ROTATION',

    // Angle
    DEGREES_90 = 'DEGREES_90',
    DEGREES_60 = 'DEGREES_60',
    DEGREES_45 = 'DEGREES_45',
    DEGREES_30 = 'DEGREES_30',

    // Plates Exercise ActivityType Grouping
    // (Note: these tags are not displayed in any UI, so the titles, colors etc stuff are not added in below utility functions)
    STANDING_POSTURE = 'STANDING_POSTURE',
    UNSTABLE_CUSHION = 'UNSTABLE_CUSHION',
    BOSU = 'BOSU',
    EXTERNAL_ACCESSORIES = 'EXTERNAL_ACCESSORIES',
    ACCESSORIES = 'ACCESSORIES',
    PLATES_SHOULDERS = 'PLATES_SHOULDERS',
    BACK_PAIN = 'BACK_PAIN'
}

export class ProtocolOrActivityConfigTagUtils {
    private static TITLE_KEYS: Map<ProtocolOrActivityConfigTag, string>;

    private static initialize = (() => {
        ProtocolOrActivityConfigTagUtils.TITLE_KEYS = new Map()
            .set(ProtocolOrActivityConfigTag.FUNCTIONAL, 'data_activity_group_functional_title')
            .set(ProtocolOrActivityConfigTag.BALANCE, 'data_activity_group_balance_title')
            .set(ProtocolOrActivityConfigTag.STRENGTH, 'data_activity_group_strength_title')
            .set(ProtocolOrActivityConfigTag.MOTION, 'data_activity_group_motion_title')
            .set(ProtocolOrActivityConfigTag.ELBOW, 'protocol.elbow_title')
            .set(ProtocolOrActivityConfigTag.SHOULDER, 'protocol.shoulder_title')
            .set(ProtocolOrActivityConfigTag.WRIST_HAND, 'protocol.wrist_hand_title')
            .set(ProtocolOrActivityConfigTag.HIP, 'protocol.hip_title')
            .set(ProtocolOrActivityConfigTag.KNEE, 'protocol.knee_title')
            .set(ProtocolOrActivityConfigTag.UPPER_LIMB, 'protocol.upper_limb_title')
            .set(ProtocolOrActivityConfigTag.LOWER_LIMB, 'protocol.lower_limb_title')
            .set(ProtocolOrActivityConfigTag.CERVICALS, 'protocol.cervicals_title')
            .set(ProtocolOrActivityConfigTag.BACK, 'protocol.back_title')
            .set(ProtocolOrActivityConfigTag.TRUNK, 'protocol.trunk_title')
            .set(ProtocolOrActivityConfigTag.ANKLE, 'protocol.ankle_title')
            .set(ProtocolOrActivityConfigTag.NECK, 'protocol.neck_title')
            .set(ProtocolOrActivityConfigTag.HAMSTRINGS, 'protocol.hamstrings_title')
            .set(ProtocolOrActivityConfigTag.QUADRICEPS, 'protocol.quadriceps_title')
            .set(ProtocolOrActivityConfigTag.ABDUCTORS, 'protocol.abductors_title')
            .set(ProtocolOrActivityConfigTag.ADDUCTORS, 'protocol.adductors_title')
            .set(ProtocolOrActivityConfigTag.CALF, 'protocol.calf_title')
            .set(ProtocolOrActivityConfigTag.TIBIAL_ANTERIOR, 'protocol.tribial_anterior_title')
            .set(ProtocolOrActivityConfigTag.GLUTEUS, 'protocol.gluteus_title')
            .set(ProtocolOrActivityConfigTag.LUMBAR, 'protocol.lumbar_title')
            .set(ProtocolOrActivityConfigTag.ABDOMINALS, 'protocol.abdominals_title')
            .set(ProtocolOrActivityConfigTag.BICEPS, 'protocol.biceps_title')
            .set(ProtocolOrActivityConfigTag.TRICEPS, 'protocol.triceps_title')
            .set(ProtocolOrActivityConfigTag.INTERNAL, 'protocol.internal_title')
            .set(ProtocolOrActivityConfigTag.EXTERNAL, 'protocol.external_title')
            .set(ProtocolOrActivityConfigTag.R1, 'protocol.r1_title')
            .set(ProtocolOrActivityConfigTag.R2, 'protocol.r2_title')
            .set(ProtocolOrActivityConfigTag.R3, 'protocol.r3_title')
            .set(ProtocolOrActivityConfigTag.INFRASPINAL, 'protocol.infraspinal_title')
            .set(ProtocolOrActivityConfigTag.CHEST, 'protocol.chest_title')
            .set(ProtocolOrActivityConfigTag.FLEXION, 'protocol.flexion_title')
            .set(ProtocolOrActivityConfigTag.EXTENSION, 'protocol.extension_title')
            .set(ProtocolOrActivityConfigTag.ABDUCTION, 'protocol.abduction_title')
            .set(ProtocolOrActivityConfigTag.ADDUCTION, 'protocol.adduction_title')
            .set(ProtocolOrActivityConfigTag.INCLINATION, 'protocol.inclination_title')
            .set(ProtocolOrActivityConfigTag.ROTATION, 'protocol.rotation_title')
            .set(ProtocolOrActivityConfigTag.DEGREES_90, 'protocol.degrees_90_title')
            .set(ProtocolOrActivityConfigTag.DEGREES_60, 'protocol.degrees_60_title')
            .set(ProtocolOrActivityConfigTag.DEGREES_45, 'protocol.degrees_45_title')
            .set(ProtocolOrActivityConfigTag.DEGREES_30, 'protocol.degrees_30_title')
            .set(ProtocolOrActivityConfigTag.FORM, 'protocol.form');
    })();

    static getTagEnum(tagString: string): ProtocolOrActivityConfigTag {
        return ProtocolOrActivityConfigTag[tagString as keyof typeof ProtocolOrActivityConfigTag];
    }

    public static getTagsForCategory(category: protocolOrActivityConfigTagCategory): ProtocolOrActivityConfigTag[] {
        const tags: ProtocolOrActivityConfigTag[] = [];
        switch (category) {
            case protocolOrActivityConfigTagCategory.BODY_PARTS:
                tags.push(
                    ProtocolOrActivityConfigTag.NECK,
                    ProtocolOrActivityConfigTag.TRUNK,
                    ProtocolOrActivityConfigTag.BACK,
                    ProtocolOrActivityConfigTag.UPPER_LIMB,
                    ProtocolOrActivityConfigTag.SHOULDER,
                    ProtocolOrActivityConfigTag.ELBOW,
                    ProtocolOrActivityConfigTag.WRIST_HAND,
                    ProtocolOrActivityConfigTag.LOWER_LIMB,
                    ProtocolOrActivityConfigTag.HIP,
                    ProtocolOrActivityConfigTag.KNEE,
                    ProtocolOrActivityConfigTag.ANKLE);
                break;
            case protocolOrActivityConfigTagCategory.TYPE:
                tags.push(ProtocolOrActivityConfigTag.FUNCTIONAL,
                    ProtocolOrActivityConfigTag.BALANCE,
                    ProtocolOrActivityConfigTag.STRENGTH,
                    ProtocolOrActivityConfigTag.MOTION,
                    ProtocolOrActivityConfigTag.FORM);
                break;
            case protocolOrActivityConfigTagCategory.MUSCLE:
                tags.push(ProtocolOrActivityConfigTag.HAMSTRINGS,
                    ProtocolOrActivityConfigTag.QUADRICEPS,
                    ProtocolOrActivityConfigTag.ABDUCTORS,
                    ProtocolOrActivityConfigTag.ADDUCTORS,
                    ProtocolOrActivityConfigTag.CALF,
                    ProtocolOrActivityConfigTag.TIBIAL_ANTERIOR,
                    ProtocolOrActivityConfigTag.GLUTEUS,
                    ProtocolOrActivityConfigTag.LUMBAR,
                    ProtocolOrActivityConfigTag.ABDOMINALS,
                    ProtocolOrActivityConfigTag.BICEPS,
                    ProtocolOrActivityConfigTag.TRICEPS,
                    ProtocolOrActivityConfigTag.INTERNAL,
                    ProtocolOrActivityConfigTag.EXTERNAL,
                    ProtocolOrActivityConfigTag.R1,
                    ProtocolOrActivityConfigTag.R2,
                    ProtocolOrActivityConfigTag.R3,
                    ProtocolOrActivityConfigTag.INFRASPINAL,
                    ProtocolOrActivityConfigTag.CHEST,
                    ProtocolOrActivityConfigTag.CERVICALS);
                break;
            case protocolOrActivityConfigTagCategory.MOVEMENT:
                tags.push(ProtocolOrActivityConfigTag.FLEXION,
                    ProtocolOrActivityConfigTag.EXTENSION,
                    ProtocolOrActivityConfigTag.ABDUCTION,
                    ProtocolOrActivityConfigTag.ADDUCTION,
                    ProtocolOrActivityConfigTag.INCLINATION,
                    ProtocolOrActivityConfigTag.ROTATION);
                break;
            case protocolOrActivityConfigTagCategory.ANGLE:
                tags.push(ProtocolOrActivityConfigTag.DEGREES_90,
                    ProtocolOrActivityConfigTag.DEGREES_60,
                    ProtocolOrActivityConfigTag.DEGREES_45,
                    ProtocolOrActivityConfigTag.DEGREES_30);
                break;
            case protocolOrActivityConfigTagCategory.PLATES_EXERCISES:
                tags.push(ProtocolOrActivityConfigTag.STANDING_POSTURE,
                    ProtocolOrActivityConfigTag.UNSTABLE_CUSHION,
                    ProtocolOrActivityConfigTag.BOSU,
                    ProtocolOrActivityConfigTag.EXTERNAL_ACCESSORIES,
                    ProtocolOrActivityConfigTag.ACCESSORIES,
                    ProtocolOrActivityConfigTag.PLATES_SHOULDERS,
                    ProtocolOrActivityConfigTag.BACK_PAIN);
                break;

        }

        return tags;
    }

    public static getTagsForBodyPartGroup(group: BodyPartGroupType): ProtocolOrActivityConfigTag[] {
        const tags: ProtocolOrActivityConfigTag[] = [];

        switch (group) {
            case BodyPartGroupType.TORSO_NECK:
                tags.push(
                    ProtocolOrActivityConfigTag.NECK,
                    ProtocolOrActivityConfigTag.TRUNK,
                    ProtocolOrActivityConfigTag.BACK);
                break;
            case BodyPartGroupType.ARMS:
                tags.push(
                    ProtocolOrActivityConfigTag.UPPER_LIMB,
                    ProtocolOrActivityConfigTag.SHOULDER,
                    ProtocolOrActivityConfigTag.ELBOW,
                    ProtocolOrActivityConfigTag.WRIST_HAND);
                break;
            case BodyPartGroupType.LEGS:
                tags.push(
                    ProtocolOrActivityConfigTag.LOWER_LIMB,
                    ProtocolOrActivityConfigTag.HIP,
                    ProtocolOrActivityConfigTag.KNEE,
                    ProtocolOrActivityConfigTag.ANKLE);
                break;
        }
        return tags;
    }

    public static getTitleKey(tag: ProtocolOrActivityConfigTag): string {
        return this.TITLE_KEYS.get(tag);
    }
}
