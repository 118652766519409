import {Component, OnInit} from '@angular/core';
import {ValueWidgetIcon} from "../../../viewmodels/valueWidgetIcons";
import {MultipleJumpsAnalysisActivityResultsModel} from "../../../dtos/statistics/multipleJumpsAnalysisActivityResultsModel";
import {Color, KeyValue, Resource, Title} from "common";
import {BodySideType, BodySideTypes} from "../../../models/bodySideType";
import {Sample} from "../../../utils/sample";
import {ActivityImageResolverService} from "../../../services/activity-image-resolver.service";
import {ResultsComponent} from "../resultsComponent";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {Value} from "../../../utils/values/value";
import {UnitType} from "../../../utils/values/unitType";
import {Centimeters} from "../../../utils/values/centimeters";
import {Seconds} from "../../../utils/values/seconds";
import {Percent} from "../../../utils/values/percent";
import {Kg} from "../../../utils/values/kg";
import {Colors} from "../../../services/colors";

interface PowerIndexRow {
    category: string;
    first: string;
    second: string;
    third: string;
    fourth: string;
    fifth: string;
}

@Component({
    selector: 'app-multiple-jumps-analysis',
    templateUrl: './multiple-jumps-analysis.component.html',
    styleUrls: ['./multiple-jumps-analysis.component.scss']
})
export class MultipleJumpsAnalysisComponent extends ResultsComponent implements OnInit {
    public readonly valueWidgetIcons: typeof ValueWidgetIcon = ValueWidgetIcon;

    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: MultipleJumpsAnalysisActivityResultsModel;
    public image: Resource;
    public numberOfJumps: Value;
    public averageHeight: Value;
    public duration: Value;
    public fatigueIndex: Value;
    public pace: Value;
    public averagePower: Value;
    public readonly powerIndexTableData: PowerIndexRow[];
    public readonly powerIndexTableColumns: string[];
    public powerIndexTableHighlightedColumn: string;
    public heightPerJumpLabels: string[];
    public heightPerJumpData: Map<KeyValue<Title, Color>, Value[]>;
    public heightPerJumpPace: KeyValue<Title, Value[]>;
    public groundReactionJumpData: Map<KeyValue<Title, Color>, Value[]>;
    public averageDistributionLeftUnits: string;
    public averageDistributionLeftPercent: string;
    public averageDistributionRightUnits: string;
    public averageDistributionRightPercent: string;
    public forcesPerSide: Map<[BodySideType, UnitType], Sample[]>;
    public forcesTotal: Sample[];

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
        let seconds = this.text('balanceTesting.seconds.hint');
        this.powerIndexTableData = [
            {category: `0-15 ${seconds}`, first: '< 25.0', second: '25.1 - 28.3', third: '28.4 - 31.6', fourth: '31.7 - 34.9', fifth: '> 35'},
            {category: `15-60 ${seconds}`, first: '< 20.0', second: '20.1 - 23.3', third: '23.4 - 26.6', fourth: '26.7 - 29.9', fifth: '> 30'}];
        this.powerIndexTableColumns = ['category', 'first', 'second', 'third', 'fourth', 'fifth'];
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. MultipleJumpsAnalysisComponent`, this.analysis);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.analysis.activitiesResults[0].activityResultsModel as MultipleJumpsAnalysisActivityResultsModel;
        const statistics = this.statistics;

        // common
        this.image = this.activityImageResolverService.do(this.activity.image);

        // metrics
        this.numberOfJumps = new Value(statistics.jumps.length);
        this.averageHeight = new Centimeters(statistics.averageHeight * 100 /* to cm */);
        this.duration = new Seconds(statistics.duration);
        this.fatigueIndex = new Value(statistics.fatigueIndex);
        this.pace = new Value(statistics.pace, UnitType.PER_MINUTE);
        this.averagePower = new Value(statistics.averagePower, UnitType.WATT_PER_KG);

        // power index table
        this.powerIndexTableHighlightedColumn = this.getHighlightedColumn();

        // height per jump && ground reaction forces
        if (statistics.jumps.length > 0) {
            this.heightPerJumpLabels = [];
            this.heightPerJumpData = new Map<KeyValue<Title, Color>, Value[]>();
            this.groundReactionJumpData = new Map<KeyValue<Title, Color>, Value[]>();
            const jumpHeights: Value[] = [];
            const groundReactionForcesLeft: Value[] = [];
            const groundReactionForcesRight: Value[] = [];
            const paces = [];
            for (let i = 0; i < statistics.jumps.length; i++) {
                this.heightPerJumpLabels.push((i + 1).toString());
                let jump = statistics.jumps[i];
                jumpHeights.push(new Value(jump.jumpHeight * 100 /* to cm */));
                paces.push(new Value(jump.pace));

                groundReactionForcesLeft.push(new Value(jump.avgLeftForce));
                groundReactionForcesRight.push(new Value(jump.avgForce - jump.avgLeftForce));
            }
            this.heightPerJumpData.set(KeyValue.of(this.text('report.chartDataType.jump_height'), Colors.colorAccent), jumpHeights);
            this.heightPerJumpPace = KeyValue.of(this.text('stats.hints.pace'), paces);
            this.groundReactionJumpData.set(KeyValue.of(BodySideTypes.format(BodySideType.LEFT), BodySideTypes.color(BodySideType.LEFT)), groundReactionForcesLeft);
            this.groundReactionJumpData.set(KeyValue.of(BodySideTypes.format(BodySideType.RIGHT), BodySideTypes.color(BodySideType.RIGHT)), groundReactionForcesRight);
        }
        this.averageDistributionLeftUnits = new Kg(statistics.averageForceLeft).format(1);
        this.averageDistributionLeftPercent = new Percent(statistics.averageForceLeftPercent).format(1);
        this.averageDistributionRightUnits = new Kg(statistics.averageForceRight).format(1);
        this.averageDistributionRightPercent = new Percent(statistics.averageForceRightPercent).format(1);

        // Forces chart
        this.forcesPerSide = new Map<[BodySideType, UnitType], Sample[]>();
        this.forcesPerSide.set([BodySideType.LEFT, UnitType.KG], statistics.leftForces.map(value => new Sample(value.time / 1000, value.force)));
        this.forcesPerSide.set([BodySideType.RIGHT, UnitType.KG], statistics.rightForces.map(value => new Sample(value.time / 1000, value.force)));
        this.forcesTotal = statistics.totalForces.map(value => new Sample(value.time / 1000, value.force));
    }

    private getHighlightedColumn(): string {
        let column: string;

        const duration = this.statistics.duration;
        const avgPower = this.statistics.averagePower;
        if (duration <= 15) {
            if (avgPower < 25.1) {
                column = 'first';
            } else if (avgPower < 28.4) {
                column = 'second';
            } else if (avgPower < 31.7) {
                column = 'third';
            } else if (avgPower < 35.0) {
                column = 'fourth';
            } else {
                column = 'fifth';
            }
        } else {
            if (avgPower < 20.1) {
                column = 'first';
            } else if (avgPower < 23.4) {
                column = 'second';
            } else if (avgPower < 26.7) {
                column = 'third';
            } else if (avgPower < 30) {
                column = 'fourth';
            } else {
                column = 'fifth';
            }
        }

        return column;
    }
}
