import {BodySideType, BodySideTypes} from "../../models/bodySideType";
import {BluetoothDeviceType} from "../../models/bluetoothDeviceType";
import {Dto} from "common";

export class MeterEnduranceActivityRepResultsModel extends Dto {
    public repOrdinal: number;
    public repSide: string;
    public maxValue: number;
    public averageValue: number;
    public rfdToMax: number;
    public rfdFromMax: number;
    public timeToMax: number;
    public maxTimeOnTarget: number;
    public forces: number[];
    public deltas: number[];
    public device?: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    public get bodySideType(): BodySideType {
        return BodySideTypes.parse(this.repSide);
    }

    public get deviceType(): BluetoothDeviceType {
        return BluetoothDeviceType[this.device as keyof typeof BluetoothDeviceType];
    }
}
