import {BaseDto, Code} from "common";

export class ParticipantTagDto extends BaseDto {
    participantTagConfigCode: Code;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
