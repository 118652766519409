import {UnipodalStanceEvaluationActivitySideResultsModel} from "./unipodalStanceEvaluationActivitySideResultsModel";
import {ActivityResultsModel} from "./activityResultsModel";

export class UnipodalStanceEvaluationActivityResultsModel extends ActivityResultsModel {
    public resultsPerSide: UnipodalStanceEvaluationActivitySideResultsModel[];

    deserialize(input: any): this {
        this.resultsPerSide = this.deserializeArray(input.resultsPerSide, UnipodalStanceEvaluationActivitySideResultsModel);

        return this;
    }
}
