import {DeviceType} from "common";

export enum BluetoothDeviceType {
    UNKNOWN = "UNKNOWN",
    ANY = "ANY",
    KFORCE_GRIP = "KFORCE_GRIP",
    MUSCLE_CONTROLLER = "MUSCLE_CONTROLLER",
    PLATES_LEFT = "PLATES_LEFT",
    PLATES_RIGHT = "PLATES_RIGHT",
    BUBBLE = "BUBBLE",
    SENS = "SENS",
    LINK = "LINK",
    HEXAS_LEFT = "HEXAS_LEFT",
    HEXAS_RIGHT = "HEXAS_RIGHT",
}

export class BluetoothDeviceTypeUtils {
    public static parse(deviceType: string): BluetoothDeviceType {
        return BluetoothDeviceType[deviceType as keyof typeof BluetoothDeviceType];
    }

    public static getBluetoothDeviceName(deviceType: BluetoothDeviceType): string {
        switch (deviceType) {
            case BluetoothDeviceType.KFORCE_GRIP:
                return "Grip";
            case BluetoothDeviceType.MUSCLE_CONTROLLER:
                return "Muscle";
            case BluetoothDeviceType.PLATES_LEFT:
                return "Plate Left";
            case BluetoothDeviceType.PLATES_RIGHT:
                return "Plate Right";
            case BluetoothDeviceType.BUBBLE:
                return "Bubble";
            case BluetoothDeviceType.SENS:
                return "Sens";
            case BluetoothDeviceType.LINK:
                return "Link";
            case BluetoothDeviceType.HEXAS_LEFT:
                return "Hexa Left";
            case BluetoothDeviceType.HEXAS_RIGHT:
                return "Hexa Right";
            default:
                return "any";
        }
    }

    /**
     * Converts the bluetooth device to the deviceType compatible
     */
    public static toDeviceType(bluetoothDeviceType: BluetoothDeviceType): DeviceType {
        let device: DeviceType;
        switch (bluetoothDeviceType) {
            case BluetoothDeviceType.UNKNOWN:
                device = DeviceType.UNKNOWN;
                break;
            case BluetoothDeviceType.ANY:
                device = undefined;
                break;
            case BluetoothDeviceType.KFORCE_GRIP:
                device = DeviceType.GRIP;
                break;
            case BluetoothDeviceType.MUSCLE_CONTROLLER:
                device = DeviceType.MUSCLE_TESTER;
                break;
            case BluetoothDeviceType.PLATES_LEFT:
                device = DeviceType.PLATES;
                break;
            case BluetoothDeviceType.PLATES_RIGHT:
                device = DeviceType.PLATES;
                break;
            case BluetoothDeviceType.BUBBLE:
                device = DeviceType.BUBBLE;
                break;
            case BluetoothDeviceType.SENS:
                device = DeviceType.SENS;
                break;
            case BluetoothDeviceType.LINK:
                device = DeviceType.LINK;
                break;
            case BluetoothDeviceType.HEXAS_LEFT:
                device = DeviceType.HEXAS;
                break;
            case BluetoothDeviceType.HEXAS_RIGHT:
                device = DeviceType.HEXAS;
                break;
        }
        return device;
    }
}
