import {I18nService} from "../services/i18n/i18n.service";
import {Colors} from "../services/colors";


export enum BodySideType {
    UNKNOWN = "UNKNOWN",
    NONE = "NONE",
    LEFT = "LEFT",
    RIGHT = "RIGHT",
    BOTH = "BOTH"
}

export class BodySideTypes {
    public static i18n: I18nService;

    public static parse(side: string): BodySideType {
        return BodySideType[side as keyof typeof BodySideType];
    }

    public static format(side: BodySideType): string {
        let formatted = '';
        switch (side) {
            case BodySideType.LEFT:
                formatted = this.i18n.text('bodypartside.left');
                break;
            case BodySideType.RIGHT:
                formatted = this.i18n.text('bodypartside.right');
                break;
            case BodySideType.BOTH:
                formatted = this.i18n.text('bodypartside.both');
                break;
        }
        return formatted;
    }

    public static color(side: BodySideType): string {
        let color = Colors.colorPrimary;
        if (side === BodySideType.LEFT) {
            color = Colors.colorAccent;
        }
        return color;
    }

    public static lightColor(side: BodySideType): string {
        let color = Colors.KINVENT_BLUE_LIGHT;
        if (side === BodySideType.LEFT) {
            color = Colors.KINVENT_ORANGE_LIGHT;
        }
        return color;
    }

    public static icon(side: BodySideType): string {
        let resource: string;
        switch (side) {
            case BodySideType.NONE:
                resource = './assets/icons/bodySideNone.png';
                break;
            case BodySideType.LEFT:
                resource = './assets/icons/bodySideLeft.png';
                break;
            case BodySideType.RIGHT:
                resource = './assets/icons/bodySideRight.png';
                break;
            case BodySideType.BOTH:
                resource = './assets/icons/bodySideBoth.png';
                break;
        }
        return resource;
    }
}
