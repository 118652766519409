import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {BaseComponent} from "../base/base.component";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {

    public copyrightNotice;
    public systemVersion;

    constructor() {
        super();
    }

    ngOnInit(): void {
        const firstYear = 2018;
        const curYear = (new Date()).getFullYear();
        this.copyrightNotice = `© ${firstYear}-${curYear} Kinvent Biomécanique`;
        this.systemVersion = environment.version;
    }
}
