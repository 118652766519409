import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions} from "chart.js";
import {DatasetStyle} from "../datasetStyle";
import {Point} from "../../../models/point";
import {KeyValue, TextUtils, Title} from "common";
import {BaseComponent} from "../../base/base.component";

/**
 * A Chart that displays sets of lines.
 * Each line has a start and end.
 */
@Component({
    selector: 'app-lines-chart',
    templateUrl: './lines-chart.component.html',
    styleUrls: ['./lines-chart.component.scss']
})
export class LinesChartComponent extends BaseComponent implements OnInit {
    @Input()
    public lines: KeyValue<DatasetStyle, Point[]>[];

    @Input()
    public xAxisTitle: Title;

    @Input()
    public yAxisTitle: Title;

    public datasets: ChartDataSets[];
    public chartOptions: ChartOptions;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.datasets = [];
        for (const line of this.lines) {
            this.datasets.push({
                data: line.value.map(v => {
                    return {x: v.x, y: v.y}
                }),
                type: 'line',
                label: line.key.title,
                backgroundColor: 'transparent',
                borderColor: line.key.color,
                pointRadius: 0,
                pointHoverRadius: 0
            });
        }

        this.chartOptions = {
            animation: {
                duration: 0,
                animateScale: false,
                animateRotate: false
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            devicePixelRatio: 2,
            tooltips: {
                enabled: false
            },
            legend: {
                display: true,
                align: 'end',
                position: 'bottom',
                labels: {boxWidth: 1}
            },
            scales: {
                yAxes: [
                    {
                        ticks: {},
                        scaleLabel: {labelString: this.yAxisTitle, display: !TextUtils.isEmpty(this.yAxisTitle)}
                    },
                ],
                xAxes: [{
                    ticks: {},
                    scaleLabel: {labelString: this.xAxisTitle, display: !TextUtils.isEmpty(this.xAxisTitle)}
                }]
            },
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    font: {size: 16},
                },
            }
        };
    }

}
