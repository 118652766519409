<div class="component container">
    <div class="row">
        <div class="col-3">
            <app-sessions-list class="sessions"
                               [sessions]="sessions"
                               [selectedSession]="selectedSession"
                               (onSessionAction)="handleSessionAction($event)"
            ></app-sessions-list>
        </div>
        <div class="col-9">
            <div [class.d-none]="screenLoadingState !== loadingStates.LOADED">
                <div class="toolbar d-flex flex-row align-items-center">
                    <h1 class="title flex-grow-1">{{title}}</h1>
                    <div class="flex-grow-1 flex-shrink-1"></div>
                    <app-protocol-results-actions class="ms-3" [supportsExportPdf]="supportsExportPdf" [supportsExportCsv]="supportsExportCsv"
                                                  (onExportPdf)="onExportPdf()" (onExportCsv)="onExportCsv()">
                    </app-protocol-results-actions>
                </div>
                <div [class.d-none]="resultsLoadingState !== loadingStates.LOADED">
                    <ng-template resultsHost></ng-template>
                </div>
                <ng-container *ngIf="resultsLoadingState !== loadingStates.LOADED">
                    <ng-container *ngTemplateOutlet="resultsLoadingView"></ng-container>
                </ng-container>
            </div>
            <ng-container *ngIf="screenLoadingState !== loadingStates.LOADED">
                <ng-container *ngTemplateOutlet="screenLoadingView"></ng-container>
            </ng-container>
        </div>
    </div>
</div>
<div class="printable-container">
    <div *ngIf="participant && selectedAnalysisServiceResponse" class="printable printable-a4-size">
        <app-protocol-results-export-header class="mb-2 d-block"
                                            [participant]="participant"
                                            [dateStart]="selectedPeriodStart" [dateEnd]="selectedPeriodEnd"></app-protocol-results-export-header>
        <app-results-overview class="d-block mb-2"
                              [title]="title"
                              [devices]="devices"
                              [activityConfig]="selectedActivityConfig"
                              [exerciseType]="selectedExerciseType"></app-results-overview>
        <ng-template resultsHost></ng-template>
        <app-protocol-results-export-footer class="d-block" [account]="userAccount"></app-protocol-results-export-footer>
    </div>
</div>
<ng-template #screenLoadingView>
    <app-loading class='d-flex align-items-center justify-content-center' [state]='screenLoadingState'></app-loading>
</ng-template>
<ng-template #resultsLoadingView>
    <app-loading class='d-flex align-items-center justify-content-center' [state]='resultsLoadingState'></app-loading>
</ng-template>
