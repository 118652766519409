import {Component, Input, OnInit} from '@angular/core';
import {AccountDto, SpecialityTypes} from "common";

@Component({
    selector: 'app-protocol-results-export-footer',
    templateUrl: './protocol-results-export-footer.component.html',
    styleUrls: ['./protocol-results-export-footer.component.scss']
})
export class ProtocolResultsExportFooterComponent implements OnInit {

    @Input()
    account: AccountDto;

    constructor() {
    }

    ngOnInit(): void {
    }

    public getSpeciality(): string {
        return SpecialityTypes.format(this.account.specialityType);
    }
}
