<div>
    <mat-tab-group class="protocol-tabs" dynamicHeight animationDuration="125ms" (selectedIndexChange)="onTabChanged($event)" [(selectedIndex)]="selectedTabIndex">
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="print-hidden">
                    {{text('protocolResults.summary.title')}}
                </div>
            </ng-template>
            <div class="p-1 pt-3" [ngSwitch]="protocol.config.getBaseConfigCode()">
                <app-protocol-summary-mccall-results *ngSwitchCase="builtInProtocols.IDENTIFIERS.MC_CALL"
                                                     [protocol]="protocol"
                                                     [analysis]="analysis"></app-protocol-summary-mccall-results>
                <app-protocol-summary-advanced-standing-evaluation-results *ngSwitchCase="builtInProtocols.IDENTIFIERS.ADVANCED_STANDING_EVALUTATION"
                                                                           [protocol]="protocol"
                                                                           [analysis]="analysis"></app-protocol-summary-advanced-standing-evaluation-results>
                <app-protocol-summary-iyt-results *ngSwitchCase="builtInProtocols.IDENTIFIERS.ASH_IYT"
                                                  [protocol]="protocol"
                                                  [analysis]="analysis"></app-protocol-summary-iyt-results>
                <app-protocol-summary-dsi-results *ngSwitchCase="builtInProtocols.IDENTIFIERS.DSI"
                                                  [protocol]="protocol"
                                                  [analysis]="analysis"></app-protocol-summary-dsi-results>
                <app-protocol-summary-neck-report-results *ngSwitchCase="builtInProtocols.IDENTIFIERS.NECK_REPORT"
                                                          [protocol]="protocol"
                                                          [analysis]="analysis"></app-protocol-summary-neck-report-results>
                <app-protocol-summary-knee-antagonist-results *ngSwitchCase="builtInProtocols.IDENTIFIERS.KNEE_ANTAGONIST"
                                                              [protocol]="protocol"
                                                              [analysis]="analysis"></app-protocol-summary-knee-antagonist-results>
                <app-protocol-summary-knee-antagonist-results *ngSwitchCase="builtInProtocols.IDENTIFIERS.KNEE_ANTAGONIST_MUSCLE"
                                                              [protocol]="protocol"
                                                              [analysis]="analysis"></app-protocol-summary-knee-antagonist-results>
                <app-protocol-summary-force-velocity-results *ngSwitchCase="builtInProtocols.IDENTIFIERS.FORCE_VELOCITY"
                                                             [protocol]="protocol"
                                                             [analysis]="analysis"
                ></app-protocol-summary-force-velocity-results>
                <app-protocol-summary-generic-results *ngSwitchDefault
                                                      [protocol]="protocol"
                                                      [analysis]="analysis">
                </app-protocol-summary-generic-results>
            </div>
        </mat-tab>
        <mat-tab *ngFor="let activityResult of activityToAnalysisMap; let index = index">
            <ng-template mat-tab-label>
                <div class="print-hidden">{{(index + 1) + '. ' + activityTitle(activityResult.key)}}</div>
            </ng-template>
            <div class="pt-3">
                <h1 class="print-visible">{{(index + 1) + '. ' + activityTitle(activityResult.key)}}</h1>
                <ng-template resultsHost></ng-template>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
