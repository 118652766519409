import {ActivityDto} from './activity.dto';
import {ProtocolConfigDto} from './protocolConfigDto';
import {ActivityType} from '../models/activityType';
import {ProtocolConfigStepDto} from './protocolConfigStep.dto';
import {I18nService} from "../services/i18n/i18n.service";
import {Activities} from "../utils/activities";
import {BaseDto, DeviceType} from "common";

export class ProtocolDto extends BaseDto {
    userCode?: string;
    config?: ProtocolConfigDto;
    participantCode?: string;
    singleActivity?: boolean;
    activities?: ActivityDto[];

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.config) {
            this.config = new ProtocolConfigDto().deserialize(input.config);
        }

        this.activities = this.deserializeArray(input.activities, ActivityDto);

        return this;
    }

    public getFirstImage(): string | null {
        if (this.config.coverImage) {
            return this.config.coverImage;
        }

        let activityWithImage = this.activities.find(value => value.image);
        return activityWithImage ? activityWithImage.image : null;
    }

    public getTitle(i18n: I18nService): string {
        let title: string;
        if (this.config && this.config.title) {
            title = this.config.title;
        } else {
            let activityWithTitle = this.activities.find(value => value.config.title);
            title = activityWithTitle ? Activities.getTitle(i18n, activityWithTitle) : null;
        }

        return title;
    }

    public getStartTime(): Date | undefined {
        if (!this.activities) {
            return undefined;
        }

        let startTimes = this.activities
            .filter(activity => !activity.deleted)
            .map(activity => (activity.startTime != undefined ? activity.startTime : 0));

        return new Date(Math.min(...startTimes));
    }

    public getRequiredDevices(): Set<DeviceType> {
        const devices: Set<DeviceType> = new Set<DeviceType>();
        for (const step of this.config.steps) {
            for (const deviceType of step.activityConfig.getDeviceTypes()) {
                devices.add(deviceType);
            }
        }
        return devices;
    }

    public get activityType(): ActivityType {
        const steps: Array<ProtocolConfigStepDto> = this.config.steps;
        if (!steps || steps.length === 0) {
            // if there are no steps, and no saved activityType in the protocol, then mark is as training by default.
            return ActivityType.EXERCISE;
        }

        let activityConfigs = Array.from(steps).flatMap(step => step.activityConfig!);

        // any protocol that has at least one non evaluation activity inside, is a training protocol
        const isTraining = activityConfigs.some(it => it.activityTypeEnum !== ActivityType.EVALUATION);
        if (isTraining) {
            return ActivityType.EXERCISE;
        } else {
            return ActivityType.EVALUATION;
        }
    }
}
