import {I18nService} from '../services/i18n/i18n.service';
import {Title} from "common";

export enum ActivityType {
    UNKNOWN = 'UNKNOWN',
    EVALUATION = 'EVALUATION',
    EXERCISE = 'EXERCISE',
    EXERCISE_GAME = 'EXERCISE_GAME',
    MIXED = 'MIXED'
}

export class ActivityTypes {
    public static title(i18n: I18nService, activityType: ActivityType): Title {
        let title = '';
        switch (activityType) {
            case ActivityType.EVALUATION:
                title = i18n.text('participants.assessment');
                break;
            case ActivityType.EXERCISE:
            case ActivityType.EXERCISE_GAME:
                title = i18n.text('activityType.exercise');
                break;
        }
        return title;
    }
}
