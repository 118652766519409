import {Dto} from "common";

export class AdvancedNordicHamstringResultsModel extends Dto {
    public leftMaxForce: number; // Kg
    public rightMaxForce: number; // Kg

    public syncedTimestamps: number[]; // timestamps produced by syncer
    public syncedForcesLeft: number[]; // transformed and synced data
    public syncedForcesRight: number[]; // transformed and synced data
    public syncedAngles: number[]; // transformed and synced data

    public averageLeft: number; // Kg
    public averageRight: number; // Kg
    public average: number; // Kg

    public rfdLeft: number; // Kg/s
    public rfdRight: number; // Kg/s
    public rfd: number; // Kg/s

    public timeToMaxLeft: number; // ms
    public timeToMaxRight: number; // ms
    public timeToMax: number; // ms

    public fatigueLeft: number; // Kg/s
    public fatigueRight: number; // Kg/s
    public fatigue: number; // Kg/s

    public angleOfMaxTorque: number; // degrees
    public inputSegmentLength: number; // cm
    public relativeForce: number; // N/Kg

    public maxTorque: number; // N*m
    public maxTorqueLeft: number; // N*m
    public maxTorqueRight: number; // N*m

    public avgTorque: number; // N*m/Kg
    public avgTorqueLeft: number; // N*m/Kg
    public avgTorqueRight: number; // N*m/Kg

    public totalTorques: number[]; // N*m
    public leftTorques: number[]; // N*m
    public rightTorques: number[]; // N*m

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
