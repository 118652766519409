import {Injectable, Type} from '@angular/core';
import {MeterEnduranceResultsComponent} from './meter-endurance-results/meter-endurance-results.component';
import {ExerciseType} from '../../models/exerciseType';
import {StanceResultsComponent} from './stance-results/stance-results.component';
import {ActivityDto} from '../../dtos/activity.dto';
import {JumpType} from '../../dtos/jumpType';
import {DynamicDistributionEvaluationResultsComponent} from './dynamic-distribution-evaluation-results/dynamic-distribution-evaluation-results.component';
import {JumpAnalysisResultsComponent} from './jump-analysis-results/jump-analysis-results.component';
import {BuiltInExerciseTemplates, BuiltInExerciseTemplateType} from '../../models/builtInExerciseTemplateType';
import {ActivitiesOrProtocolsOverTimeResultsComponent} from './activities-or-protocols-over-time-results/activities-or-protocols-over-time-results.component';
import {ResultsComponent} from './resultsComponent';
import {SingleLegJumpAnalysisComponent} from './single-leg-jump-analysis/single-leg-jump-analysis.component';
import {MultipleJumpsAnalysisComponent} from './multiple-jumps-analysis/multiple-jumps-analysis.component';
import {SkippingAnalysisComponent} from './skipping-analysis/skipping-analysis.component';
import {ProtocolDto} from '../../dtos/protocol.dto';
import {ProtocolResultsComponent} from './protocol-results/protocol-results.component';
import {ResultsViewType} from './resultsViewType';
import {StanceEvaluationSingleLegResultsComponent} from './stance-evaluation-single-leg-results/stance-evaluation-single-leg-results.component';
import {Activities} from '../../utils/activities';
import {BuiltinProtocols} from '../../models/builtinProtocols';
import {ActivityAnalysisServiceResponseDto} from "../../dtos/statistics/activityAnalysisServiceResponseDto";
import {StaticDistributionResultsComponent} from "./static-distribution-results/static-distribution-results.component";
import {BodyWeightCalculationResultsComponent} from "./body-weight-calculation-results/body-weight-calculation-results.component";
import {IsometryResultsComponent} from "./isometry-results/isometry-results.component";
import {RepCountResultsComponent} from "./rep-count-results/rep-count-results.component";
import {NordicHamstringResultsComponent} from "./nordic-hamstring-results/nordic-hamstring-results.component";
import {IkdcResultsComponent} from "./ikdc-results/ikdc-results.component";
import {AdvancedSquatAnalysisResultsComponent} from "./advanced-squat-analysis-results/advanced-squat-analysis-results.component";
import {WomacResultsComponent} from "./womac-results/womac-results.component";

@Injectable({
    providedIn: 'root'
})
export class ResultsComponentFactoryService {

    constructor() {
    }

    public forActivity(activity: ActivityDto, type: ResultsViewType, analysis?: ActivityAnalysisServiceResponseDto): Type<ResultsComponent> {
        const activityConfig = activity.config;
        const baseConfigCode = activityConfig.baseConfigCode;

        if (!baseConfigCode) {
            throw new Error('Unknown activity config');
        }

        let jumpType = activity.jumpType;
        let component: Type<ResultsComponent>;

        if (type === ResultsViewType.FULL || type === ResultsViewType.ROW) {
            switch (activityConfig.exerciseTypeEnum) {
                case ExerciseType.METER:
                case ExerciseType.METER_ENDURANCE:
                    component = MeterEnduranceResultsComponent;
                    break;
                case ExerciseType.STANDING_EVALUATION:
                    let isCustomSingleLegStance = false;

                    // we need the analysis to check if it is an instance of UnipodalStanceEvaluationActivityResultsModel.
                    if (analysis) {
                        isCustomSingleLegStance = !activityConfig.buildIn && Activities.isStanceWithSingleLeg(analysis.activitiesResults[0]);
                    }

                    if (BuiltInExerciseTemplates.isUnipodalStanceWithOneSensor(baseConfigCode) ||
                        BuiltInExerciseTemplates.isUnipodalDropLand(baseConfigCode) ||
                        isCustomSingleLegStance
                    ) {
                        component = StanceEvaluationSingleLegResultsComponent;
                    } else {
                        component = StanceResultsComponent;
                    }
                    break;
                case ExerciseType.DYNAMIC_DISTRIBUTION_EVALUATION:
                    if (BuiltInExerciseTemplateType.ADVANCED_SQUAT_ANALYSIS_HEXAS === baseConfigCode || BuiltInExerciseTemplateType.ADVANCED_SQUAT_ANALYSIS_PLATES === baseConfigCode) {
                        component = AdvancedSquatAnalysisResultsComponent;
                    } else if (BuiltInExerciseTemplates.isUnipodalCombinedAnalysis()) {
                        throw new Error('Unipodal combined analysis not supported');
                    } else if (BuiltInExerciseTemplates.isDynamicDistributionSimple(baseConfigCode)) {
                        component = DynamicDistributionEvaluationResultsComponent;
                    } else {
                        throw new Error(`Activity not supported dynamic distribution`);
                    }
                    break;
                case ExerciseType.JUMP_ANALYSIS:
                    if (jumpType === JumpType.MULTIPLE_JUMPS) {
                        component = MultipleJumpsAnalysisComponent;
                    } else if (BuiltInExerciseTemplates.isUnipodalJump(baseConfigCode)) {
                        component = SingleLegJumpAnalysisComponent;
                    } else if (baseConfigCode === BuiltInExerciseTemplateType.SKIPPING_DELTAS) {
                        component = SkippingAnalysisComponent;
                    } else {
                        component = JumpAnalysisResultsComponent;
                    }
                    break;
                case ExerciseType.STATIC_DISTRIBUTION_EXERCISE:
                    component = StaticDistributionResultsComponent;
                    break;
                case ExerciseType.TOTAL_EVALUATION:
                    if (BuiltInExerciseTemplates.isBodyWeightCalculation(baseConfigCode)) {
                        component = BodyWeightCalculationResultsComponent;
                    } else {
                        component = MeterEnduranceResultsComponent;
                    }
                    break;
                case ExerciseType.ISOMETRIC:
                    component = IsometryResultsComponent;
                    break;
                case ExerciseType.REP_COUNT:
                    component = RepCountResultsComponent;
                    break;
                case ExerciseType.NORDIC_HAMSTRING:
                    component = NordicHamstringResultsComponent;
                    break;
                case ExerciseType.SENSORLESS:
                    if (BuiltInExerciseTemplateType.WOMAC === baseConfigCode) {
                        component = WomacResultsComponent;
                    } else if (BuiltInExerciseTemplateType.IKDC === baseConfigCode) {
                        component = IkdcResultsComponent;
                    }
                    break;
                default:
                    throw new Error(`Activity not supported. ${activity.config.exerciseTypeEnum}`);
            }
        } else if (type === ResultsViewType.MINI) {
            throw new Error('Mini results not supported');
        }

        return component;
    }

    public forActivities(activities: ActivityDto[]): Type<ResultsComponent> {
        // check for common exercise type and select component
        let exerciseTypes = new Set<ExerciseType>(activities.map(a => a.config.exerciseTypeEnum === ExerciseType.METER_ENDURANCE ? ExerciseType.METER : a.config.exerciseTypeEnum));

        if (exerciseTypes.size !== 1) {
            throw new Error(`Unsupported exercise sets: ${exerciseTypes.values()}`);
        }
        const exerciseType = exerciseTypes.values().next().value as ExerciseType;
        const firstActivity = activities[0];
        const baseConfigCode = firstActivity.config.baseConfigCode;

        let componentType: Type<ResultsComponent>;
        switch (exerciseType) {
            case ExerciseType.METER:
            case ExerciseType.STANDING_EVALUATION:
                componentType = ActivitiesOrProtocolsOverTimeResultsComponent;
                break;
            case ExerciseType.JUMP_ANALYSIS:
                const jumpType = firstActivity.jumpType;
                if (jumpType === JumpType.MULTIPLE_JUMPS) {
                    componentType = ActivitiesOrProtocolsOverTimeResultsComponent;
                } else if (BuiltInExerciseTemplates.isUnipodalJump(baseConfigCode)) {
                    componentType = ActivitiesOrProtocolsOverTimeResultsComponent;
                } else if (baseConfigCode === BuiltInExerciseTemplateType.SKIPPING_DELTAS) {
                    componentType = ActivitiesOrProtocolsOverTimeResultsComponent;
                } else {
                    componentType = ActivitiesOrProtocolsOverTimeResultsComponent;
                }
                break;
            case ExerciseType.DYNAMIC_DISTRIBUTION_EVALUATION:
                if (BuiltInExerciseTemplates.isDynamicDistributionSimple(baseConfigCode)) {
                    componentType = ActivitiesOrProtocolsOverTimeResultsComponent;
                } else {
                    throw new Error(`Unsupported dynamic distribution activity`);
                }
                break;
            case ExerciseType.TOTAL_EVALUATION:
                if (BuiltInExerciseTemplates.isBodyWeightCalculation(baseConfigCode)) {
                    componentType = ActivitiesOrProtocolsOverTimeResultsComponent;
                } else {
                    throw new Error(`Unsupported total evaluation activity`);
                }
                break;
            default:
                throw new Error(`Exercise type not supported: ${exerciseType}`);
        }

        return componentType;
    }

    public forProtocols(protocols: ProtocolDto[]): Type<ActivitiesOrProtocolsOverTimeResultsComponent> {
        // check for common exercise type and select component
        let componentType: Type<ActivitiesOrProtocolsOverTimeResultsComponent>;
        const protocolConfig = protocols[0].config;
        switch (protocolConfig.getBaseConfigCode()) {
            case BuiltinProtocols.IDENTIFIERS.MC_CALL:
            case BuiltinProtocols.IDENTIFIERS.ADVANCED_STANDING_EVALUTATION:
            case BuiltinProtocols.IDENTIFIERS.ASH_IYT:
            case BuiltinProtocols.IDENTIFIERS.KNEE_ANTAGONIST:
            case BuiltinProtocols.IDENTIFIERS.FORCE_VELOCITY:
            case BuiltinProtocols.IDENTIFIERS.DSI:
                componentType = ActivitiesOrProtocolsOverTimeResultsComponent;
                break;
            default:
                throw new Error(`Exercise type not supported: ${protocolConfig.getBaseConfigCode()}`);
        }

        return componentType;
    }

    public instantiateComponent(resultsComponentType: Type<ResultsComponent>, component: ResultsComponent, referenceComponent?: ResultsComponent) {
        if (resultsComponentType === ActivitiesOrProtocolsOverTimeResultsComponent) {
            const target = component as ActivitiesOrProtocolsOverTimeResultsComponent;
            const source = referenceComponent as ActivitiesOrProtocolsOverTimeResultsComponent;
            target.filter = source.filter;
        } else if (resultsComponentType === ProtocolResultsComponent) {
            const target = component as ProtocolResultsComponent;
            const source = referenceComponent as ProtocolResultsComponent;
            target.selectedTabIndex = source.selectedTabIndex;
        }
    }

    public forProtocol(_protocol: ProtocolDto): Type<ProtocolResultsComponent> {
        return ProtocolResultsComponent;
    }
}
