/**
 * Static utils for drawing
 */
import {TextDrawingParams} from "./textDrawingParams";
import {ArrowDrawingParams} from "./arrowDrawingParams";
import {Rect} from "../../models/rect";
import {Point} from "../../models/point";
import {UnitConverter} from "common";

export class Drawing {
    /**
     * Returns the biggest square that fits inside a square.
     * Doesn't translate it
     */
    public static getFittedSquare(rect: Rect): Rect {
        const smallestSide = Math.min(rect.width, rect.height);
        return new Rect(rect.top, rect.left, smallestSide, smallestSide);
    }

    public static getFittedRectangle(area: Rect, target: Rect): Rect {
        let scaleRatio: number;

        if (target.aspectRatio > area.aspectRatio) {
            scaleRatio = area.width / target.width;
        } else {
            scaleRatio = area.height / target.height;
        }

        const width = target.width * scaleRatio;
        const height = target.height * scaleRatio;
        const top = area.top + (area.height - height) / 2;
        const left = area.left + (area.width - width) / 2;
        return new Rect(top, left, width, height);
    }

    /**
     * Centers a rect horizontally and vertically relative to another rect
     */
    public static center(source: Rect, relativeTo: Rect): Rect {
        let center = relativeTo.center;
        return new Rect(center.y - source.height / 2, center.x - source.width / 2, source.width, source.height);
    }

    public static drawText(ctx: CanvasRenderingContext2D, params: TextDrawingParams): void {
        ctx.save();
        ctx.font = params.fontStyle;
        ctx.fillStyle = params.color;
        ctx.translate(params.point.x, params.point.y);
        ctx.rotate(params.rotationRad);
        ctx.textAlign = 'center';
        ctx.fillText(params.text, 0, 0);
        ctx.restore();
    }

    /**
     * Draws an arrow. end is the tip (triangle) of the arrow
     */
    public static drawArrow(ctx: CanvasRenderingContext2D, params: ArrowDrawingParams): void {
        // theming
        const arrowTipAngle = UnitConverter.degreeToRad(25);

        // line
        ctx.beginPath();
        ctx.globalAlpha = 1;
        ctx.lineWidth = params.lineWidth;
        ctx.strokeStyle = params.color;
        ctx.moveTo(params.start.x, params.start.y);
        ctx.lineTo(params.end.x, params.end.y);
        ctx.stroke();

        // arrow
        if (params.tipOnEnd) {
            const dx = params.end.x - params.start.x;
            const dy = params.end.y - params.start.y;
            const angle = Math.atan2(dy, dx);
            ctx.beginPath();
            ctx.moveTo(params.end.x, params.end.y);
            ctx.lineTo(params.end.x - params.tipSize * Math.cos(angle - arrowTipAngle), params.end.y - params.tipSize * Math.sin(angle - arrowTipAngle));
            ctx.lineTo(params.end.x - params.tipSize * Math.cos(angle + arrowTipAngle), params.end.y - params.tipSize * Math.sin(angle + arrowTipAngle));
            ctx.closePath();
            ctx.fillStyle = params.color;
            ctx.fill();
        }
        if (params.tipOnStart) {
            const dx = params.start.x - params.end.x;
            const dy = params.start.y - params.end.y;
            const angle = Math.atan2(dy, dx);
            ctx.beginPath();
            ctx.moveTo(params.start.x, params.start.y);
            ctx.lineTo(params.start.x - params.tipSize * Math.cos(angle - arrowTipAngle), params.start.y - params.tipSize * Math.sin(angle - arrowTipAngle));
            ctx.lineTo(params.start.x - params.tipSize * Math.cos(angle + arrowTipAngle), params.start.y - params.tipSize * Math.sin(angle + arrowTipAngle));
            ctx.closePath();
            ctx.fillStyle = params.color;
            ctx.fill();
        }
    }

    public static drawRect(ctx: CanvasRenderingContext2D, rect: Rect, style: string): void {
        ctx.beginPath();
        ctx.strokeStyle = style;
        ctx.rect(rect.left, rect.top, rect.width, rect.height);
        ctx.stroke();
    }

    /**
     * Draws horizontal lines in a rect. Top and bottom edges are excluded.
     * Uses existing stroke style
     */
    public static drawHorizontalLines(ctx: CanvasRenderingContext2D, target: Rect, numberOfLines): void {
        ctx.beginPath();
        const increment = target.height / (numberOfLines + 1);
        for (let i = 1; i <= numberOfLines; i++) {
            ctx.moveTo(target.left, target.top + i * increment);
            ctx.lineTo(target.right, target.top + i * increment);
        }
        ctx.stroke();
    }

    /**
     * Draws vertical lines in a rect. Left and right edges are excluded.
     * Uses existing stroke style
     */
    public static drawVerticalLines(ctx: CanvasRenderingContext2D, target: Rect, numberOfLines): void {
        ctx.beginPath();
        const increment = target.width / numberOfLines;
        for (let i = 1; i < numberOfLines; i++) {
            ctx.moveTo(target.left + i * increment, target.top);
            ctx.lineTo(target.left + i * increment, target.bottom);
        }
        ctx.stroke();
    }

    public static drawDiagonals(ctx: CanvasRenderingContext2D, rect: Rect, style: string): void {
        ctx.beginPath();
        ctx.strokeStyle = style;
        ctx.moveTo(rect.left, rect.top);
        ctx.lineTo(rect.right, rect.bottom);
        ctx.moveTo(rect.right, rect.top);
        ctx.lineTo(rect.left, rect.bottom);
        ctx.stroke();
    }

    public static drawCross(ctx: CanvasRenderingContext2D, point: Point, width: number, color?: string, lineWidth?: number): void {
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.lineWidth = lineWidth;
        ctx.moveTo(point.x - width / 2, point.y);
        ctx.lineTo(point.x + width / 2, point.y);
        ctx.moveTo(point.x, point.y - width / 2);
        ctx.lineTo(point.x, point.y + width / 2);
        ctx.stroke();
        ctx.restore();
    }

    public static rotateAroundPoint(ctx: CanvasRenderingContext2D, point: Point, rad: number): void {
        let offsetX = point.x;
        let offsetY = point.y;

        ctx.translate(offsetX, offsetY);
        ctx.rotate(rad);
        ctx.translate(-offsetX, -offsetY);
    }

    public static measureTextHeight(ctx: CanvasRenderingContext2D, text: string, fontStyle: string): number {
        // let metrics = ctx.measureText(text);
        // const height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

        // Height isn't yet supported by major browsers, instead measure the width of specific text which is kind of close in width to the measured height
        ctx.font = fontStyle;
        let metrics = ctx.measureText('M!');
        return metrics.width;
    }

    /**
     * displays a rectangle on canvas for debugging purposes
     */
    public static indicateRect(ctx: CanvasRenderingContext2D, rect: Rect, title: string, color: string = 'red'): void {
        Drawing.drawRect(ctx, rect, color);
        Drawing.drawDiagonals(ctx, rect, color);
        const params = new TextDrawingParams()
            .setText(title)
            .setPoint(new Point(rect.left + 75, rect.top + 50))
            .setFontStyle(`2em sans-serif`)
            .setColor(color);
        Drawing.drawText(ctx, params);
    }

    /**
     * displays a point on canvas for debugging purposes
     */
    public static indicatePoint(ctx: CanvasRenderingContext2D, point: Point, title: string, color: string = 'red'): void {
        Drawing.drawCross(ctx, point, 30, color, 3);
        const params = new TextDrawingParams()
            .setText(title)
            .setPoint(new Point(point.x + 30, point.y + 30))
            .setFontStyle(`2em sans-serif`)
            .setColor(color);
        Drawing.drawText(ctx, params);
    }
}
