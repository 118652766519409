import {ComponentFactoryResolver, Injectable} from '@angular/core';
import {ResultsComponent} from '../components/results/resultsComponent';
import {ResultsComponentFactoryService} from '../components/results/results-component-factory.service';
import {ResultsDirective} from '../components/results/resultsDirective';
import {ActivityDto} from '../dtos/activity.dto';
import {ActivityAnalysisServiceResponseDto} from '../dtos/statistics/activityAnalysisServiceResponseDto';
import {ProtocolDto} from '../dtos/protocol.dto';
import {ProtocolAnalysisServiceResponseDto} from '../dtos/statistics/protocolAnalysisServiceResponseDto';
import {ProtocolResultsComponent} from '../components/results/protocol-results/protocol-results.component';
import {ResultsViewType} from '../components/results/resultsViewType';
import {ViewMediumType} from "../components/results/viewMediumType";

@Injectable({
    providedIn: 'root'
})
export class DynamicResultsLoaderService {

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private resultsComponentFactory: ResultsComponentFactoryService) {
    }

    public forActivity(activity: ActivityDto, resultsHost: ResultsDirective, analysis: ActivityAnalysisServiceResponseDto, type: ResultsViewType, mediumType: ViewMediumType, referenceResultsComponent?: ResultsComponent): ResultsComponent {
        // Create results component
        const resultsComponentType = this.resultsComponentFactory.forActivity(activity, type, analysis);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(resultsComponentType);
        const viewContainerRef = resultsHost.viewContainerRef;
        viewContainerRef.clear();
        const component = viewContainerRef.createComponent<ResultsComponent>(componentFactory);
        const instance = component.instance;

        // Data to results component
        instance.activities = [activity];
        instance.analysis = analysis;
        instance.viewType = type;
        instance.mediumType = mediumType;
        if (referenceResultsComponent) {
            this.resultsComponentFactory.instantiateComponent(resultsComponentType, component.instance, referenceResultsComponent);
        }

        return instance;
    }

    public forActivitiesOverTime(activities: ActivityDto[], resultsHost: ResultsDirective, analysis: ActivityAnalysisServiceResponseDto, mediumType: ViewMediumType, referenceResultsComponent?: ResultsComponent): ResultsComponent {
        const resultsComponentType = this.resultsComponentFactory.forActivities(activities);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(resultsComponentType);
        const viewContainerRef = resultsHost.viewContainerRef;
        viewContainerRef.clear();
        const component = viewContainerRef.createComponent<ResultsComponent>(componentFactory);
        const instance = component.instance;

        // Data to results component
        instance.activities = activities;
        instance.analysis = analysis;
        instance.mediumType = mediumType;
        if (referenceResultsComponent) {
            this.resultsComponentFactory.instantiateComponent(resultsComponentType, component.instance, referenceResultsComponent);
        }

        return instance;
    }

    public forProtocol(protocol: ProtocolDto, resultsHost: ResultsDirective, analysis: ProtocolAnalysisServiceResponseDto, mediumType: ViewMediumType, referenceResultsComponent?: ResultsComponent): ResultsComponent {
        const resultsComponentType = this.resultsComponentFactory.forProtocol(protocol);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(resultsComponentType);
        const viewContainerRef = resultsHost.viewContainerRef;
        viewContainerRef.clear();

        const component = viewContainerRef.createComponent<ProtocolResultsComponent>(componentFactory);
        const instance = component.instance;

        // Data to results component
        instance.protocol = protocol;
        instance.analysis = analysis;
        instance.mediumType = mediumType;
        if (referenceResultsComponent) {
            this.resultsComponentFactory.instantiateComponent(resultsComponentType, component.instance, referenceResultsComponent);
        }
        return instance;
    }

    public forProtocolsOverTime(protocols: ProtocolDto[], resultsHost: ResultsDirective, analysis: ProtocolAnalysisServiceResponseDto, mediumType: ViewMediumType, referenceResultsComponent?: ResultsComponent): ResultsComponent {
        const resultsComponentType = this.resultsComponentFactory.forProtocols(protocols);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(resultsComponentType);
        const viewContainerRef = resultsHost.viewContainerRef;
        viewContainerRef.clear();

        const component = viewContainerRef.createComponent<ResultsComponent>(componentFactory);
        const instance = component.instance;

        // Data to results component
        instance.protocols = protocols;
        instance.analysis = analysis;
        instance.mediumType = mediumType;
        if (referenceResultsComponent) {
            this.resultsComponentFactory.instantiateComponent(resultsComponentType, component.instance, referenceResultsComponent);
        }

        return instance;
    }
}
