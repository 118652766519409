import {ChartDataSets, ChartOptions} from "chart.js";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ProgressOverTimeFilter} from "../../../viewmodels/progressOverTimeFilter";
import {Label} from "ng2-charts";
import {I18nService} from "../../../services/i18n/i18n.service";
import {ActivityResultsModel} from "../../../dtos/statistics/activityResultsModel";
import {KeyValue} from "common";

export abstract class ActivityLineChartProvider {
    public abstract getDatasets(activities: ActivityDto[], analysis: ActivityAnalysisServiceResponseDto, filter: ProgressOverTimeFilter, chartOptions: ChartOptions, i18n: I18nService): KeyValue<Label[], ChartDataSets[]>;

    protected combineActivitiesToResults<T extends ActivityResultsModel>(activities: ActivityDto[], analysis: ActivityAnalysisServiceResponseDto): Map<ActivityDto, T> {
        return new Map(activities.map(a => [a, analysis.activitiesResults.find(r => r.activityCode === a.code).activityResultsModel] as [ActivityDto, T]));
    }
}
