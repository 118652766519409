import {Value} from './values/value';

export class MathUtils {
    public static maxOrDefault(values: number[], defaultValue: number): number {
        return values.length > 0 ? Math.max(...values) : defaultValue;
    }

    public static average(values: number[]): number {
        return values.reduce((value, v, i, a) => (value + v / a.length), 0);
    }

    public static sum(values: number[]): number {
        return values.reduce((a, b) => a + b);
    }

    public static getNumberFromValueOrNumber<Type extends number | Value | undefined>(type: Type): number | undefined {
        let val: number;
        if (!type) {
            return;
        }
        if (type instanceof Value) {
            val = type.value;
        } else {
            val = Number(type);
        }
        return val;
    }

    /**
     * https://stackoverflow.com/questions/7342957/how-do-you-round-to-1-decimal-place-in-javascript
     */
    public static round(value: number, precision: number): number {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    public static randomInt(min, max): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public static randomFloat(min, max): number {
        return (Math.random() * (max - min) + min);
    }
}
