import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from "../base/base.component";
import {ParticipantTagConfigDto} from "../../dtos/participantTagConfig.dto";

@Component({
    selector: 'app-tag-selection',
    templateUrl: './tag-selection.component.html',
    styleUrls: ['./tag-selection.component.scss']
})
export class TagSelectionComponent extends BaseComponent implements OnInit {

    @Input()
    public tags: ParticipantTagConfigDto[];

    @Input()
    public selected: ParticipantTagConfigDto[];

    @Output()
    public selectedChange: EventEmitter<ParticipantTagConfigDto[]>;

    constructor() {
        super();
        this.selectedChange = new EventEmitter<ParticipantTagConfigDto[]>();
    }

    ngOnInit(): void {
    }

    onTagClick(tag: ParticipantTagConfigDto) {
        let selectedIndex = this.selected.findIndex(t => t.code === tag.code);
        if (selectedIndex < 0) {
            this.selected.push(tag);
        } else {
            this.selected.splice(selectedIndex, 1);
        }
        this.selectedChange.emit(this.selected);
    }
}
