import {Injectable} from '@angular/core';
import {ParticipantDto} from '../dtos/participant.dto';
import {OrderingType} from '../viewmodels/orderingType';

@Injectable({
    providedIn: 'root'
})
export class ParticipantOrderingService {

    constructor() {
    }

    public order(items: ParticipantDto[], type: OrderingType): void {
        switch (type) {
            case OrderingType.DATE_ASC:
                items.sort(ParticipantOrderingService.orderByDateAsc);
                break;
            case OrderingType.DATE_DESC:
                items.sort(ParticipantOrderingService.orderByDateDesc);
                break;
            case OrderingType.NAME_ASC:
                items.sort(ParticipantOrderingService.orderByNameAsc);
                break;
            case OrderingType.NAME_DESC:
                items.sort(ParticipantOrderingService.orderByNameDesc);
                break;
            case OrderingType.LASTNAME_ASC:
                items.sort(ParticipantOrderingService.orderByLastNameAsc);
                break;
            case OrderingType.LASTNAME_DESC:
                items.sort(ParticipantOrderingService.orderByLastNameDesc);
                break;
        }
    }

    private static orderByDateAsc(first: ParticipantDto, second: ParticipantDto): number {
        return first.createdOn - second.createdOn;
    }

    private static orderByDateDesc(first: ParticipantDto, second: ParticipantDto): number {
        return -(first.createdOn - second.createdOn);
    }

    private static orderByNameAsc(first: ParticipantDto, second: ParticipantDto): number {
        return first.firstName?.localeCompare(second.firstName);
    }

    private static orderByNameDesc(first: ParticipantDto, second: ParticipantDto): number {
        return -first.firstName?.localeCompare(second.firstName);
    }

    private static orderByLastNameAsc(first: ParticipantDto, second: ParticipantDto): number {
        return first.lastName?.localeCompare(second.lastName);
    }

    private static orderByLastNameDesc(first: ParticipantDto, second: ParticipantDto): number {
        return -first.lastName?.localeCompare(second.lastName);
    }
}
