import {Component, Input, OnInit} from '@angular/core';
import {Color, Title} from "common";
import {ChartDataSets, ChartOptions} from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {Value} from "../../../utils/values/value";
import {TextUtils} from "common";

@Component({
    selector: 'app-simple-barchart',
    templateUrl: './simple-barchart.component.html',
    styleUrls: ['./simple-barchart.component.scss']
})
export class SimpleBarchartComponent implements OnInit {
    @Input()
    public data: Value[];

    @Input()
    public title: Title;

    @Input()
    public color: Color;

    @Input()
    public maxY: number;

    @Input()
    public displayAxisY = true;

    @Input()
    public axisYLabel: string;

    @Input()
    public valueFractionDigits: number = 0;

    public datasets: ChartDataSets;
    public dataTitles: string[];
    public chartOptions: ChartOptions;
    public chartPlugins: any[];

    constructor() {
        this.chartPlugins = [pluginDataLabels];
    }

    ngOnInit(): void {
        this.dataTitles = [];
        for (let i = 0; i < this.data.length; i++) {
            this.dataTitles.push(i.toString());
        }

        this.datasets = {
            data: this.data.map(v => v.value),
            backgroundColor: this.color,
            borderWidth: 0,
            hoverBackgroundColor: this.color,
            label: this.title
        };

        this.chartOptions = {
            animation: {
                duration: 0,
                animateScale: false,
                animateRotate: false
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            devicePixelRatio: 2,
            tooltips: {
                enabled: false
            },
            legend: {
                align: 'end',
                position: 'bottom'
            },
            scales: {
                yAxes: [{
                    ticks: {
                        fontColor: this.displayAxisY ? undefined : 'transparent',
                        min: 0,
                        max: this.maxY ? this.maxY : this.getAxisYMax()
                    },
                    scaleLabel: {display: !TextUtils.isEmpty(this.axisYLabel), labelString: this.axisYLabel}
                }],
                xAxes: [{
                    display: false
                }]
            },
            plugins: {
                datalabels: {
                    formatter: this.labelFormatter.bind(this),
                    anchor: 'end',
                    align: 'end',
                    font: {size: 16},
                },
            }
        };
    }

    private labelFormatter(value: number): string {
        // find the value in the data by value and format it
        let foundValue: Value;
        for (const datum of this.data) {
            if (datum.value === value) {
                foundValue = datum;
                break;
            }
        }

        if (foundValue) {
            return foundValue.format(this.valueFractionDigits);
        } else {
            return value.toFixed(this.valueFractionDigits);
        }
    }

    private getAxisYMax(): number {
        // 20% above max value, rounded
        return Math.round(Math.max(...this.data.map(v => v.value)) * 1.2);
    }

}
