<div>
    <canvas class=""
            baseChart
            [datasets]="datasets"
            [labels]="columns"
            [plugins]="chartPlugins"
            chartType="line"
            [options]="chartOptions"
    ></canvas>
</div>
