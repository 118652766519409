import {Component, OnInit} from '@angular/core';
import {ParticipantDto} from "../../dtos/participant.dto";
import {ParticipantService} from "../../services/api/participant.service";
import {takeUntil} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {BreadcrumbInitializerService} from "../../services/breadcrumb-initializer.service";
import {BaseComponent} from "../../components/base/base.component";
import {LoadingState} from "common";

@Component({
    selector: 'app-participant',
    templateUrl: './participant.component.html',
    styleUrls: ['./participant.component.scss']
})
export class ParticipantComponent extends BaseComponent implements OnInit {
    public loadingState: LoadingState;
    public participant: ParticipantDto;

    constructor(private participantService: ParticipantService,
                private route: ActivatedRoute,
                private breadcrumbInitializerService: BreadcrumbInitializerService) {
        super();
    }

    ngOnInit(): void {
        this.route.paramMap
            .pipe(takeUntil(this.destroySubject))
            .subscribe((params) => {
                const code = params.get('code');
                if (!code) {
                    this.notification.info(`Participant doesn't exist`);
                    this.navigation.goToHome();
                    return;
                }

                this.analyticsSwitchPage('Participant', undefined, '/participants/participant');

                this.loadingState = LoadingState.LOADING;
                this.participantService.get(code)
                    .pipe(takeUntil(this.destroySubject))
                    .subscribe(participant => {
                        this.participant = participant;
                        this.breadcrumbInitializerService.setParticipantName(this.participant.fullName);
                        this.loadingState = LoadingState.LOADED;
                    });
            });
    }

    participantDescription() {
        return `${this.participant.gender.toLowerCase()}, ${this.participant.age}`;
    }

    onProgressPress() {
        this.navigation.goToParticipantProgress(this.participant.code);
    }
}
