import {ProtocolSummaryResultsModelDto} from "./protocolSummaryResultsModelDto";

export class AdvancedStanceEvaluationProtocolSummaryResultsModel extends ProtocolSummaryResultsModelDto {
    public rombergQuotient: number;
    public leftDistributions: Array<{ activityCode: string; distribution: number }>; // % [0-100], the right is 100 - leftDistributions
    public surfaces: Array<{ activityCode: string; surface: number }>; // mm^2
    public meanVelocities: Array<{ activityCode: string; meanVelocity: number }>; // mm/s
    public longitudinalAmplitudes: Array<{ activityCode: string; longitudinalAmplitude: number }>; // mm
    public lateralAmplitudes: Array<{ activityCode: string; lateralAmplitude: number }>; // mm

    public averageLeftDistributions: { eyesOpen: number; eyesClosed: number }; // % [0-100], the right is 100 - leftDistributions
    public averageSurfaces: { eyesOpen: number; eyesClosed: number }; // mm^2
    public averageMeanVelocities: { eyesOpen: number; eyesClosed: number }; // mm/s
    public averageLongitudinalAmplitudes: { eyesOpen: number; eyesClosed: number }; // mm
    public averageLateralAmplitudes: { eyesOpen: number; eyesClosed: number }; // mm

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
