import {Component, Input, OnInit} from '@angular/core';
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {Resource} from "common";
import {ActivityImageResolverService} from "../../../services/activity-image-resolver.service";
import {ActivityDto} from "../../../dtos/activity.dto";
import {BaseComponent} from "../../base/base.component";

/**
 * Displays the activity info on the side of the generic protocol results summary
 */
@Component({
    selector: 'app-protocol-summary-row-activity-info',
    templateUrl: './protocol-summary-row-activity-info.component.html',
    styleUrls: ['./protocol-summary-row-activity-info.component.scss']
})
export class ProtocolSummaryRowActivityInfoComponent extends BaseComponent implements OnInit {

    @Input()
    public activity: ActivityDto;

    @Input()
    public activityAnalysis: ActivityAnalysisResultDto;

    @Input()
    public displayConfig: boolean;

    public image: Resource;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
        this.displayConfig = true;
    }

    ngOnInit(): void {
        this.image = this.activityImageResolverService.do(this.activity.image);
    }

}
