/**
 * One activity-group item for participant history.
 */
import {ProtocolDto} from '../dtos/protocol.dto';
import {ActivityType} from '../models/activityType';
import GameTypes, {GameType} from '../models/gameType';
import {ActivityConfigDto} from '../dtos/activityConfig.dto';
import {I18nService} from '../services/i18n/i18n.service';
import {Activities} from '../utils/activities';
import {ProtocolGroupFilter} from './protocolGroupFilter';
import {ExerciseType, ExerciseTypes} from '../models/exerciseType';
import {ActivityDto} from '../dtos/activity.dto';
import {ResultsComponentFactoryService} from '../components/results/results-component-factory.service';
import {ProtocolOrActivityConfigTag} from '../models/protocolOrActivityConfigTag';
import {ResultsViewType} from "../components/results/resultsViewType";
import {DeviceType, TextUtils} from "common";

export class ProtocolViewModel {
    public code: string;
    public filter: ProtocolGroupFilter;
    public readonly protocols: ProtocolDto[];
    public date: Date;
    public devices: DeviceType[];
    public tags: ProtocolOrActivityConfigTag[];
    public title: string;
    public image: string;
    public isFavorite: boolean;
    public isSingleActivity: boolean;
    public activityType: ActivityType;
    public gameType: GameType; // only for mock protocols
    public groupedConfigs: ActivityConfigDto[]; // only for mock protocols
    public overflowImage: string;
    public exerciseTypeIcons: string[];
    public enabled = false; // If the viewmodel can be selected

    constructor(code: string) {
        this.code = code;
        this.protocols = [];
        this.devices = [];
        this.tags = [];
        this.exerciseTypeIcons = [];
    }

    public static createForProtocol(i18n: I18nService, protocol: ProtocolDto, isFavorite: boolean): ProtocolViewModel {
        const protocolConfig = protocol.config!;
        const viewModel = new ProtocolViewModel(protocol.code);
        viewModel.isSingleActivity = false;
        viewModel.date = new Date(protocol.createdOn);
        viewModel.title = i18n.text(protocolConfig.title);
        viewModel.image = protocol.getFirstImage();
        viewModel.devices = Array.from(protocol.getRequiredDevices());
        viewModel.tags = protocol.config.getTags();
        viewModel.activityType = protocol.activityType;
        viewModel.isFavorite = isFavorite;

        viewModel.enabled = this.isProtocolSupported(protocol);
        return viewModel;
    }

    public static createForSingleActivity(i18n: I18nService, protocol: ProtocolDto, groupedConfigs: ActivityConfigDto[], isFavorite: boolean): ProtocolViewModel {
        const activity = protocol.activities![protocol.activities!.length - 1];
        let config = activity.config;
        const viewModel = new ProtocolViewModel(protocol.code);
        viewModel.isSingleActivity = true;
        viewModel.date = new Date(protocol.createdOn);
        viewModel.title = Activities.getTitle(i18n, activity);
        viewModel.image = activity.image;
        viewModel.devices = config.getDeviceTypes();
        viewModel.tags = config.getTags();
        viewModel.gameType = config.gameTypeEnum;
        viewModel.groupedConfigs = groupedConfigs;
        viewModel.activityType = config.activityTypeEnum;
        viewModel.isFavorite = isFavorite;
        viewModel.enabled = this.isActivitySupported(activity);

        if (viewModel.gameType) {
            viewModel.overflowImage = GameTypes.getOverflowImage(viewModel.gameType);
        }

        for (const exerciseType of viewModel.exerciseTypes) {
            const icon = ExerciseTypes.icon(exerciseType);
            if (TextUtils.isEmpty(icon)) {
                continue;
            }
            viewModel.exerciseTypeIcons.push(ExerciseTypes.icon(exerciseType));
        }

        return viewModel;
    }


    public get exerciseTypes(): ExerciseType[] {
        if (!this.groupedConfigs) {
            return [];
        }

        const exerciseTypes = this.groupedConfigs!.map((it) => it.exerciseTypeEnum);

        // METER and METER_ENDURANCE are considered the same
        let filteredTypes: ExerciseType[] = exerciseTypes.map(value => {
            return value === ExerciseType.METER_ENDURANCE ? ExerciseType.METER : value;
        });
        return [...new Set(filteredTypes)];
    }

    public static isActivitySupported(activity: ActivityDto): boolean {
        let isSupported = true;
        try {
            // Throws when not supported
            new ResultsComponentFactoryService().forActivity(activity, ResultsViewType.FULL);

            if (activity.config.gameTypeEnum !== undefined) {
                isSupported = false;
            }
        } catch (e) {
            isSupported = false;
        }

        return isSupported;
    }

    public static isProtocolSupported(protocol: ProtocolDto): boolean {
        let isSupported = true;
        try {
            // Throws when not supported
            new ResultsComponentFactoryService().forProtocol(protocol);
        } catch (e) {
            isSupported = false;
        }

        return isSupported;
    }
}
