import {Component, OnInit} from '@angular/core';
import {ResultsComponent} from "../resultsComponent";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {BodyWeightActivityResultsModel} from "../../../dtos/statistics/bodyWeightActivityResultsModel";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {Resource} from "common";
import {ActivityImageResolverService} from "../../../services/activity-image-resolver.service";
import {Kg} from "../../../utils/values/kg";

@Component({
    selector: 'app-body-weight-calculation-results',
    templateUrl: './body-weight-calculation-results.component.html',
    styleUrls: ['./body-weight-calculation-results.component.scss']
})
export class BodyWeightCalculationResultsComponent extends ResultsComponent implements OnInit {
    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    private statistics: BodyWeightActivityResultsModel;

    public weightFormatted: string;
    public image: Resource;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. BodyWeightCalculationResultsComponent`, this.analysis);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.activityAnalysis.activityResultsModel as BodyWeightActivityResultsModel;

        this.weightFormatted = `${this.text('exercise.jump_analysis.weight')} ${new Kg(this.statistics.weight).formatTo(this.userSettings.weightUnit, 1)}`;
        this.image = this.activityImageResolverService.do(this.activity.image);

    }

}
