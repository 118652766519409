import {Dto} from "common";

export class AcceptAccountAssociationDto extends Dto {
    email: string;
    token: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
