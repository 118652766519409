<div class="component full-width d-flex flex-column">
    <div class="dateHeader position-relative">
        <div class="title text-center">{{title}}</div>
        <app-kinvent-logo size="48"></app-kinvent-logo>
    </div>
    <div class="infoBox d-flex">
        <div class="info">
            <div class="avatar d-flex flex-row">
                <app-avatar [imageBase64]="participant?.photo" size="90" class="me-2 p-1"></app-avatar>
                <div class="flex-grow-1 d-flex flex-column justify-content-center">
                    <strong class="title">{{text('report.common.header.participantFirstName')}}</strong>
                    <div>{{participant?.firstName}}</div>
                    <strong class="title">{{text('report.common.header.participantLastName')}}</strong>
                    <div>{{participant?.lastName}}</div>
                    <i class="participant-info">{{participantInfo}}</i>
                </div>
            </div>
            <div class="painScale d-flex flex-row ps-3 mt-2 mb-2">
                <strong>V.A.S.</strong>
                <div>-</div>
            </div>
        </div>
        <div class="comments pt-2 ps-3">
            <strong>Comments</strong>
        </div>
    </div>
</div>
