import {ForceAndTimePacket} from "./forceAndTimePacket";
import {Dto} from "common";

export class JumpCycle extends Dto {
    public timestamp: number;
    public timeOnAir: number;
    public ordinal: number;
    public maxForce: number;
    public leftForceInMaxTotal: number;
    public maxLeftForce: number;
    public maxRightForce: number;
    public avgForce: number;
    public avgLeftForce: number;
    public jumpHeight: number;
    public invalid: boolean;
    public invalidPhaseKg: number;
    public pace: number;
    public maxVelocity: number;
    public avgVelocity: number;
    public takeOffVelocity: number;
    public velocities: ForceAndTimePacket[];
    public thrustDistance: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
