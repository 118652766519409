import {Component, Input, OnInit} from '@angular/core';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {BuiltinProtocols} from '../../../models/builtinProtocols';
import {BodySideType} from '../../../models/bodySideType';
import {Resource} from "common";
import {ActivityImageResolverService} from '../../../services/activity-image-resolver.service';
import {Protocols} from '../../../utils/protocols';
import {BaseComponent} from "../../base/base.component";
import {Colors} from "../../../services/colors";
import {Degrees} from "../../../utils/values/degrees";
import {Kg} from "../../../utils/values/kg";

@Component({
    selector: 'app-protocol-summary-neck-report-results',
    templateUrl: './protocol-summary-neck-report-results.component.html',
    styleUrls: ['./protocol-summary-neck-report-results.component.scss']
})

export class ProtocolSummaryNeckReportResultsComponent extends BaseComponent implements OnInit {
    public readonly colors = Colors;

    @Input()
    public protocol: ProtocolDto;

    @Input()
    public analysis: ProtocolAnalysisServiceResponseDto;

    public neckAnglesImage: Resource;
    public neckForceImage: Resource;

    public neckFlexionValue: Degrees;
    public neckExtensionValue: Degrees;
    public neckTiltRightValue: Degrees;
    public neckTiltLeftValue: Degrees;
    public neckRotationRightValue: Degrees;
    public neckRotationLeftValue: Degrees;

    public neckLinkFlexionValue: Kg;
    public neckLinkExtensionValue: Kg;
    public neckLinkTiltRightValue: Kg;
    public neckLinkTiltLeftValue: Kg;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
    }

    ngOnInit(): void {
        //Sens
        this.neckAnglesImage = this.activityImageResolverService.do('neck_report_protocol_image.jpg');
        const neckFlexion = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.HEAD_NECK_FLEXION_METER);
        const neckExtension = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.HEAD_NECK_EXTENSION_METER);
        const neckTiltRight = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.HEAD_NECK_TILTING_METER, BodySideType.RIGHT);
        const neckTiltLeft = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.HEAD_NECK_TILTING_METER, BodySideType.LEFT);
        const neckRotationRight = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.HEAD_NECK_ROTATION_METER, BodySideType.RIGHT);
        const neckRotationLeft = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.HEAD_NECK_ROTATION_METER, BodySideType.LEFT);

        this.neckFlexionValue = new Degrees(neckFlexion ? neckFlexion.maxPerSide.get(BodySideType.BOTH) : 0);
        this.neckExtensionValue = new Degrees(neckExtension ? neckExtension.maxPerSide.get(BodySideType.BOTH) : 0);
        this.neckTiltRightValue = new Degrees(neckTiltRight ? neckTiltRight.maxPerSide.get(BodySideType.RIGHT) : 0);
        this.neckTiltLeftValue = new Degrees(neckTiltLeft ? neckTiltLeft.maxPerSide.get(BodySideType.LEFT) : 0);
        this.neckRotationRightValue = new Degrees(neckRotationRight ? neckRotationRight.maxPerSide.get(BodySideType.RIGHT) : 0);
        this.neckRotationLeftValue = new Degrees(neckRotationLeft ? neckRotationLeft.maxPerSide.get(BodySideType.LEFT) : 0);

        //Link
        this.neckForceImage = this.activityImageResolverService.do('neck_report_protocol_link.jpg');
        const neckLinkFlexion = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.HEAD_NECK_LINK_EXTENSION_METER);
        const neckLinkExtension = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.HEAD_NECK_LINK_EXTENSION_METER);
        const neckLinkTiltRight = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.HEAD_RIGHT_NECK_LINK_TILTING_METER, BodySideType.RIGHT);
        const neckLinkTiltLeft = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.HEAD_NECK_LINK_TILTING_METER, BodySideType.LEFT);

        this.neckLinkFlexionValue = new Kg(neckLinkFlexion ? neckLinkFlexion.maxPerSide.get(BodySideType.BOTH) : 0);
        this.neckLinkExtensionValue = new Kg(neckLinkExtension ? neckLinkExtension.maxPerSide.get(BodySideType.BOTH) : 0);
        this.neckLinkTiltRightValue = new Kg(neckLinkTiltRight ? neckLinkTiltRight.maxPerSide.get(BodySideType.RIGHT) : 0);
        this.neckLinkTiltLeftValue = new Kg(neckLinkTiltLeft ? neckLinkTiltLeft.maxPerSide.get(BodySideType.LEFT) : 0);
    }
}
