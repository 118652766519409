import {Component, Input, OnInit} from '@angular/core';
import {DatasetStyle} from '../datasetStyle';
import {Chart, ChartDataSets, ChartOptions} from 'chart.js';
import {Rect} from '../../../models/rect';
import * as chartjsPluginAnnotation from 'chartjs-plugin-annotation';
import {BaseComponent} from "../../base/base.component";
import {Value} from "../../../utils/values/value";
import {KeyValue, TextUtils, Title} from "common";

@Component({
    selector: 'app-force-velocity-chart',
    templateUrl: './force-velocity-chart.component.html',
    styleUrls: ['./force-velocity-chart.component.scss']
})
export class ForceVelocityChartComponent extends BaseComponent implements OnInit {

    @Input()
    public xAxisLabels: number[];

    @Input()
    public xAxisTitle: Title;

    @Input()
    public yAxisLeftTitle: Title;

    @Input()
    public yAxisRightTitle: Title;

    @Input()
    public leftAxisData: KeyValue<DatasetStyle, Value[]>[] = [];

    @Input()
    public rightAxisData: KeyValue<DatasetStyle, Value[]>[] = [];

    @Input()
    public rightAxisHighlightRegion: Rect;

    public datasets: ChartDataSets[];
    public chartLabels: string[];
    public chartPlugins: any[];
    public chartOptions: ChartOptions;

    constructor() {
        super();
        Chart.pluginService.register(chartjsPluginAnnotation);
    }

    ngOnInit(): void {
        this.datasets = [];

        this.chartLabels = [];
        for (const datum of this.xAxisLabels) {
            this.chartLabels.push(datum.toFixed(1));
        }

        for (const datum of this.leftAxisData) {
            this.datasets.push({
                data: datum.value.map(v => v.value),
                label: datum.key.title,
                spanGaps: true,
                yAxisID: 'y-axis-left',

                borderColor: datum.key.color,
                backgroundColor: 'transparent',
                borderWidth: 3,
                pointRadius: 0,
                pointHoverRadius: 0,
            });
        }

        for (const datum of this.rightAxisData) {
            this.datasets.push({
                data: datum.value.map(v => v.value),
                label: datum.key.title,
                spanGaps: true,
                yAxisID: 'y-axis-right',

                borderColor: datum.key.color,
                backgroundColor: 'transparent',
                borderWidth: 3,
                pointRadius: 0,
                pointHoverRadius: 0,
            });
        }

        this.chartOptions = {
            animation: {
                duration: 0,
                animateScale: false,
                animateRotate: false
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            devicePixelRatio: 2,
            tooltips: {
                enabled: false
            },
            legend: {
                display: this.datasets.length > 0,
                align: 'end',
                position: 'bottom',
                labels: {boxWidth: 1}
            },
            scales: {
                yAxes: [{
                    id: 'y-axis-left',
                    scaleLabel: {labelString: this.yAxisLeftTitle, display: !TextUtils.isEmpty(this.yAxisLeftTitle)}
                }, {
                    id: 'y-axis-right',
                    position: 'right',
                    gridLines: {display: false},
                    scaleLabel: {labelString: this.yAxisRightTitle, display: !TextUtils.isEmpty(this.yAxisRightTitle)}
                }],
                xAxes: [{
                    id: 'x-axis-0', ticks: {
                        autoSkip: true, maxRotation: 0, autoSkipPadding: 20,
                        callback: function (value, index, values) {
                            return Number(value).toFixed(1);
                        }
                    }, scaleLabel: {labelString: this.xAxisTitle, display: !TextUtils.isEmpty(this.xAxisTitle)}
                }]
            },
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    font: {size: 16},
                },
            }
        };

        if (this.rightAxisHighlightRegion) {
            this.chartOptions.annotation = {
                annotations: [{
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y-axis-right',
                    value: this.rightAxisHighlightRegion.top,
                    borderColor: 'red',
                    borderDash: [3],
                    borderWidth: 2
                }, {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    value: this.getVerticalAnnotationValueX(),
                    borderColor: 'red',
                    borderDash: [3],
                    borderWidth: 2,
                }]
            }
        }
    }

    private getVerticalAnnotationValueX(): number {
        // returns the index on the axis x where the value is the max

        // find the first xaxis value that is smaller than the target and return its index
        let index = this.xAxisLabels.findIndex(v => v > this.rightAxisHighlightRegion.left);
        if (index > 0) {
            index = index - 1;
        }
        return index;
    }
}
