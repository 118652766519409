import {SquatCycle} from "./squatCycle";
import {BalanceDistributionConfigViewModel} from "./balanceDistributionConfigViewModel";
import {StanceEvaluationActivityResultsModel} from "./stanceEvaluationActivityResultsModel";
import {Dto} from "common";

export class AdvancedSquatAnalysisResultsModel extends Dto {
    public duration: number; //secs

    public timeStamps: number[];
    public leftForces: number[]; // list of the total left forces
    public rightForces: number[]; // list of the total right forces
    public sensAngles: number[];
    public powers: number[];

    public smallLoadsLeftLimbRatio: number;
    public normalLoadsLeftLimbRatio: number;
    public bigLoadsLeftLimbRatio: number;

    public maxForce: number; // Kg
    public maxLeftForce: number; // Kg
    public maxRightForce: number; // Kg

    public avgLeftDistribution: number; // [0-100] %
    public minLeftDistribution: number; // [0-100] %
    public maxLeftDistribution: number; // [0-100] %

    public standardDeviation: number;
    public leftDeviceMean: number; // mean of left forces, kg
    public rightDeviceMean: number; // mean of right forces, kg

    public squatsDone: SquatCycle[];
    public pace: number; // squats / min

    public balanceDistributionConfig: BalanceDistributionConfigViewModel;
    public weight: number; // Kg, added just a default in case it can't be retrieved (for activities of benoit's tests before we added weight calculation)

    public stanceResults: StanceEvaluationActivityResultsModel;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input.squatsDone) {
            this.squatsDone = this.deserializeArray(input.squatsDone, SquatCycle);
        }

        if (input.balanceDistributionConfig) {
            this.balanceDistributionConfig = new BalanceDistributionConfigViewModel().deserialize(input.balanceDistributionConfig);
        }

        if (input.stanceResults) {
            this.stanceResults = new StanceEvaluationActivityResultsModel().deserialize(input.stanceResults);
        }

        return this;
    }
}
