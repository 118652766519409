/**
 * Annotations are the horizontal lines that we display for Targets or Participant weight on the graphs
 */

import {Value} from "../../utils/values/value";
import {Color, Title} from "common";

export class Annotation {
    public title: Title;
    public value: Value;
    public color: Color;
    public borderDash: number[] = [3];
    public borderDashOffset: number = 0;

    public static of(title: Title, value: Value, color: Color): Annotation {
        const a = new Annotation();
        a.title = title;
        a.value = value;
        a.color = color;
        return a;
    }
}
