import {Component, OnInit} from '@angular/core';
import {AccountRole, Title} from "common";
import {BaseComponent} from "../base/base.component";
import {DevGuard} from "../../guards/dev-guard.service";

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends BaseComponent implements OnInit {

    public readonly accountRoleType: typeof AccountRole = AccountRole;
    public title: Title;
    public toolbarVisible: boolean;

    constructor(public devGuard: DevGuard) {
        super();
    }

    ngOnInit(): void {
        this.title = 'Kinvent Connect';

        this.toolbarVisible = this.userAccount && this.navigation.toolbarVisible;
    }

    public get fullName() {
        return this.authenticationService.account.firstName;
    }

    onProfileSelected() {
        this.navigation.goToProfile();
    }

    onLogoutSelected() {
        this.authenticationService.logout();
    }

    onTitlePress() {
        this.navigation.goToHome();
    }

    public isAccountRole(role: AccountRole): boolean {
        return this.authenticationService.account.accountRoleType === role;
    }

    public onDevClick(): void {
        this.navigation.goToDevPlayground();
    }

    onParticipantsPress() {
        this.navigation.goToHome();
    }
}
