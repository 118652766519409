import {Component, Input, OnInit} from '@angular/core';
import {ProtocolDto} from '../../../dtos/protocol.dto';
import {ProtocolAnalysisServiceResponseDto} from '../../../dtos/statistics/protocolAnalysisServiceResponseDto';
import {BodySideType} from '../../../models/bodySideType';
import {BuiltinProtocols} from '../../../models/builtinProtocols';
import {MeterEnduranceActivityResultsModel} from '../../../dtos/statistics/meterEnduranceActivityResultsModel';
import {Biomechanics} from '../../../utils/biomechanics';
import {MeterEnduranceStatisticsRowViewModel} from '../../../viewmodels/meterEnduranceStatisticsRowViewModel';
import {Protocols} from '../../../utils/protocols';
import {BaseComponent} from "../../base/base.component";
import {Value} from "../../../utils/values/value";
import {Kg} from "../../../utils/values/kg";

@Component({
    selector: 'app-protocol-summary-iyt-results',
    templateUrl: './protocol-summary-iyt-results.component.html',
    styleUrls: ['./protocol-summary-iyt-results.component.scss']
})
export class ProtocolSummaryIytResultsComponent extends BaseComponent implements OnInit {
    @Input()
    public protocol: ProtocolDto;

    @Input()
    public analysis: ProtocolAnalysisServiceResponseDto;

    public maxPerPositionTitles: string[];
    public maxPerPositionData: Map<BodySideType, Value>[];
    public iDeficitFormatted: string;
    public yDeficitFormatted: string;
    public tDeficitFormatted: string;

    public iRepsViewmodel: MeterEnduranceStatisticsRowViewModel[];
    public yRepsViewmodel: MeterEnduranceStatisticsRowViewModel[];
    public tRepsViewmodel: MeterEnduranceStatisticsRowViewModel[];

    constructor() {
        super();
    }

    ngOnInit(): void {
        // Max force per position
        this.maxPerPositionTitles = [];
        this.maxPerPositionTitles.push('I');
        this.maxPerPositionTitles.push('Y');
        this.maxPerPositionTitles.push('T');
        let iLeft = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_I, BodySideType.LEFT);
        let iRight = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_I, BodySideType.RIGHT);
        let yLeft = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_Y, BodySideType.LEFT);
        let yRight = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_Y, BodySideType.RIGHT);
        let tLeft = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_T, BodySideType.LEFT);
        let tRight = Protocols.findResultPerProtocolActivityCodeSide(this.analysis, BuiltinProtocols.ACTIVITY_IDENTIFIERS.ASH_IYT_T, BodySideType.RIGHT);

        const iData = new Map<BodySideType, Value>();
        iData.set(BodySideType.LEFT, new Kg(this.getActivityMax(iLeft, BodySideType.LEFT) || 0));
        iData.set(BodySideType.RIGHT, new Kg(this.getActivityMax(iRight, BodySideType.RIGHT) || 0));
        const yData = new Map<BodySideType, Value>();
        yData.set(BodySideType.LEFT, new Kg(this.getActivityMax(yLeft, BodySideType.LEFT) || 0));
        yData.set(BodySideType.RIGHT, new Kg(this.getActivityMax(yRight, BodySideType.RIGHT) || 0));
        const tData = new Map<BodySideType, Value>();
        tData.set(BodySideType.LEFT, new Kg(this.getActivityMax(tLeft, BodySideType.LEFT) || 0));
        tData.set(BodySideType.RIGHT, new Kg(this.getActivityMax(tRight, BodySideType.RIGHT) || 0));
        this.maxPerPositionData = [iData, yData, tData];

        this.iDeficitFormatted = Value.formatOrEmptyValue(Biomechanics.deficit(iData.get(BodySideType.LEFT), iData.get(BodySideType.RIGHT)), 2);
        this.yDeficitFormatted = Value.formatOrEmptyValue(Biomechanics.deficit(yData.get(BodySideType.LEFT), yData.get(BodySideType.RIGHT)), 2);
        this.tDeficitFormatted = Value.formatOrEmptyValue(Biomechanics.deficit(tData.get(BodySideType.LEFT), tData.get(BodySideType.RIGHT)), 2);

        // Reps
        // Activities are performed separately, combine them to one result for display
        this.iRepsViewmodel = [];
        if (iLeft) {
            this.iRepsViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(iLeft.repResults[0]));
        }
        if (iRight) {
            this.iRepsViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(iRight.repResults[0]));
        }

        this.yRepsViewmodel = [];
        if (yLeft) {
            this.yRepsViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(yLeft.repResults[0]));
        }
        if (yRight) {
            this.yRepsViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(yRight.repResults[0]));
        }
        this.tRepsViewmodel = [];
        if (tLeft) {
            this.tRepsViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(tLeft.repResults[0]));
        }
        if (tRight) {
            this.tRepsViewmodel.push(MeterEnduranceStatisticsRowViewModel.fromMeterEnduranceActivityRepResultsModel(tRight.repResults[0]));
        }
    }

    private getActivityMax(activity: MeterEnduranceActivityResultsModel, side: BodySideType): number | undefined {
        if (!activity) {
            return undefined;
        }
        return activity.maxPerSide.get(side);
    }
}
