import {ActivityResultsModel} from "./activityResultsModel";
import {StaticDistributionRepResultsModel} from "./staticDistributionRepResultsModel";

export class StaticDistributionActivityResultsModel extends ActivityResultsModel {
    public repResults: StaticDistributionRepResultsModel[];

    deserialize(input: any): this {
        Object.assign(this, input);

        this.repResults = [];
        if (input.repResults) {
            for (const repDatum of (input.repResults as any)) {
                this.repResults.push(new StaticDistributionRepResultsModel().deserialize(repDatum));
            }
        }
        return this;
    }

}
