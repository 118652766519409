import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-results-title',
    templateUrl: './results-title.component.html',
    styleUrls: ['./results-title.component.scss']
})
export class ResultsTitleComponent {
    @Input()
    public title: string;
}
