import {Component, OnInit} from '@angular/core';
import {ResultsComponent} from "../resultsComponent";
import {IsometryActivityResultsModel} from "../../../dtos/statistics/isometryActivityResultsModel";
import {ActivityDto} from "../../../dtos/activity.dto";
import {ActivityAnalysisServiceResponseDto} from "../../../dtos/statistics/activityAnalysisServiceResponseDto";
import {ActivityAnalysisResultDto} from "../../../dtos/statistics/activityAnalysisResultDto";
import {Resource} from "common";
import {ActivityImageResolverService} from "../../../services/activity-image-resolver.service";
import {BodySideType} from "../../../models/bodySideType";
import {Sample} from "../../../utils/sample";
import {SampleUtils} from "../../../utils/sampleUtils";
import {ChartAreaViewModel} from "../../../viewmodels/chartAreaViewModel";
import {Activities} from "../../../utils/activities";
import {Value} from "../../../utils/values/value";
import {UnitType} from "../../../utils/values/unitType";
import {Percent} from "../../../utils/values/percent";
import {Colors} from "../../../services/colors";

@Component({
    selector: 'app-isometry-results',
    templateUrl: './isometry-results.component.html',
    styleUrls: ['./isometry-results.component.scss']
})
export class IsometryResultsComponent extends ResultsComponent implements OnInit {
    public activity: ActivityDto;
    public activityAnalysis: ActivityAnalysisResultDto;
    public statistics: IsometryActivityResultsModel;

    public image: Resource;
    public maxPerSide: Map<BodySideType, Value>;
    public repChartData: Map<string, Map<[BodySideType, UnitType], Sample[]>>;
    public repChartArea: Map<string, ChartAreaViewModel>;

    constructor(private activityImageResolverService: ActivityImageResolverService) {
        super();
    }

    ngOnInit(): void {
        if (!(this.analysis instanceof ActivityAnalysisServiceResponseDto)) {
            console.log(`Unsupported analysis. JumpAnalysisResultsComponent`, this.analysis);
            return;
        }

        this.activity = this.activities[0];
        this.activityAnalysis = this.analysis.activitiesResults[0];
        this.statistics = this.activityAnalysis.activityResultsModel as IsometryActivityResultsModel;
        const statistics = this.statistics;

        this.image = this.activityImageResolverService.do(this.activity.image);

        // max per side
        this.maxPerSide = new Map<BodySideType, Value>();
        for (const sideData of statistics.maxSuccessRatePerSide) {
            this.maxPerSide.set(sideData.side, new Percent(sideData.value));
        }

        this.repChartData = new Map<string, Map<[BodySideType, UnitType], Sample[]>>();
        this.repChartArea = new Map<string, ChartAreaViewModel>();
        for (const rep of statistics.repResults) {
            const map = new Map<[BodySideType, UnitType], Sample[]>();
            map.set([rep.repSide, Activities.getUnitType(this.activity)], SampleUtils.createSampleCollection(rep.deltas, rep.forces));
            this.repChartData.set(rep.repOrdinal + rep.repSide, map);

            const area = new ChartAreaViewModel();
            area.yMin = rep.bottomBoundTarget;
            area.yMax = rep.upperBoundTarget;
            area.borderColor = Colors.RED;
            area.backgroundColor = Colors.GREEN_PASTEL;
            this.repChartArea.set(rep.repOrdinal + rep.repSide, area);
        }
    }
}
