import {Component, Input, OnInit} from '@angular/core';
import {BodySideType, BodySideTypes} from '../../../models/bodySideType';
import {MeterEnduranceStatisticsRowViewModel} from "../../../viewmodels/meterEnduranceStatisticsRowViewModel";
import {BodyParts} from "../../../models/bodyPart";
import {Color, Title} from "common";
import {ViewMediumType} from "../../results/viewMediumType";
import {BaseComponent} from "../../base/base.component";
import {UnitType, UnitTypes} from "../../../utils/values/unitType";
import {Value} from "../../../utils/values/value";
import {KeyValue} from "common";

@Component({
    selector: 'app-meter-endurance-row-stats',
    templateUrl: './meter-endurance-row-stats.component.html',
    styleUrls: ['./meter-endurance-row-stats.component.scss']
})
export class MeterEnduranceRowStatsComponent extends BaseComponent implements OnInit {

    @Input()
    public repetitionResults: MeterEnduranceStatisticsRowViewModel[];

    @Input()
    public mediumType: ViewMediumType;

    public titles: Title[];
    public data: KeyValue<[Title, Color], string[]>[];
    public compact: boolean;

    constructor() {
        super();
        this.titles = [];
        this.titles.push(this.format('exercise.endurance.results.averageTitle', UnitTypes.symbol(UnitType.KG)));
        this.titles.push(this.format('exercise.endurance.results.rfdTitle', UnitTypes.symbol(UnitType.KG)));
        this.titles.push(this.text('exercise.endurance.results.timeToMaxTitle'));
        this.titles.push(this.format('exercise.endurance.results.fatigueTitle', UnitTypes.symbol(UnitType.KG)));
    }

    ngOnInit(): void {
        this.data = [];
        for (const rep of this.repetitionResults) {
            let sideFormatted: string;
            if ([BodySideType.NONE, BodySideType.BOTH].includes(rep.side)) {
                sideFormatted = BodyParts.format(rep.bodyPart, rep.side);
            } else {
                sideFormatted = BodySideTypes.format(rep.side);
            }
            const color = BodySideTypes.color(rep.side);

            const sideValues: string[] = [];
            sideValues.push(this.formatValue(new Value(rep.averageValue), 1));
            sideValues.push(this.formatValue(new Value(rep.rfdToMax), 1));
            sideValues.push(this.formatValue(new Value(rep.timeToMax / 1000), 1));
            sideValues.push(this.formatValue(new Value(rep.rfdFromMax), 1));
            this.data.push(KeyValue.of([sideFormatted, color], sideValues));
        }
        this.compact = this.mediumType === ViewMediumType.PRINT;
    }
}
