<div class="d-flex flex-column align-items-center">
    <div *ngIf="kneeExtensionActivity !== undefined" class="section full-width">
        <div class="title pb-3">{{kneeExtensionActivityTitle}}</div>
        <div class="d-flex flex-column">
            <div class="d-flex flex-row">
                <div class="image me-3">
                    <app-image [image]="kneeExtensionImage" [lazily]="false"
                               [description]="kneeExtensionActivityTitle"></app-image>
                </div>
                <div class="max-per-side flex-grow-1 flex-column">
                    <app-max-per-side-chart [maxPerSide]="kneeExtensionMaxPerSide"></app-max-per-side-chart>
                </div>
            </div>
            <div class="repetitions mt-5">
                <div>
                    <app-meter-endurance-statistics-row class="d-inline-block full-width"
                                                        displayRepetitionOrdinal="false"
                                                        [repetitionResults]="kneeExtensionViewmodel"
                    >
                    </app-meter-endurance-statistics-row>
                    <mat-divider class="mt-4 mb-4"></mat-divider>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="kneeFlexionActivity !== undefined" class="section full-width">
        <div class="title pb-3">{{kneeFlexionActivityTitle}}</div>
        <div class="d-flex flex-column">
            <div class="d-flex flex-row">
                <div class="image me-3">
                    <app-image [image]="kneeFlexionImage" [lazily]="false"
                               [description]="kneeFlexionActivityTitle"></app-image>
                </div>
                <div class="max-per-side flex-grow-1 flex-column">
                    <app-max-per-side-chart [maxPerSide]="kneeFlexionMaxPerSide"></app-max-per-side-chart>
                </div>
            </div>
            <div class="repetitions mt-5">
                <div>
                    <app-meter-endurance-statistics-row class="d-inline-block full-width"
                                                        displayRepetitionOrdinal="false"
                                                        [repetitionResults]="kneeFlexionViewmodel"
                    >
                    </app-meter-endurance-statistics-row>
                    <mat-divider class="mt-4 mb-4"></mat-divider>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="kneeFlexionActivity !== undefined && kneeExtensionActivity !== undefined" class="section full-width">
        <div class="d-flex flex-row">
            <div class="d-flex flex-column table-column">
                <app-table-title centerText="true"
                                 [color]="colors.WHITE">{{text("activity")}}</app-table-title>
                <app-table-value
                    [color]="colors.GRAY_600">{{activityTitle(kneeExtensionActivity)}}</app-table-value>
                <app-table-value [color]="colors.GRAY_600">{{activityTitle(kneeFlexionActivity)}}</app-table-value>
                <app-table-value [color]="colors.GRAY_600">{{text("ratios")}}</app-table-value>
            </div>
            <div class="d-flex flex-column table-column">
                <app-table-title centerText="true" [sideForBackgroundColor]="sideTypes.LEFT"
                                 [color]="colors.WHITE">{{formatSideAttribute(sideTypes.LEFT, sideTypeAttributes.TITLE)}}</app-table-title>
                <app-table-value
                    [sideForColor]="sideTypes.LEFT">{{kneeExtensionMaxLeftFormatted}}</app-table-value>
                <app-table-value
                    [sideForColor]="sideTypes.LEFT">{{kneeFlexionMaxLeftFormatted}}</app-table-value>
                <app-table-value
                    [sideForColor]="sideTypes.LEFT">{{ratioLeftFormatted}}</app-table-value>
            </div>
            <div class="d-flex flex-column table-column">
                <app-table-title centerText="true" [color]="colors.WHITE">{{text("deficit")}}</app-table-title>
                <app-table-value
                    [color]="colors.GRAY_600">{{kneeExtensionDeficitFormatted}}</app-table-value>
                <app-table-value
                    [color]="colors.GRAY_600">{{kneeFlexionDeficitFormatted}}</app-table-value>
                <app-table-value
                    [color]="colors.GRAY_600">{{ratioDeficitFormatted}}</app-table-value>
            </div>
            <div class="d-flex flex-column table-column">
                <app-table-title centerText="true" [sideForBackgroundColor]="sideTypes.RIGHT"
                                 [color]="colors.WHITE">{{formatSideAttribute(sideTypes.RIGHT, sideTypeAttributes.TITLE)}}</app-table-title>
                <app-table-value
                    [sideForColor]="sideTypes.RIGHT">{{kneeExtensionMaxRightFormatted}}</app-table-value>
                <app-table-value
                    [sideForColor]="sideTypes.RIGHT">{{kneeFlexionMaxRightFormatted}}</app-table-value>
                <app-table-value
                    [sideForColor]="sideTypes.RIGHT">{{ratioRightFormatted}}</app-table-value>
            </div>
        </div>
    </div>
</div>
